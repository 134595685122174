import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JSONUtil } from '@utils/json-util';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface MapOfURLs {
  [slug: string]: { url: string; isLoading: boolean };
}

@Injectable()
export class PolicyService {
  private cachedPrivacyPolicy: string[];

  constructor(
    private readonly http: HttpClient,
    private readonly apollo: Apollo
  ) {}

  getPrivacyPolicy(): Observable<string[]> {
    if (this.cachedPrivacyPolicy) {
      return of(this.cachedPrivacyPolicy);
    } else {
      return this.http
        .get<string[]>('assets/custom_data/privacy-policy.json')
        .pipe(tap((data) => (this.cachedPrivacyPolicy = data)));
    }
  }

  getTermByGymUnitSlugObservable(gymUnitSlug: string): Observable<MapOfURLs> {
    if (!gymUnitSlug.length) {
      return of({});
    }

    const queryBody = `term${0}: getSubscriptionTermBySlug(gymUnitSlug: "${gymUnitSlug}") { url }`;

    return this.apollo
      .query<any>({
        query: gql`query { ${queryBody} }`,
      })
      .pipe(
        map((json) => {
          const terms: MapOfURLs = {};
          const result = JSONUtil.turnApolloMutable<MapOfURLs>()(json);

          terms[gymUnitSlug] = result[`term${0}`];
          terms[gymUnitSlug].isLoading = false;
          return terms;
        })
      );
  }
}
