export class JSONUtil {

  static get(json: any, key: string): any {
    try {
      let result = json;
      const keys = key.split('.');
      keys.forEach(k => result = result[k]);
      return result;
    } catch (e) {
      console.error('Json Parse Error:', e.message, `- Asked for [${key}] from`, json);
      return null;
    }
  }

  static exists = (val: any) => typeof val !== 'undefined' && val !== null;  // exists(0) => true
  static truthy = (val: any) => typeof val !== 'undefined' && val !== null && Boolean(val);  // truthy(0) => false

  static getNumber(json: any, key: string) {
    return Number.parseInt(JSONUtil.get(json, key), 10);
  }

  static getDate(json: any, key: string): Date {
    const date: string = JSONUtil.get(json, key);
    if (date) {
      return new Date(Number.parseInt(date.slice(6, -7), 10));
    } else {
      return null;
    }
  }

  static turnApolloMutable<T>(key?: string): (immutable: any) => T {
    if (key) {
      return (immutable: any): T => JSON.parse(JSON.stringify(immutable.data[key])) as T;
    } else {
      return (immutable: any): T => JSON.parse(JSON.stringify(immutable.data)) as T;
    }
  }
}
