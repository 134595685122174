<div class="plan-container h-100">
  <div class="plan border position-sticky">
    <div class="main-container">
      <div class="title title-section">
        {{ typeOfProduct === TypesOfProducts.Income ? data.planName : (data.portalName || data.plan) }}
        {{ data.payment }}
      </div>
      <div class="title-md gym-name">{{ data.gym.portalName }}</div>
      <div class="legal-container">
        <div>{{ data.gym.businessName }}</div>
        <div>CNPJ: {{ data.gym.cnpj }}</div>
      </div>
    </div>

    <div>
      <ng-container *ngTemplateOutlet="couponInputSection"></ng-container>

      <ul class="list-group list-group-flush text-uppercase">
        <li class="list-group-item d-flex justify-content-between border-line"
          [class.border-dashed]="couponData?.type === cupomType.plan">
          <ng-container *ngIf="typeOfProduct === TypesOfProducts.Plan">
            <ng-container *ngTemplateOutlet="planPriceInfo"></ng-container>
          </ng-container>
          <ng-container *ngIf="typeOfProduct === TypesOfProducts.Income">
            <ng-container  *ngTemplateOutlet="incomePriceInfo"></ng-container>
          </ng-container>
          <span>{{ (data.originalValue * quantity) / data.parcels | currency:'BRL':'symbol':'':locale }}</span>
        </li>

        <ng-container *ngTemplateOutlet="planDiscount"></ng-container>

        <ng-container *ngTemplateOutlet="adhesionInfo"></ng-container>

        <ng-container *ngTemplateOutlet="adhesionDiscount"></ng-container>

        <div class="resume-payment-block">
          <ng-container *ngIf="typeOfProduct === TypesOfProducts.Plan">
            <ng-container *ngTemplateOutlet="resumePaymentPlanBlock"></ng-container>
          </ng-container>
          <ng-container *ngIf="typeOfProduct === TypesOfProducts.Income && !couponData">
            <ng-container  *ngTemplateOutlet="resumePaymentIncomeBlock"></ng-container>
          </ng-container>
        </div>
      </ul>
      <ng-container *ngIf="typeOfProduct === TypesOfProducts.Income">
        <app-product-quantity-choicer
          [disableQuantitySelector]="disableQuantitySelector"
          [numberOfProducts]="quantity"
          (changeQuantityOfProducts)="changeQuantityOfProducts($event)"
        ></app-product-quantity-choicer>
      </ng-container>

      <div class="payment-info">
        <strong>Atenção:</strong> pagamento realizado apenas com Cartão de Crédito
      </div>
      <ng-content></ng-content>
    </div>

  </div>
</div>


<ng-template #adhesionInfo>
  <li class="list-group-item d-flex justify-content-between border-line"
    [class.border-dashed]="couponData?.type === cupomType.adhesion"
    *ngIf="(data.adhesion && data.adhesion !== 0) || (canaisData && canaisData?.adhesion !== 0)">
    <div class="dashed" *ngIf="couponData?.type === cupomType.adhesion"></div>
    <span>{{ data.adhesionText }}</span>
    <span>{{ data.adhesion | currency:'BRL':'symbol':'':locale }}</span>
  </li>
</ng-template>

<ng-template #adhesionDiscount>
  <ng-container *ngIf="couponData?.type === cupomType.adhesion">
    <li class="list-group-item d-flex justify-content-between border-dashed">
      <span class="w-75">DESCONTO {{ data.adhesionText }}</span>
      <span class="highlight">-{{ couponData.discount | currency:'BRL':'symbol':'':locale }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between"
      [class.income-coupon]="!data.adhesionText">
      <span class="income-coupon-parcels">{{ (data.adhesionText) || (data.parcels) }}
        <span *ngIf="!data.adhesionText">
          { data.parcels, plural, =1 { parcela } other { parcelas } } de
        </span>
      </span>
      <span class="income-coupon-value">
        {{ (data.adhesion - couponData.discount) || (couponData.parcels[data.parcels - 1]?.value) | currency:'BRL':'symbol':'':locale }}
      </span>
    </li>
  </ng-container>
</ng-template>

<ng-template #planDiscount>
  <ng-container *ngIf="couponData?.type === cupomType.plan">
    <li class="list-group-item d-flex justify-content-between border-dashed">
      <span>Desconto {{ !isDCC ? '' : !couponData?.monthDiscountCupom ? 'Mensal' : '(' +
        couponData?.monthDiscountCupom + ' Mens.)' }}</span>
      <span class="highlight">-{{ couponData.discount | currency:'BRL':'symbol':'':locale }}</span>
    </li>
    <li class="list-group-item d-flex justify-content-between border-dashed">
      <span *ngIf="!isDCC">{{ data.parcels }} { data.parcels, plural, =1 { parcela } other { parcelas } }
        de</span>
      <span *ngIf="isDCC">Mens. c/ desconto</span>
      <span>{{ (data.value / data.parcels) | currency:'BRL':'symbol':'':locale }}</span>
    </li>
  </ng-container>
</ng-template>

<ng-template #couponInputSection>
  <div
    class="d-flex coupon-container flex-column"
    [ngClass]="{
      'hidden position-absolute': !(showCoupon),
      'coupon-container-margin': !(couponData)
    }"
  >
    <button
      class="btn no-btn accordion-header d-flex align-items-center pl-2 pr-3"
      type="button"
      (click)="accordionHandler()"
    >
      <ng-container *ngIf="!couponData; else couponFilledTemplate">
        <span class="mr-auto">Cupom de desconto?</span>

        <img
          alt="Seta para {{ !isCouponContract ? 'cima' : 'baixo' }}"
          class="ic-caret"
          src="assets/icons/ic-caret-{{ !isCouponContract ? 'up' : 'down' }}.svg"
        >
      </ng-container>

      <ng-template #couponFilledTemplate>
        <span>Cupom</span>

        <img
          alt="Seta circular"
          class="ic-undo ml-2"
          src="assets/icons/ic-undo.svg"
        >

        <span class="coupon-filled ml-auto">{{ coupon }}</span>
      </ng-template>
    </button>

    <div [(appAccordion)]="isCouponContract">
      <div class="input-group p-2">
        <input
          id="registration-coupon"
          class="form-control light auto-height"
          placeholder="Digite seu cupom"
          (keydown.enter)="confirmButton.click()"
          [(ngModel)]="coupon"
          #inputComp
        >
        <div class="input-group-append d-block">
          <button
            id="registration-coupon-confirm"
            class="btn btn-primary d-flex justify-content-center"
            (click)="checkCoupon()"
            #confirmButton
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #planPriceInfo>
  <div class="dashed" *ngIf="couponData?.type === cupomType.plan && !isDCC"></div>
  <span *ngIf="!isDCC">{{ data.parcels }} { data.parcels, plural, =1 { parcela } other { parcelas } } de</span>
  <span *ngIf="isDCC">Mensalidade</span>
  <span class="ml-auto">{{ data.originalValue / data.parcels | currency:'BRL':'symbol':'':locale }}</span>
</ng-template>

<ng-template #resumePaymentPlanBlock>
  <li
    class="list-group-item list-group-item-total d-flex justify-content-between align-items-center border-0 font-weight-normal">
    <span>1ª Cobrança</span>
    <span class="total">{{ data.value | calculate:data.adhesion:data.parcels:couponData:canaisData?.adhesion !==
      0 | currency:'BRL':'symbol':'':locale }}</span>
  </li>
  <li *ngIf="couponData?.monthDiscountCupom && couponData?.monthDiscountCupom > 1"
    class="list-group-item list-group-item-total d-flex justify-content-between align-items-center border-0">
    <span class="sub-total">2ª {{ couponData?.monthDiscountCupom > 2 ? 'à ' + couponData?.monthDiscountCupom +
      'ª' : '' }} cobrança</span>
    <span class="total sub-total">{{ data.value / data.parcels | currency:'BRL':'symbol':'':locale }}</span>
  </li>
  <li class="list-group-item list-group-item-total d-flex justify-content-between align-items-center border-0"
    *ngIf="isDCC || data.parcels !== A_VISTA">
    <span class="sub-total">Demais cobranças</span>
    <span class="total sub-total">
      {{ data.originalValue | calculateOtherCharges:data.parcels:couponData | currency:'BRL':'symbol':'':locale
      }}</span>
  </li>
</ng-template>


<!--  Additional Income -->
<ng-template #incomePriceInfo>
  <div *ngIf="data.parcels" class="w-100 d-flex justify-content-between">
    <div class="dashed" *ngIf="couponData?.type === cupomType.adhesion"></div>
    <div>{{ data.parcels }} { data.parcels, plural, =1 { parcela } other { parcelas } } de</div>
    <div> {{ ((data.basePrice * quantity) / data.parcels) |  currency:'BRL':'symbol':'':locale }}</div>
  </div>
</ng-template>


<ng-template #resumePaymentIncomeBlock>
  <li
    class="list-group-item list-group-item-total d-flex justify-content-between align-items-center border-line font-weight-normal"
    >
    <span class="bt-pass-name">{{ data.planName }}</span>
    <span class="total">{{ (data.basePrice * quantity)| currency:'BRL':'symbol':'':locale }}</span>
  </li>
</ng-template>
