<div class="modal fade" bsModal #templateModal="bs-modal"
  (onHidden)="dismiss()"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title text-center">{{ modalObj.title }}</div>
      </div>
      <div class="modal-body text-center">
        {{ modalObj?.message }} <br>
        <p class="title mt-4 text-left">MOTIVO DO CANCELAMENTO</p>
        <p [class.error]="error" style="display: none;">O motivo do cancelamento deve ter entre 10 e 200 caractéres.</p>
        <textarea [(ngModel)]="cancelReason" class="w-100" name="textarea" cols="30" rows="5" minlength="10"></textarea>
      </div>
      <div class="modal-footer">
        <button *ngIf="modalObj?.cancelButton" type="button"
          class="col btn btn-outline-primary" (click)="cancel()">
          {{ modalObj.cancelButton || 'Cancelar' }}
        </button>
        <button type="button" class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || 'OK' }}
        </button>
      </div>
    </div>
  </div>
</div>
