import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ResolveResult } from './type-resolve-bundle';
import { AgendaAgeRange } from '@models';
import { AgeRangeService } from '@services';

@Injectable()
export class AgeRangeResolveGuard implements Resolve<AgendaAgeRange[]> {
  constructor(
    private readonly ageRangeService: AgeRangeService,
  ) {}

  resolve(): ResolveResult<AgendaAgeRange[]> {
    return this.ageRangeService.getAgeRangeList();
  }
}
