<div class="modal fade" bsModal #templateModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}"
    (onShow)="init()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog text-center" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title w-100">{{ modalObj.title }}</div>
      </div>
      <div class="modal-body">
        <div class="text-left">
          <div class="checkbox">
            <app-checkbox [(check)]="allChecked" message="Todos"></app-checkbox>
          </div>
          <div class="checkbox" *ngFor="let age of ages">
            <app-checkbox [(check)]="age.selected" [message]="age.name + ' (' + age.description + ')'"></app-checkbox>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="modal-age-confirm" type="button"
          class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || "OK" }}
        </button>
      </div>
    </div>
  </div>
</div>
