import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';
import { FaqComponent } from './faq.component';
import { FaqRoutingModule } from './faq.routing';
import { FaqCityComponent } from './faq-city/faq-city.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    FaqRoutingModule,
  ],
  declarations: [
    FaqComponent,
    FaqCityComponent,
  ],
})
export class FaqModule { }
