<div class="container">
  <div class="section">
    <div class="row">

      <div class="col-12 text-container">
        <h2 class="title-lg">O resultado é a trajetória</h2>
        <p>Sabemos que o resultado da atividade física não é um destino onde se chega. Não são só 10 quilos a menos ou 15% de massa magra.
        O resultado é na verdade uma trajetória, que já começa no dia seguinte ao primeiro treino, com um novo humor, com mais três segundos no recorde pessoal ou no prazer em sentir a água quentinha da piscina no corpo no fim de um dia de trabalho.</p>
      </div>

      <div class="col-12 col-lg-4 d-flex text-container">
        <div class="image-col">
          <img src="/assets/icons/ic-front-technology.svg">
        </div>
        <div class="text-col">
          <h3 class="title-md">Tecnologia</h3>
          <p>Dados, telas e botões garantem análises mais precisas e soluções mais personalizadas. Na Bodytech, a tecnologia aproxima cliente e equipe e deixa o dia a dia descomplicado.</p>
        </div>
      </div>

      <div class="col-12 col-lg-4 d-flex text-container">
        <div class="image-col">
          <img src="/assets/icons/ic-front-people.svg">
        </div>
        <div class="text-col">
          <h3 class="title-md">Gente</h3>
          <p>Não é só sobre salões, aparelhos de ponta ou serviço premium. Pessoas precisam de cuidado e afeto. Nós construímos relações que ultrapassam os muros da academia e fazem da BT uma família.</p>
        </div>
      </div>

      <div class="col-12 col-lg-4 d-flex text-container">
        <div class="image-col">
          <img src="/assets/icons/ic-front-structure.svg">
        </div>
        <div class="text-col">
          <h3 class="title-md">Estrutura</h3>
          <p>Nossos espaços e equipamentos garantem o conforto e a segurança imprescindíveis para um ambiente onde as pessoas precisam se desafiar, experimentar e ultrapassar seus limites.</p>
        </div>
      </div>
    </div>

  </div>
</div>
