export * from './activity.service';
export * from './address.service';
export * from './age-range.service';
export * from './agenda.service';
export * from './alert-message.service';
export * from './alert.service';
export * from './analytics.service';
export * from './auth.service';
export * from './banner.service';
export * from './benefit.service';
export * from './blog.service';
export * from './body-class.service';
export * from './brand.service';
export * from './breakpoints.service';
export * from './bt-company-page.service';
export * from './bt-pass.service';
export * from './corporate.service';
export * from './customer.service';
export * from './error.service';
export * from './evo';
export * from './faq-page.service';
export * from './faqbot.service';
export * from './footer.service';
export * from './franchise.service';
export * from './geolocation.service';
export * from './gym-unit-periodicity.service';
export * from './gym.service';
export * from './header.service';
export * from './http-cache.service';
export * from './imageHeader.service';
export * from './landing-kids.service';
export * from './loading.service';
export * from './modal.service';
export * from './my-contract.service';
export * from './my-online-class-schedules.service';
export * from './my-purchases.service';
export * from './partners.service';
export * from './plan.service';
export * from './policy.service';
export * from './portal-config.service';
export * from './press-release-page.service';
export * from './price.service';
export * from './private-partner.service';
export * from './registration-locking.service';
export * from './registration.service';
export * from './seo.service';
export * from './user-profile.service';
export * from './user-training.service';
export * from './user.service';
export * from './window-context.service';

import { ActivityService } from './activity.service';
import { AddressService } from './address.service';
import { AgeRangeService } from './age-range.service';
import { AgendaService } from './agenda.service';
import { AlertMessageService } from './alert-message.service';
import { AlertService } from './alert.service';
import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth.service';
import { BannerService } from './banner.service';
import { BenefitService } from './benefit.service';
import { BlogService } from './blog.service';
import { BodyClassService } from './body-class.service';
import { BrandService } from './brand.service';
import { BreakpointsService } from './breakpoints.service';
import { BtCompanyPageService } from './bt-company-page.service';
import { BTPassService } from './bt-pass.service';
import { CorporateService } from './corporate.service';
import { CustomerService } from './customer.service';
import { ErrorService } from './error.service';
import { FaqPageService } from './faq-page.service';
import { FaqBotService } from './faqbot.service';
import { FranchiseService } from './franchise.service';
import { GymUnitPeriodicityService } from './gym-unit-periodicity.service';
import { GymService } from './gym.service';
import { HeaderService } from './header.service';
import { ImageHeaderService } from './imageHeader.service';
import { LandingKidsService } from './landing-kids.service';
import { LoadingService } from './loading.service';
import { ModalService } from './modal.service';
import { MyContractService } from './my-contract.service';
import { MyOnlineClassSchedulesService } from './my-online-class-schedules.service';
import { MyPurchasesService } from './my-purchases.service';
import { MySchedulesService } from './my-schedules.service';
import { PartnersService } from './partners.service';
import { PlanService } from './plan.service';
import { PolicyService } from './policy.service';
import { PortalConfigService } from './portal-config.service';
import { PressReleasePageService } from './press-release-page.service';
import { PriceService } from './price.service';
import { PrivatePartnerService } from './private-partner.service';
import { RegistrationLockingService } from './registration-locking.service';
import { RegistrationService } from './registration.service';
import { SEOService } from './seo.service';
import { UserProfileService } from './user-profile.service';
import { UserTrainingService } from './user-training.service';
import { UserService } from './user.service';
import { WindowContextService } from './window-context.service';


export const services = [
  ActivityService,
  ActivityService,
  AddressService,
  AddressService,
  AgendaService,
  AgeRangeService,
  AlertMessageService,
  AlertService,
  AnalyticsService,
  AuthService,
  BannerService,
  BenefitService,
  BenefitService,
  BlogService,
  BodyClassService,
  BrandService,
  BreakpointsService,
  BtCompanyPageService,
  BTPassService,
  CorporateService,
  CustomerService,
  ErrorService,
  FaqBotService,
  FaqPageService,
  FranchiseService,
  GymService,
  GymUnitPeriodicityService,
  HeaderService,
  ImageHeaderService,
  LandingKidsService,
  LoadingService,
  ModalService,
  MyContractService,
  MyOnlineClassSchedulesService,
  MyPurchasesService,
  MySchedulesService,
  PartnersService,
  PlanService,
  PolicyService,
  PortalConfigService,
  PressReleasePageService,
  PriceService,
  PrivatePartnerService,
  RegistrationLockingService,
  RegistrationService,
  SEOService,
  UserProfileService,
  UserService,
  UserTrainingService,
  WindowContextService,
];
