import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ButtonType } from '@models/bt-home.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[nextClasses]',
  templateUrl: './next-classes.component.html',
  styleUrls: [
    '../../../area-user.component.scss',
    './next-classes.component.scss',
  ],
})
export class NextClassesComponent implements OnInit {

  @Input() button: ButtonType;
  @Input() classInfo: any;

  @Output() favoriteEmitter = new EventEmitter<any>();

  time: string;
  live = false;
  lessThanOneHour = false;
  showEasterEgg = false;
  classHour: number;

  eventDay: any;
  eventHour: any;

  constructor() { }

  ngOnInit() {
    this.time = this.tempoParaComeçarAula();

    const date = new Date(this.classInfo.formattedHour);
    this.classHour = date.getHours();
  }


  cardClick() {
    if (this.live) {
      window.open(this.classInfo.classUrl);
    } else {
      this.favoriteEmitter.emit(this.classInfo);
    }
  }

  // tslint:disable-next-line:cognitive-complexity
  tempoParaComeçarAula() {
    const today = new Date();
    const classDate = new Date(this.classInfo.formattedHour);

    this.eventDay = classDate.getDate();
    this.eventHour = classDate.getHours();

    setTimeout(() => {
      this.time = this.tempoParaComeçarAula();
    }, 60000);

    const diff = classDate.getTime() - today.getTime();
    const diffInHour = Math.floor(diff / 3600000);

    if (diff > 90000 /* 1m30s */) {
      if (diffInHour >= 24) {
        const days = Math.floor(diffInHour / 24);
        const hours = diffInHour % 24;
        return `Em ${ days }d ${ hours > 0 ? hours + 'h' : '' }`;
      } else {
        const minutes = Math.floor(((diff % 3600000) / 60000));
        if (diff / 3600000 > 0 && diff / 3600000 < 1) {
          this.lessThanOneHour = true;
          return `Em ${ Math.floor(minutes) }m`;
        } else {
          return `Em ${ diffInHour }h ${ Math.floor(minutes) > 0 ? Math.floor(minutes) + 'm' : '' }`;
        }
      }
    } else {
      this.live = true;
      return 'AO VIVO';
    }

  }
}
