import { Component } from '@angular/core';


@Component({
  selector: 'app-concept-results',
  templateUrl: './concept-results.component.html',
  styleUrls: [
    '../concept.component.scss',
    '../../styles/shared/content-header-component.scss',
  ],
})
export class ConceptResultsComponent {}
