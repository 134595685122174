import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-debug-view-id',
  templateUrl: './debug-view-id.component.html',
})
export class DebugViewIdComponent {

  mouseTarget: any;

  @HostListener('window:mouseover', ['$event'])
  enter(event: MouseEvent) {
    let target: any = event.target;
    while (!target.id && target.parentElement) {
      target = target.parentElement;
    }
    this.mouseTarget = target;
  }

}
