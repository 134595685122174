<ng-template #header>
  <app-header-static-image [headerObj]="config.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container">

  <div class="card row-on-xs no-side-border-xs">
    <app-tabs name="screen" [tabs]="tabs" [(activeTab)]="activeTab"></app-tabs>

    <div class="tab-content">
      <app-contract *appLazyCreate="activeTab == tabs[0]"></app-contract>
      <app-purchases *appLazyCreate="activeTab == tabs[1]"></app-purchases>
    </div>

  </div>
</div>

<app-modal-presale-details></app-modal-presale-details>
