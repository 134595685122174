import { AbstractControl } from '@angular/forms';

export class MatchValidation {

  static MatchPassword(AC: AbstractControl): any {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors({ matchPassword: true });
    } else {
      AC.get('confirmPassword').setErrors(null);
    }
    return null;
  }

  static MatchEmail(AC: AbstractControl): any {
    const email = AC.get('email').value; // to get value in input tag
    const confirmEmail = AC.get('confirmEmail').value; // to get value in input tag
    if (email !== confirmEmail) {
      AC.get('confirmEmail').setErrors({ matchEmail: true });
    } else {
      AC.get('confirmEmail').setErrors(null);
    }
    return null;
  }
}
