import { Component, Input } from '@angular/core';

import { BtPassPlanInfo } from '@models/bt-pass.model';


@Component({
  selector: 'app-bt-pass-plan-card',
  templateUrl: './bt-pass-plan-card.component.html',
  styleUrls: ['./bt-pass-plan-card.component.scss'],
})
export class BtPassPlanCardComponent {

  @Input() btpassPlan?: BtPassPlanInfo;

}
