<ng-template #header>
  <app-header-static-image 
    [pageTitle]="headerTitle"
    [imageUrl]="imageHeader"
  ></app-header-static-image>
</ng-template>

<div class="main-wrapper container">
  <div class="title-lg text-center">TREINE SEM SAIR DE CASA!</div>
  <div class="subtitle text-center">Com o Bodytech Home você assiste a Aulas Coletivas sem sair de casa! 
    Favorite aulas e monte sua rotina perfeita de exercícios.</div>
  <div class="card no-side-border-xs row-on-xs">

    <app-tabs name="screen" [tabs]="tabs" [(activeTab)]="activeTab"></app-tabs>

    <app-online-group-classes *ngIf="activeTab === tabs[0]"></app-online-group-classes>
    <app-my-workouts *ngIf="activeTab === tabs[1]"></app-my-workouts>
  </div>

</div>