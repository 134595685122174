<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<section class="container py-3 py-md-4">
  <h1 class="text-center title mt-0 mt-md-3 pt-3 pt-md-4 pb-1 pb-md-0">PAGUE APENAS PELOS DIAS QUE FOR TREINAR</h1>

  <p class="section-description ml-auto mr-auto mt-3 mb-md-4 pb-md-3 text-center">
    Você investe em pacote de passes e cada passe te dá o direito de treinar por um dia inteiro na Bodytech.
  </p>
</section>

<app-bt-pass-plans-list [btpassPlanList]="btPassListRO?.response" [btPassPlanLoading]="btPassListRO?.loading"></app-bt-pass-plans-list>
<app-modal-btpass-pass-info></app-modal-btpass-pass-info>

<section class="pt-3">
  <app-bt-pass-grid
    (selectedPassEmitter)="openPassInfoModal($event)"
    [btpassPlanList]="btPassListRO?.response"
    [btPassPlanLoading]="btPassListRO?.loading"
  ></app-bt-pass-grid>
</section>
