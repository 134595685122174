import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BrandGuard } from '@guards/brand.guard';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';

import { ActionBtComponent } from './action-bt/action-bt.component';
import { BtfitLandingpageComponent } from './btfit-landingpage/btfit-landingpage.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RedirectWithParameterComponent } from './shared/components/redirect-with-parameter/redirect-with-parameter.component';
import { UrlInternalRedirect } from './shared/utils/app-url-redirects';


// @ts-ignore
export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('app/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'btflexivel',
        component: ActionBtComponent,
        canActivate: [BrandGuard],
        resolve: {
          config: PortalConfigResolveGuard,
        },
        data: {
          windowTitle: 'Ação BT',
          windowContext: 'btflexivel',
          brand: 'bt',
          config: { links: 'btflexivel-links' },
        }
      },
      {
        path: 'btfit',
        component: BtfitLandingpageComponent,
        resolve: {
          config: PortalConfigResolveGuard,
        },
        data: {
          windowTitle: 'BTFIT',
          windowContext: 'terms',
          config: { terms: 'btfit-terms' },
        },
      },
      {
        path: 'academias',
        loadChildren: () => import('app/gyms/gyms.module').then(m => m.GymsModule)
      },
      {
        path: 'academias/:unitSlug/compra/:planSlug/:periodicitySlug',
        loadChildren: () => import('app/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'academia',
        redirectTo: 'academias',
      },
      {
        path: 'agenda',
        loadChildren: () => import('app/agenda/agenda.module').then(m => m.AgendaModule)
      },
      {
        path: 'cliente',
        loadChildren: () => import('app/area-user/area-user.module').then(m => m.AreaUserModule)
      },
      {
        path: 'login',
        redirectTo: UrlInternalRedirect.login,
        pathMatch: 'full',
      },
      {
        path: 'bodytech',
        loadChildren: () => import('app/bt-company/bt-company.module').then(m => m.BtCompanyModule)
      },
      {
        path: 'bt-pass',
        loadChildren: () => import('app/bt-pass/bt-pass.module').then(m => m.BtPassModule)
      },
      {
        path: 'formula',
        loadChildren: () => import('app/bt-company/bt-company.module').then(m => m.BtCompanyModule)
      },
      {
        path: 'kids',
        loadChildren: () => import('app/kids/kids.module').then(m => m.KidsModule)
      },
      {
        path: 'privacidade',
        loadChildren: () => import('app/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },
      // {
      //   path: 'imprensa',
      //   loadChildren: () => import('app/press-release/press-release.module').then(m => m.PressReleaseModule)
      // },
      {
        path: 'trabalhe-conosco',
        loadChildren: () => import('app/work-with-us/work-with-us.module').then(m => m.WorkWithUsModule)
      },
      {
        path: 'franqueado',
        loadChildren: () => import('app/franchise/franchise.module').then(m => m.FranchiseModule)
      },
      {
        path: 'central-privacidade',
        loadChildren: () => import('app/privacy-center/privacy-center.module').then(m => m.PrivacyCenterModule)
      },
      {
        path: 'contato',
        loadChildren: () => import('app/ticket/ticket.module').then(m => m.TicketModule)
      },
      {
        path: 'duvidas',
        redirectTo: 'faq',
      },
      {
        path: 'faq',
        loadChildren: () => import('app/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'conceito',
        loadChildren: () => import('app/concept/concept.module').then(m => m.ConceptModule)
      },
      {
        path: 'corporativo',
        loadChildren: () => import('app/corporate/corporate.module').then(m => m.CorporateModule)
      },
      /** This route is inactive on the footer, but we wont remove it from the project.
       *  This is commented so the user can't access it by the url. */
      // {
      //   path: 'private-partner',
      //   loadChildren: () => import('app/private-partner/private-partner.module').then(m => m.PrivatePartnerModule)
      // },

      // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado
      { path: 'ecommerce/cadastronovasenha',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.recoveryPassword,
        },
      },
      { path: 'ECommerce/cadastronovasenha',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.recoveryPassword,
        },
      },

      // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado
      { path: 'ECommerce/DesbloqueioConta',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.login,
        },
      },

       // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado FORMULA
      { path: 'ContaUsuario/DesbloquearUsuario',
       component: RedirectWithParameterComponent,
       data: {
         redirectTo: UrlInternalRedirect.login,
       },
      },

       // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado FORMULA
      { path: 'ContaUsuario/AlterarSenha',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.recoveryPassword,
        },
      },

     // Redirecionamento do link de cadastro de menor e estrangeiro
      { path: 'Ecommerce/CriarAcesso',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: '/cliente/novo',
        },
      },
      {
        path: '**',
        component: NotFoundComponent,
        resolve: {
          config: PortalConfigResolveGuard,
        },
        data: {
          windowTitle: '${data.config.notFound.header.title}',
          metaRobots: 'noindex,follow',
          config: { notFound: 'not-found' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
