import { Pipe, PipeTransform } from '@angular/core';
import { GymUnitPeriodicity, Gym } from '@models';
import { Brand } from '@services/brand.service';

@Pipe({
  name: 'sortPlans',
})
export class SortPlansPipe implements PipeTransform {
  private static planOrder: string[];

  private static setPlanOrder(gymUnit: Gym, brand: Brand) {
    const current_date = new Date();
    const initial_date = new Date(2020, 10, 1, 1, 0);
    const final_date = new Date(2020, 11, 1, 23, 59, 59);

    SortPlansPipe.planOrder =
      !!gymUnit.activePromotion &&
      current_date.getTime() >= initial_date.getTime() &&
      current_date.getTime() <= final_date.getTime() &&
      brand === 'fr'
        ? ['DÉBITO MENSAL', 'ANUAL', 'SEMESTRAL', 'TRIMESTRAL', 'MENSAL']
        : ['ANUAL', 'SEMESTRAL', 'TRIMESTRAL', 'MENSAL', 'DÉBITO MENSAL'];
  }

  transform(
    gymUnitPeriodicity: GymUnitPeriodicity[],
    gymUnit: Gym,
    brand: Brand,
  ): GymUnitPeriodicity[] {
    SortPlansPipe.setPlanOrder(gymUnit, brand);
    let extraIndex = SortPlansPipe.planOrder.length;

    return gymUnitPeriodicity
      .map((gup): [number, GymUnitPeriodicity] => {
        let idx = SortPlansPipe.planOrder.indexOf(
          gup.periodicity.name.toUpperCase(),
        );
        if (idx < 0) {
          idx = extraIndex;
          extraIndex++;
        }
        return [idx, gup];
      })
      .sort((a, b) => a[0] - b[0])
      .map(([_, gup]) => gup);
  }
}
