<div class="outside-container swiper-arrow-container position-relative" [class.formula-only]="brand === 'fr' && gymUnitGroupPlans?.length <= 2" *ngIf="gymUnitGroupPlans?.length; else noPlans">
  <div id="plans-prev" class="invert plans-prev swiper-button-prev swiper-arrow d-none d-md-block"></div>
  <div id="plans-next" class="invert plans-next swiper-button-next swiper-arrow d-none d-md-block"></div>

  <div class="container">
    <div
      class="swiper-container"
      [swiper]="swiperConfig"
      [class.plan-center]="gymUnitGroupPlans.length === 2"
    >
      <div #planSwiperContainer class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let gymUnitGroupPlan of gymUnitGroupPlans; let first = first" [class.first-group]="first">
          <app-gym-detail-plan
            *ngIf="gymUnitGroupPlan"
            [gymUnitGroupPlan]="gymUnitGroupPlan"
            [(plansExpanded)]="plansExpanded"
            [isLandingPageKids]="true"
          >
          </app-gym-detail-plan>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</div>

<ng-template #noPlans>
  <div class="container">
    <app-empty-card
      icon="/assets/icons_custom/ic-empty-list.svg"
      altIcon="Lista vazia"
      message="Entre em contato com a unidade para mais informações de planos, atividades e valores."
    ></app-empty-card>
  </div>
</ng-template>
