<section *ngIf="(activitiesList$ | async) as activitiesList">
  <div class="container mt-5">
    <h1 class="text-center title">ATIVIDADES PARA EXPERIMENTAR</h1>
    <div class="section-description ml-auto mr-auto mt-4 mb-4 text-center">
      São várias opções de aulas disponíveis e todas dentro dos protocolos de segurança e com equipes experientes.
      Venha trazer seu pequeno para uma <b>aula experimental</b>.
    </div>
  </div>
  <div class="outside-container swiper-arrow-container position-relative">
    <div id="activity-prev" class="invert activity-prev swiper-button-prev swiper-arrow d-none d-md-block"></div>
    <div id="activity-next" class="invert activity-next swiper-button-next swiper-arrow d-none d-md-block"></div>
  
    <div class="container">
      <div class="swiper-container" [swiper]="swiperConfig">
        <div #planSwiperContainer class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let activity of activitiesList">
            <app-activity-card [activityItem]="activity"></app-activity-card>
          </div>
        </div>
        <div class="d-md-none swiper-pagination"></div>
      </div>
    </div>
  </div>  
</section>