<main class="main-wrapper container justify-content-around d-md-flex">
  <div class="card small-card row-on-xs no-side-border-xs p-4 p-md-5 mb-4">
    <div class="title-section">Agendar Trancamento</div>

    <div class="mb-3">
      {{ isDCC ? '' : 'Selecione uma data para continuar.'}}
    </div>

    <div [formGroup]="form" *ngIf="minDate">
      <label for="locking-initial-date" class="label-input">DATA INICIAL DO TRANCAMENTO</label>
      <p *ngIf="!isDCC && minDate.getTime() > maxDate.getTime()" class="error">Não há dias disponíveis para trancamento.</p>
      <div fxLayout="row" class="form-group mb-4" id="group-initial-date" (click)="picker.open()">
        <input matInput  [min]="minDate" [matDatepickerFilter]="isDCC ? allowedDatesFilter : '' " [max]="!isDCC ? maxDate : ''" placeholder="Selecione uma opção"
          formControlName="lockingInitialDate" [matDatepicker]="picker" id="initial-date" name="initial-date" class="form-control select-initialDate" readonly>
        <mat-datepicker-toggle class="toggle-picker" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="year" [dateClass]="isDCC ? dateClass : null" class="date-picker"></mat-datepicker>
      </div>
      <app-error-message
        [control]="form.controls.lockingInitialDate"
      ></app-error-message>
    </div>
      <div *ngIf="isDCC" class="mt-4">
        <label class="label-input">PERÍODO DE TRANCAMENTO</label>
        <ng-select
          [items]="periodOptions"
          [searchable]="false"
          [formControl]="period"
          [clearable]="false"
          [selectOnTab]="true"
          bindLabel="optionName"
          placeholder="Selecione uma opção"
          class="mb-4"
          required
        ></ng-select>

        <article *ngIf="!isDCC; else articleDCC">
          Selecione o mês e o ano para iniciar o trancamento e sua duração.
          Você não será debitado nos meses de trancamento, voltando a ser debitado no mês seguinte ao trancamento.
          A data do trancamento será contabilizada a partir do dia de início selecionado.
        </article>
        <ng-template #articleDCC>
          <article>
            Selecione a data para iniciar o trancamento e sua duração. Você não será debitado nos meses de trancamento,
             voltando a ser debitado no mês seguinte ao trancamento. A data do trancamento será contabilizada a partir do dia de início selecionado.
          </article>
        </ng-template>
        </div>
      <div class="flex mt-4">
        <button type="button" class="btn btn-outline-primary w-100" routerLink="/cliente/trancamento" [state]="{contractCode: contractCode, gymUnitId: gymUnitId}">
          VOLTAR
        </button>
        <button type="submit" class="btn btn-primary w-100 ml-8 ml-md-24" (click)="lockingRegistration()">AGENDAR TRANCAMENTO</button>
      </div>

  </div>
</main>
