<div class="container">
  <div class="section">
    <h2 class="title-lg">Motivos para se tornar um franqueado bodytech</h2>

    <div class="row">
      <div class="col-12 col-lg-6 d-flex text-container">
        <div class="image-col">
          <img src="/assets/icons/ic-front-management-and-support.svg">
        </div>
        <div class="text-col">
          <h3 class="title-md">Gestão e suporte</h3>
          <p>Sistemas exclusivos que acompanham e monitoram o ciclo de relacionamento de cada aluno, desde a sua inscrição, até a renovação de contrato. Esse acompanhamento de perto garante mais eficiência.</p>
        </div>
      </div>

      <div class="col-12 col-lg-6 d-flex text-container">
        <div class="image-col">
          <img src="/assets/icons/ic-front-experience.svg">
        </div>
        <div class="text-col">
          <h3 class="title-md">Experiência</h3>
          <p>Bodytech Company é um dos 20 maiores grupos do mundo no setor fitness. Seus sócios acreditam que a Bodytech é um moderno conceito e uma excelente oportunidade de negócio.</p>
        </div>
      </div>

      <div class="col-12 col-lg-6 d-flex text-container">
        <div class="image-col">
          <img src="/assets/icons/ic-front-consulting.svg">
        </div>
        <div class="text-col">
          <h3 class="title-md">Consultoria multidisciplinar</h3>
          <p>O franqueado Bodytech conta com o apoio de um grupo experiente com diferentes especialidades que pode auxiliar em todo o processo de implementação do negócio.</p>
        </div>
      </div>

      <div class="col-12 col-lg-6 d-flex text-container">
        <div class="image-col">
          <img src="/assets/icons/ic-front-innovation-and-confiance.svg">
        </div>
        <div class="text-col">
          <h3 class="title-md">Inovação e confiança</h3>
          <p>Um modelo único e inovador com serviços diversificados e padronizados com a chancela de uma marca reconhecida pelo seu público-alvo como a melhor opção no mercado.</p>
        </div>
      </div>
    </div>

  </div>
</div>
