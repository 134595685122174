<div class="modal fade" bsModal #templateModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}" tabindex="-1"
  role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg text-center">
    <div class="modal-content">
      <div class="mb-3">
        O código de segurança (CVV) é o número adicional de 3 ou 4 dígitos localizado no verso ou na frente do seu
        cartão.
      </div>
      <img class="d-none d-md-block image" src="/assets/custom_images/img-payment-cvv.svg" alt="Exemplo Cartão de Crédito">

      <img class="d-md-none" src="/assets/custom_images/img-payment-cvv-mobile.svg" alt="Exemplo Cartão de Crédito">

      <div class="mt-5">
        <button id="modal-gym-cancel" type="button" class="col btn btn-primary" (click)="cancel()">OK</button>
      </div>
    </div>
  </div>
</div>
