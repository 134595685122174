<div class="modal fade" bsModal #templateModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title pull-left">{{ modalObj.title }}</div>
      </div>
      <div class="modal-body" [innerHTML]="modalObj.message"></div>
      <div class="modal-footer">
        <button type="button" class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || "OK" }}
        </button>
      </div>
    </div>
  </div>
</div>
