import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalKidsDetailObject, ModalType, Tab } from '@models';
import { ModalService } from '@services';
import { DateUtil } from '@utils/date-util';

import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-kids-details',
  templateUrl: './modal-kids-details.component.html',
  styleUrls: ['./modal-kids-details.component.scss'],
})
export class ModalKidsDetailsComponent extends ModalCommonComponent implements OnInit {

  modalObj: ModalKidsDetailObject;
  selectDateForm: FormGroup;
  selectedDateIndex = 0;
  availableHours: string[] = [];
  dateUtil = DateUtil;
  activeTab: Tab;
  kidsScheduleTabs: Tab[] = [
    {
      id: 1,
      name: 'Agendar'
    },
    {
      id: 2,
      name: 'Lista de espera'
    }
  ];

  constructor(
    protected modalService: ModalService,
    protected datePipe: DatePipe,
    protected formBuilder: FormBuilder,
  ) {
    super();
    this.type = ModalType.kidsDetails;

    this.subsModal = this.modalService.modalEmitter.subscribe((modalObj: ModalKidsDetailObject) => {
      if (modalObj && modalObj.type === this.type) {
        this.modalObj = modalObj;
        this.config = (this.modalObj.config || {});
      } else {
        this.modal.hide();
      }
    });
  }

  init() {
    this.activeTab = this.kidsScheduleTabs[0];

    this.selectDateForm = this.formBuilder.group({
      date: new FormControl(null, Validators.required),
      hour: new FormControl(null, Validators.required),
      event: new FormControl(null, Validators.required),
    });

    const bodyWrapper = document.getElementsByClassName('modal-body-wrapper')[0];
    setTimeout(() => bodyWrapper?.scrollTo(0, 0), 200);
  }

  get classEvent() {
    return this.selectDateForm.get('event');
  }

  selectClassToSchedule(selectedDate: string, selectedHour: string, selectedClass: any): void {
    this.selectDateForm.get('date').setValue(selectedDate);
    this.selectDateForm.get('hour').setValue(selectedHour);
    this.classEvent.setValue(selectedClass);

    this.confirm();
  }

  enterWaitingList(): void {
    this.selectDateForm.get('date').setValue('0');
    this.selectDateForm.get('hour').setValue('0');
    this.classEvent.setValue(this.modalObj.event);

    this.confirm();
  }

  confirm() {
    if (this.selectDateForm.valid) {
      this.modalObj.data = this.selectDateForm.value;
      this.clearForm();
      super.confirm();
    }
  }

  cancel() {
    this.clearForm();
    super.cancel();
  }

  dismiss() {
    this.clearForm();
    super.dismiss();
  }

  clearForm(): void {
    this.selectDateForm.reset();
  }
}
