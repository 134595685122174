import { BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';

export const enum ScreenSize {
  xs = 1,
  XS = xs,

  sm = 2,
  SM = sm,

  md = 3,
  MD = md,

  lg = 4,
  LG = lg,

  xl = 5,
  XL = xl,
}

export function screenSizeToString(size: ScreenSize) {
  const sizes = {
    [ScreenSize.xs]: 'xs',
    [ScreenSize.sm]: 'sm',
    [ScreenSize.md]: 'md',
    [ScreenSize.lg]: 'lg',
    [ScreenSize.xl]: 'xl',
  };
  return sizes[size];
}

export function screenSizeMap(size: ScreenSize, values: any[] = [0, 1, 2, 3, 4], fallback = 0) {
  const sizes = {
    [ScreenSize.xs]: values[0],
    [ScreenSize.sm]: values[1],
    [ScreenSize.md]: values[2],
    [ScreenSize.lg]: values[3],
    [ScreenSize.xl]: values[4],
  };
  return sizes[size] || fallback;
}

export function screenWidthMap(w: number, values: any[] = [0, 1, 2, 3, 4], fallback = 0) {
  /* Brute force way to rescale the pagination */
  if (w < 576) {
    return values[0] || fallback;
  } else if (w < 768) {
    return values[1] || fallback;
  } else if (w < 992) {
    return values[2] || fallback;
  } else if (w < 1200) {
    return values[3] || fallback;
  } else {
    return values[4] || fallback;
  }
}

function widthToScreensize(width: number): ScreenSize {
  return screenWidthMap(width, [ScreenSize.xs, ScreenSize.sm, ScreenSize.md, ScreenSize.lg, ScreenSize.xl]);
}

@Injectable()
export class BreakpointsService implements OnDestroy {
  public static screenSize = screenWidthMap;

  public readonly emitterEvery: BehaviorSubject<ScreenSize>;
  public readonly emitter: BehaviorSubject<ScreenSize>;

  private eventListenerObject: EventListenerObject = {
    handleEvent: (event: UIEvent) => {
      this.emitterEvery.next(widthToScreensize((event.target as Window).innerWidth));
    },
  };

  constructor() {
    const startValue = widthToScreensize(window.innerWidth);
    this.emitterEvery = new BehaviorSubject(startValue);
    this.emitter = new BehaviorSubject(startValue);
    this.emitterEvery.pipe(distinctUntilChanged()).subscribe(val => this.emitter.next(val));

    window.addEventListener('resize', this.eventListenerObject);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.eventListenerObject);
  }

}
