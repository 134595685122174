export const enum AlertMessageType {
  S = 'S',
  SUCCESS = S,
  E = 'E',
  ERROR = E,
  W = 'W',
  WARNING = W,
  I = 'I',
  INFO = I,
  D = 'D',
  DEBUG = D,
}

export const enum MessageMap {
  CAMPOS_EM_VERMELHO = 'Verifique os campos marcados em vermelho.',
  NECESSARIO_INFORMAR_CPF = 'Para continuar é necessário informar seu CPF.',
  TOKEN_NAO_ENCONTRADO = 'Não foi encontrado o token de validação. Tente novamente.',
  TELEFONE_REPETIDOS = 'Verifique os campos de telefone. Os números não podem ser repetidos.',
  CAMPO_NAO_SOU_ROBO = 'É necessário marcar o campo \'Não sou um robô\' para enviar o formulário.',
  ACEITAR_TERMO_ADESAO = 'É necessário aceitar o Termo de Adesão.',
  CEP_INVALIDO = 'CEP não encontrado',
  CEP_NAO_ENCONTRADO = 'O CEP {{0}} não foi encontrado.',
  DATA_NASCIMENTO_INVALIDA = 'Data de nascimento inválida.',
  CONFIRA_DADOS_FORNECIDOS = 'Confira os dados fornecidos.',
  SESSAO_EXPIRADA = 'Sessão expirada! Por favor realize novamente o login.',
  ACADEMIA_NAO_ENCONTRADA = 'Academia desejada não foi encontrada.',
  PLANO_NAO_ENCONTRADO = 'Plano desejado não foi encontrado.',
  PROBLEMA_ENVIO_EMAIL = 'Ocorreu um problema com o envio do e-mail. Tente novamente ou entre em contato com sua unidade.',
  SEM_CONEXAO_INTERNET = 'Não foi possível conectar. Verifique sua conexão e tente novamente mais tarde.',
  INFO_NAO_DISPONIVEIS = 'Suas informações não estão disponíveis no momento. Tente novamente mais tarde.',
  SERVIDOR_INDISPONIVEL = 'Lamentamos, mas o servidor está indisponível. Por favor, tente novamente mais tarde.',

  CONTA_ATIVADA = 'Sua conta foi ativada.',
  INFO_SALVAS = 'Suas informações foram salvas com sucesso.',
  CARTAO_TROCADO = 'Sua forma de pagamento foi alterada e seus dados serão atualizados em breve.',
  EMAIL_ENVIADO = 'E-mail enviado com sucesso. Verifique sua caixa de entrada.',
  PRE_VENDA_CANCELADA = 'Pré-Venda cancelada com sucesso. Foi enviado um e-mail para {{0}} com maiores detalhes.',

  ROUTE_TEST_DONE = 'Route test done!',
  AGENDA_DONE = 'Agenda: done!',
  TOKEN_GERADO = 'Token gerado {{0}}...',

  ERRO_ENVIAR_FORMULARIO = 'Ocorreu um erro ao tentar enviar o formulário. {{0}}',
  ERRO_PROCESSAMENTO = 'Erro de processamento: {{0}}',
  ATUALIZACAO_DCC_NAO_ACESSIVEL = 'Não há um contrato compatível com o plano informado.',

  AGENDAMENTO_INDISPONIVEL = 'Nenhum agendamento disponível para essa {{0}}.',

  PROBLEMA_CRIAR_USUARIO_MICROSSERVICO = 'Sua compra foi efetuada com sucesso, porém não foi possível criar seus dados de login. Aguarde alguns minutos.',
  USER_LEGACY_TOKEN_INDISPONÍVEL = 'Não foi possível obter suas informações, por favor tente novamente em alguns minutos.',

  NAO_POSSUI_CONTRATO_ATIVO = 'Erro ao acessar Agendamento. Não foi possível identificar um contrato associado à sua conta.',

  CARTAO_CREDITO_NAO_ENCONTRADO = 'Se o seu plano não tem débito automático no cartão de crédito, pediremos os dados apenas na hora da renovação para ativar o novo contrato.',

  VERIFIQUE_SEU_EMAIL = 'Tempo expirado. Verifique seu e-mail caso ainda não tenha assinado o contrato.',
  CONTRATO_ASSINADO = 'Este contrato já foi assinado.',
  CONTRATO_INDISPONIVEL = 'Seu contrato está indisponível no momento. Procure a unidade para mais informações.',
}

export class AlertMessage {
  constructor(
    public type: AlertMessageType,
    public msg: string,
  ) { }

  static success(msg: string) {
    return new AlertMessage(AlertMessageType.S, msg);
  }

  static error(msg: string) {
    return new AlertMessage(AlertMessageType.E, msg);
  }

  static warning(msg: string) {
    return new AlertMessage(AlertMessageType.W, msg);
  }

  static info(msg: string) {
    return new AlertMessage(AlertMessageType.I, msg);
  }

  static debug(msg: string) {
    return new AlertMessage(AlertMessageType.D, msg);
  }
}

export enum ToastType {
  E = 'toast-error',
  I = 'toast-info',
  S =  'toast-success',
  W = 'toast-warning',
}
