import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalType, Tab } from '@models';
import { HeaderService, ModalService } from '@services';

import { TabControllerComponent } from 'app/shared/abstracts/tab-controller.component';
import { BasicHeaderConfig } from '@models/configs';
import { ContractComponent } from '../finances/contract/contract.component';
import { docuSignCompleteMessages } from '@utils/docusign-transaction-status.util';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends TabControllerComponent {
  @ViewChild(ContractComponent) contractComponent: ContractComponent;

  public config: BasicHeaderConfig;

  readonly tabs: Tab[] = [
    { id: 0, name: 'Informações principais', icon: 'assets/icons/ic-dadospessoais-', url: '/cliente/perfil/cadastro' },
    { id: 1, name: 'Endereços e telefones', icon: 'assets/icons/ic-contato-', url: '/cliente/perfil/conta' },
    { id: 2, name: 'E-mail e senha', icon: 'assets/icons/ic-acesso-', url: '/cliente/perfil/pagamento' },
    { id: 3, name: 'Contratos e pagamento', icon: 'assets/icons/ic-financeiro-', url: '/cliente/perfil/contratos' },
    { id: 4, name: 'Histórico de Compras', icon: 'assets/icons/ic-historico-de-compras-', url: '/cliente/perfil/historico' },
  ];

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly headerService: HeaderService,
    protected readonly modalService: ModalService,
  ) {
    super(route, headerService);
    this.config = this.route.snapshot.data.config.profile;
    this.route.queryParams.subscribe(params => {
      const contractStatus = params['event'];

      if (contractStatus && docuSignCompleteMessages[contractStatus]) {
        this.modalService.show({
          type: ModalType.confirm,
          title: 'Atenção',
          message: docuSignCompleteMessages[contractStatus],
        });
      }
    });
  }

  closeProfilePayment() {
    if (this.contractComponent) {
      this.contractComponent.showUpdatePaymentForm = false;
    }
  }
}
