
<div class="btcompany-banner text-center">
  <a id="footer-home" href="https://www.bodytech.com.br" target="_blank" rel="noopener">
    <img alt="Bodytech" src="assets/footer/img-logo-bodytech.svg">
  </a>
  <hr class="divider">
  <ng-container *ngIf="data">
    <div class="footer-text">
      {{ data }}
    </div>
  </ng-container>
  <div class="footer-text ng-star-inserted"> A!Bodytech Participações S/A CNPJ: 07.737.623/0001-90 Rua Guilhermina Guinle 272, parte  CEP: 22270-060 - Rio de Janeiro - RJ
    <br>
    Se quiser falar com a Holding  <a [routerLink]="['/contato']" class="link">clique aqui</a>, e se quiser falar com uma das academias consulte Endereço e Telefone <a [routerLink]="['/academia']" class="link">aqui</a>.
    <br>
    Todas as ofertas do site são para compra exclusiva no cartão de crédito.
  </div>
</div>
