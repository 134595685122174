<div class="row justify-content-center">
  <div class="search-container position-relative w-100 mt-0">
    <label for="modal-select-city-search">
      <img src="assets/icons/ic-search.svg">
    </label>
    <input
      id="modal-select-city-search"
      class="form-control text-center auto-height adjust-on-ie--h50"
      placeholder="Buscar cidade"
      type="text"
      [(ngModel)]="searchCity"
    >
  </div>

  <div
    class="w-100 d-flex flex-column justify-content-center align-items-center text-center"
    *ngFor="let state of citiesByState; let index = index"
  >
    <div class="title modal-title state-name font-weight-normal text-primary mt-3 mb-2">{{ state.stateName }}</div>
    <div
      id="modal-gym-{{index}}"
      class="city w-100 border cursor-pointer mb-2"
      *ngFor="let city of (state.cities | fuse:searchCity)"
      (click)="selectCity(city)"
    >
      <span class="title modal-title font-weight-normal">{{ city }}</span>
    </div>
  </div>
</div>
