<div class="row">
  <div class="col-12 text-container">
    <h2 class="title-lg text-center">O que você espera de uma academia</h2>
  </div>
  <div class="col-12 text-container">
    <p>Somos uma empresa da Bodytech Company, um dois maiores grupos do setor fitness da América Latina em faturamento.
      A Fórmula nasceu em 2010, como resposta à grande procura por serviços que valorizassem a qualidade de vida e ao mesmo
      garantissem um excelente custo-benefício. Uma proposta inovadora, simples, padronizada e com base no conceito full
      service e low price.</p>

    <p>Sob liderança dos empresários Alexandre Accioly e Luiz Urquiza, com sócios como João Paulo Diniz e Bernardinho,
      a rede se expande por dois caminhos: academias próprias e franquias.</p>

    <p>Na Fórmula, você encontra equipamentos de última geração, uma grande variedade de aulas coletivas e profissionais
      qualificados: tudo isso por um preço acessível. É por isso que dizemos com convicção que a Fórmula é exatamente o
      que você espera de uma academia. </p>
  </div>
</div>
