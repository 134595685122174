import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';
import { FranchiseRoutingModule } from './franchise.routing';

import { FranchiseComponent } from './franchise.component';
import { FranchiseFormComponent } from './franchise-form/franchise-form.component';
import { FranchiseHeaderComponent } from './franchise-header/franchise-header.component';
import { FranchisePersonalComponent } from './franchise-form/franchise-personal/franchise-personal.component';
import { FranchisePropertyComponent } from './franchise-form/franchise-property/franchise-property.component';
import { FranchiseSuccessComponent } from './franchise-success/franchise-success.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,

    FranchiseRoutingModule,
  ],
  declarations: [
    FranchiseComponent,
    FranchiseFormComponent,
    FranchiseHeaderComponent,
    FranchisePersonalComponent,
    FranchisePropertyComponent,
    FranchiseSuccessComponent,
  ],
})
export class FranchiseModule { }
