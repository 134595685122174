<table class="table table-flush table-striped mb-0">
  <tbody>
    <tr class="border-top border-bottom" *ngFor="let item of schedules">
      <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0">{{ item.appointmentDate | date:'shortDate':'':locale }}</td>
      <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-md-2 pt-1 border-0">
        {{ item.teacherAvailableHour.availableHour.formattedInitialTime }} - {{ item.teacherAvailableHour.availableHour.formattedFinalTime }}
      </td>
      <td class="d-flex flex-column d-md-table-cell w-md-50 pb-md-2 pb-0 pt-md-2 pt-1 border-0">
        <span  [appMaxLines]="2">{{ item.teacherAvailableHour.teacher.name | capitalize }}</span>
        <div [appMaxLines]="2" class="small">{{ item.allActivitiesText }}</div>
        <div [appMaxLines]="2" class="small" *ngIf="item.totalSimultaneousClientsText"
          [ngClass]="{
            'green': item.teacherAvailableHour.totalSimultaneousClients === 1,
            'sea': item.teacherAvailableHour.totalSimultaneousClients > 1 && item.teacherAvailableHour.totalSimultaneousClients <= 2,
            'blue': item.teacherAvailableHour.totalSimultaneousClients > 2
          }">
            <strong>{{ item.totalSimultaneousClientsText }}</strong>
        </div>
      </td>
      <td class="d-flex d-md-table-cell border-0"><a class="join-action" href="{{item.liveClassUrl}}" rel="noopener" target="_blank">ENTRAR NA SALA VIRTUAL</a></td>
      <td class="d-flex d-md-table-cell border-0"><a class="cancel-action" href="javascript:void(0)" (click)="onCancelClick(item)">CANCELAR</a></td>
    </tr>
  </tbody>
</table>
