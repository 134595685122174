import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appMatch][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MatchValidatorDirective, multi: true },
  ],
})
export class MatchValidatorDirective implements Validator {
  @Input() appMatch: string;

  validate(control: AbstractControl): {[key: string]: any} {
    return (!this.appMatch || this.appMatch && control.value === this.appMatch) ? null : { match: this.appMatch };
  }
}
