<ng-template #header>
  <app-header-static-image [headerObj]="config.header"></app-header-static-image>
</ng-template>

<main #target class="main-wrapper container">
  <ng-container *ngIf="currentStep <= steps.length; else finish">
    <div class="section">
      <app-steps [steps]="steps" [(currentStep)]="currentStep"></app-steps>
    </div>
  </ng-container>

  <ng-template #finish>
    <div class="title-lg text-center title-success">Sucesso!</div>
  </ng-template>

  <div class="section data-section">
    <div class="card no-side-border-xs row-on-xs" *ngIf="!errorMessage; else empty">
      <div class="form-validate forms-container" *ngIf="!user; else logged">
        <app-form-personal-data
          *appLazyCreate="currentStep == 0"
          [adhesionUrl]="data.terms"
          [typeOfProduct]="typeOfProduct"
          [(formPersonalData)]="formData.personal"
          #personal
        ></app-form-personal-data>

        <app-form-additional-data #additional *appLazyCreate="currentStep == 1"
          [(formAdditionalData)]="formData.additional" [adhesionUrl]="data.terms"
          (parcel)="onParcelChange($event)"
          [flagCardOptions]="flagCardOptions"></app-form-additional-data>

        <app-form-success #success *appLazyCreate="currentStep == 2" [formData]="formData"
          [docuSignSearchType]="typeOfProduct" [isRegistration]="isRegistration"></app-form-success>
      </div>

      <ng-template #logged>
        <div class="form-validate flex-grow">
          <app-form-additional-data #additional *appLazyCreate="currentStep == 0"
            [showAddress]="false"
            [(formAdditionalData)]="formData.additional" [adhesionUrl]="data.terms"
            (parcel)="onParcelChange($event)"
            [flagCardOptions]="flagCardOptions"></app-form-additional-data>

          <app-form-success #success *appLazyCreate="currentStep == 1"
            [docuSignSearchType]="typeOfProduct" [formData]="formData"></app-form-success>
        </div>
      </ng-template>

      <div class="card-plan">
        <app-card-plan
          #cardPlan
          [data]="data"
          [disableQuantitySelector]="currentStep === steps.length"
          [typeOfProduct]="typeOfProduct"
          [showCoupon]="(currentStep === steps.length - 1)"
          (passQuantity)="updatedPassQuantity($event)"
          (parcelListChange)="updateParcels($event)"
        >
          <ng-container *ngIf="currentStep < steps.length">
            <button id="registration-next" class="w-100 btn btn-primary" (click)="nextStep()">
              {{ currentStep < steps.length - 1 ? 'Próximo' : (isRenew ? 'Renovar' : (isRenewDCC ? 'Atualizar Dados' : 'Matricular')) }}
            </button>

            <button id="registration-prev" class="w-100 btn btn-outline-primary" (click)="prevStep()">
              Cancelar
            </button>
          </ng-container>
        </app-card-plan>
      </div>
    </div>
    <ng-template #empty>
      <app-empty-card
        icon="/assets/icons_custom/ic-empty-list.svg"
        altIcon="Problema com os dados"
        message="{{errorMessage}}"
      ></app-empty-card>
    </ng-template>
  </div>
</main>
