import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { HeaderService } from '@services';

import { FranchiseRequest, DataAddress, ResponseFutureFranchiseeCreate } from '@models';
import { Brand, BrandService } from '@services/brand.service';

@Component({
  templateUrl: './franchise.component.html',
  styleUrls: ['./franchise.component.scss'],
})
export class FranchiseComponent implements OnInit, AfterViewInit {
  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  info: FranchiseRequest = {
    name: '',
    email: '',
    birthDate: '',
    landlinePhone: '',
    cellPhone: '',
    gender: '',
    maritalStatus: { id: null },
    identityDocument: '',
    cpf: '',
    address: {
      zipCode: '',
      street: '',
      number: '',
      adjunct: '',
      neighborhood: '',
      city: { id: null },
      state: { id: null },
      publicPlace: { id: 1 },
    } as DataAddress,

    franchiseInformation: {
      availableProperty: true,
      propertyAddress: {
        zipCode: '',
        street: '',
        number: '',
        adjunct: '',
        neighborhood: '',
        city: { id: null },
        state: { id: null },
        publicPlace: { id: 1 },
      } as DataAddress,
    },
  } as FranchiseRequest;

  formResponse: ResponseFutureFranchiseeCreate = null;
  brand: Brand;

  constructor(
    public readonly brandService: BrandService,
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
  ) {
    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  formSent(event: ResponseFutureFranchiseeCreate) {
    this.formResponse = event;
    window.scrollTo(0, 0);
  }
}
