import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer-bottom-banner',
  templateUrl: './footer-bottom-banner.component.html',
  styleUrls: ['./footer-bottom-banner.component.scss'],
})

export class FooterBottomBannerComponent {
  @Input() data: string;
}
