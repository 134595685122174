<div class="modal fade" bsModal #templateModal="bs-modal" (onShow)="init()" [config]="{backdrop: 'static', keyboard: false}"
  tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title w-100 text-center">{{config.title}}</div>
      </div>
      <div class="modal-body">
        <div>
          <div>{{config.subtitle}}</div>
          <div class="row">

            <div class="select-container col-12 col-md-6">
              <label>{{config.from}}</label>
              <app-select id="price-start"
                name="price-start"
                class="form-custom"
                [(ngModel)]="price[0]"
                [list]="listStart"
                ></app-select>
            </div>
            <div class="select-container col-12 col-md-6">
              <label>{{config.to}}</label>
              <app-select id="price-end"
                name="price-end"
                class="form-custom"
                [(ngModel)]="price[1]"
                [list]="listEnd"
                ></app-select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="modal-price-confirm" type="button"
          class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || config.buttonText || 'OK' }}
        </button>
      </div>
    </div>
  </div>
</div>
