import { Component, Input } from '@angular/core';

import { GymBusinessHours } from '@models';


@Component({
  selector: 'app-gym-business-hours',
  templateUrl: './gym-business-hours.component.html',
  styleUrls: ['./gym-business-hours.component.scss']
})
export class GymBusinessHoursComponent {

  @Input() businessHours: GymBusinessHours[];

}
