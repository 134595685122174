import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { HeaderService, PolicyService } from '@services';

@Component({
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit, AfterViewInit {
  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  policy: string[];

  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly policyService: PolicyService,
  ) {}

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
    this.policyService.getPrivacyPolicy().subscribe(data => (this.policy = data));
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  callPrint() {
    (window as any).print();
  }
}
