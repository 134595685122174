import { Injectable } from '@angular/core';
import { Gym, Schedule } from '@models';
import { Observable } from 'rxjs';
import { GymService } from './gym.service';
import { mergeMap, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { ScheduleRequest } from '@models/requests/schedule-request.model';

@Injectable()
export class MySchedulesService {

  constructor(
    private readonly gymService: GymService,
    private readonly apollo: Apollo,
  ) { }

  getAllSchedulingByClient(registration: number): Observable<Schedule[]> {
    return this.apollo.query({
      query: AppGraphql.queryListAllSchedulingByClient,
      variables: {
        registration,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<Schedule[]>('listAllSchedulingByClient')),
    );
  }

  getGymUnitsToAvailable(): Observable<Gym[]> {
    return this.gymService.getAllGymsOfCustomer()
      .pipe(
        mergeMap((allGyms: []) => this.gymService.getGymUnitsThatAreAvailableToScheduling()
          .pipe(
            map((gyms: []) => allGyms.filter((gym: any) => gyms.some((_gym: any) => _gym.id === gym.id))),
          ),
        ),
      );
  }

  getAvailableHourToScheduleByGymUnitAndPlan(gymUnitId: number, planId: number): Observable<any> {
    return this.apollo.query({
      query: AppGraphql.queryListAvailableHourToScheduleByGymUnitAndPlan,
      variables: {
        gymUnitId,
        planId,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable('listAvailableHourToScheduleByGymUnitAndPlan')),
    );
  }

  createScheduling(scheduleRequest: ScheduleRequest) {
    return this.apollo.mutate({
      mutation: AppGraphql.mutationCreateScheduling,
      variables: {
        scheduling: scheduleRequest,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<any>('createScheduling')),
    );
  }

  cancelSchedule(id: number, registration: number): Observable<any> {
    return this.apollo.mutate({
      mutation: AppGraphql.mutationCancelScheduling,
      variables: {
        id,
        registration,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<any>()),
    );
  }

  getGymUnitAvailableSchedulingMessage(gymId: number): Observable<any[]> {
    return this.apollo.query({
      query: AppGraphql.queryGetGymUnitAvailableSchedulingMessage,
      variables: {
        gymId,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable('getGymUnitAvailableSchedulingMessage')),
    );
  }
}
