<div class="background-shadow">
  <div toastContainer class="toastr"></div>
</div>

<nav id="navbar" class="navbar fixed-top w-100 navbar-dark align-items-start p-0">
  <div appOpenModalPadding class="nav-container d-flex w-100 position-relative">
    <div *ngIf="showBackground" class="nav-background w-100 position-absolute"></div>

    <ul class="navbar-nav container title flex-row justify-content-start position-relative">
      <!-- LOGO -->
      <li class="brand-section h-0">
        <ng-container *ngTemplateOutlet="brandTemplate"></ng-container>
      </li>

      <ng-container *ngTemplateOutlet="linkTemplate; context: { links: navbarItems }"></ng-container>

      <app-navbar-user-menu
        *ngIf="isUserLogged; else loginButtonTemplate"
        [navbarUserMenu]="navbarItemsLogged"
      ></app-navbar-user-menu>

      <li class="navbar-toggle-wrapper">
        <ng-container *ngTemplateOutlet="hambugerButtonTemplate"></ng-container>
      </li>
    </ul>
  </div>

  <div class="lateral-navbar title" [class.uncollapsed]="navbarExpanded">
    <div class="d-flex top-section justify-content-between w-100">
      <ng-container *ngTemplateOutlet="brandTemplate"></ng-container>

      <div class="d-flex right-section">
        <a
          *ngIf="isUserLogged"
          class="user-link d-block d-lg-none"
          id="navbar-user-link"
          routerLink="/cliente"
          routerLinkActive="active"
          (click)="setNavbarExpanded(false)"
        >
          <img
            alt="Perfil do usuário"
            class="user-pic rounded-circle"
            [src]="userAvatar || 'assets/images/default-user-image.png'"
          >
        </a>

        <ng-container *ngTemplateOutlet="hambugerButtonTemplate; context: { alwaysCloseIcon: true }"></ng-container>
      </div>
    </div>

    <ul *ngIf="navbarOpenItems" class="w-100 overflow-auto mb-0 pb-3">
      <ng-container
        *ngIf="isUserLogged"
        [ngTemplateOutlet]="linkTemplate"
        [ngTemplateOutletContext]="{ links: navbarItemsLogged }"
      ></ng-container>

      <ng-container *ngTemplateOutlet="linkTemplate; context: { links: navbarOpenItems.primary }"></ng-container>

      <li *ngIf="isUserLogged; else loginButtonTemplate" class="nav-item highlight">
        <a
          appClickable
          class="nav-link login"
          id="navbar-logout"
          (click)="setNavbarExpanded(false); logout();"
        >
          Logout
        </a>
      </li>

      <ul class="sub-links">
        <ng-container *ngTemplateOutlet="linkTemplate; context: { links: navbarOpenItems.secondary }"></ng-container>
      </ul>
    </ul>
  </div>

  <div
    class="lateral-navbar-backdrop"
    [class.show]="navbarExpanded"
    (click)="setNavbarExpanded(false)"
  ></div>
</nav>


<ng-template #brandTemplate>
  <a
    class="navbar-brand d-flex align-items-end"
    id="navbar-home"
    routerLink="/"
    (click)="setNavbarExpanded(false)"
  >
    <img
      alt="Logo da {{brandNameLowerCase}}"
      class="img-fluid"
      src="assets/custom_images/img-logo.svg"
    >
  </a>
</ng-template>

<ng-template let-links="links" #linkTemplate>
  <li
    *ngFor="let link of links"
    class="nav-item"
    [ngClass]="link.class"
  >
    <ng-container *ngIf="!link.isExternal; else externalLink">
      <a
        class="nav-link d-flex"
        routerLinkActive="active"
        [id]="link.id"
        [routerLink]="link.url"
        [routerLinkActiveOptions]="{exact: link?.exact}"
        (click)="setNavbarExpanded(false)"
      >
        {{ link.text }}
      </a>
    </ng-container>

    <ng-template #externalLink>
      <a
        class="nav-link d-flex align-items-center"
        target="_blank"
        [href]="link.url"
        [id]="link.id"
        (click)="setNavbarExpanded(false)"
      >
        {{ link.text }}
      </a>
    </ng-template>
  </li>
</ng-template>

<ng-template #loginButtonTemplate>
  <li class="nav-item highlight">
    <a
      class="nav-link login"
      id="navbar-login"
      routerLink="/cliente/login"
      (click)="setNavbarExpanded(false)"
    >
      Login
    </a>
  </li>
</ng-template>

<ng-template let-alwaysCloseIcon="alwaysCloseIcon" #hambugerButtonTemplate>
  <!-- BOTÃO MENU HAMBURGER -->
  <button
    #toggleIcon
    aria-controls="navbarSupportedContent"
    aria-label="Toggle navigation"
    class="navbar-toggler"
    type="button"
    (click)="setNavbarExpanded(!navbarExpanded)"
  >
    <img alt="Menu" [src]="navbarExpanded || alwaysCloseIcon ? menuCloseImagePath : menuOpenImagePath">
  </button>
</ng-template>
