import { Component } from '@angular/core';
import { ModalAgendaObject, ModalObject, ModalType } from '@models';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-debug-modal-test',
  templateUrl: './modal-test.component.html',
  styleUrls: ['./modal-test.component.scss'],
})
export class ModalTestComponent {

  public modals: {modal: ModalObject|ModalAgendaObject, icon: string, class: string}[] = [
    {
      modal: {
        type: ModalType.alert,
        title: 'Alert Modal Example',
        message: 'Example of a modal with only a single confirmation button, not dismissable',
        confirmButton: 'Primary',
        confirmCallback: () => this.confirmCallback(),
      },
      icon: 'fas fa-info',
      class: 'success',
    }, {
      modal: {
        type: ModalType.confirm,
        title: 'Confirm Modal Example',
        message: 'Example of a modal with two buttons. It is dismissable (click on background or ESC)',
        confirmButton: 'Primary',
        cancelButton: 'Secondary',
        confirmCallback: () => this.confirmCallback(),
        cancelCallback: () => this.cancelCallback(),
        dismissCallback: () => this.dismissCallback(),
      },
      icon: 'fas fa-check',
      class: 'success',
    }, {
      modal: {
        type: ModalType.confirm,
        title: 'Confirm w/o Cancel Example',
        message: 'A confirm modal can be used without cancel button, but will still be dismissable',
        confirmButton: 'Primary',
        confirmCallback: () => this.confirmCallback(),
        dismissCallback: () => this.dismissCallback(),
      },
      icon: 'far fa-check-circle',
      class: 'success',
    }, {
      modal: {
        type: ModalType.confirm,
        title: 'Confirm Danger Example',
        message: 'Are you sure you want to erase all your data? (dismissable)',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'danger',
        confirmCallback: () => this.confirmCallback(),
        cancelCallback: () => this.cancelCallback(),
        dismissCallback: () => this.dismissCallback(),
      },
      icon: 'fas fa-check',
      class: 'danger',
    }, {
      modal: {
        type: ModalType.agenda,
        title: 'Agenda example',
        message: '',
        event: {
          id: 0,
          dayOfTheWeek: 'Segunda',
          itemGridTitle: 'Grid Title',
          teacherName: 'Teacher name',
          activityInitialTimeFormatted: '12:59',
          activityInitialTimeHour: 12,
          activityInitialTimeMinute: 59,
          itemGridPlace: '',
          duration: 60,
          audiences: [
            { id: 0, minimumAge: 0.5, maximumAge: 60, name: 'Mock audience'  },
          ],
          targetAudienceLevel: '',
        },
      },
      icon: 'fas fa-calendar',
      class: 'primary',
    }, {
      modal: {
        type: ModalType.confirm,
        title: 'Methods missing',
        message: 'Passing less methods than the necessary is okay',
        confirmButton: 'Okay',
        cancelButton: 'OK',
      },
      icon: 'fas fa-question',
      class: 'warning',
    },
  ];

  confirmCallback() {
    console.log('onConfirm callback');
  }

  cancelCallback() {
    console.log('onCancel callback');
  }

  dismissCallback() {
    console.log('onDismiss callback');
  }

  constructor(
    public readonly modalService: ModalService,
  ) { }

  showModal(modal: ModalObject) {
    this.modalService.show(modal);
  }

}
