import { Component, Input, OnInit } from '@angular/core';
import { ActionBtItem } from '@models';

@Component({
  selector: 'app-action-bt-item',
  templateUrl: './action-bt-item.component.html',
  styleUrls: ['./action-bt-item.component.scss']
})
export class ActionBtItemComponent implements OnInit {

  @Input() public action: ActionBtItem;

  constructor() { }

  ngOnInit(): void {
  }

}
