import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { back, BackEndpoints, isDocuSignMicroservices, isMicroservices, isToOldCanais } from '@utils/app-endpoints';


@Injectable()
export class XAuthorizationInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Not logged purchase legacy's request doesnt accept userToken
    if (request.url.includes('purchase') && !request.url.includes('customer')) {
      return next.handle(request);
    }

    // Evo redirect request must be done always with the app token not with the user token, even if the user is logged.
    const userToken = !this.isEvoRedirectRequest(request.url)
      ? this.authService.getAppUserToken()
      : null;

    if (isToOldCanais(request.url) && userToken) {
      return this.setRequestWithToken(request, next, userToken);
    }

    return this.requestWithAppToken(request, next);
  }

  private isEvoRedirectRequest = (url: string) => url.startsWith(back(BackEndpoints.EvoSendRequest));

  private setRequestWithToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    token: string,
  ): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      setHeaders: { 'X-Authorization': token },
    });

    return next.handle(newRequest);
  }

  private getTokenKey(url: string): string {
    if (isDocuSignMicroservices(url)) {
      return AppConstants.STOR_DOCUSIGN_TOKEN;
    } else if (isMicroservices(url)) {
      return AppConstants.STOR_COGNITO_TOKEN;
    }

    return AppConstants.STOR_SUR_TOKEN;
  }

  private requestWithAppToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getAppToken(this.getTokenKey(request.url)).pipe(
      mergeMap((token: string) => this.setRequestWithToken(request, next, token)),
    );
  }

}
