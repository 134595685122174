import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingContentComponent } from './loading-content.component';


@NgModule({
  declarations: [LoadingContentComponent],
  imports: [
    CommonModule
  ],
  exports: [LoadingContentComponent],
})
export class LoadingContentModule { }
