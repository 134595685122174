<div
  class="apps-row d-flex flex-sm-row"
  [class.flex-column]="columnOnMobile"
>
  <a
    id="get-app-android"
    (click)="clickApp('BT-Android')"
    (auxclick)="clickApp('BT-Android')"
    target="_blank"
    rel="noopener"
    [href]="androidDownloadLink"
  >
    <img loading="lazy" alt="Baixar na Play Store" src="assets/footer/google.svg"/>
  </a>

  <a
    id="get-app-ios"
    (click)="clickApp('BT-iOS')"
    (auxclick)="clickApp('BT-iOS')"
    target="_blank"
    rel="noopener"
    [href]="iosDownloadLink"
  >
    <img loading="lazy" alt="Baixar na App Store" src="assets/footer/apple.svg"/>
  </a>
</div>
