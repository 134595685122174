<ng-template #header>
  <app-header-static-image [headerObj]="config.header"></app-header-static-image>
</ng-template>

<main class="main-wrapper container justify-content-around px-0 mt-0">
  <div class="schedules-container">
    <form [formGroup]="form" class="form-validate card small-card text-center row-on-xs no-side-border-xs">
      <div class="title-section text-black text-left">AGENDAR HORÁRIO</div>
      <p  class="text-left mb-24" *ngIf="gymAdviceText" [innerHTML]="gymAdviceText"></p>
      <div class="text-left">
        <label for="gym-field" class="label text-left text-uppercase">SELECIONE A ACADEMIA</label>
        <div class="position-relative">
          <input name="gymUnit" class="form-control select-gym" placeholder="Selecione uma opção" formControlName="gym"
          (focus)="showGymSelectModal()" required>
          <div class="arrow"></div>
        </div>
        <app-error-message [control]="form.controls.gym"></app-error-message>
      </div>

      <div class="flex sm-column">
        <div class="w-100 text-left">
          <label for="gym-field" class="label text-uppercase">DATA</label>
          <app-select formControlName="date" name="documentType" [list]="dates" (ngModelChange)="updateAvailableHours()"
            required></app-select>
          <app-error-message [control]="form.controls.date"></app-error-message>
        </div>
        <div class="w-100 text-left ml-md-24">
          <label for="gym-field" class="label text-uppercase">HORÁRIO</label>
          <app-select formControlName="hour" name="documentType" [list]="hours" required></app-select>
          <app-error-message [control]="form.controls.hour"></app-error-message>
        </div>
      </div>

      <div class="text-left">
        <label for="gym-field" class="label text-left text-uppercase">Irá acompanhado(a) de Personal?</label>
        <app-checkbox [(check)]="hasPersonal" message="Sim, irei à academia acompanhado(a) de um(a) Personal.">
        </app-checkbox>
      </div>

      <div class="flex mt-15">
        <button class="btn btn-outline-primary w-100" routerLink="/cliente/agendamentos">VOLTAR</button>
        <button class="btn btn-primary w-100 ml-8 ml-md-24" (click)="submit()">AGENDAR</button>
      </div>
    </form>
  </div>
</main>

<app-modal-gym-select [analyticsCategory]="analyticsCategory" [analyticsTag]="analyticsTag">
</app-modal-gym-select>
