<ng-container *ngIf="!inProd">
  <app-debug-banner [class.d-none]="debug" (click)="toggleDebug()" style="cursor: pointer"></app-debug-banner>
  <div *ngIf="debug" class="d-print-none text-white debug-container p-3 position-fixed">
    <div class="close" (click)="toggleDebug()"><span aria-hidden="true">×</span><span class="sr-only">Close</span></div>
    <div class="text-center"><i class="fab fa-angular"></i> <span class="font-weight-bold">DEGUB PANEL</span></div>
    <app-debug-screen-size></app-debug-screen-size>
    <app-debug-modal-test></app-debug-modal-test>
    <app-debug-route-test></app-debug-route-test>
  </div>
  <div *ngIf="mouseinfo" class="d-print-none text-white debug-container p-3 mb-4 position-fixed">
    <app-debug-view-id></app-debug-view-id>
  </div>
  <div [class.d-none]="!guidelines" class="d-print-none">
    <app-debug-guide-lines></app-debug-guide-lines>
  </div>
</ng-container>
