<div class="text-center content-margin description content-margin-top">
  As Aulas Coletivas acontecem ao vivo e para participar basta entrar na sala online no dia e horário
  da aula desejada. Você também pode favoritar as aulas que se encaixam melhor na sua rotina.
</div>

<div class="d-flex content-margin overflow-y-auto no-margin-right-xs mb-next-classes" *ngIf="nextClasses?.length">
  <div class="item-class-box" nextClasses (favoriteEmitter)="favoriteButtonAction($event)" [classInfo]="classInfo" *ngFor="let classInfo of nextClasses"></div>
</div>

<div class="content-margin no-margin-right-xs">
  <app-days-filter [selectedDay]="selectedDay" (changeDay)="changeSelectedDay($event)"></app-days-filter>
</div>

<div class="classes-list content-margin" *ngIf="classesOfSelectedDay?.groupClassClientCustomResponse?.length; else hasNoClassesForTheSelectedDay">
  <ng-container *ngFor="let class of classesOfSelectedDay.groupClassClientCustomResponse">
    <div listItem [classInfo]="class" (favoriteEventEmitter)="favoriteButtonAction($event)" [userRegistration]="userRegistration" class="item-container"></div>
  </ng-container>
</div>

<ng-template #hasNoClassesForTheSelectedDay>
  <div class="content-margin-top">
    <app-empty-card 
      icon="/assets/icons_custom/ic-empty-list.svg" 
      altIcon="Lista vazia"
      message="Nenhuma aula disponível para o dia selecionado." 
    ></app-empty-card>
  </div>
</ng-template>