<div id="filter-component" class="row no-gutters mt-3">
  <!-- Activities filter -->
  <button
    appDropdownArrows
    class="btn no-btn col p-0 title-md"
    (click)="showActivitiesSelectModal()"
  >
    <img
      class="filter-icon"
      src="assets/icons/ic-filter-activity.svg"
      alt="Ícone de atividades da academia"
    >
    <span class="dark d-none d-md-block font-weight-normal ">
      {{ config.activitiesModal.title }}
    </span>
  </button>

  <hr class="vertical">

  <!-- Benefits filter (BT only) -->
  <button
    appDropdownArrows
    class="btn no-btn col p-0 title-md hide-on-fr"
    (click)="showBenefitsSelectModal()"
  >
    <img
      class="filter-icon"
      src="assets/icons/ic-filter-service.svg"
      alt="Ícone de serviços da academia"
    >
    <span class="dark d-none d-md-block font-weight-normal">
      {{ config.servicesModal.title }}
    </span>
  </button>

  <hr class="vertical">

  <!-- Clear filter -->
  <button
    class="clear-btn btn no-btn col p-0 title-md d-flex align-items-center justify-content-center"
    [ngClass]="(activityTags.length || filter.benefits.length) ? 'text-primary' : 'text-secondary'"
    (click)="clearFilter()"
  >
    <img
      class="clear-icon mr-md-2"
      src="assets/icons_custom/ic-recycle.svg"
      alt="Ícone de X, simbolizando opção para limpar os filtros"
    >
    <span class="d-none d-md-block font-weight-normal">
      {{ config.gymSearch.search.clear }}
    </span>
  </button>
</div>

<!-- Tags -->
<div
  class="tags row justify-content-center mt-0 mt-md-3 pt-3"
  *ngIf="activityTags.length || filter.benefits.length"
>
  <ng-container *ngTemplateOutlet="filterTag; context: { group: activityTags, list: filter.activities }"></ng-container>
  <ng-container *ngTemplateOutlet="filterTag; context: { group: filter.benefits, list: filter.benefits }"></ng-container>

  <ng-template #filterTag let-list="list" let-group="group">
    <div
      *ngFor="let tag of group"
      class="badge title text-light d-flex align-items-center"
    >
      <p class="m-0" [class.text-underline]="tag.activityGroup?.id < 0">
        {{ tag.name }}
      </p>
      <img
        class="cross-icon align-baseline"
        src="assets/icons/ic-close.svg"
        alt="Símbolo para remover tag de filtro"
        (click)="removeFilter(list, tag)"
      >
    </div>
  </ng-template>
</div>

<app-modal-gym-activity
  analyticsCategory="Academias"
  analyticsTag="Filtros"
></app-modal-gym-activity>

<app-modal-gym-benefit></app-modal-gym-benefit>
