import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FranchiseProperty, SelectItem } from '@models';
import { AddressService } from '@services/address.service';
import { NgForm } from '@angular/forms';
import { DataAddressComponent } from '@sharedcomponents/data-address/data-address.component';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-franchise-property',
  templateUrl: './franchise-property.component.html',
  styleUrls: [
    './franchise-property.component.scss',
    '../../franchise.component.scss',
  ],
})
export class FranchisePropertyComponent implements OnInit {
  _loadingCities = false;

  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('formAddress', { static: false }) formAddress: DataAddressComponent;
  @Input() property: FranchiseProperty;
  @Output() propertyChange = new EventEmitter<FranchiseProperty>();

  states: SelectItem[];
  cities: SelectItem[];

  state: number;

  constructor(
    private readonly addressService: AddressService,
  ) { }

  ngOnInit() {
    this.addressService.getStates().subscribe(data => {
      this.states = data.map(state => this.addressService.stateToSelect(state));
    });
  }

  selectState(state: number) {

    this._loadingCities = true;
    this.cities = [];
    this.addressService.getCitiesInState(state).subscribe(data => {
      this.cities = data.map(city => this.addressService.cityToSelect(city));
      if (this.cities.length > 1 && !this.cities.find(city => city.id === this.property.propertyAddress.city.id)) {
        this.property.propertyAddress.city = { id: null };
      }
      this._loadingCities = false;
    });
  }

  isValid(): boolean {
    FormUtil.touchForm(this.form.control);
    const valid = [
      !this.property.availableProperty || this.formAddress.isValid(),
      this.form.valid,
    ];
    return valid.every(isValid => isValid);
  }

}
