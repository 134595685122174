<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>
<main class="container main-wrapper">
  <app-notice *ngFor="let notice of notices" [notice]="notice"></app-notice>
  <div class="filter-container d-print-none">
    <app-agenda-filter
      [showPlace]="true"
      [filter]="filter"
      [hideActivities]="selectedGymIsMigrated"
      (filterChanges)="onFilterChange($event)"
      [gym]="filter.gym"
      analyticsCategory="Agenda"
      analyticsTag="Filtros"
    ></app-agenda-filter>
  </div>

  <div class="timeline-container" [hidden]="hideAgenda">
    <app-agenda-timeline-ie *ngIf="isIE; else notIE" #timelineIE [filter]="filter"></app-agenda-timeline-ie>
    <ng-template #notIE>
      <app-agenda-timeline #timeline [filter]="filter"></app-agenda-timeline>
    </ng-template>
  </div>

</main>
