import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@services';

@Component({
  selector: 'app-registration-locking',
  templateUrl: './registration-locking.component.html',
  styleUrls: ['./registration-locking.component.scss']
})
export class RegistrationLockingComponent implements OnInit, AfterViewInit {

  imageHeader: string;
  headerTitle: string;

  daysRemaining = 15;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
  ) {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
