interface Array<T> {
  /** Group a list of objects by a certain value. */
  groupBy: (valueToGroup: keyof T) => { [key: string]: T[] };
}


Array.prototype.groupBy = function(valueToGroup: keyof any[]) {
  return this.reduce((previousValue: any[], currentValue: any[]) => {
    (previousValue[currentValue[valueToGroup]] = previousValue[currentValue[valueToGroup]] || []).push(currentValue);

    return previousValue;
  }, {});
};
