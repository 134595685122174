import { Component, OnInit } from '@angular/core';

import { ModalGymSelectByCityObject, ModalObject, ModalType, Tab } from '@models';
import { GymsByCitiesOfState, GymToSelect } from '@models/bt-pass.model';
import { ModalService } from '@services';
import { GymSelectByCityTabs } from '@utils/gym-util';

import { ModalCommonComponent } from '../modal-common.component';


@Component({
  selector: 'app-modal-gym-select-by-city',
  templateUrl: './modal-gym-select-by-city.component.html',
  styleUrls: ['./modal-gym-select-by-city.component.scss'],
})
export class ModalGymSelectByCityComponent extends ModalCommonComponent implements OnInit {
  readonly gymSelectByCityTabs = GymSelectByCityTabs;

  readonly tabs: Tab[] = [
    { id: GymSelectByCityTabs.Cities, name: 'Cidade', icon: 'assets/icons/ic-city.svg' },
    { id: GymSelectByCityTabs.Gyms, name: 'Academia', icon: 'assets/icons/ic-filter-place.svg'},
  ];

  modalObj: ModalObject<ModalGymSelectByCityObject>;
  gymsByState: GymsByCitiesOfState[];
  filteredGyms: GymsByCitiesOfState[];

  selectedGyms: GymToSelect[] = [];
  selectedCity: string;

  maxNumberOfGymSelections: number;

  private _activeTab: Tab = this.tabs[GymSelectByCityTabs.Cities];

  set activeTab(newValue: Tab) {
    this._activeTab = newValue;
  }

  get activeTab(): Tab { return this._activeTab; }

  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.gymSelectByCity;
  }

  init() {
    this.clearFilter();

    this.activeTab = this.modalObj.config.selectedGyms?.length
      ? this.tabs[GymSelectByCityTabs.Gyms]
      : this.tabs[GymSelectByCityTabs.Cities];

    this.selectedGyms = [];
    this.maxNumberOfGymSelections = this.modalObj.config.maxNumberOfGymSelections;
    this.gymsByState = this.modalObj.config.gymsByCitiesOfState;
    this.selectedGyms = this.modalObj.config.selectedGyms;
    this.filteredGyms = this.gymsByState;
  }

  clearFilter(clearGyms = false): void {
    if (clearGyms) {
      this.selectedGyms.forEach(gym => gym.selected = false);
    }

    this.selectedGyms = [];
    this.selectedCity = null;
    this.filteredGyms = this.gymsByState;
    this.activeTab = this.tabs[GymSelectByCityTabs.Cities];
  }

  selectCity(selectedCity: string): void {
    this.activeTab = this.tabs[GymSelectByCityTabs.Gyms];
    this.filteredGyms = this.gymsByState.map(state => ({
      ...state,
      gyms: state.gyms.filter(gym => gym.cityName === selectedCity),
    }));
    this.selectedCity = selectedCity;
  }

  updateSelectedGymList(gymList: GymToSelect[]): void {
    this.selectedGyms = gymList;
  }

  confirm(): void {
    this.modalObj.data = this.selectedGyms;
    this.selectedGyms = [];
    this.gymsByState = [];
    super.confirm();
  }
}
