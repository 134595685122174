<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="eventTable.length; else empty">
    <app-agenda-header [left]="_left" (leftChange)="left = $event" [days]="daysColHeader" today="{{today}}"
      [weekstart]="weekstart" [highlight]="_hoveringColumn"></app-agenda-header>
    <div
      id="timeline-title"
      (scroll)="left = scrollContainer.scrollLeft"
      #scrollContainer
      [scrollLeft]="_left"
      (mouseleave)="hoveringColumn = -1"
      class="light-shadow table-container today-index-{{today}}"
    >
      <div class="time-entry row true-columns"
          #timeEntry
          *ngFor="let row of eventTable; let lineIndex = index">
        <div class="col time-col title-md text-light p-absolute-md-down"
            (mouseenter)="hoveringColumn = -1">
          <div class="time">
            {{ timesRowHeader[lineIndex] }}
          </div>
        </div>
        <div class="col time-col d-lg-none"></div>
        <div class="col day-of-week"
            (mouseenter)="hoveringColumn = cellIndex"
            *ngFor="let cell of row; let cellIndex = index">

          <app-day-of-week [cell]="cell"></app-day-of-week>

        </div>
      </div>

    </div>
  </ng-container>

  <ng-template #empty>
    <div class="loading-container">
      <app-empty-card
        icon="/assets/icons_custom/ic-empty-list.svg"
        altIcon="Agenda não encontrada"
        message="Nenhuma aula foi encontrada com os filtros selecionados.<br>
        Faça uma pesquisa com filtros vazios para ver a agenda completa."
      ></app-empty-card>
    </div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <div class="title text-center title-md loading-container">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>
