import { Component } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {

  valueMin = 10;
  valueMax = 50;

  getWidthStart() {
    return `calc(${this.valueMax}% - ${32 * this.valueMax / 100}px)`;
  }

  getWidthEnd() {
    return `calc(${this.valueMin < 100 ? (100 - this.valueMin) + '%' : '32px'})`;
  }

}
