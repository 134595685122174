import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { AppGraphql } from '@utils/app-graphql';
import { map } from 'rxjs/operators';
import { JSONUtil } from '@utils/json-util';

@Injectable()
export class ImageHeaderService {
  public template = new BehaviorSubject<TemplateRef<any>>(null);

  constructor(private readonly apollo: Apollo) {}

  public getPortalPageHeaderByTag(tag: string): Observable<any> {
    return this.apollo
      .query({
        query: AppGraphql.queryGetPortalPageHeaderByTag,
        variables: {
          tag,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable('getPortalPageHeaderByTag')));
  }
}
