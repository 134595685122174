<div class="container">
  <div class="row">
    <div class="col-12 px-sm-0 text-container">
      <h2 class="title-lg">CONTRATE AGORA UM PLANO EXCLUSIVO PARA OS COLABORADORES DA SUA EMPRESA. É MUITO SIMPLES E RÁPIDO!</h2>
      <p class="subtitle text-justify pb-2">Com o plano <b>Fitness Exclusive Corporativo</b>, os funcionários da sua empresa podem ter acesso a toda infraestrutura e atividades da <b>Bodytech</b>, com uma condição super especial. Veja abaixo os pré-requisitos para a sua empresa ser conveniada, as academias disponíveis e os nossos preços.</p>
      <p class="subtitle text-justify">É muito simples para cadastrar a sua empresa! Basta preencher o cadastro abaixo, e aguardar o nosso contato com a resposta se a adesão ao plano foi aprovada.</p>
    </div>
    <div class="col-12 col-lg-4 pl-sm-0">
      <div class="media">
        <img class="mr-3" src="assets/icons/ic-money.svg" alt="Generic placeholder image">
        <div class="media-body">
          <span class="subtitle ">Mínimo de 20MM de faturamento anual</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 pl-sm-0">
      <div class="media">
        <img class="mr-3" src="assets/icons/ic-crew.svg" alt="Generic placeholder image">
        <div class="media-body">
          <span class="subtitle">Mínimo de 50 funcionários</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 pl-sm-0">
      <div class="media">
        <img class="mr-3" src="assets/icons/ic-weight.svg" alt="Generic placeholder image">
        <div class="media-body">
          <span class="subtitle">Mínimo de 10 planos ativos a cada 6 meses</span>
        </div>
      </div>
    </div>
  </div>
</div>
