import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FaqComponent } from './faq.component';
import { FaqCityComponent } from './faq-city/faq-city.component';

const faqRoute = [
    {
        path: '',
        component: FaqComponent,
        resolve: {
            imageHeader: ImageHeaderResolve,
        },
        data: {
            windowTitle: '${data.imageHeader.title}',
            windowContext: 'faq',
            slug: 'faq',
        },
    },
    {
      path: 'covid',
      component: FaqCityComponent,
      resolve: {
        imageHeader: ImageHeaderResolve,
      },
      data: {
          windowTitle: '${data.imageHeader.title}',
          windowContext: 'faq-city',
          slug: 'faq-covid',
      },
    },
];

@NgModule({
    imports: [RouterModule.forChild(faqRoute)],
    exports: [RouterModule],
})
export class FaqRoutingModule { }
