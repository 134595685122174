<div class="landingpage">

  <div class="header-section">

    <div class="container">

      <div class="background-header">
        <img src="/assets/action_bt/hero-btflexivel.png">
        <div class=background-cover></div>
        <div class=background-kleber></div>
      </div>

      <img class="logo-bt" src="/assets/action_bt/logo-bt.svg">
      <div class="header-main text-center-mobile-only row">
        <div class="header-text col-12 col-md-8 col-lg-7">
          <div class="actionbt-title font-heading-1">
            A BT de sempre ainda mais flexível
          </div>
          <div class="actionbt-subtitle font-subtitle">
            A missão de garantir o melhor treino todos os dias continua igual por aqui, mas mudanças importantes entraram no nosso radar.
          </div>
          <div class="our-actions-button action-bt-button d-none d-md-block col-auto" (click)="scrollToActions()">
            Conheça nossas ações
          </div>
        </div>

        <div class="actionbt-logo justify-content-around d-flex col-12 col-md-4 col-lg-5">
          <div class="glow-fx centralize-y"></div>
          <div class="col-6 col-md-12">
            <img src="/assets/action_bt/logo-action-bt.png">
          </div>
        </div>

        <div class="arrow d-none d-lg-block">
          <img src="/assets/action_bt/icon-arrow.svg">
        </div>

        <div class="col-12 d-md-none d-flex justify-content-center">
          <div class="our-actions-button action-bt-button" (click)="scrollToActions()">
            Conheça nossas ações
          </div>
        </div>

      </div>

      <div class="header-disclaimer text-center-mobile-tablet row">
        <div class="font-heading-4 text-brand-green col-12 col-lg-6">
          O momento pede mais flexibilidade e empatia de todo mundo. E foi com esses valores em mente que a BT partiu para a ação.
        </div>
        <div class="font-body-p1 col-12 col-lg-6">
          Além de colocar o rígido protocolo de segurança como prioridade máxima, demos um upgrade na lista de benefícios!
          Opções de treino online e ao vivo, mais facilidades na contratação e vantagens para os já clientes são nossos compromissos.
          <br><br>
          Estamos com você em todos os lugares, sempre que precisar.
        </div>
      </div>

    </div>

  </div>
  <div id="our-actions" class="actions-section">
    <div class="container">
      <div class="font-heading-2 actionbt-title text-center">Confira todas as ações BT</div>
      <div class="actions-list text-center-mobile-only row">
        <div class="action-container col-12 col-md-6 col-lg-3" *ngFor="let action of actions">
          <app-action-bt-item [action]="action"></app-action-bt-item>
        </div>
        <div class="action-container d-none d-md-block col-12 col-md-6 col-lg-3 tag-btflexivel">
          <div class="img-container">
            <div class="slow-rotation">
              <img class="centralize-img" src="/assets/action_bt/tag-btflexivel-externo.png">
            </div>
            <img class="centralize-img" src="/assets/action_bt/tag-btflexivel-interno.png">
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center row">
        <div class="font-heading-3 actionbt-title text-center">Ainda não faz parte do <span class="text-brand-green">#MUNDOBT</span>?</div>
        <a [href]="linkWannaJoin" class="mundobt-button action-bt-button">Quero fazer parte</a>
        <div class="font-body-p2 text-center regulation-link">
          <span>Benefícios podem não ser aplicáveis a todos os planos e terem validade até 31/12/21 ou pelo prazo do contrato. Consulte condições e academias participantes &nbsp;</span>
          <a [href]="linkRegulation" target="_blank">aqui</a>
        </div>
      </div>
    </div>

  </div>
  <div class="footer-section font-body-small">
    <div class="container d-flex flex-column-reverse flex-md-row justify-content-between align-items-center">
      <div class="footer-logo text-center-mobile-only">
        <img src="/assets/action_bt/logo-bt.svg"><br>
        <span>Transformando histórias através do movimento</span>
      </div>
      <div class="d-flex flex-column flex-md-row align-items-center">
        <span class="social-label">Redes Sociais:</span>
        <div>
          <a href="https://www.instagram.com/bt_bodytech/" target="_blank"><img class="social instagram"></a>
          <a href="https://pt-br.facebook.com/bodytech.academias/" target="_blank"><img class="social facebook"></a>
        </div>
      </div>
    </div>
  </div>

</div>
