<div class="next-class light-shadow d-flex justify-content-between" [class.green-background]="live" (click)="cardClick()">
  <div class="d-flex flex-column">
    <div class="class-name text-ellipsis" [class.live-font-color-classname]="live">{{ classInfo.activity }}</div>
    <div class="teacher-name text-ellipsis">{{ (classInfo.teacher?.reducedName || '-') | capitalize }}</div>
    <div class="class-date">
      {{ classInfo.weekDayName.toUpperCase() }} <span (click)="showEasterEgg = !showEasterEgg">|</span>
      {{ classHour > 9 ? classHour : '0' + classHour }}:{{ classInfo.initialMinute > 9 ? classInfo.initialMinute : '0' + classInfo.initialMinute }}
      | {{classInfo.duration}} min
    </div>
  </div>
  <div class="d-flex flex-column align-end justify-content-between align-items-end">
    <div *ngIf="showEasterEgg && lessThanOneHour && !live; else showStandardEstimatedTime"
      [class.live]="live" class="estimated-time"
      timer
      [eventMinutes]="classInfo.initialMinute"
      [eventSeconds]="0"
      [eventHour]="eventHour"
      [eventDay]="eventDay"
    ></div>
    <ng-template #showStandardEstimatedTime>
      <div  [class.live]="live" class="estimated-time">{{ time }}</div>
    </ng-template>
    <img
      class="favorite"
      [alt]="live ? 'abrir aula' : 'favoritar aula'"
      [src]="live ? '/assets/bt_home/ic-play.svg' : classInfo.favoriteGroupClass ? '/assets/bt_home/ic-fav-2.svg' : '/assets/bt_home/ic-fav-1.svg'"
    >
  </div>
</div>
