import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateUtil } from '@utils/date-util';

export const maximumAgeValidator = (maxAge: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    let isValid = true;
    if (control?.value?.length > 9) {
      const formatedDate = new Date(DateUtil.dateToISO(control.value));
      const age = DateUtil.calculateAge(formatedDate);
      isValid = age <= maxAge;
    }
    return isValid ? null : { 'maxAge': true };
  };
};
