<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper">

  <ng-container *ngIf="brand === 'bt'">
    <app-franchise-header
      *appLazyCreate="!formResponse">
    </app-franchise-header>
  </ng-container>

  <app-franchise-form
    [info]="info"
    (formSentEmitter)="formSent($event)"
    *appLazyCreate="!formResponse">
  </app-franchise-form>

  <app-franchise-success
    [formResponse]="formResponse"
    *appLazyCreate="formResponse">
  </app-franchise-success>

</main>
