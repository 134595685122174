import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CouponResponse, CurrentPurchase, PaymentData, RegistrationPersonalData, RegistrationRequest, RegistrationValues } from '@models';
import { LoadingService } from './loading.service';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class RegistrationService {

  private total = 3;

  constructor(
    private readonly http: HttpClient,
    private readonly apollo: Apollo,
    private readonly loadingService: LoadingService,
  ) { }

  checkCouponDiscount(coupon: string, cpf: string): Observable<CouponResponse> {

    const couponEndpoint = (localStorage.getItem('app-user-token'))
      ? back(BackEndpoints.CuponCliente, coupon)
      : back(BackEndpoints.Coupon, cpf, coupon);

    const registrationEndpoint = (localStorage.getItem('app-user-token'))
      ? back(BackEndpoints.PurchaseGetItem)
      : back(BackEndpoints.RegistrationGetItem, cpf);

    return this.http.get<any>(couponEndpoint)
      .pipe(
        mergeMap(() => this.http.get<any>(registrationEndpoint)),
        map(data => {

          const purchaseSummary =
            data.ResumoCompraDcc ||
            data.ResumoCompraDemaisPeriodicidades ||
            data.ResumoCompraReceitaAdicional;

          const listItens: any[] = JSONUtil.get(purchaseSummary, 'ListaItensEscolhidos');
          const listParcels: any[] = JSONUtil.get(purchaseSummary, 'ListaOpcoesParcelamento');

          const couponResp: CouponResponse = {
            value: 0,
            discount: 0,
            total: 0,
            type: 0,
            parcels: listParcels.map(parc => ({
              value: parc.Valor,
              quantity: parc.QuantidadeParcelas,
            })),
          };

          listItens.forEach(item => {
            if (item.ValorDoDesconto !== 0) {
              couponResp.discount = Math.abs(item.ValorDoDesconto);
              couponResp.total = item.ValorComDesconto;
              couponResp.value = item.Valor;
              couponResp.type = item.TipoItem;
              couponResp.monthDiscountCupom = purchaseSummary.MesesDescontoCupom ?? null;
            }
          });

          return couponResp;
        }),
      );
  }

  checkValues(cpf: string): Observable<RegistrationValues> {
    const registrationEndpoint = cpf
      ? back(BackEndpoints.RegistrationGetItem, cpf)
      : back(BackEndpoints.PurchaseGetItem);

    return this.http.get(registrationEndpoint)
      .pipe(
        map(data => {
          const purchaseSummary = JSONUtil.get(data, 'ResumoCompraDcc')
          || JSONUtil.get(data, 'ResumoCompraDemaisPeriodicidades')
          || JSONUtil.get(data, 'ResumoCompraReceitaAdicional');

          const value = JSONUtil.get(purchaseSummary, 'ListaItensEscolhidos.0');
          const adhesion = JSONUtil.get(purchaseSummary, 'ListaItensEscolhidos.1');

          return {
            value: value ? value.Valor : 0,
            adhesion: adhesion ? adhesion.Valor : 0,
          };
        }),
      );
  }

  checkOperations(gymId: string, groupId: string, planId: string) {
    return this.http.get(back(BackEndpoints.CheckOperations, gymId, groupId, planId));
  }

  checkCardFlag(flag: number, cardNumber: string): Observable<boolean> {
    return this.http.get<{number: string, flag: number}[]>('assets/mockup/registration/card-flag.json')
    .pipe(
      map(values => values.find(v => cardNumber.startsWith(v.number)).flag === flag),
    );
  }

  // @ts-ignore
  sendPersonalData(formData: RegistrationPersonalData): Observable<boolean> {
    return this.http.get<{origin: string}>('https://httpbin.org/get')
    .pipe(
      map(json => json.origin.length > 0),
    );
  }

  // @ts-ignore
  sendRegistrationData(formData: RegistrationRequest): Observable<string> {
    return of(Math.random().toString().split('.')[1]);
  }

  setTotalSteps(total: number) {
    this.total = total;
  }

  startPurchase(data: any, cpf: string, preSale: boolean): Observable<PaymentData> {
    this.loadingService.startLoading(this.percent(0, this.total));

    const endpoint = preSale ? BackEndpoints.PreSaleNewClientStart : BackEndpoints.RegistrationStart;

    return this.http.post(back(endpoint, cpf), data.personal).pipe(
      tap(      () => this.loadingService.startLoading(this.percent(1, this.total))             ),
      mergeMap( () => this.http.post(back(BackEndpoints.RegistrationSetItem, cpf), data.plans) ),
      tap(      () => this.loadingService.startLoading(this.percent(2, this.total))             ),
      mergeMap(() => this.http.get<PaymentData>(back(BackEndpoints.RegistrationCheckPayment, cpf))      ),
      catchError(err => {
        console.log('Error in personal', err);
        return throwError(err);
      }),
    );
  }

  getCurrentPurchase(cpf: string): Observable<CurrentPurchase> {
    return this.http.get<CurrentPurchase>(back(BackEndpoints.RegistrationGetItem, cpf)).pipe(
      catchError(err => {
        console.log('Error in get current purchase', err);
        return throwError(err);
      }),
    );
  }

  registerUser(data: any, cpf: string) {
    this.loadingService.startLoading(this.percent(this.total - 3, this.total));

    return this.http.post(back(BackEndpoints.RegistrationSendPersonal, cpf), data.personal).pipe(
        tap(      () => this.loadingService.startLoading(this.percent(this.total - 2, this.total))         ),
        mergeMap( () => this.http.post(back(BackEndpoints.RegistrationSendAddress, cpf), data.address) ),
        catchError(err => {
          console.log('Error in registering', err);
          return throwError(err);
        }),
      );
  }

  finalizePurchase(data: any, cpf: string) {
    this.loadingService.startLoading(this.percent(this.total - 1, this.total));

    return this.http.post(back(BackEndpoints.RegistrationFinish, cpf), data.payment).pipe(
        catchError(err => {
          console.log('Error in finalize', err);
          return throwError(err);
        }),
      );
  }

  startLoggedPurchase(data: any, preSale: boolean, planId: string) {
    this.loadingService.startLoading(this.percent(0, this.total));

    const endpointsWithParams = preSale
    ? back(BackEndpoints.PreSaleStart, data.personal.unidade)
    : back(BackEndpoints.PurchaseStart, data.personal.unidade, planId);

    return this.http.get(endpointsWithParams).pipe(
      tap(      () => this.loadingService.startLoading(this.percent(1, this.total)) ),
      mergeMap( () => this.http.post(back(BackEndpoints.PurchaseSetItem), data.plans)  ),
      tap(      () => this.loadingService.startLoading(this.percent(2, this.total)) ),
      mergeMap( () => this.http.get(back(BackEndpoints.PurchaseCheckPayment))       ),
        catchError(err => {
          console.log('Error in init', err);
          return throwError(err);
        }),
      );
  }

  getCreditCardFlags(cpf?: string): Observable<PaymentData> {
    return this.http.get<PaymentData>(
      cpf
        ? back(BackEndpoints.RegistrationCheckPayment, cpf)
        : back(BackEndpoints.PurchaseCheckPayment)
    );
  }

  finalizeLoggedPurchase(data: any) {
    this.loadingService.startLoading(this.percent(1, 2));

    return this.http.post(back(BackEndpoints.PurchaseFinish), data).pipe(
        catchError(err => {
          console.log('Error in finalize logged', err);
          return throwError(err);
        }),
      );
  }

  private percent(current: number, total: number): string {
    return Math.floor(100 * current / total).toString() + '%';
  }

  getCoupomDescription(id: number) {
    return this.apollo.query({
      query: AppGraphql.queryCoupon,
      variables: { id },
    }).pipe(
      map(JSONUtil.turnApolloMutable<any>('coupon')),
    );
  }

  /**
   * PRE SALE METHODS
   */

  finalizePreSalePurchase(data: any, cpf: string) {
    this.loadingService.startLoading(this.percent(this.total - 1, this.total));

    return this.http.post(back(BackEndpoints.PreSaleNewClientFinish, cpf), data.payment).pipe(
        catchError(err => {
          console.log('Error in finalize Pre-Sale', err);
          return throwError(err);
        }),
      );
  }

  finalizeLoggedPurchasePresale(data: any) {
    this.loadingService.startLoading(this.percent(1, 2));

    return this.http.post(back(BackEndpoints.PreSaleClientFinish), data).pipe(
        catchError(err => {
          console.log('Error in finalize', err);
          return throwError(err);
        }),
      );
  }
}
