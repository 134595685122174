import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { debounceTime } from 'rxjs/operators';

import {
  AnalyticsService,
  AuthService,
  FooterService,
  HeaderService,
  LoadingService,
  ModalService,
  MyOnlineClassSchedulesService,
  SEOService,
  WindowContextService,
} from '@services';
import { TagManagerService } from '@services/tag-manager.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {

  loading = false;
  public currentUrl: string;
  showHeaderAndFooter: boolean;

  readonly landingPagesUrls = [
    '/btfit',
    '/btflexivel',
  ];

  constructor(
    public readonly headerService: HeaderService,
    public readonly footerService: FooterService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly seoService: SEOService,
    private tagManagerService: TagManagerService,
    private readonly windowContextService: WindowContextService,
    private readonly modalService: ModalService,
    private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private readonly analyticsService: AnalyticsService,
    private ccService: NgcCookieConsentService,
    private myOlineClassService: MyOnlineClassSchedulesService,
    private authService: AuthService,
  ) { }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (environment.production) {
        this.tagManagerService.create(environment.googleAnalytics.tagDmpId);
      }
    }, 3000);
  }

  ngOnInit() {
    this.analyticsService.init();
    this.angulartics2GoogleAnalytics.startTracking();
    this.angulartics2GoogleTagManager.startTracking();
    this.seoService.init();
    this.windowContextService.init();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
        this.showHeaderAndFooter = !this.landingPagesUrls.includes(this.currentUrl);
        this.loadingService.startLoading();
        this.modalService.init();
      }
      this.loadingService.stopLoading();
    });

    this.loadingService.loadingEmitter
      .pipe(
        debounceTime(100),
      )
      .subscribe((display: boolean) => this.loading = display);

    /*
     * Do not remove this call, this is necessary so that the
     * lint does not complain that the import has not been used.
     * This import is required for CookieConsent to work.
     */
    this.ccService.getConfig();

    if (this.authService.checkAuth()) {
      this.myOlineClassService.setIsvailiableHourToScheduleList(false);
    }

  }
}
