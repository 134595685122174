export * from './bucket/bucket.pipe';
export * from './calculate.pipe';
export * from './calculate-other-charges.pipe';
export * from './capitalize/capitalize.module';
export * from './capitalize/capitalize.pipe';
export * from './date-transform.pipe';
export * from './filesize.pipe';
export * from './filter-activity/filter-activity.module';
export * from './filter-activity/filter-activity.pipe';
export * from './fuse/fuse.pipe';
export * from './is-overflown/is-overflown.module';
export * from './order-by/order-by.pipe';
export * from './order-by/order-by.module';
export * from './safe.pipe';
export * from './short-weekday-name.pipe';
export * from './sort-plans.pipe';
export * from './split.pipe';

import { CalculateOtherChargesPipe } from './calculate-other-charges.pipe';
import { CalculatePipe } from './calculate.pipe';
import { DateTransformPipe } from './date-transform.pipe';
import { FilesizePipe } from './filesize.pipe';
import { SafePipe } from './safe.pipe';
import { ShortWeekdayNamePipe } from './short-weekday-name.pipe';
import { SortPlansPipe } from './sort-plans.pipe';
import { SplitPipe } from './split.pipe';

export const pipes = [
  CalculatePipe,
  CalculateOtherChargesPipe,
  DateTransformPipe,
  FilesizePipe,
  SafePipe,
  ShortWeekdayNamePipe,
  SortPlansPipe,
  SplitPipe,
];
