import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GymActivitiesResolveGuard, GymBenefitResolveGuard, GymGroupActivitiesResolveGuard } from '@guards';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';

import { GymsComponent } from './gyms.component';


const routes: Routes = [
  {
    path: ':slug/agenda',
    redirectTo: '/agenda/:slug'
  },
  {
    path: ':slug',
    loadChildren: () => import('./gym/gym.module').then(m => m.GymModule)
  },
  {
    path: '',
    component: GymsComponent,
    resolve: {
      activities: GymActivitiesResolveGuard,
      benefits: GymBenefitResolveGuard,
      config: PortalConfigResolveGuard,
      groups: GymGroupActivitiesResolveGuard,
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.searchMessage || data.imageHeader.title}',
      slug: 'gym-search',

      /** Portal config attributes (need to PortalConfigResolve work) */
      config: {
        activitiesModal: 'modal-activities',
        gymSearch: 'gym-search',
        pricesModal: 'modal-prices',
        servicesModal: 'modal-services',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GymsRoutingModule { }
