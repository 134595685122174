<li id="navbar-user-menu" class="nav-user-menu nav-item d-lg-flex d-none position-relative">
  <div
    appDropdownArrows
    appClickable
    #userLinkItem
    aria-controls="userMenu"
    aria-label="Toggle navigation"
    class="light"
    [attr.aria-expanded]="collapse.expanded"
    (click)="collapse.toggle()"
  >
    <div class="nav-link user-link d-flex align-items-center active">
      <span class="d-none d-xl-block user-name">{{ userName || 'Usuário' }}</span>

      <img alt="Perfil do usuário" class="user-pic rounded-circle" [src]="userAvatar || 'assets/images/default-user-image.png'">
    </div>
  </div>

  <div
    app-collapse
    #collapse
    class="user-menu position-absolute text-center"
    id="userMenu"
    (click)="collapse.close()"
    (mouseleave)="collapse.close()"
  >
    <div class="arrow"></div>

    <a
      *ngFor="let link of navbarUserMenu; let index = index"
      class="list-group-item list-group-item-action"
      id="navbar-user-{{index}}"
      [routerLink]="link.url"
    >
      {{ link.text }}
    </a>
    <a
      appClickable
      class="list-group-item list-group-item-action text-primary"
      id="navtop-user-logout"
      (click)="logout()"
    >
      Logout
    </a>
  </div>
</li>
