import { AppConstants } from '@utils/app-constants';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { OnlineClassSchedule } from '@models';
import { OnlineClassUtil } from '@utils/online-class-util';

@Component({
  selector: 'app-online-class-schedule-item',
  templateUrl: './schedule-item.component.html',
  styleUrls: ['../online-class-schedules-list.component.scss'],
})
export class OnlineClassScheduleItemComponent implements OnInit {
  readonly locale = AppConstants.LOCALE;

  @Input() schedules: OnlineClassSchedule[];

  @Output() cancelClick = new EventEmitter<OnlineClassSchedule>();

  constructor() { }

  ngOnInit() {
    this.schedules.forEach(schedule => {
      schedule.allActivitiesText = schedule.teacherAvailableHour.teacher.activities.map(a => a.name).join(', ');

      schedule.totalSimultaneousClientsText = OnlineClassUtil.getClassCapacity(schedule.teacherAvailableHour.totalSimultaneousClients);
    });
  }

  onCancelClick(item: OnlineClassSchedule) {
    this.cancelClick.emit(item);
  }

}
