import { Router, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PortalConfigService } from '@services/portal-config.service';
import { FooterResponse, FooterPartner } from '@models';
import { PartnersService } from '@services/partners.service';
import { Observable } from 'rxjs';
import { BrandService } from '@services/brand.service';


@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent implements OnInit {

  json: FooterResponse;
  jsonPartners: Observable<FooterPartner[]>;
  showComponent = false;
  brand = '';

  constructor(
    private readonly portalConfigService: PortalConfigService,
    private readonly partnersService: PartnersService,
    private readonly router: Router,
    private readonly brandService: BrandService,
  ) {
    const sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd
        || event instanceof NavigationCancel
        || event instanceof NavigationError) {
          setTimeout(() => this.showComponent = true, 0);
          sub.unsubscribe();
        }
    });

    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    this.portalConfigService.getPortalConfiguration<FooterResponse>('footer-br')
      .subscribe((response: FooterResponse) => this.json = response);

    // Load partners only on BT
    if (this.brand === 'bt') {
      this.jsonPartners = this.partnersService.getPartnerList();
    }
  }
}
