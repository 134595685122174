import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertMessage, MessageMap } from '@models';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthService } from '@services/auth.service';
import { AppConstants } from '@utils/app-constants';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { Apollo } from 'apollo-angular';
import { catchError } from 'rxjs/operators';

import { appRoutes } from 'app/app.routing';

@Component({
  selector: 'app-debug-route-test',
  templateUrl: './route-test.component.html',
  styleUrls: ['./route-test.component.scss'],
})
export class RouteTestComponent {

  routesToTest = appRoutes[0].children;

  constructor(
    private readonly router: Router,
    private readonly alertMessageService: AlertMessageService,
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly apollo: Apollo,
  ) { }

  navigateToIndex(index: number) {
    if (this.routesToTest[index]) {
      if (this.routesToTest[index].path.includes(':')) {
        this.navigateToIndex(index + 1);
      } else {
        this.router.navigateByUrl(this.routesToTest[index].path)
          .then(() => setTimeout(() => this.navigateToIndex(index + 1), 600));
      }
    } else {
      this.alertMessageService.showToastr(AlertMessage.debug(MessageMap.ROUTE_TEST_DONE));
    }
  }

  navigateToNotFound() {
    this.router.navigateByUrl('404');
  }

  getUnknownFile() {
    this.http.get('/batata').subscribe(() => {});
  }

  getToken() {
    const tokenKey = AppConstants.STOR_SUR_TOKEN;
    this.authService.clearAppToken(tokenKey);
    this.authService.getAppToken(tokenKey).subscribe(token => {
      this.alertMessageService.showToastr(AlertMessage.debug(MessageMap.TOKEN_GERADO), [token.slice(0, 32)]);
    });
  }

  getAgenda() {
    this.http.get<any>(back(BackEndpoints.Grade), {
        params: {
          idunidade: '1',
          idatividade: '',
          guidprofessor: 'a42ee991-22fd-42f9-a8ea-ace44155cd2f',
          idespaco: '',
          idfaixaetaria: '',
        },
      })
      .pipe(
        catchError((err: any) => {
          console.log('Erro em route-test.comp', err);
          return [];
        }),
      )
      .subscribe(json => {
        this.alertMessageService.showToastr(AlertMessage.debug(MessageMap.AGENDA_DONE));
        console.log('Agenda', json);
      });
  }

  getApollo() {
    this.apollo.query<any>({
      query: AppGraphql.queryListActiveActivities,
    })
    .subscribe(data => console.log(data));
  }

}
