<ng-template #header>
  <app-header-static-image [headerObj]="config.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container">

  <div class="card d-flex flex-lg-row flex-md-column no-side-border-xs row-on-xs">
    <app-lateral-tabs name="screen" (click)="closeProfilePayment()" [hasIcon]="true" [tabs]="tabs" [(activeTab)]="activeTab"></app-lateral-tabs>
    <div class="tab-content">
      <app-profile-data *appLazyCreate="activeTab == tabs[0]"></app-profile-data>
      <app-profile-contact *appLazyCreate="activeTab == tabs[1]"></app-profile-contact>
      <app-profile-account *appLazyCreate="activeTab == tabs[2]"></app-profile-account>
      <app-contract *appLazyCreate="activeTab == tabs[3]"></app-contract>
      <app-purchases *appLazyCreate="activeTab == tabs[4]"></app-purchases>
    </div>

  </div>

</div>
