<ng-template #header>
  <app-header-static-image
  [pageTitle]="headerTitle"
  [imageUrl]="imageHeader">
  </app-header-static-image>
</ng-template>
<div class="main-wrapper container">
  <ng-template #sectionTitle>
    <div class="title-section text-black">Agendamentos</div>
  </ng-template>
  <ng-container *ngIf="!!schedules; else loading">
    <div class="card p-4 p-md-5 row-on-xs no-side-border-xs">
      <ng-container *ngIf="schedules.length; else empty">
        <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
        <app-schedule-item [schedules]="schedules" (cancelClick)="onCancelClick($event)"></app-schedule-item>
        <div class="btn-container text-center pt-4">
          <button routerLink="/cliente/agendamentos/novo" class="btn btn-primary">
            Agendar novo horário
          </button>
        </div>
      </ng-container>
      <ng-template #empty>
        <div class="error-container">
          <app-empty-card
            *ngIf="!!gyms && gyms.length; else emptygym"
            icon="/assets/icons_custom/ic-empty-list.svg"
            altIcon="Lista vazia"
            message="Você ainda não possui nenhum horário agendado."
            buttonText="Agendar novo horário"
            buttonLink="/cliente/agendamentos/novo">
            <ng-template #cardHeader>
              <div class="section-title-container">
                <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
              </div>
            </ng-template>
          </app-empty-card>
          <ng-template #emptygym>
            <app-empty-card
              icon="/assets/icons_custom/ic-empty-list.svg"
              altIcon="Lista vazia"
              message="Seu agendamento não está disponível, pois sua unidade ainda não reabriu ou seu plano está vencido. Para mais informações, procure a equipe da sua academia.">
              <ng-template #cardHeader>
                <div class="section-title-container">
                  <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
                </div>
              </ng-template>
            </app-empty-card>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="title text-center">
      <i class="fas fa-spinner fa-pulse"></i> Carregando
    </div>
  </ng-template>
</div>
