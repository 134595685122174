import { Injectable } from '@angular/core';
import { PressArea, PressPosts, PostTotal } from '@models';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { AppGraphql } from '@utils/app-graphql';
import { map } from 'rxjs/operators';
import { JSONUtil } from '@utils/json-util';

@Injectable()
export class PressReleasePageService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getPressAreaList(): Observable<PressArea[]> {
    return this.apollo.query<any>({
      query: AppGraphql.queryPressAreaList,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<PressArea []>('pressAreaList')),
    );
  }

  getPressPostByArea(area: string, offset: number): Observable<PressPosts> {
    return this.apollo.query<any>({
      query: AppGraphql.queryListPressPostsByPressAreaName,
      variables: {area, offset},
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<PressPosts>('listPressPostsByPressAreaName')),
    );
  }

  getPressPostTotalCount(area: string, offset: number): Observable<PostTotal> {
    return this.apollo.query<any>({
      query: AppGraphql.queryListPressPostsTotalCount,
      variables: {area, offset},
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<PostTotal>('listPressPostsByPressAreaName')),
    );
  }
}
