<ng-template #loader>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>

<ng-container *ngIf="!loading; else loader">
  <form #form="ngForm" class="form-validate" *ngIf="info; else error">
    <!-- ENDEREÇO -->
    <div class="section">
      <app-data-address #dataAddress name="profile" [address]="info.address">
      </app-data-address>
    </div>

    <!-- TELEFONES -->
    <div class="section mt-5">
      <app-profile-data-phones #dataPhones [phones]="info.phones">
      </app-profile-data-phones>
    </div>

    <!-- CONTATOS DE EMERGÊNCIA -->
    <div class="section mt-5">
      <app-profile-data-emergency #dataEmergency [emergency]="info.emergency">
      </app-profile-data-emergency>
    </div>

    <!-- ACEITO RECEBER EMAIL/ SMS -->
    <div class="newsletter mt-5">
      <app-checkbox [(check)]="info.newsletter"
        message="Aceito receber e-mail/SMS/WhatsApp com informações importantes e ofertas especiais {{brandService.fullBrandName}}.">
      </app-checkbox>
    </div>

    <div class="text-center button-container mt-4">
      <button id="profile-save" (click)="saveData()" class="btn btn-primary btn-confirm">
        Salvar
      </button>
    </div>
  </form>
</ng-container>

<ng-template #error>
  <app-empty-card icon="/assets/icons_custom/ic-empty-list.svg" altIcon="Lista vazia"
    [message]="emptyMessage"
  ></app-empty-card>
</ng-template>