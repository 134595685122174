<ng-template #header>
  <app-header-carousel [data]="banners"></app-header-carousel>
</ng-template>

<main class="main-wrapper">
  <div class="section">
    <app-main-gym [data]="json?.search"></app-main-gym>
  </div>
  <div class="section">
    <app-main-concept [data]="json?.concept"></app-main-concept>
  </div>
  <div class="section">
    <app-main-blog [data]="json?.blog"></app-main-blog>
  </div>
</main>
