import { Component, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ResetUserAccessRequest } from '@models';
import { AlertMessageService, AuthService, HeaderService, LoadingService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from '@services/brand.service';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-reset-access',
  templateUrl: './reset-access.component.html',
  styleUrls: [
    '../../../area-user.component.scss',
  ],
})
export class ResetAccessComponent implements AfterViewInit {

  public config: BasicHeaderConfig;

  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  resetAcess: ResetUserAccessRequest = {
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
  };

  success = false;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly headerService: HeaderService,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
   }

  resetAccess() {
    FormUtil.touchForm(this.form.control);

    if (this.form.invalid) {
      return;
    }

    this.loadingService.startLoading();

    this.authService.resetUserAccess(this.resetAcess, localStorage.getItem(AppConstants.STOR_RESET_USER_ACESS_TOKEN))
      .subscribe(
        () => {
          localStorage.removeItem(AppConstants.STOR_RESET_USER_ACESS_TOKEN);
          this.success = true;
        },
        (err: any) => this.alertMessageService.showToastr(err),
        () => this.loadingService.stopLoading(),
      );
  }

}
