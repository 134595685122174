<a
  *ngIf="phoneNumber"
  class="btn btn-whatsapp h-auto d-flex position-fixed"
  href="https://wa.me/{{phoneNumber}}"
  target="_blank"
>
  <span class="title align-self-center">Tenho uma dúvida</span>

  <img class="img-fluid" src="assets/icons/ic-general-wp.svg" alt="Whatsapp icon">
</a>
