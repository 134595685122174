import { Component, Input } from '@angular/core';
import { FooterSocial } from '@models';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'app-footer-social-media',
  templateUrl: './footer-social-media.component.html',
  styleUrls: ['./footer-social-media.component.scss'],
})
export class FooterSocialMediaComponent {

  @Input() data: FooterSocial;

  constructor(
    private readonly analyticsService: AnalyticsService,
  ) { }

  clickSocial(social: string) {
    this.analyticsService.trackEvent(
      `Rede Social - ${social}`,
      'Footer',
      'Rede Social',
    );
  }
}
