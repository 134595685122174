import { Component, OnInit } from '@angular/core';
import { Gym, GymActivity, GymPlan, ModalType } from '@models';
import { ModalService } from '@services';

import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-gym-plan',
  templateUrl: './modal-gym-plan.component.html',
  styleUrls: ['./modal-gym-plan.component.scss'],
})
export class ModalGymPlanComponent extends ModalCommonComponent implements OnInit {

  plan: GymPlan;
  gym: Gym;
  title: string;
  availableActivities: string;
  gymsAvailableOnPlan: Gym[];

  gymActivities: GymActivity[];

  combinatedMessage: string;

  constructor(
    protected modalService: ModalService,
  ) {
    super();
    this.type = ModalType.gymPlan;
  }

  init() {
    this.title = this.modalObj.title;
    this.plan = this.modalObj.data.plan || [];
    this.gym = this.modalObj.data.gym || [];
    this.availableActivities = this.modalObj.data.availableActivities;

    this.combinatedMessage = this.modalObj.data.combinatedMessage;
    this.gymsAvailableOnPlan = this.modalObj.data.gymsAvailableOnPlan;
  }

  confirm() {
    this.combinatedMessage = null;
    super.confirm();
  }
}
