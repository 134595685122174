<div class="card text-center">
  <div class="plan" [class.less-info]="!plansExpanded">
    <div class="title-section no-bottom-line">{{ gymUnitGroupPlan.portalName || gymUnitGroupPlan.plan.shortName }}</div>
    <div [appMaxLines]="4" class="description" [class.mb-4]="isLandingPageKids">{{ gymUnitGroupPlan.shortDescription ||  gymUnitGroupPlan.longDescription }}</div>
    <div class="title more-info link" *ngIf="!isLandingPageKids">
      <a id="plan-{{gymUnitGroupPlan.plan.slug}}-info" href="javascript:void(0)" (click)="showPlanDetail()">Mais informações</a>
    </div>
    <div *ngFor="let periodicity of gymUnitGroupPlan.plan.gymUnitPeriodicities | sortPlans : gymUnitGroupPlan.gymUnit : brand; let first = first"
      class="pay-options-container"
      [class.only-when-expanded]="!first"
      [class.has-promo-bt]="gymUnitGroupPlan.gymUnit?.activePromotion && brand === 'bt'"
      [class.anual-card]="first">
      <app-gym-detail-pay-option
        gymName="{{gymUnitGroupPlan.gymUnit.portalName}}"
        [preSale]="gymUnitGroupPlan.gymUnit.preSale"
        [planTitle]="gymUnitGroupPlan.portalName || gymUnitGroupPlan.plan.shortName"
        [planSlug]="gymUnitGroupPlan.plan.slug"
        [gymUnitPeriodicity]="periodicity"
        [promotionActived]="gymUnitGroupPlan.gymUnit.activePromotion"
        [showPromotionTag]="first"
        [isLandingPageKids]="isLandingPageKids"
      ></app-gym-detail-pay-option>
    </div>
  </div>
  <div id="plan-{{gymUnitGroupPlan.plan.slug}}-more" appClickable class="title font-weight-normal expand-action" (click)="planExpandToggle()">
    <div *ngIf="plansExpanded; else retracted">
      <img src="assets/icons/ic-caret-up.svg">
      <span>Ver menos</span>
    </div>
    <ng-template #retracted>
      <div>
        <img src="assets/icons/ic-caret-down.svg">
        <span>Ver mais</span>
      </div>
    </ng-template>
  </div>
</div>
