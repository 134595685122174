<div class="title position-absolute bestseller" *ngIf="gymUnitPeriodicity.popular && !isPromotionActived">
  <div class="background"></div>
  <div class="text">
    Mais<br>vendido
  </div>
</div>

<div class="title position-absolute promotion {{ brand }}" *ngIf="isPromotionActived">
  <div class="background"></div>
  <div class="text">Black<br>Friday!</div>
</div>

<ng-container [ngSwitch]="gymUnitPeriodicity.status">
  <div *ngSwitchCase="1" class="pay-option disponible">
    <div *ngIf="isLandingPageKids; else withSubscribeButton" 
    class="d-flex flex-column justify-content-center h-100">
    <ng-container *ngTemplateOutlet="payPrice"></ng-container>
  </div>
  <ng-template #withSubscribeButton>
    <a 
      id="pay-{{gymUnitPeriodicity.periodicity.slug}}-payOption"
      class="text-dark w-100 h-100 d-flex flex-column justify-content-center align-items-center"
      (click)="clickOnEnroll()"
      (auxclick)="clickOnEnroll()"
      routerLink="./compra/{{ gymUnitPeriodicity.planSlug }}/{{ gymUnitPeriodicity.periodicity.slug }}/dados-pessoais">
      <ng-container *ngTemplateOutlet="payPrice"></ng-container>
      <div class="title-md pay-link link text-primary">{{ preSale ? 'Pré-Matrícula' : 'Matricule-se'}}</div>
    </a>
  </ng-template>
  </div>
  <div *ngSwitchCase="2" class="pay-option">
    <div class="pay-option">
      <div class="unavaliable d-flex flex-column justify-content-center">
        <div class="title font-weight-normal pay-name">{{ planTitle }} • {{ gymUnitPeriodicity.periodicity.name }}</div>
        <div class="title-md">Produto indisponível<br>Consulte outras opções</div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="3" class="pay-option">
    <div class="unavaliable d-flex flex-column justify-content-center">
      <div class="title font-weight-normal pay-name">{{ planTitle }} • {{ gymUnitPeriodicity.periodicity.name }}</div>
      <div class="title-md pay-link" routerLink="/contato">Consulte a unidade</div>
    </div>
  </div>
</ng-container>


<ng-template #payPrice>
  <div class="title font-weight-normal pay-name">
    <ng-container *ngIf="!isPromotionActived || brand === 'fr'; else hasPromotion">
      {{ planTitle }} • {{ gymUnitPeriodicity.periodicity.name }}
    </ng-container>
    <ng-template #hasPromotion>
      {{ planTitle }}
      <br>
      {{ gymUnitPeriodicity.periodicity.name }}
    </ng-template>
  </div>
  <div i18n>
    {{ gymUnitPeriodicity.periodicity.debitedMonthly ? 'Débito no Cartão de Crédito -' : '' }}
    { gymUnitPeriodicity.periodicity.months, plural, =1 { Valor à vista } other {
     {{isLandingPageKids ? 'A partir de' : ''}} {{ gymUnitPeriodicity.periodicity.months }}x de 
    } }
  </div>
  <div class="title pay-value">{{ amount | currency:'BRL':'symbol':'':locale }}</div>
  <ng-container *ngIf="brand === 'bt' && !isLandingPageKids; else defaultFee">
    <div *ngIf="gymUnitPeriodicity.showChannelSubscriptionFee || gymUnitPeriodicity.showChannelRegistration; else defaultFee">+ {{ feeText }} de {{ feeValue | currency:'BRL':'symbol':'':locale }}</div>
  </ng-container>
  <ng-template #defaultFee>
    <div *ngIf="!isLandingPageKids">+ Taxa de Adesão de {{ feeValue | currency:'BRL':'symbol':'':locale }}</div>
    <div *ngIf="isLandingPageKids">Consulte a taxa de adesão na unidade</div>
  </ng-template>

</ng-template>
