<div class="card no-side-border-xs row-on-xs">
  <div class="d-flex flex-column flex-md-row">
    <div class="icon-container">
      <img src="{{ notice?.image || '/assets/icons_custom/ic-filter-megaphone.svg' }}">
    </div>

    <div class="text-container d-flex flex-column justify-content-between">
      <div class="title-md font-weight-normal">{{ notice?.title }}</div>
      <div class="notice-message" [innerHTML]="notice?.description"></div>
    </div>
  </div>
</div>