<div class="swiper-arrow-container outside-container position-relative">
  <ng-container *ngIf="memberships?.length; else emptyMembershipsTemplate">
    <swiper *ngIf="memberships?.length > 2 || isBreakpointSmOrLower; else centeredMembershipsTemplate" [config]="swiperConfig">
      <app-membership
        *ngFor="let membership of memberships"
        [membership]="membership"
        [hasSubscribeButton]="hasSubscribeButton"
        (clickShowMore)="this.clickShowMore.emit($event)"
        [(offersIsContract)]="offersIsContract"
      ></app-membership>
    </swiper>

    <ng-template #centeredMembershipsTemplate>
      <div class="d-flex justify-content-center">
        <app-membership
          *ngFor="let membership of memberships"
          class="membership-card-center"
          [membership]="membership"
          [hasSubscribeButton]="hasSubscribeButton"
          (clickShowMore)="this.clickShowMore.emit($event)"
          [(offersIsContract)]="offersIsContract"
        ></app-membership>
      </div>
    </ng-template>
  </ng-container>
</div>

<app-modal-gym-plan></app-modal-gym-plan>

<ng-template #emptyMembershipsTemplate>
  <app-empty-card
    icon="/assets/icons_custom/ic-empty-list.svg"
    altIcon="Lista vazia"
    [message]="emptyMessage"
  ></app-empty-card>
</ng-template>
