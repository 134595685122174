<main class="main-wrapper container" >
  <div class="card no-side-border-xs row-on-xs mb-4">
    <section *ngIf="extensionData; else loading">
    <div class="pt-4 pl-4 pr-4 pt-md-5 pl-md-5 pr-md-5">
      <div class="title-section">Solicitar Trancamento</div>

      <div class="text-center">
        <div class="mb-4 days-remaining-disclaimer" *ngIf="!isDCC">
          Você tem <strong >{{ daysRemaining }} dias</strong> disponíveis para trancamento.
        </div>
        <button
          type="button"
          id="credit-card-save"
          (click)="lockingRegistration()"
          class="btn btn-primary btn-confirm mb-12"
          [class.disabled]="(isDCC && !extensionData?.AptoSolicitarBloqueioDCC?.Apto) || (!isDCC && daysRemaining === 0)"
          [disabled]="(isDCC && !extensionData?.AptoSolicitarBloqueioDCC?.Apto) || (!isDCC && daysRemaining === 0)"
        >
          Solicitar Trancamento
        </button>
        <div class="locking-disclaimer">
          <ng-container *ngIf="isDCC; else isDMP">
            Você poderá solicitar o primeiro trancamento a partir do sexto mês de débito e depois a cada seis meses.
            O período de trancamento sempre começa na data da renovação mensal. Só é possível agendar um trancamento por vez.
          </ng-container>
          <ng-template #isDMP>
            É permitido agendar mais de um trancamento por vez e o cancelamento pode ser realizado a qualquer momento.
          </ng-template>
        </div>
      </div>
    </div>

    <ng-container *ngIf="ongoingLockingList?.length > 0 || filteredScheduleList?.length > 0">
      <div class="title-section pl-4 pr-4 pl-md-5 pr-md-5 mb-5">Trancamentos</div>
       <div class="table-container pl-4 pr-4 pl-md-5 pr-md-0 mb-5">
        <table *ngIf="!isDCC; else ongoingListDCC">
          <tr *ngFor="let lockingItem of ongoingLockingList" class="border-top border-bottom" >
            <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0" >
              <b>{{ lockingItem.DataInicioProrrogacao | dateTransform | date:'shortDate':'':locale }}  a
              {{ lockingItem.finalDayVacation | date:'shortDate':'':locale }}</b></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0">{{ lockingItem.NrDiasProrrogacao }}
                {{lockingItem.NrDiasProrrogacao === 1 ? ' dia' : ' dias'}}
              </td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0 green bold">
               Trancamento em curso
              </td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-0 border-0"></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-0 border-0"></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-2 pt-2 border-0 title link primary">
                <a (click)="cancelLocking(lockingItem, false)"> CANCELAR</a></td>
          </tr>
          <tr *ngFor="let schedule of filteredScheduleList" class="border-top border-bottom" >
            <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0">
              <b>{{ schedule.DataInicioFerias | dateTransform | date:'shortDate':'':locale }}  a
              {{ schedule.finalDayVacation | date:'shortDate':'':locale }}</b></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0">{{ schedule.NrDiasFerias }}
                {{schedule.NrDiasProrrogacao === 1 ? ' dia' : ' dias'}}
              </td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0 gray-500" >
                Trancamento agendado
              </td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-0 border-0"></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-0 border-0"></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-2 pt-2 border-0 title link primary">
                <a (click)="cancelLocking(schedule, true)"> CANCELAR</a></td>
          </tr>
        </table>

        <ng-template #ongoingListDCC>
         <table>
          <tr *ngFor="let lockingItem of ongoingLockingList" class="border-top border-bottom">
            <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0">
              <b>{{ lockingItem.DtInicioOcorrencia | dateTransform | date:'shortDate':'':locale }}  a
              {{ lockingItem.DtTerminoOcorrencia | dateTransform | date:'shortDate':'':locale }}</b></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0">{{ lockingItem.duration }}
                {{lockingItem.duration === 1 ? ' mês' : ' meses'}}
              </td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-2 border-0"
                [ngClass]="lockingItem.CdEstadoOcorrencia === 1 ? 'green bold' : 'gray-500'">
                {{ lockingItem.CdEstadoOcorrencia === 1 ? 'Trancamento em curso' : 'Trancamento agendado'}}
              </td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-0 border-0"></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-0 pt-0 border-0"></td>
              <td class="d-flex d-md-table-cell pb-md-2 pb-2 pt-2 border-0 title link primary"><a (click)="cancelLockingDCC(lockingItem)"> CANCELAR</a></td>
          </tr>
        </table>
      </ng-template>
      </div>

    </ng-container>

    <div class="pl-4 pr-4 pl-md-5 pr-md-5">
      <div class="title-section">Histórico de Trancamento</div>
    </div>

    <div class="table-container mb-5 ml-4 ml-md-5"
      *ngIf="filteredHistoryList && filteredHistoryList?.length > 0; else empty">
      <div *ngIf="isDCC === false; else historyDCC">
        <table class="table table-striped">
          <tr>
            <th>PLANO</th>
            <th>UNIDADE</th>
            <th>SOLICITAÇÃO</th>
            <th>INÍCIO</th>
            <th>FINAL</th>
            <th>DURAÇÃO</th>
          </tr>
          <tr *ngFor="let historyItem of filteredHistoryList">
            <td>{{ historyItem.NmPlano }}</td>
            <td>{{ historyItem?.gymUnitName }}</td>
            <td>{{ historyItem.DataSolicitacaoProrrogacao | dateTransform | date:'shortDate':'':locale }}</td>
            <td>{{ historyItem.DataInicioProrrogacao | dateTransform | date:'shortDate':'':locale }}</td>
            <td>{{ historyItem.finalDayVacation | date:'shortDate':'':locale  }}</td>
            <td>{{ historyItem.NrDiasProrrogacao }}
              {{historyItem.NrDiasProrrogacao === 1 ? ' dia' : ' dias'}}
            </td>
          </tr>
        </table>
        </div>
      <ng-template #historyDCC>
      <table class="table table-striped">
        <tr>
          <th>UNIDADE</th>
          <th>SOLICITAÇÃO</th>
          <th>INÍCIO</th>
          <th>FINAL</th>
        </tr>
        <tr *ngFor="let historyItem of filteredHistoryList">
          <td>{{ historyItem?.gymUnitName }}</td>
          <td>{{ historyItem.DtSolicitacao  | dateTransform | date:'shortDate':'':locale }}</td>
          <td>{{ historyItem.DtInicioOcorrencia | dateTransform | date:'shortDate':'':locale }}</td>
          <td>{{ historyItem.CdEstadoOcorrencia === 0
            ? (historyItem.finalDay | date:'shortDate':'':locale)
            : historyItem.formattedEndOcurrence | date:'shortDate':'':locale  }}</td>
        </tr>
      </table>
      </ng-template>
    </div>
    <ng-template #empty>
        <div class="container">
          <app-empty-card icon="/assets/icons_custom/ic-empty-list.svg" altIcon="Lista vazia"
          message="Não há itens para exibir no histórico."></app-empty-card>
        </div>
      </ng-template>
    </section>
  </div>
</main>

<ng-template #loading>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>

<app-modal-registration-locking></app-modal-registration-locking>