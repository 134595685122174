import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { isProduction } from '@env/environment';
import { RegistrationPersonalData, TypesOfProducts } from '@models';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';
import { BrandService } from '@services/brand.service';

@Component({
  selector: 'app-form-personal-data',
  templateUrl: './form-personal-data.component.html',
  styleUrls: ['./form-personal-data.component.scss'],
})
export class FormPersonalDataComponent implements OnInit {

  readonly docMask = { mask: AppConstants.Mask.doc };
  readonly dateMask = { mask: AppConstants.Mask.dateFull };
  readonly phoneMask = { mask: AppConstants.Mask.phone };
  readonly genderOptions = [
    { id: 'M', text: 'Masculino' },
    { id: 'F', text: 'Feminino' },
  ];

  readonly emailRegexp = AppConstants.EMAIL_REGEXP;

  @ViewChild('formPersonal', { static: false }) form: NgForm;

  @Input() adhesionUrl = '';
  @Input() typeOfProduct: number;

  @Input() formPersonalData: RegistrationPersonalData;
  @Output() formPersonalDataChange = new EventEmitter<RegistrationPersonalData>();

  redirectToMe: any;
  typesOfProducts = TypesOfProducts;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.redirectToMe = { next: this.router.url };
  }

  isValid(): boolean {
    FormUtil.touchForm(this.form.control);
    return this.form.valid;
  }

  @HostListener('keydown', ['$event'])
  autoFill(event: KeyboardEvent) {
    if (!isProduction && event.key === '/' && event.ctrlKey) {
      const p = 'qwe123';
      Object.assign(this.formPersonalData, {
        name: 'Portal Test',
        cpf: '32798120039',
        birthdate: '04/02/1991',
        email: 'henrique.n@mailinator.com',
        emailCheck: 'henrique.n@mailinator.com',
        phone: '(16) 1234-9876',
        acceptMail: false,
        gender: 'M',
        password: p,
        passwordCheck: p,
      });
      this.isValid();
    }
  }

}
