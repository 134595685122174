<div *ngFor="let purchase of purchaseItem?.purchases; let index = index; let first = first">
  <div
    *ngIf="first || purchase.date !== purchaseItem.purchases[index - 1].date"
    class="mt-2 mb-2">
    {{ purchase.date }}
  </div>
  <div class="contract-container">
    <div [class.collapsed]="!block.expanded"
      appDropdownArrows
      appClickable
      (click)="block.toggle()"
      class="list-group list-group-flush title table-header flex-row"
      [attr.aria-expanded]="block.expanded"
      attr.aria-controls="block{{ index }}">
      <div class="d-flex flex-column flex-md-row w-100 justify-content-between">
        <span class="plan">{{ purchase.item }}</span>
      </div>
    </div>
    <div [class.mb-3]="block.expanded" class="d-flex flex-column flex-md-row purchase-item Oswald"
     id="block{{index}}" #block app-collapse [expanded]="false">
      <div class="pl-md-0 pr-md-2 mb-3 mb-md-0">
        <div class="dark title font-weight-normal mb-2">CÓDIGO</div>
        <div class="Roboto-Regular">{{ purchase.code }}</div>
      </div>
      <div class="col-md-4 pl-0 px-md-2 mb-3 mb-md-0">
        <div class="dark title font-weight-normal mb-2">UNIDADE</div>
        <div class="Roboto-Regular">{{ purchase.gym }}</div>
      </div>
      <div class="col-md-3 pl-0 px-md-2 mb-3 mb-md-0">
        <div class="dark title font-weight-normal mb-2">VALOR</div>
        <div class="Roboto-Regular">{{ purchase.value | currency:'BRL':'symbol':'':locale }}</div>
      </div>
      <div class="col-md-2 pl-0 px-md-2 mb-3 mb-md-0">
        <div class="dark title font-weight-normal mb-2">STATUS</div>
        <div class="Roboto-Regular">{{ purchase.status }}</div>
      </div>
      <div class="col-md-2 pl-0 pl-md-2 pr-0">
        <div class="dark title font-weight-normal mb-2">CONTRATO</div>
        <div class="link" (click)="getContractUrl(purchase)">VER CONTRATO</div>
      </div>
    </div>
</div>