import { Component, OnInit, Input } from '@angular/core';

import { ModalType, OnlineClassTeacher, SelectItem } from '@models';
import { ModalService } from '@services';

import { ModalCommonComponent } from '../modal-common.component';


@Component({
  selector: 'app-modal-teacher-select',
  templateUrl: './modal-teacher-select.component.html',
  styleUrls: ['./modal-teacher-select.component.scss'],
})
export class ModalTeacherSelectComponent extends ModalCommonComponent implements OnInit {

  @Input() analyticsCategory: string;
  @Input() analyticsTag: string;

  data: SelectItem<any>[];
  selectedItem: SelectItem<any>;
  showDayOfTheWeek = false;

  modalObj: any;

  constructor(
    protected modalService: ModalService,
  ) {
    super();
    this.type = ModalType.teacherSelect;
  }

  init() {
    this.data = this.modalObj.data || [];
    this.selectedItem = this.modalObj.selectedValue;
    this.showDayOfTheWeek = this.modalObj.showDayOfTheWeek;
  }

  select(item: SelectItem<any>) {
    this.modalObj.data = item;
    super.confirm();
  }

  getActivities(item: OnlineClassTeacher) {
    return item.teacher.activities.map(a => a.name).join(', ');
  }

}
