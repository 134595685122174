import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { GymActivity, GymBenefit, GymFilter, GymSearchFilter, ModalGymActivityObject, ModalGymBenefitsObject, ModalType } from '@models';
import { FullGymSearchConfig } from '@models/configs';
import { ModalService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { ArrayUtil } from '@utils/array-util';
import { FormatUtils } from '@utils/format-utils';


@Component({
  selector: 'app-gym-filter',
  templateUrl: './gym-filter.component.html',
  styleUrls: ['./gym-filter.component.scss'],
})
export class GymFilterComponent implements OnInit {

  @Output() filterChange = new EventEmitter<GymSearchFilter>();

  private activityGroups: GymFilter[];
  private allActivities: GymActivity[];
  private allBenefits: GymBenefit[];

  private timeout: NodeJS.Timeout;

  public readonly locale = AppConstants.LOCALE;
  public readonly filter: GymSearchFilter = {
    activities: [],
    benefits: [],
  };

  public activityTags: GymFilter[] = [];
  public config: FullGymSearchConfig;


  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    const data = this.activatedRoute.snapshot.data;
    this.config = data.config;

    this.allActivities = data.activities ?? [];

    this.activityGroups = data.groups?.map((group: GymFilter) => ({
      ...group,
      activityGroup: { id: -1 },
    })) ?? [];

    this.allBenefits = data.benefits ?? [];
    this.allBenefits = this.allBenefits.filter(benefit => benefit.name.toUpperCase() !== 'PERSONAL');

    this.updateTagsFromQueryParam(this.activatedRoute.snapshot.queryParamMap);
  }

  private filtersToParams(filter: GymSearchFilter, timeout = 0): void {
    if (this.timeout) { clearTimeout(this.timeout); }

    const queryParams = {
      ...FormatUtils.encodeGymSearchFilterToParamObject(filter),
      q: this.activatedRoute.snapshot.queryParamMap.get('q') ?? null,
    };

    /** This is set to a timeout in cases the user removes filters in sequence, wait it removes every
     *  filter they want before call te service */
    this.timeout = setTimeout(() => {
      this.router.navigate(['.'], { queryParams, relativeTo: this.activatedRoute });
    }, timeout);
  }

  private updateTagsFromQueryParam(queryParamMap: ParamMap): void {
    this.clearFilter(false);

    const activities = atob(queryParamMap.get(AppConstants.PARAM_SEARCHGYM_ACTIVITIES) ?? '');

    activities.split(',').forEach(activityId => {
      const activityFinded = this.allActivities.find(activity => activity.id === +activityId);

      if (activityFinded) {
        activityFinded.selected = true;
        this.filter.activities.push(activityFinded as GymFilter);
      }
    });

    this.updateActivityTags();

    const services = atob(queryParamMap.get(AppConstants.PARAM_SEARCHGYM_SERVICES) || '');

    services.split(',').forEach(serviceId => {
      const service = this.allBenefits.find(benefit => benefit.id === +serviceId);

      if (service) {
        service.selected = true;
        this.filter.benefits.push(service as GymFilter);
      }
    });

    this.filterChange.emit(this.filter);
  }

  private updateActivityTags(): void {
    this.activityTags = [];

    for (const group of this.activityGroups) {
      this.activityTags.push(...this.groupTagsWhenAllChecked(group.id));
    }
  }

  private groupTagsWhenAllChecked(group: number): GymFilter[] {
    const listActivities = this.filter.activities.filter(activity => activity.activityGroup.id === group);
    const allActivitites = this.allActivities.filter(activity => activity.activityGroup.id === group);

    return listActivities?.length === allActivitites.length
      ? [this.activityGroups.find(item => item.id === group)]
      : listActivities;
  }

  public showActivitiesSelectModal(): void {
    this.modalService.show({
      type: ModalType.gymActivity,
      title: this.config.activitiesModal.title,
      message: '',
      confirmCallback: (data: GymFilter[]) => {
        this.filter.activities = data;
        this.updateActivityTags();
        this.filterChange.emit(this.filter);
        this.filtersToParams(this.filter);
      },
      activities: this.allActivities,
      groups: this.activityGroups,
      config: this.config.activitiesModal,
    } as ModalGymActivityObject);
  }

  public showBenefitsSelectModal(): void {
    this.modalService.show({
      type: ModalType.gymBenefit,
      title: this.config.servicesModal.title,
      message: '',
      confirmButton: this.config.servicesModal.buttonText,
      confirmCallback: (data: GymFilter[]) => {
        this.filter.benefits = data;
        this.filterChange.emit(this.filter);
        this.filtersToParams(this.filter);
      },
      benefits: this.allBenefits,
      config: this.config.servicesModal,
    } as ModalGymBenefitsObject);
  }

  public clearFilter(navigate = true): void {
    this.allActivities = this.allActivities.map(item => ({
      ...item,
      selected: false,
    }));

    this.allBenefits = this.allBenefits.map(item => ({
      ...item,
      selected: false,
    }));

    this.activityTags       = [];
    this.filter.activities  = [];
    this.filter.benefits    = [];

    this.filterChange.emit(this.filter);

    if (navigate) { this.filtersToParams(this.filter); }
  }

  public removeFilter(list: GymFilter[], specificItem: GymFilter): void {
    specificItem.selected = false;
    ArrayUtil.removeItem(list, specificItem);

    if (list === this.filter.activities) {
      ArrayUtil.removeItem(this.activityTags, specificItem);

      if (specificItem.activityGroup.id < 0) {
        list.filter(item => item.activityGroup.id === item.id).forEach(item => this.removeFilter(list, item));
      }
    }

    this.filterChange.emit(this.filter);
    this.filtersToParams(this.filter, 2500);
  }

}
