import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';

import { BreakpointsService, screenSizeMap } from '@services/breakpoints.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnDestroy {

  @Input() p = 1;
  @Output() pChange = new EventEmitter<number>();
  @Input() range = 2;
  @Input() totalpages = 1;

  private subs: Subscription;
  private screen = 3;

  constructor(
    private readonly breakpointsService: BreakpointsService,
  ) {
    this.subs = this.breakpointsService.emitter.subscribe(size => {
      this.screen = screenSizeMap(size, [3, 2, 1]);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getRange() {
    const start = this.getStart();
    const end = this.getEnd();
    const result = [];
    for (let i = start; i <= end; i++) {
      const dist = Math.max(Math.abs(i), 0);
      result.push(dist);
    }
    return result;
  }

  getStart() {
    const range = this.getRealRange();
    let start = Math.min(this.p - range, this.totalpages - 2 * range - 1);
    start = Math.max(start, 1);
    return start;
  }

  getEnd() {
    const range = this.getRealRange();
    let end = Math.max(this.p + range, 2 * range + 2);
    end = Math.min(end, this.totalpages);
    return end;
  }

  getRealRange() {
    return Math.max(this.range - this.screen, 0);
  }

  getPage(i: number) {
    if (i > 0 && i <= this.totalpages && this.p !== i) {
      this.p = i;
      this.pChange.emit(i);
    }
  }

}
