<div class="image-container">
  <img class="carousel-image" [src]="activityItem.imgUrl" [alt]="activityItem.name">
  <div class="text-section">
    <div class="title">
      {{ activityItem.name | titlecase }}
    </div>
    <div class="description description-desktop d-none d-md-flex">
      {{ activityItem.description }}
    </div>
    <article class="description description-mobile mt-2 d-md-none d-inline-block">
      {{ collapsedDescription ? activityItem.description : shortDescription }}
      <span class="read-more" (click)="collapsedDescription = !collapsedDescription">
        {{ collapsedDescription ? 'Ler Menos.' : 'Ler Mais.' }}
      </span>
    </article>
  </div>
  <div class="mask"></div>
</div>
