<div
  aria-hidden="true"
  aria-labelledby="modalSelectByCity"
  class="modal fade"
  role="dialog"
  tabindex="-1"
  bsModal
  #templateModal="bs-modal"
  (onHidden)="dismiss()"
  (onShow)="init()"
>
  <div class="modal-dialog" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header p-0 border-bottom-0">
        <div class="card no-side-border-xs shadow-none">
          <app-tabs name="screen" [hasIcon]="true" [tabs]="tabs" [(activeTab)]="activeTab"></app-tabs>
        </div>
        <button type="button" class="d-none close pull-right" aria-label="Close" (click)="templateModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body p-4">
        <div *ngIf="activeTab === tabs[gymSelectByCityTabs.Cities]">
          <app-select-city-tab
            [citiesByState]="gymsByState"
            (selectCityEmitter)="selectCity($event)"
          ></app-select-city-tab>
        </div>

        <div *ngIf="activeTab === tabs[gymSelectByCityTabs.Gyms]">
          <app-select-gym-tab
            [gymsByState]="filteredGyms"
            [maxNumberOfGymSelections]="maxNumberOfGymSelections"
            [selectedGyms]="selectedGyms"
            (clearFilterEmitter)="clearFilter(true)"
            (updateSelectedGymList)="updateSelectedGymList($event)"
            [(selectedCity)]="selectedCity"
          ></app-select-gym-tab>
        </div>
      </div>

      <div class="modal-footer">
        <button
          class="col btn btn-{{activeTab === tabs[gymSelectByCityTabs.Gyms] ? 'outline-primary' : 'primary'}}"
          type="button"
          (click)="cancel()"
        >
          CANCELAR
        </button>
        <button
          *ngIf="activeTab === tabs[gymSelectByCityTabs.Gyms]"
          class="col btn btn-primary" type="button"
          (click)="confirm()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
