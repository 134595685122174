<div class="product-quantity-choicer-container d-flex justify-content-between align-items-center">
  <button
    id="minus-btn"
    [class.btn-disabled]="(numberOfProducts <= 1) || (disableQuantitySelector)"
    [disabled]="(numberOfProducts <= 1) || (disableQuantitySelector)"
    class="btn btn-outline-primary position-relative" (click)="removeProduct()">
    <!-- - -->
    <div class="horizontal-line"></div>
  </button>
  <div class="quantity-number">{{ numberOfProducts }}</div>
  <button
    id="plus-btn"
    [class.btn-disabled]="disableQuantitySelector"
    [disabled]="disableQuantitySelector"
    class="btn btn-outline-primary position-relative" (click)="addProduct()">
    <!-- + -->
    <div class="vertical-line"></div>
    <div class="horizontal-line"></div>
  </button>
</div>