import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConceptComponent } from './concept.component';
import { ConceptRoutingModule } from './concept.routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { ConceptFrComponent } from './concept-fr/concept-fr.component';
import { ConceptGymsComponent } from './concept-gyms/concept-gyms.component';
import { ConceptResultsComponent } from './concept-results/concept-results.component';
import { ConceptObjectivesComponent } from './concept-objectives/concept-objectives.component';
import { ConceptFrCardComponent } from './concept-fr/concept-fr-card/concept-fr-card.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    ConceptRoutingModule,
  ],
  declarations: [
    ConceptComponent,
    ConceptFrCardComponent,
    ConceptFrComponent,
    ConceptGymsComponent,
    ConceptResultsComponent,
    ConceptObjectivesComponent,
  ],
})
export class ConceptModule { }
