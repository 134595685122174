import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AllGymsCustomerResolve } from '@guards/all-gyms-customer-resolve.guard';
import { AuthGuard } from '@guards/auth.guard';
import { ContractCancelResolve } from '@guards/contract-cancel-resolve.guard';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';
import { PreSalePurchaseResolveGuard } from '@guards/presale-purchase-resolve.guard';
import { ProfileCivilResolveGuard } from '@guards/profile-civil-resolve.guard';
import { ProfileProfessionsResolveGuard } from '@guards/profile-professions-resolve.guard';
import { ProfileRelationshipResolveGuard } from '@guards/profile-relationship-resolve.guard';
import { PurchaseResolveGuard } from '@guards/purchase-resolve.guard';
import { RedirectWithParameterComponent } from '@sharedcomponents/redirect-with-parameter/redirect-with-parameter.component';

import { AreaUserComponent } from './area-user.component';
import { ContractCancelComponent } from './contract-cancel/contract-cancel.component';
import { CreateAccountComponent } from './login/create-account/create-account.component';
import { ChangeEmailComponent } from './login/forgot-email/change-email.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { RecoveryEmailComponent } from './login/recovery-email/recovery-email.component';
import { CreateOrRecoveryAccountComponent } from './new-recovery-account/create-or-recovery-account/create-or-recovery-account.component';
import { NewRecoveryAccountComponent } from './new-recovery-account/new-recovery-account.component';
import { RememberEmailComponent } from './new-recovery-account/remember-email/remember-email.component';
import { ProfileComponent } from './profile/profile.component';
import { SchedulesCreateComponent } from './schedules/schedules-create/schedules-create.component';
import { SchedulesListComponent } from './schedules/schedules-list/schedules-list.component';
import { TrainingDashboardComponent } from './training-dashboard/training-dashboard.component';


// tslint:disable:no-duplicate-string
export const userRoute: Route[] = [
  {
    path: '',
    component: AreaUserComponent,
    data: {
      metaRobots: 'noindex,follow',
      windowContext: 'user',
    },
    children: [
      { path: '', redirectTo: 'treinos', pathMatch: 'full' },
      {
        path: '',
        data: { windowTitle: '${data.config.config.header.title}' },
        children: [
          {
            path: 'login',
            component: LoginComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'login' } },
          },
          {
            path: 'esqueci',
            component: ForgotPasswordComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'reset-access' } },
          },
          {
            path: 'trocar-email',
            component: ChangeEmailComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'reset-access' } },
          },
          {
            path: 'recuperar-email',
            component: RecoveryEmailComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'reset-access' } },
          },
          {
            path: 'novo',
            component: CreateAccountComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'create-account' } },
          },
          {
            path: 'recuperar-senha',
            component: NewPasswordComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'create-account' } },
          },
          {
            path: 'alterar-senha',
            component: NewPasswordComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'create-account' } },
          },
          {
            path: 'nao-consigo-logar',
            component: NewRecoveryAccountComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'login' } },
          },
          {
            path: 'desbloqueio-usuario',
            component: RedirectWithParameterComponent,
            data: {
              redirectTo: '/cliente/login',
            },
          },
          {
            path: 'meu-email',
            component: RememberEmailComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'remember-email' } },
          },
          {
            path: 'novo-cadastro',
            component: CreateOrRecoveryAccountComponent,
            resolve: { config: PortalConfigResolveGuard },
            data: { config: { config: 'create-or-recovery-account' } },
          },
        ],
      },

      {
        // Acesso restrito via canActivate
        path: '',
        children: [
          {
            path: 'agenda',
            loadChildren: () => import('app/agenda/agenda.module').then(m => m.AgendaModule)
          },
          {
            path: 'compras',
            children: [
              {
                path: 'contratos/cancelar/:code',
                component: ContractCancelComponent,
                resolve: {
                  info: ContractCancelResolve,
                },
                data: {
                  windowContext: 'cancel-contract',
                },
              },
            ],
            resolve: {
              config: PortalConfigResolveGuard,
              info: PurchaseResolveGuard,
              infoPreSale: PreSalePurchaseResolveGuard,
            },
            data: {
              windowTitle: '${data.config.finances.header.title}',
              windowContext: 'user-contract',
              config: { finances: 'finances' },
            },
          },
          {
            path: 'perfil',
            children: [
              { path: ':tab', component: ProfileComponent },
              { path: '', redirectTo: 'cadastro' },
            ],
            resolve: {
              professions: ProfileProfessionsResolveGuard,
              civilStates: ProfileCivilResolveGuard,
              relationships: ProfileRelationshipResolveGuard,
              config: PortalConfigResolveGuard,
            },
            data: {
              windowTitle: '${data.config.profile.header.title}',
              windowContext: 'user-profile',
              config: { profile: 'profile' },
            },
          },
          {
            path: 'treinos',
            resolve: {
              config: PortalConfigResolveGuard,
            },
            data: {
              windowTitle: '${data.config.training.header.title}',
              windowContext: 'user-training',
              config: { training: 'training-dashboard' },
            },
            children: [
              {
                path: '',
                component: TrainingDashboardComponent,
              },
            ],
          },
          {
            path: 'agendamentos',
            resolve: {
              config: PortalConfigResolveGuard,
              gyms: AllGymsCustomerResolve,
              imageHeader: ImageHeaderResolve,
            },
            data: {
              windowTitle: '${data.config.profile.header.title}',
              windowContext: 'user-schedules',
              config: { schedules: 'schedules' },
              slug: 'schedules',
            },
            children: [
              {
                path: '',
                component: SchedulesListComponent,
              },
              {
                path: 'novo',
                component: SchedulesCreateComponent,
              },
            ],
          },
          {
            path: 'trancamento',
            loadChildren: () => import('app/area-user/registration-locking/registration-locking.module').then(
              m => m.RegistrationLockingModule
            )
          },
        ],
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },

      {
        path: 'evo',
        loadChildren: () => import('./evo-page/evo-page.module').then(m => m.EvoPageModule)
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(userRoute)],
  exports: [RouterModule],
})
export class UserRoutingModule { }
