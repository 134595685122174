import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';

import { PaymentData } from '@models';
import { BtPassPlanInfo, BtPassValuesGrid, FormatedBtPassValuesGrid, IncomeDetails } from '@models/bt-pass.model';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { StringUtil } from '@utils/string-util';

import { Apollo } from 'apollo-angular';

import { EvoGymService } from './evo';
import { LoadingService } from './loading.service';


@Injectable()
export class BTPassService {

  private selectedPass = new BehaviorSubject<any>(null);
  private total = 3;

  constructor(
    protected readonly http: HttpClient,
    protected readonly apollo: Apollo,
    protected readonly loadingService: LoadingService,
    protected readonly evoGymService: EvoGymService,
  ) { }

  getBtPassCardsList(): Observable<BtPassPlanInfo[]> {
    return this.apollo.query<BtPassPlanInfo[]>({
      query: AppGraphql.queryListBTPassIncomesToCard,
    }).pipe(
      map(JSONUtil.turnApolloMutable<BtPassPlanInfo[]>('listBTPassIncomesToCard')),
    );
  }

  getBtPassGrid(): Observable<FormatedBtPassValuesGrid> {
    return this.apollo.query<BtPassValuesGrid>({
      query: AppGraphql.queryListBTPassIncomesToGrid,
    }).pipe(
      map(JSONUtil.turnApolloMutable<BtPassValuesGrid>('listBTPassIncomesToGrid')),
      /** @todo: adicionar tipagems nesse serviço */
      map((grid: any) => {
        const formatedIncomeList = grid.incomesList.map((item: any) => ({
          state: item.gymUnit.stateName,
          city: item.gymUnit.cityName,
          gymName: item.gymUnit.gymUnitName,
          values: item.values,
          link: item.gymUnit.gymUnitName,
          gymCode: item.gymUnit.gymUnitCode,
          isMigrated: item.gymUnit.isGymUnitMigrated,
          gym: item.gymUnit,
        }));

        grid.incomesList = formatedIncomeList.sort((a: any, b: any) => StringUtil.strcmp(a.state, b.state));

        return grid;
      }),
      mergeMap((grid: FormatedBtPassValuesGrid) => {
        const formatedList = grid;

        return this.evoGymService.getGymsContactInfo().pipe(
          map(gyms => {
          formatedList.incomesList = grid.incomesList.map(item => {
            if (item.isMigrated) {
              const migratedGym = this.evoGymService.getContactInfoById(gyms, item.gymCode);

              /*Passing Array(4).fill({}) to fill the grid.*/
              return {
                ...item,
                city: migratedGym.city,
                state: migratedGym.state ?? item.state,
                gymName: migratedGym.name,
                values: [...Array(4).fill({})],
              };
            }

            return item;
          });

          return formatedList;
        }));
      }),
    );
  }

  setSelectedPass(selectedPass: any): void {
    this.selectedPass.next(selectedPass);
  }

  getSelectedPass() {
    return this.selectedPass.asObservable().pipe(take(1));
  }


  getBTPassIncome(gymUnitId: number, additionalIncomeId: number): Observable<IncomeDetails> {
     return this.apollo.query<any>({
       query: AppGraphql.queryGetBTPassIncome,
       variables: {
         gymUnitId,
         additionalIncomeId
       }
     })
     .pipe(
        map(JSONUtil.turnApolloMutable<any>('getBTPassIncome')),
     );
  }

  private percent(current: number, total: number): string {
    return Math.floor(100 * current / total).toString() + '%';
  }

  startIncomePurchase(data: any, cpf: string): Observable<PaymentData> {
    this.loadingService.startLoading(this.percent(0, this.total));

    return this.http.post(back(BackEndpoints.RegistrationIncomeStart, cpf), data.personal).pipe(
      tap(      () => this.loadingService.startLoading(this.percent(1, this.total))),
      mergeMap( () => this.http.post(back(BackEndpoints.RegistrationSetItem, cpf), data.plans) ),
      tap(      () => this.loadingService.startLoading(this.percent(2, this.total))),
      mergeMap( () => this.http.get<PaymentData>(back(BackEndpoints.RegistrationCheckPayment, cpf))),
      catchError(err => {
        console.log('Error in personal', err);
        return throwError(err);
      }),
    );
  }

  startLoggedIncomePurchase(data: any) {
    this.loadingService.startLoading(this.percent(0, this.total));

    return this.http.get(back(BackEndpoints.LoggedRegistrationIncomeStart, data.personal.unidade)).pipe(
      tap(      () => this.loadingService.startLoading(this.percent(1, this.total))),
      mergeMap( () => this.http.post(back(BackEndpoints.PurchaseSetItem), data.plans)),
      tap(      () => this.loadingService.startLoading(this.percent(2, this.total))),
      mergeMap( () => this.http.get(back(BackEndpoints.PurchaseCheckPayment))),
      catchError(err => {
        console.log('Error in init', err);
        return throwError(err);
      }),
    );
  }
}
