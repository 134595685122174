
export class OnlineClassUtil {

  static getClassCapacity(totalSimultaneousClients: number) {

    const isGreaterThan1 = totalSimultaneousClients > 1;

    return `${isGreaterThan1 ? 'Até ' : ''}${totalSimultaneousClients} aluno${isGreaterThan1 ? 's' : ''} por aula`;
  }

}
