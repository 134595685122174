<div class="h-100 d-flex flex-column">
  <div class="image">
    <div class="position-relative backdrop d-flex justify-content-around align-items-center">
      <img src="/assets/icons_custom/ic-success.svg">
    </div>
  </div>

  <div class="card-body text-center d-flex flex-column justify-content-around">
    <div class="message">
      <div *ngIf="isRegistration; else clientMessage">
        Seu cadastro foi concluído com sucesso. Um e-mail será enviado com a confirmação do pedido.
        Enviamos também seus dados de acesso para nosso site<ng-container *ngIf="brand === 'bt'"> e aplicativo</ng-container>.
      </div>
      <div class="data-box row border text-left">
        <div class="col-12 col-md-6 border-right">
          <label class="label-input">Nome</label>
          <div>{{ formData.personal.name }}</div>
          <label class="label-input">Forma de pagamento</label>
          <div>{{ formData.additional.payment.paymentCardText }}</div>
        </div>

        <div class="col-12 col-md-6 col-right">
          <label class="label-input">E-mail</label>
          <div>{{ formData.personal.email }}</div>
          <label class="label-input">Número do pedido</label>
          <div>{{ formData.orderNumber }}</div>
        </div>
      </div>
    </div>

    <div class="button-wrapper">
      <a id="success-profile" routerLink="/cliente/perfil" class="btn btn-primary btn-confirm profile-btn">
        Dados cadastrais
      </a>
      <a id="success-training" routerLink="/cliente" class="btn btn-primary btn-confirm d-none">
        Meus treinos
      </a>
      <a *ngIf="formData.useDocuSign" (click)="signContract()" class="btn btn-primary btn-confirm">
        Assinar contrato
      </a>
    </div>
    <div *ngIf="formData.useDocuSign" class="text-center docusign-disclaimer">
      * Caso o contrato não seja assinado em até 15 minutos, o mesmo será enviado no e-mail registrado.
    </div>
  </div>
</div>
<ng-template #clientMessage>
  <div>Sua solicitação de compra foi concluída com sucesso. Um e-mail será enviado com a confirmação do pedido.</div>
</ng-template>
