import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { BreakpointsService } from '@services/breakpoints.service';


/**
 * **Note:** Avoid use `display: none` in a parent element because `clientHeight` can't make a precise calculation when
 * the accordion element isn't visible, use `visibility: hidden` and `position: absolute` instead.
 */
@Directive({
  selector: '[appAccordion]'
})
export class AccordionDirective implements OnInit, AfterViewInit, OnDestroy {

  private _appAccordion: boolean;
  get appAccordion(): boolean { return this._appAccordion; }

  /** Define if element will be **contract** or **retract**. If this is `false` it's means that the element will be **retract**. */
  @Input() set appAccordion(newValue: boolean) {
    this._appAccordion = newValue;

    if (this._appAccordion) {
      this.contract();
      return;
    }

    this.retract();
  }

  /**
   * Use when the accordion element doesn't need to fully contract.
   * This `@Input` will receive a query selector value of the element you wish to stop the contraction.
   */
  @Input() stopContractionInSelector?: string;

  private elementHeight: number;

  private breakpointServiceSubscribe: Subscription;

  constructor(
    private el: ElementRef<HTMLElement>,
    private readonly breakpointService: BreakpointsService,
  ) {}

  ngOnInit(): void {
    this.el.nativeElement.classList.add('accordion-wrapper');

    if (this.stopContractionInSelector) {
      this.el.nativeElement.classList.add('position-relative');
    }

    this.breakpointServiceSubscribe = this.breakpointService.emitter.subscribe(() => {
      this.refreshElementHeight();
    });
  }

  ngAfterViewInit(): void {
    this.refreshElementHeight();
  }

  ngOnDestroy(): void {
    this.breakpointServiceSubscribe.unsubscribe();
  }

  private contract(): void {
    this.el.nativeElement.style.height = this.stopContractionInSelector ? this.semiContract() : '0';
  }

  private semiContract(): string {
    const stopContractionInElement = this.el.nativeElement.querySelector<HTMLElement>(this.stopContractionInSelector);

    return `${stopContractionInElement?.offsetTop || 0}px`;
  }

  private retract(): void {
    this.el.nativeElement.style.height = `${this.elementHeight}px`;
  }

  private refreshElementHeight() {
    if (this.appAccordion) {
      this.el.nativeElement.style.visibility = 'hidden';
      this.el.nativeElement.style.position   = 'absolute';
      this.el.nativeElement.style.height     = 'auto';

      this.elementHeight = this.el.nativeElement.clientHeight;

      this.el.nativeElement.style.visibility = '';
      this.el.nativeElement.style.position   = '';
      this.contract();

      return;
    }

    this.elementHeight = this.el.nativeElement.clientHeight;
    this.retract();
  }
}
