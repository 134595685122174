<div class="title-section">Gerais</div>
<form #form="ngForm">
  <div class="row">
    <div class="col-12 col-md">
      <label for="profile-general-father" class="label-input">Nome do pai</label>
      <input id="profile-general-father" name="father" class="form-control" [(ngModel)]="general.father">
    </div>
    <div class="col-12 col-md">
      <label for="profile-general-mother" class="label-input">Nome da mãe</label>
      <input id="profile-general-mother" name="mother" class="form-control" [(ngModel)]="general.mother">
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <label for="profile-general-birth" class="label-input">Naturalidade</label>
      <input id="profile-general-birth" name="birth" class="form-control" [(ngModel)]="general.birthplace">
    </div>
  </div>

</form>
