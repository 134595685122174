import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FranchiseResolveGuard } from '@guards/franchise-resolve.guard';
import { ProfileCivilResolveGuard } from '@guards/profile-civil-resolve.guard';

import { FranchiseComponent } from './franchise.component';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';


const franchiseRoute = [
  {
    path: '',
    component: FranchiseComponent,
    resolve: {
      civilStates: ProfileCivilResolveGuard,
      personalInfo: FranchiseResolveGuard,
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.imageHeader.title}',
      windowContext: 'franchise',
      slug: 'franchise',
    },
  },
];

@NgModule({
    imports: [RouterModule.forChild(franchiseRoute)],
    exports: [RouterModule],
})
export class FranchiseRoutingModule { }
