import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';

import { SharedModule } from 'app/shared/shared.module';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home.routing';
import { MainBlogComponent } from './main-blog/main-blog.component';
import { PostCarouselItemComponent } from './main-blog/post-carousel/post-carousel-item/post-carousel-item.component';
import { PostCarouselComponent } from './main-blog/post-carousel/post-carousel.component';
import { StickyPostComponent } from './main-blog/sticky-post/sticky-post.component';
import { MainConceptComponent } from './main-concept/main-concept.component';
import { MainGymComponent } from './main-gym/main-gym.component';
import { OpenModalPaddingModule } from 'app/component-modules/open-modal-padding/open-modal-padding.module';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    SwiperModule,

    HomeRoutingModule,
    OpenModalPaddingModule,
  ],
  declarations: [
    HomeComponent,
    MainGymComponent,
    MainBlogComponent,
    PostCarouselComponent,
    PostCarouselItemComponent,
    MainConceptComponent,
    HeaderCarouselComponent,
    StickyPostComponent,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class HomeModule { }
