<div class="modal fade" bsModal #templateModal="bs-modal"
    (onHidden)="dismiss()"
    (onShow)="init()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center w-100 pull-left">
          <div class="m-0 title-section no-bottom-line">
            {{ modalObj.title }}
          </div>
          <div class="m-0">{{ modalObj.kidsEvent.teacherName | capitalize }}</div>
        </div>
        <button type="button" class="d-none close pull-right" aria-label="Close" (click)="templateModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="showScheduleForm" [formGroup]="scheduleForm">
        <div class="info-content" >
          <div class="principal-title title mb-2">
            {{ modalObj.selectedDate.event.podeAgendar ? 'AGENDAR AULA EXPERIMENTAL' : 'ENTRAR NA LISTA DE ESPERA' }}
          </div>
          <p>
            Insira as informações abaixo para
            {{ modalObj.selectedDate.event.podeAgendar 
              ? ' agendar uma aula experimental desta atividade. Existe um limite de agendamentos de aula experimental.' 
              : ' entrar na lista de espera desta atividade e entraremos em contato assim que houver uma vaga disponível.' }}
          </p>
        </div>
        <div class="info-content mb-0">
          <div class="title mb-3 py-2 bottom-line">INFORMAÇÕES DO RESPONSÁVEL</div>
          <div class="d-flex justify-content-between align-items-center">
            <label for="father-cpf" class="text-uppercase label-input">CPF</label>
            <a id="btn-clean-responsable-info" target="_blank" rel="noopener" 
            class="title link" (click)="cleanResponsibleForm()">
            limpar
          </a>
        </div>
          <input id="father-cpf"
            class="form-control"
            name="cpf"
            appCpfValidator
            [appMaskedInput]="cpfMask"
            autocomplete="document-main"
            minlength="14"
            maxlength="14"
            formControlName="fatherCpf"
            required>
          <app-error-message [control]="scheduleForm.controls.fatherCpf"></app-error-message>

          <div class="flex-column" [class.d-none]="!validCpf">
            <label for="father-name" class="text-uppercase label-input mt-3">Nome Completo</label>
            <input id="father-name"
              type="text"
              class="form-control"
              name="father-name"
              formControlName="fatherName"
              required>
            <app-error-message [control]="scheduleForm.controls.fatherName"
              [messages]="{ pattern: 'Informe nome e sobrenome' }"></app-error-message>

            <label for="email" class="text-uppercase label-input mt-3">E-MAIL</label>
            <input id="email"
              type="email"
              class="form-control"
              name="email"
              formControlName="fatherEmail"
              required>
            <app-error-message [control]="scheduleForm.controls.fatherEmail"
              [messages]="{ pattern: 'E-mail inválido' }"></app-error-message>

            <label for="phone-number" class="text-uppercase label-input mt-3">TELEFONE</label>
            <input
              id="phone-number"
              name="phone-number"
              class="form-control"
              formControlName="fatherPhoneNumber"
              [appMaskedInput]="phoneMask">
            <app-error-message [control]="scheduleForm.controls.fatherPhoneNumber"></app-error-message>

            <label for="already-client-checkbox" class="text-uppercase label-input mb-4">VOCÊ É CLIENTE BODYTECH?</label>
            <app-checkbox
              class="mb-2 mt-2"
              id="already-client-checkbox"
              name="already-client-checkbox"
              (click)="alreadyBodytechClientCheck()"
              [(check)]="alreadyBodytechClient"
              message="Sim, já sou cliente Bodytech"
            ></app-checkbox>
          </div>
        </div>
        <div class="info-content mt-4" [class.d-none]="!validCpf">
          <div class="title mb-3 py-2 bottom-line">INFORMAÇÕES DA CRIANÇA</div>
          <div class="d-flex justify-content-between align-items-center">
            <label for="children-name" class="text-uppercase label-input">Nome Completo</label>
            <a id="btn-clean-responsable-info" target="_blank" rel="noopener" 
              class="title link" (click)="cleanChildrenForm()">
              limpar
            </a>
          </div>
          <input id="children-name"
            type="text"
            class="form-control"
            name="children-name"
            formControlName="childrenName"
            required>
          <app-error-message [control]="scheduleForm.controls.childrenName"
            [messages]="{ pattern: 'Informe nome e sobrenome' }"></app-error-message>

          <label for="personal-birthdate" class="label-input mt-3">Data de nascimento</label>
          <input
            id="personal-birthdate"
            name="birthdate"
            class="form-control"
            required
            [appMaskedInput]="dateMask"
            formControlName="childrenBirthday"
            autocomplete="bday">
          <app-error-message 
          [messages]="{ 
            maxAge: 'A idade limite para agendamento é de até 11 anos',
            minAge: 'Data inválida'
          }"
          [control]="scheduleForm.controls.childrenBirthday"></app-error-message>

          <label for="children-cpf" class="text-uppercase label-input mt-3">CPF</label>
          <input id="children-cpf"
            class="form-control"
            name="cpf"
            appCpfValidator
            [appMaskedInput]="cpfMask"
            autocomplete="document-main"
            minlength="14"
            [formControl]="childrenCpf"
            maxlength="14">
          <app-error-message [control]="childrenCpf"></app-error-message>

          <app-checkbox
            class="mb-2 mt-3"
            id="dont-has-cpf-checkbox"
            name="dont-has-cpf-checkbox"
            (click)="childrenDoesNotHasCpf()"
            [(check)]="kidDoesNotHasCpf"
            message="A criança não possui CPF"
          ></app-checkbox>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button"
          class="col btn btn-outline-primary" (click)="cancel()">
          {{ modalObj.cancelButton || 'VOLTAR' }}
        </button>
        <button type="button" class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ validCpf ? 'CONFIRMAR' : 'Continuar' }}
        </button>
      </div>
    </div>
  </div>
</div>
