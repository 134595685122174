import { Component, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';

import { HeaderService } from '@services/header.service';
import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute, Router } from '@angular/router';
import { Tab } from '@models';
import { TabControllerComponent } from 'app/shared/abstracts/tab-controller.component';


@Component({
  selector: 'app-online-class-main',
  templateUrl: './online-class-main.component.html',
  styleUrls: [
    '../../area-user.component.scss',
    './online-class-main.component.scss',
  ],
})
export class OnlineClassMainComponent extends TabControllerComponent implements AfterViewInit {

  config: BasicHeaderConfig;
  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  readonly tabs: Tab[] = [
    { id: 0, name: 'Coletivas', url: '/cliente/bodytech-home/aulas-coletivas' },
    { id: 1, name: 'Minhas Aulas', url: '/cliente/bodytech-home/aulas-online' },
  ];

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly headerService: HeaderService,
    private readonly activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super(route, headerService);

    this.config = this.activatedRoute.snapshot.data.config.btHome;
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;

    if (!this.activatedRoute.snapshot.data.onlineClass) {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

}
