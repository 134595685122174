import { Component, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RecoveryEmailRequest, SelectItem } from '@models';
import { BasicHeaderConfig } from '@models/configs';
import { AlertMessageService, AuthService, HeaderService, LoadingService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';
import { BrandService } from '@services/brand.service';

@Component({
  templateUrl: './recovery-email.component.html',
  styleUrls: [
    '../../area-user.component.scss',
  ],
})
export class RecoveryEmailComponent implements AfterViewInit {

  public config: BasicHeaderConfig;

  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  readonly docMask = { mask: AppConstants.Mask.doc };

  listOptions: SelectItem<any>[] = [
    { id: 0, text: 'CPF' },
    { id: 1, text: 'Matrícula' },
  ];

  documentType = 0;
  document: string;

  email = '';

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly headerService: HeaderService,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  searchEmail() {
    FormUtil.touchForm(this.form.control);

    if (this.form.invalid) {
      return;
    }

    this.loadingService.startLoading();

    const CPF_TYPE = 0;
    const recoveryEmail: RecoveryEmailRequest = {
      [this.documentType === CPF_TYPE ? 'cpf' : 'registration']: this.document,
    };

    this.authService.searchEmail(recoveryEmail)
      .subscribe(
        value => this.email = value.email,
        (err: any) => this.alertMessageService.showToastr(err),
        () => this.loadingService.stopLoading(),
      );
  }
}
