import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { DayFilter, DaysOfTheWeek } from '@models/bt-home.model';

@Component({
  selector: 'app-days-filter',
  templateUrl: './days-filter.component.html',
  styleUrls: [
    '../../../area-user.component.scss',
    './days-filter.component.scss',
  ],
})
export class DaysFilterComponent implements OnInit {

  @Input() selectedDay: number;
  @Output() changeDay = new EventEmitter<DaysOfTheWeek>();

  daysOfTheWeek: DayFilter[] = [
    { name: 'segunda-feira', shortName: 'seg', code: DaysOfTheWeek.Segunda, selected: false },
    { name: 'terça-feira', shortName: 'ter', code: DaysOfTheWeek.Terca, selected: false },
    { name: 'quarta-feira', shortName: 'qua', code: DaysOfTheWeek.Quarta, selected: false },
    { name: 'quinta-feira', shortName: 'qui', code: DaysOfTheWeek.Quinta, selected: false },
    { name: 'sexta-feira', shortName: 'sex', code: DaysOfTheWeek.Sexta, selected: false },
  ];

  constructor(
  ) { }

  ngOnInit() {
    this.daysOfTheWeek.find(day => day.code === this.selectedDay).selected = true;
  }

  selectDay(day: DayFilter) {
    this.daysOfTheWeek.forEach(d => d.selected = false);
    day.selected = true;
    this.selectedDay = day.code;
    this.changeDay.emit(this.selectedDay);
  }
}
