import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@env/environment';


@Pipe({
  name: 'bucket',
})
export class BucketPipe implements PipeTransform {

  transform(path: string, bucketName: keyof typeof environment.s3buckets = 'images'): string {
    if (!path) {
      return '';
    }

    if (path.startsWith('http://') || path.startsWith('https://')) {
      return path;  // URL absoluto, não aplicar bucket
    }

    return environment.s3buckets[bucketName] + (path.startsWith('/') ? '' : '/') + path;
  }
}
