import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ResolveResult } from './type-resolve-bundle';
import { MyOnlineClassSchedulesService } from '@services/my-online-class-schedules.service';
import { OnlineClassDateAvailableToSchedule } from '@models';
import { AppConstants } from '@utils/app-constants';


@Injectable()
export class OnlineClassHoursResolve implements Resolve<OnlineClassDateAvailableToSchedule[]> {

  userRegistration = parseInt(localStorage.getItem(AppConstants.STOR_APP_USER_REGISTRATION), 10);

  constructor(
    private readonly myScheduleService: MyOnlineClassSchedulesService,
  ) {}

  resolve(): ResolveResult<OnlineClassDateAvailableToSchedule[]> {
    return this.myScheduleService.getAvailiableHourToScheduleList(this.userRegistration);
  }
}
