import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { GymBenefit } from '@models';

import { BenefitService } from '../services/benefit.service';
import { ResolveResult } from './type-resolve-bundle';


@Injectable()
export class GymBenefitResolveGuard implements Resolve<GymBenefit[]> {


  constructor(private readonly benefitService: BenefitService) { }

  resolve(): ResolveResult<GymBenefit[]> {
    return this.benefitService.getBenefits();
  }
}
