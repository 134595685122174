import { postPerPage } from '@models';

import gql from 'graphql-tag';


export class AppGraphql {

  public static readonly queryListActiveActivities = gql`
    query {
      listActiveActivities {
        id
        name
        activityGroup {
          id,
          text: name
        }
      }
    }
  `;

  public static readonly queryGetActivity = gql`
    query($id: Long!) {
      activity(id: $id) {
        id
        name
        description
      }
    }
  `;

  public static readonly queryListActiveActivityGroups = gql`
    query {
      listActiveActivityGroups {
        id
        name
        icon
      }
    }
  `;

  public static readonly queryListDoubtAreasByChannelName = gql`
    query {
      listDoubtAreasByChannelName(name: "Portal"){
        id
        displayOrder
        name: title
        sectionsDoubtArea {
          id
          displayOrder
          title
          doubts {
            id
            displayOrder
            question
            answer
          }
        }
      }
    }
  `;

  public static readonly queryListCovidFaqByCity = gql`
   query CovidFaq($city: Long!) {
      listCovidFaqByCity(cityId: $city) {
        id
        displayOrder
        question
        answer
      }
    }
  `;

  public static readonly queryListGymUnitsThatAreNotHolding = gql`
  query {
    listGymUnitsThatAreNotHolding {
      id
      code
      name
      portalName
      slug
      tag
    }
  }`;

  public static readonly queryListGymUnitsThatAreNotHoldingWithActivities = gql`
  query {
    listGymUnitsThatAreNotHolding {
      id
      code
      name
      portalName
      slug
      tag
      activeActivities { id }
    }
  }`;

  public static readonly listGymUnitsByStateCityAndNeighborhood = gql`
  query($stateInitials: String!, $city: String, $neighborhood: String) {
    listGymUnitsByStateCityAndNeighborhood(stateInitialsSlug: $stateInitials, cityNameSlug: $city, addressNeighborhoodSlug: $neighborhood) {
      address {
        adjunct
        city {
          id
          name
          state {
            country {
              id
              initials
              name
            }
            id
            initials
            name
          }
        }
        latitude
        longitude
        neighborhood
        number
        publicPlace {
          acronym
          id
          idiom
          name
          visible
        }
        street
        zipCode
      }
      businessName
      cnpj
      conveniences {
        id
        name
        icon
        description
      }
      gymUnitServices: activeGymUnitServices {
        freeCost
        main
        service{id}
      }
      id
      name
      portalName
      phones {
        ddd
        id
        main
        number
      }
      slug
      tag
      photos {
        description
        portalDescription
        url
        objectType
        displayOrder
      }
      showChannels
      activities {id}
      gymUnitPeriodicities {subscriptionFee}
      workingPeriods {
        dayOfTheWeek
        timeOfDay
      }
    }
  }`;

  public static readonly queryListGymUnitAvailableToScheduling = gql`
    query {
      listGymUnitAvailableToScheduling {
        id
        name
      }
    }
  `;

  public static readonly queryMaritalStatusList = gql`
    query {
      listMaritalStatusesRedis {
        id
        text: name
      }
    }
  `;

  public static readonly queryServiceList = gql`
    query {
      listServices {
        id
        name
        icon
        description
      }
    }
  `;

  public static readonly queryState = gql`
    query ($stateId: Long!) {
      state(id: $stateId) {
        id
        name
        cities {
          id
          name
        }
      }
    }
  `;

  public static readonly queryCityState = gql`
    query($id: Long!) {
      city (id: $id) {
        state {
          id
          name
        }
      }
    }
  `;

  public static readonly queryStateList = gql`
    query {
      listStatesRedis {
        id
        name
        initials
      }
    }
  `;

  public static readonly queryCitiesWithGymUnits = gql`
    query {
      listCitiesWithGymUnits {
        id
        name
        state {
          initials
        }
      }
    }
  `;

  public static readonly mutationFutureFranchiseeCreate = gql`
    mutation FranchiseeCreate($data: FutureFranchiseeUpdate!) {
      futureFranchiseeCreate(futureFranchisee: $data) {
        id
        errors {
          field
          message
        }

        name
        email
        address {
          city {
            id
            name
            state {
              id
              name
            }
          }
        }
      }
    }
  `;

  public static readonly queryPressAreaList = gql`
    query {
      pressAreaList {
        id
        name
      }
    }
  `;

  public static readonly queryListPressPostsByPressAreaName = gql`
    query ($area: String!, $offset: Int!) {
      listPressPostsByPressAreaName(pressAreaName: $area, max:${postPerPage}, offset: $offset) {
        results {
          title
          text
          postDate
          documentUrl
          documentSize
        }
      }
    }
  `;

  public static readonly queryListPressPostsTotalCount = gql`
    query ($area: String!, $offset: Int!) {
      listPressPostsByPressAreaName(pressAreaName: $area, max:5, offset: $offset) {
        totalCount
      }
    }
  `;

  public static readonly queryGetPortalConfiguration = gql`
    query ($tag: String!) {
      getPortalConfiguration(tag: $tag) {
        configValue
      }
    }
  `;

  public static readonly queryListActivePartners = gql`
    query{
      listActivePartners(channelName: "Portal"){
        id
        name
        icon
        link
        active
        displayOrder
      }
    }
  `;

  public static queryListActivePlansWithPeriodicityByGymUnit = gql`query ($gym: Long!) {
    listActivePlansWithPeriodicityByGymUnit(id: $gym) {
      id
      shortName
      longName
      slug
      gymUnitGroupPlans {
        group {
          name
        }
      }
      gymUnitPeriodicities {
        subscriptionFee
        planValue
        periodicity {
          name
          days
          months
          slug
        }
      }
    }
  }`;

  /** @todo: mapear somente campos utilziados no getGymUnitBySlug. */
  public static readonly queryGetGymUnitBySlug = gql`
    query($slug: String!) {
      getGymUnitBySlug(slug: $slug) {
        id
        code
        showChannels
        name
        portalName
        businessName
        cnpj
        slug
        preSale
        isGymUnit
        activePromotion {
          id
          thumbnailText
          purchaseTitle
          purchaseText
          initialDate
          finalDate
        }
        gymUnitSocialMedias {
          url
          display
          socialMedia {
            id
            name
            urlImage
          }
        }
        gymUnitDestinations {
          id
          name
          portalName
          address {
            id
            city {
              id
              name
              state {
                id
                name
                initials
              }
            }
          }
        }
        address {
          id
          street
          number
          adjunct
          neighborhood
          zipCode
          latitude
          longitude
          publicPlace {
            acronym
            id
            idiom
            name
            visible
          }
          city {
            id
            name
            state {
              id
              name
              initials
              country {
                id
                name
              }
            }
          }
        }
        phones {
          id
          ddd
          number
          main
        }
        gymUnitServices: activeGymUnitServices {
          main
          freeCost
          service {
            id
            name
            icon
            description
          }
        }
        conveniences {
          id
          name
          icon
          description
          url
        }
        workingPeriods {
          id
          dayOfTheWeek
          timeOfDay
        }
        photos {
          id
          description
          url
          portalDescription
          objectType
        }
        evoMigratedUnit { id }
      }

      listActiveActivitiesByGymUnit(slug: $slug) {
        id
        name
        activityGroup {
          id
        }
      }
    }
  `;

  public static readonly queryListActiveAgeRanges = gql`
    query {
      listActiveAgeRanges {
        id
        name
        minimumAge
        maximumAge
      }
    }
  `;

  public static readonly queryListSchedules = gql`
    query($gym: Long!, $activities: [Long!], $ages: [Long!]) {
      listSchedules(gymUnitId: $gym, activities: $activities, ageRanges: $ages) {
        id
        dayOfTheWeek
        itemGridTitle
        itemGridId
        teacherName
        itemGridPlace
        isOutdoorPlace
        gridActivityId
        activityInitialTimeFormatted
        activityInitialTimeHour
        activityInitialTimeMinute
      }
      # REMOVE_INTENSITY
      #listIntensities(activities: $activities) {
      #  activityId
      #  weekDay
      #  intensityColorCode
      #  hasColorCode
      #}
    }
  `;

  public static readonly querySchedule = gql`
  # REMOVE_INTENSITY
  # query ($id: Long!, $activityId: Long!, $weekDay: String!) {

  query ($id: Long!) {
    schedule(id: $id) {
      id
      itemGridId
      itemGridTitle
      teacherName
      itemGridPlace
      audiences {
        name
        minimumAge
        maximumAge
      }
      targetAudienceLevel
      duration
      activityInitialTimeFormatted
    }
    # REMOVE_INTENSITY
    #getIntensityByActivityIdAndWeekDay(activityId: $activityId, weekDay: $weekDay) {
    #  activityId
    #  weekDay
    #  intensityColorCode
    #  hasColorCode
    #  domain13Description
    #  domain14Description
    #  domain15Description
    #  domain16Description
    #}
  }
  `;

  public static readonly queryListSchedulesByItemGridId = gql`
  query ($itemGridId: Long!, $activityId: Long!, $weekDay: String!) {
    listSchedulesByItemGridId(itemGridId: $itemGridId) {
      id
      itemGridTitle
      teacherName
      isOutdoorPlace
      itemGridId
      itemGridPlace
      audiences {
        id
        name
        minimumAge
        maximumAge
      }
      targetAudienceLevel
      duration
      activityInitialTimeFormatted
    }
    getIntensityByActivityIdAndWeekDay(activityId: $activityId, weekDay: $weekDay) {
      activityId
      weekDay
      intensityColorCode
      hasColorCode
      domain13Description
      domain14Description
      domain15Description
      domain16Description
    }
  }
  `;

  public static readonly queryGetAddressByZipCode = gql`
    query($zip: String!) {
      getAddressByZipCode(zipCode: $zip) {
        street
        neighborhood
        city {
          id
          state {
            id
          }
        }
      }
    }
  `;

  public static queryGetGymUnitStateCityAndNeighborhood = gql`
    query($state: String!, $city: String, $neighborhood: String) {
      getGymUnitStateCityAndNeighborhood(stateInitialsSlug: $state, cityNameSlug: $city, addressNeighborhoodSlug: $neighborhood) {
        id
        stateName
        cityName
        addressNeighborhood
      }
    }
  `;

  public static readonly queryListCollaboratorDepartment = gql`
    query {
      listCollaboratorQuantitiesRedis {
        id
        numberRange
      }
      listDepartmentsRedis {
        id
        name
      }
    }
  `;

  public static queryListCombinatedGymUnits = gql`
    query($gymUnitId: Long!) {
      listCombinatedGymUnits(gymUnitId: $gymUnitId) {
        id
        name
        portalName
      }
    }
    `;

  public static queryGetPlanFromView = gql`
    query ($gymUnitSlug: String!, $planSlug: String!, $periodicitySlug: String!) {
      getGymUnitBySlug(slug: $gymUnitSlug) {
        id
        name
        portalName
        cnpj
        slug
        businessName
        preSale
        activePromotion {
          id
          thumbnailText
          purchaseTitle
          purchaseText
          initialDate
          finalDate
        }
      }
      getPlanFromView(gymUnitSlug: $gymUnitSlug, planSlug: $planSlug, periodicitySlug: $periodicitySlug) {
        planShortName
        periodicityName

        gymUnitId
        gymUnitGroupPlanId
        planId
        planDebitPlanId
        gymUnitPeriodicityId

        gymUnitPeriodicityPlanValue
        gymUnitPeriodicitySubscriptionFee
        gymUnitPeriodicitySubscriptionFeeText
        gymUnitPeriodicityShowChannelSubscriptionFee
        gymUnitPeriodicityRegistration
        gymUnitPeriodicityRegistrationText
        gymUnitPeriodicityShowChannelRegistration
        periodicityMonths
        gymUnitGroupPlanPortalName

        gymUnitPeriodicityCouponId
      }
      getSubscriptionTermBySlug(gymUnitSlug: $gymUnitSlug) {
        url
        subscriptionDate
      }
    }`;

  public static readonly queryListGymUnitsNotHoldingWithState = gql`
    query {
      listGymUnitsThatAreNotHolding {
        id
        name
        portalName
        slug
        tag
        address {
          id
          city {
            id
            name
            state {
              id
              name
              initials
            }
          }
        }
      }
    }
  `;

  public static queryListPlansFromView = gql`
    query($gymUnitId: Long!) {
      listPlansFromView(gymUnitId: $gymUnitId) {
        gymUnitId
        gymUnitSlug
        gymUnitPreSale
        gymUnitPeriodicityId
        gymUnitPeriodicityCouponId
        gymUnitPeriodicityPlanValue
        gymUnitPeriodicityPopular
        gymUnitPeriodicitySubscriptionFee
        gymUnitPeriodicitySubscriptionFeeText
        gymUnitPeriodicityShowChannelSubscriptionFee
        gymUnitPeriodicityRegistration
        gymUnitPeriodicityRegistrationText
        gymUnitPeriodicityShowChannelRegistration
        planSuperiorId
        planId
        planShortName
        planLongName
        planSlug
        planDebitPlanId
        planDebitedMonthly
        planCircuit
        planShowChannels
        planGymUnitExclusive
        planForSale
        periodicityName
        periodicitySlug
        periodicityMonths
        periodicityDays
        gymUnitGroupPlanId
        gymUnitGroupPlanShortDescription
        gymUnitGroupPlanLongDescription
        gymUnitGroupPlanAvailableActivities
        gymUnitGroupPlanOrderInSite
        gymUnitGroupPlanShowPlan
        gymUnitGroupPlanPortalName
      }
    }
  `;

  public static readonly queryListActivitiesByGymUnitAndPlan = gql`
    query($gymUnitId: Long!, $planId: Long!) {
      listActivitiesByGymUnitAndPlan(gymUnitId: $gymUnitId, planId: $planId) {
        id
        name
        activityGroup {
          id
          name
          icon
        }
      }
    }
  `;

  public static readonly queryCoupon = gql`
    query($id: Long!) {
      coupon(id: $id) {
        name
        couponKey
        description
      }
    }
  `;

  public static readonly queryListPlanViewMinAndMaxTranchesValues = gql`
    query {
      listPlanViewMinAndMaxTranchesValues {
        gymUnitPeriodicityTrancheValue
      }
    }
  `;

  public static queryGetGymUnitSlug = gql`
    query($gymUnitId: Long!) {
      listPlansFromView(gymUnitId: $gymUnitId) {
        gymUnitSlug
      }
    }
  `;

  public static readonly queryListPortalHomeBannerHeaders = gql`
    query {
      listCurrentPortalHomeBannerHeaders {
        title
        subtitle
        image
        buttonText
        linkUrl
        legalText
        displayOrder
        initialDate
        finalDate
      }
    }
  `;

  public static readonly queryListCorporates = gql`
  query {
    listCorporates {
      gymUnit {
        portalName
        name
        slug
        address {
          city {
            name
            state {
              name
            }
           }
         }
      }
      dccValue
      halfYearlyValue
      yearlyValue
      displayOrder
    }
  }
`;

  public static readonly queryGetSuccessorPlanBySGAPlan = gql`
    query($sgaPlanName: String!, $periodicityName: String!, $gymUnitId: Long!) {
      getSuccessorPlanBySGAPlan(sgaPlanName: $sgaPlanName, periodicityName: $periodicityName, gymUnitId: $gymUnitId) {
        periodicity {
          id
          name
          slug
        }
        plan {
          shortName
          slug
        }
      }
    }
  `;

  public static readonly queryGetPortalPageHeaderByTag = gql`
    query($tag: String!) {
      getPortalPageHeaderByTag(tag: $tag) {
        image
        title
      }
    }
  `;

  public static readonly queryListAllSchedulingByClient = gql`
    query($registration: Long!) {
      listAllSchedulingByClient(registration: $registration) {
        id
        userRegistration
        clientName
        scheduledDate
        hasPersonal
        isCancelable
        gymUnit {
          id
          name
        }
        gymUnitAvaiabilityHour {
          id
          formattedInitialTime
          formattedFinalTime
        }
      }
    }
  `;

  public static readonly queryListAvailableHourToScheduleByGymUnitAndPlan = gql`
    query($gymUnitId: Long!, $planId: Long!) {
      listAvailableHourToScheduleByGymUnitAndPlan(gymUnitId: $gymUnitId, planId: $planId) {
        dateToSchedule
        gymUnitAvaiabilityHourList {
          id
          weekDay
          initialHour
          initialMinute
          finalHour
          finalMinute
          formattedInitialTime
          formattedFinalTime
        }
      }
    }
  `;

  public static readonly mutationCreateScheduling = gql`
    mutation CreateScheduling($scheduling: SchedulingUpdate!) {
      createScheduling(scheduling: $scheduling) {
        id
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly mutationCancelScheduling = gql`
    mutation CancelScheduling($id: Long!, $registration: Long!) {
      cancelScheduling(id: $id, registration: $registration) {
        id
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly queryListAvailableHourToScheduleLiveClass = gql`
    query($registration: Long!) {
      listAvailableHourToScheduleLiveClass(registration: $registration) {
        dateToSchedule
        availableHours {
          id
          formattedInitialTime
          formattedFinalTime
        }
      }
    }
  `;

  public static readonly queryListAvailableTeacherToLiveClass = gql`
    query ($date: Date!, $hourId: Long!) {
      listAllTeacherAvailableToScheduleLiveClass(date: $date, availableHourId: $hourId) {
        id
        totalSimultaneousClients
        teacher {
          id
          name
          reducedName
          activities {
            name
          }
        }
      }
    }
  `;

  public static readonly queryListAllAppointmentLiveClassByClient = gql`
    query ($registration: Long!) {
      listAllAppointmentLiveClassByClient(registration: $registration) {
        id
        appointmentDate
        appointmentCanceledDate
        liveClassUrl
        teacherAvailableHour {
          totalSimultaneousClients
          id
          availableHour {
            id
            formattedInitialTime
            formattedFinalTime
          }
          teacher {
            id
            name
            reducedName
            activities {
              name
            }
          }
        }
      }
    }
  `;

  public static readonly mutationCreatelLiveClass = gql`
    mutation CreateLiveClassSchedule($teacherId: String!, $hourId: Long!, $date: Date, $userRegistration: BigInteger!) {
      createAppointmentLiveClass(teacherId: $teacherId, availableHourId: $hourId,
        appointmentLiveClass: {clientRegistration: $userRegistration, appointmentDate: $date}) {
        id
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly mutationCancelLiveClass = gql`
    mutation CancelLiveClass($id: Long!, $registration: Long!) {
      cancelAppointmentLiveClass(id: $id, registration: $registration) {
        id
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly queryGetGymUnitAvailableSchedulingMessage = gql`
    query GetGymUnitAvailableSchedulingMessage($gymId: Long!) {
      getGymUnitAvailableSchedulingMessage(gymUnitId: $gymId) {
        message
      }
    }
  `;

  public static readonly queryListAvailableGymUnitByPlan = gql`
    query ListAvailableGymUnitByPlan($gymUnitId: Long!, $planId: Long!) {
      listAvailableGymUnitByPlan(gymUnitId: $gymUnitId, planId: $planId) {
        message
        gymUnitListLimitedUse {
          id
          portalName
          slug
          address {
            id
            city {
              id
              name
              state {
                id
                name
                initials
              }
            }
          }
        }
        gymUnitListIncludedByPlan {
          id
          portalName
          slug
          address {
            id
            city {
              id
              name
              state {
                id
                name
                initials
              }
            }
          }
        }
      }
    }
  `;

  public static readonly queryGetUserAvailabilityToSchedule = gql`
    query GetUserAvailabilityToSchedule($registration: Long!) {
      getUserAvailabilityToSchedule(registration: $registration) {
        canSchedule
        maxCapacity
        currentMonth {
          monthText
          numberOfAvailableClasses
        }
        nextMonth {
          monthText
          numberOfAvailableClasses
        }
      }
    }`;

  public static readonly queryListAllWeekDaysWithGroupClassesByClient = gql`
    query($registration: Long!) {
      listAllWeekDaysWithGroupClassesByClient(registration: $registration) {
        id
        code
        name
        groupClassClientCustomResponse {
          id
          teacher {
            id
            name
            reducedName
          }
          classUrl
          duration
          activity
          initialHour
          initialMinute
          formattedHour
          favoriteGroupClass {
            id
          }
        }
      }
    }
  `;

  public static readonly mutationCreateFavoriteGroupClass = gql`
    mutation($groupClassId: Long!, $registration: Long!) {
      createFavoriteGroupClass(groupClassId: $groupClassId, registration: $registration) {
        id
        groupClass {
          id
          activity
        }
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly mutationDeleteFavoriteGroupClass = gql`
    mutation($favoriteGroupClassId: Long!) {
      deleteFavoriteGroupClass(favoriteGroupClassId: $favoriteGroupClassId) {
        id
        groupClass {
          id
          activity
        }
      }
    }
  `;

  public static readonly queryListAllFavoriteGroupClassByClient = gql`
    query($registration: Long!) {
      listAllFavoriteGroupClassByClient(registration: $registration) {
        id
        clientRegistration
        groupClass {
          id
          activity
          formattedHour
          duration
          classUrl

          initialHour
          initialMinute
          weekDay {
            name
          }
          teacher {
            name
            reducedName
          }
        }
      }
    }
  `;

  public static readonly queryListBabyKidsActivities = gql`
    query {
      listBabyKidsActivities {
        id
        name
        description
        imgUrl
        displayOrder
      }
    }
  `;

  public static readonly queryListOnlyGymUnitsWithBabyKidsPlansFromView = gql`
    query {
      listOnlyGymUnitsWithBabyKidsPlansFromView {
        gymUnitId
        gymUnitSlug
        gymUnitGroupPlanPortalName
        gymUnitPortalName
      }
    }
  `;

  public static readonly queryListBabyKidsPlansByGymUnitFromView = gql`
    query($gymUnitId: Long!) {
      listBabyKidsPlansByGymUnitFromView(gymUnitId: $gymUnitId) {
        planShortName
        planSuperiorId,
        planId,
        planSlug,
        planShortName,
        planLongName,
        planCircuit,
        planShowChannels,
        planDebitedMonthly,
        planGymUnitExclusive,
        planForSale

        periodicityName
        periodicityMonths

        gymUnitId
        gymUnitGroupPlanId
        gymUnitPeriodicityId

        gymUnitGroupPlanShortDescription
        gymUnitGroupPlanLongDescription
        gymUnitGroupPlanAvailableActivities

        gymUnitPeriodicityPlanValue
        gymUnitPeriodicitySubscriptionFee
        gymUnitPeriodicitySubscriptionFeeText
        gymUnitPeriodicityShowChannelSubscriptionFee
        gymUnitPeriodicityRegistration
        gymUnitPeriodicityRegistrationText
        gymUnitPeriodicityShowChannelRegistration
        gymUnitGroupPlanPortalName

        gymUnitPeriodicityCouponId

      }
    }
  `;

  public static readonly queryListBTPassIncomesToGrid = gql`{
		listBTPassIncomesToGrid {
      incomesName
      incomesList {
        gymUnit {
          gymUnitId
          stateName
          cityName
          gymUnitName
          gymUnitCode
          isGymUnitMigrated
        }
				values {
	        additionalIncomeId
	        planName
	        basePrice
	      }
      }
    }
  }`;

  public static readonly queryListBTPassIncomesToCard = gql`{
    listBTPassIncomesToCard {
      planName
      planShortDescription
      numberOfPasses
      durationInDays
    }
  }`;

  public static readonly queryGetBTPassIncome = gql`
    query($gymUnitId: Int!, $additionalIncomeId: Int!) {
      getBTPassIncome(gymUnitId: $gymUnitId, additionalIncomeId: $additionalIncomeId) {
            additionalIncomeId
            gymUnitId
            gymUnitName
            planId
            planName
            numberOfPasses
            durationInDays
            planType
            planShortDescription
            planLongDescription
            isMarketed
            hasDocuSign
            basePrice
            cityId
            cityName
            stateId
            stateName
            maxInstallments
      }
    }
  `;

  public static readonly queryGetGymUnitById = gql`
    query($id: Long!) {
      getGymUnitById(gymUnitId: $id) {
        id
        showChannels
        name
        portalName
        businessName
        cnpj
        slug
        preSale
        isGymUnit
      }
    }
  `;

}
