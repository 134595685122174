import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertMessage, MessageMap } from '@models/alert-message.model';
import { ProfileData } from '@models/responses/profile-response.model';
import { AlertMessageService } from '@services/alert-message.service';
import { LoadingService } from '@services/loading.service';
import { UserProfileService } from '@services/user-profile.service';
import { DataAddressComponent } from '@sharedcomponents/data-address/data-address.component';
import { ProfileDataEmergencyComponent } from './profile-data-emergency/profile-data-emergency.component';
import { ProfileDataPhonesComponent } from './profile-data-phones/profile-data-phones.component';
import { BrandService } from '@services/brand.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-contact',
  templateUrl: './profile-contact.component.html',
  styleUrls: ['./profile-contact.component.scss',
  '../profile.shared.scss',
  ]
})
export class ProfileContactComponent implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('dataAddress', { static: false }) formAddress: DataAddressComponent;
  @ViewChild('dataPhones', { static: false }) formPhones: ProfileDataPhonesComponent;
  @ViewChild('dataEmergency', { static: false }) formEmergency: ProfileDataEmergencyComponent;

  info: ProfileData;
  loading = true;

  emptyMessage = MessageMap.INFO_NAO_DISPONIVEIS;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly profileService: UserProfileService,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  saveData() {

    const valid = [
      this.formAddress.isValid(),
      this.formPhones.isValid(),
      this.formEmergency.isValid(),
    ];

    if (valid.some(v => !v)) {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
      return;
    }
    this.info.phones.work = this.info.phones.work.replace(/\D+/g, '');
    this.info.phones.cell = this.info.phones.cell.replace(/\D+/g, '');
    this.info.phones.home = this.info.phones.home.replace(/\D+/g, '');

    if (this.equalsPhone(this.info.phones.work, this.info.phones.home)
      || this.equalsPhone(this.info.phones.work, this.info.phones.cell)
      || this.equalsPhone(this.info.phones.cell, this.info.phones.home)) {

      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.TELEFONE_REPETIDOS));
      return;
    }


    this.info.auxStateId = this.formAddress.state;
    this.info.address.city.id = this.formAddress.city;

    this.loadingService.startLoading();
    this.profileService.setData(this.info)
      .pipe(
        mergeMap(() => this.profileService.getData()),
      )
      .subscribe(
        () => this.alertMessageService.showToastr(AlertMessage.success(MessageMap.INFO_SALVAS)),
        (err: any) => this.alertMessageService.showToastr(err),
        () => this.loadingService.stopLoading(),
      );
  }

  private equalsPhone(phone1: string, phone2: string) {
    return (phone1 && phone2) && phone1 === phone2;
  }

  private getData() {
    this.profileService.getCachedData()
    .subscribe(data => {
      this.loading = false;
      this.info = data;
    },
    () => {
      this.loading = false;
    });
  }

}
