<article class="card-container d-flex flex-column h-100 p-3">
  <div class="title text-center green">
    {{ btpassPlan.planName | uppercase }}
  </div>

  <div class="description text-center mb-3" [innerHTML]="btpassPlan.planShortDescription"></div>

  <div class="d-flex flex-row align-items-center justify-content-around mt-auto">
    <div class="d-flex flex-row">
      <img class="mr-1" src="assets/icons/calendar.svg" alt="Ícone de calendário">

      <div>
        <p class="info-title green">Diárias</p>
        <p class="info-content dark">{{ btpassPlan.numberOfPasses }} diárias</p>
      </div>
    </div>

    <div class="d-flex flex-row">
      <img class="mr-1" src="assets/icons/clock.svg" alt="Ícone de relógio">

      <div>
        <p class="info-title green">Período</p>
        <p class="info-content dark">{{ btpassPlan.durationInDays }} dias</p>
      </div>
    </div>
  </div>
</article>
