import { Tab } from '../tab.model';

export const postPerPage = 5;

export interface PressFile {
  title: string;
  source: string;
  postDate: Date;
  text: string;
  documentUrl: string;
  documentSize: number;
  tag?: string;
}

export interface PressArea extends Tab {
  id: number;
  name: string;
  pressPosts: PressFile[];
}

export interface PressPosts {
  id: number;
  results: PressFile[];
}

export interface PostTotal {
  totalCount: number;
}
