<div class="title-section">Contatos de emergência</div>
<div class="text-help">A prática de exercícios é uma atividade que não envolve sérios riscos, entretanto é sempre recomendável manter informações de pessoas que possam lhe auxiliar em qualquer eventualidade.
  Coloque nesta área o nome e o principal telefone de familiares e pessoas de sua confiança.
</div>

<form #form="ngForm">
  <div class="row position-relative" *ngFor="let contact of emergency; let index = index">
    <div class="col">
      <div class="row">
        <div class="col-12 col-md-4">
          <label for="profile-contact-name-{{index}}" class="label-input">Nome</label>
          <input id="profile-contact-name-{{index}}"
            class="form-control"
            name="profile-contact-name-{{index}}"
            [(ngModel)]="contact.name"
            required>
        </div>

        <div class="col-12 col-md-4">
          <label for="profile-contact-relation-{{index}}" class="label-input">Relação</label>
          <app-select id="profile-contact-relation-{{index}}"
            class="form-custom"
            name="profile-contact-relation-{{index}}"
            [(ngModel)]="contact.relation"
            [list]="relationships"
            ngDefaultControl
            required>
          </app-select>
        </div>

        <div class="col-12 col-md-4">
          <label for="profile-contact-phone-{{index}}" class="label-input">Telefone</label>
          <input id="profile-contact-phone-{{index}}"
            [appMaskedInput]="phoneMask"
            class="form-control"
            name="profile-contact-phone-{{index}}"
            [(ngModel)]="contact.phone"
            required>
        </div>
      </div>
    </div>

    <div id="profile-contact-remove-{{index}}"
        class="col-auto d-flex align-items-center remove-emergency"
        (click)="removeEmergencyContact(contact)"
        appClickable>
      <img alt="Limpar filtros" class="clear-icon text-primary" src="assets/icons_custom/ic-recycle.svg">
      <span class="font-weight-normal title-md text-primary">Remover</span>
    </div>

  </div>
</form>

<div class="add-contact">
  <a id="profile-add-contact" class="title link" href="javascript:void(0)" (click)="newEmergencyContact()">Adicionar um contato</a>
</div>
