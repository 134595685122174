<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container justify-content-around d-md-flex text-center">

  <div class="card small-card row-on-xs no-side-border-xs">
    <form #form="ngForm" class="form form-validate" (submit)="requestNewPassword()">
      <div class="form-container text-left">
        <div class="title-section text-black">Recuperar senha de acesso</div>
        <div>
          Informe seu e-mail de cadastro no site da {{ brandService.fullBrandName }}.
          Enviaremos uma mensagem para que você possa criar uma nova senha de acesso ao site.
        </div>

        <label for="forgot-email" class="text-left text-uppercase">E-mail</label>
        <input id="forgot-email"
          #email="ngModel"
          name="email"
          [(ngModel)]="user.email"
          type="email"
          class="form-control"
          email
          required>
        <app-error-message [control]="email"></app-error-message>
      </div>

      <div class="button-container row no-gutters">
        <button type="button" id="request-cancel" class="col btn btn-outline-primary" routerLink="/cliente/login">
          Voltar
        </button>
        <button type="submit" id="request-confirm" class="col btn btn-primary submit-btn">
          Enviar
        </button>
      </div>
    </form>

    <div class="forgot-container text-left">
      <a id="recovery-email" routerLink="/cliente/recuperar-email" class="title link">Esqueci meu e-mail de cadastro</a>
      <a id="lost-email" routerLink="/cliente/trocar-email" class="title link">Não tenho mais acesso ao e-mail</a>
    </div>
  </div>
</div>
