import { Component, Input, ViewChild } from '@angular/core';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-profile-data-phones',
  templateUrl: './profile-data-phones.component.html',
  styleUrls: [
    './profile-data-phones.component.scss',
    '../../profile.shared.scss',
  ],
})
export class ProfileDataPhonesComponent {

  @ViewChild('form', { static: false }) form: NgForm;
  @Input() phones: any;

  readonly phoneMask = { mask: AppConstants.Mask.phone };

  isValid() {
    FormUtil.touchForm(this.form.control);
    return this.form.valid;
  }

}
