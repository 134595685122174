import { Component, Input } from '@angular/core';
import { ResponseFutureFranchiseeCreate } from '@models';

@Component({
  selector: 'app-franchise-success',
  templateUrl: './franchise-success.component.html',
  styleUrls: ['./franchise-success.component.scss'],
})
export class FranchiseSuccessComponent {

  @Input() formResponse: ResponseFutureFranchiseeCreate;

}
