import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[timer]',
  templateUrl: './timer.component.html',
})
export class TimerComponent implements OnInit {

  @Input() eventMinutes: number;
  @Input() eventSeconds: number;

  @Input() eventHour: number;
  @Input() eventDay: number;

  minutesNow: number;
  secondsNow: number;

  minutesLeft: number;
  secondsLeft: number;

  constructor() { }

  ngOnInit() {
    this.initializeTimer();
  }

  private initializeTimer() {
    const now = new Date();

    const hour = now.getHours();
    const day = now.getDate();

    const hoursLeft = (hour - this.eventHour);

    this.minutesNow = now.getMinutes();
    this.secondsNow = now.getSeconds();
    this.minutesLeft = (this.eventMinutes - this.minutesNow) + Math.abs(hoursLeft * 60);
    this.secondsLeft = this.secondsNow;

    if (day === this.eventDay &&  hoursLeft <= 1) {
      this.timer();
    } else {
      setTimeout(() => this.timer(), 60000);
    }

  }

  private timer() {
    if (this.minutesLeft >= 0) {
      if (this.secondsLeft > 0) {
        this.secondsLeft--;
      } else {
        this.minutesLeft--;
        this.secondsLeft = 59;
      }
      if (!(this.minutesLeft === 0 && this.secondsLeft === 0)) {
        setTimeout(() => this.timer(), 1000);
      }
    }
  }
}
