import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LazyCreateDirective } from './lazy-create.directive';


@NgModule({
  declarations: [ LazyCreateDirective ],
  imports: [
    CommonModule
  ],
  exports: [ LazyCreateDirective ],
})
export class LazyCreateModule { }
