import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivityModel } from '@models';


@Component({
  selector: 'app-gym-activities-group',
  templateUrl: './gym-activities-group.component.html',
  styleUrls: [
    '../gym-experience.component.scss',
    './gym-activities-group.component.scss',
  ],
})
export class GymActivitiesGroupComponent {

  @Input() activities: ActivityModel[];
  @Input() groupName: string;

  @Output() showDescriptionModal = new EventEmitter<ActivityModel>();

  readonly maxIndexShow = 8;

  activitiesIsContract = true;

  activitiesGroupLength: number;
}
