<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container justify-content-around d-flex text-center" *ngIf="!success; else successPage">

  <div class="card small-card row-on-xs no-side-border-xs">
    <form #form="ngForm" class="form" (submit)="requestNewPassword()">
      <div class="form-container text-left">
        <div class="title-section text-black">Nova Senha</div>

        <div>
          Cadastre sua nova senha de acesso ao site da {{ brandService.fullBrandName }}.
        </div>
        <label for="new-password" class="text-left text-uppercase">Senha</label>
        <input
          id="new-password"
          #password="ngModel"
          name="newPassword"
          [(ngModel)]="passwordRequest.password"
          type="password"
          class="form-control"
          (keyup)="confirmPassword.control.updateValueAndValidity()"
          appPasswordValidator
          required>
        <app-error-message [control]="password"></app-error-message>

        <label for="confirm-password" class="text-left text-uppercase">Confirmar Senha</label>
        <input
          id="confirm-password"
          #confirmPassword="ngModel"
          name="confirmPassword"
          [(ngModel)]="passwordRequest.confirm"
          [appMatch]="passwordRequest.password"
          type="password"
          class="form-control"
          appPasswordValidator
          required>
        <app-error-message [control]="confirmPassword"
          [messages]="{ match: 'Senhas digitadas não conferem' }"></app-error-message>
      </div>

      <div class="button-container row no-gutters">
        <button type="submit" id="request-confirm" class="col btn btn-primary submit-btn">
          Enviar
        </button>
      </div>
    </form>
  </div>

</div>

<ng-template #successPage>
  <app-success-page
    buttonName="Login"
    pageTitle="Login"
    title="Sucesso"
    message="Sua nova senha foi cadastrada com sucesso."
    redirectTo="/cliente/login">
  </app-success-page>
</ng-template>
