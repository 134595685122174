import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EvoGymResponse, GymBasicInfoModel, GymBenefitModel, GymServiceModel, ModalBenefitsObject, ModalType } from '@models';
import { BrandService, ModalService } from '@services';
import { RequestObject } from '@utils/request-object.class';


@Component({
  selector: 'app-gym-info-card',
  templateUrl: './gym-info-card.component.html',
  styleUrls: ['./gym-info-card.component.scss'],
})
export class GymInfoCardComponent implements OnInit {

  @Input() gym: GymBasicInfoModel;
  /** Input to control the loading and error state of the contact info area. */
  @Input() contactRO: RequestObject<EvoGymResponse[]>;

  private readonly maxBenefitsToShow = 4;

  public readonly formula = this.brandService.getBrand() === 'fr';

  public serviceLabel = 'Serviços';
  /** List of all benefits to show when the modal opens */
  public allBenefits: GymBenefitModel[];
  /** List of benefits that icon will be shown on the card, `maxBenefitsToShow` controls how much will be shown. */
  public benefits: GymBenefitModel[];
  public hiddenBenefits: number;


  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly brandService: BrandService,
    private readonly modalService: ModalService,
  ) { }

  ngOnInit(): void {
    if (!this.formula) {
      this.serviceLabel = this.activatedRoute.snapshot.data.config.gymSearch.results.services;

      this.allBenefits = this.getGymBenefits();
      this.benefits = this.allBenefits.slice(0, this.maxBenefitsToShow);

      this.hiddenBenefits = this.allBenefits.length - this.benefits.length;
    }
  }

  private getGymBenefits(): GymBenefitModel[] {
    const gymServices = this.gym.gymUnitServices
      .filter(service => service.showChannels)
      .map((service, i) => ({
        ...service.service,
        free: service.freeCost,
        tooltip: i < this.maxBenefitsToShow
          ? this.getBenefitTooltipHTML(service)
          : '',
      }));

    return [ ...gymServices, ...this.gym.conveniences ];
  }

  private getBenefitTooltipHTML(benefit: GymServiceModel): string {
    if (!benefit) { return ''; }

    const freeBadge = benefit.freeCost
      ? '<span class="badge badge-primary title text-white">Grátis</span>'
      : '';

    return `
      <div class="gym-benefit-tooltip">
        <div>${freeBadge}</div>
        <div class="title font-weight-normal">${benefit.service.name}</div>
        <div class="text">${benefit.service.description ?? ''}</div>
      </div>`;
  }

  public showModalBenefits(): void {
    this.modalService.show({
      type: ModalType.gymBenefits,
      title: this.activatedRoute.snapshot.data.config.servicesModal.title,
      benefits: this.allBenefits,
    } as ModalBenefitsObject);
  }
}
