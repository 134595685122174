import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClickableModule } from '../../directives/clickable/clickable.module';
import { CheckboxComponent } from './checkbox.component';


@NgModule({
  declarations: [
    CheckboxComponent,
  ],
  imports: [
    CommonModule,

    ClickableModule,
  ],
  exports: [
    CheckboxComponent,
  ]
})
export class CheckboxModule { }
