import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { AgmCoreModule } from '@agm/core';
import { Angulartics2Module } from 'angulartics2';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { PixelModule } from 'ngx-multi-pixel';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';

import { environment } from '@env/environment';

import { ActionBtItemComponent } from './action-bt/action-bt-item/action-bt-item.component';
import { ActionBtComponent } from './action-bt/action-bt.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { MyWorkoutsListItemComponent } from './area-user/online-class/templates/my-workouts-list-item/my-workouts-list-item.component';
import { BtfitLandingpageComponent } from './btfit-landingpage/btfit-landingpage.component';
import { OpenModalPaddingModule } from './component-modules/open-modal-padding/open-modal-padding.module';
import { DebugModule } from './debug/debug.module';
import { NotFoundComponent } from './not-found/not-found.component';
import './shared/prototype';
import { SharedModule } from './shared/shared.module';
import { footerComponents } from './site-footer';
import { headerComponents } from './site-header';
import { NavbarUserMenuComponent } from './site-header/navbar-top/navbar-user-menu/navbar-user-menu.component';


const toastrConfig = {
  positionClass: 'inline',
  timeOut: 10000,
  preventDuplicates: true,
  progressBar: true,
  closeButton: true,
  tapToDismiss: false,
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain,
  },
  palette: {
    popup: {
      background: '#2f3033',
      text: '#ffffff',
    },
    button: {
      background: '#71c5a5',
      text: '#ffffff',
    },
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">
    {{message}} <a href="{{privacyPolicyHref}}">{{privacyPolicyLink}}</a>
    </span>
    `,
  },
  content: {
    message: 'Este site utiliza cookies para garantir que você tenha uma melhor experiência.',
    dismiss: 'ENTENDI!',

    privacyPolicyLink: 'Mais informações',
    privacyPolicyHref: '/privacidade',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ...headerComponents,
    ...footerComponents,
    NavbarUserMenuComponent,
    BtfitLandingpageComponent,
    MyWorkoutsListItemComponent,
    ActionBtComponent,
    ActionBtItemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    ModalModule.forRoot(),
    // AgmCoreModule.forRoot({ apiKey: googlemapsapikey }),
    DebugModule,
    AppRoutingModule,
    Angulartics2Module.forRoot({
      developerMode: false,
      gtm: { userId: environment.googleAnalytics.tagManagerId },
      ga: { userId: environment.googleAnalytics.trackingID },
    }),
    PixelModule.forRoot({ enabled: true, pixelId: [environment.facebook.pixelId]}),
    OpenModalPaddingModule,
    ToastrModule.forRoot(toastrConfig),
    ToastContainerModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
