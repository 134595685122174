<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container justify-content-around d-flex text-center" *ngIf="!success; else successPage">

  <div class="card small-card row-on-xs no-side-border-xs">
    <form #form="ngForm" class="form form-validate" (submit)="resetAccess()">
      <div class="form-container text-left">
        <div class="title-section text-black">Novo E-mail e Senha</div>
        <div>
          Cadastre seu novo e-mail e senha de acesso ao site da {{ brandService.fullBrandName }}.
        </div>

        <label for="new-email" class="text-left text-uppercase">E-mail</label>
        <input
          id="new-email"
          #email="ngModel"
          name="email"
          [(ngModel)]="resetAcess.email"
          type="email"
          (keyup)="confirmEmail.control.updateValueAndValidity()"
          class="form-control" required>
        <app-error-message [control]="email"></app-error-message>

        <label for="confirm-email" class="text-left text-uppercase">Confirmar E-mail</label>
        <input
          id="confirm-email"
          #confirmEmail="ngModel"
          name="confirmEmail"
          [(ngModel)]="resetAcess.confirmEmail"
          [appMatch]="resetAcess.email"
          type="email"
          class="form-control"
          required>
        <app-error-message [control]="confirmEmail" [messages]="{ match: 'E-mails digitados não conferem' }"></app-error-message>

        <label for="new-password" class="text-left text-uppercase">Senha</label>
        <input
          id="new-password"
          #password="ngModel"
          name="password"
          [(ngModel)]="resetAcess.password"
          type="password"
          class="form-control"
          (keyup)="confirmPassword.control.updateValueAndValidity()"
          appPasswordValidator
          required>
        <app-error-message [control]="password"></app-error-message>

        <label for="confirm-password" class="text-left text-uppercase">Confirmar Senha</label>
        <input
          id="confirm-password"
          #confirmPassword="ngModel"
          name="confirmPassword"
          [(ngModel)]="resetAcess.confirmPassword"
          [appMatch]="resetAcess.password"
          type="password"
          class="form-control"
          appPasswordValidator
          required>
        <app-error-message [control]="confirmPassword"
          [messages]="{ match: 'Senhas digitadas não conferem' }"></app-error-message>
      </div>

      <div class="button-container row no-gutters">
        <button id="request-confirm" class="col btn btn-primary submit-btn">
          Enviar
        </button>
      </div>
    </form>

  </div>

</div>
<ng-template #successPage>
  <app-success-page
    buttonName="Login"
    pageTitle="Login"
    title="Sucesso"
    message="Dados atualizados com sucesso."
    redirectTo="/cliente/login">
</app-success-page></ng-template>
