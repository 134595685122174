import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@services';
import { BasicHeaderConfig } from '@models/configs';
import { BrandService, Brand } from '@services/brand.service';


@Component({
  selector: 'app-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['./concept.component.scss'],
})
export class ConceptComponent implements OnInit, AfterViewInit {
  public config: BasicHeaderConfig;
  brand: Brand;
  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  constructor(
    public readonly brandService: BrandService,
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
  ) {
    this.config = this.route.snapshot.data.config.company;
    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
