import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { AlertMessageService, EvoAuthService, LoadingService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { MicroserviceErrorMessage } from '@utils/app-errors';
import { EvoErrorMessages } from '@utils/evo';
import { FormUtil } from '@utils/form-util';


export enum UserDocumentType {
  CPF = 0,
}

@Component({
  selector: 'app-can-not-login',
  templateUrl: './can-not-login.component.html',
  styleUrls: [ '../../area-user.component.scss' ],
  providers: [ EvoAuthService ],
})
export class CanNotLoginComponent {

  public readonly docMask = { mask: AppConstants.Mask.doc };

  public readonly documentTypeOptions = [
    { text: 'CPF', id: UserDocumentType.CPF, value: UserDocumentType.CPF },
  ];

  public form: FormGroup = this.formBuilder.group({
    documentType: new FormControl(this.documentTypeOptions[0].value, Validators.required),
    cpf: new FormControl(null, Validators.required),
  });


  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly loadingService: LoadingService,
    private readonly formBuilder: FormBuilder,
    private readonly evoAuthService: EvoAuthService,
  ) { }

  public submit(): void {
    FormUtil.touchForm(this.form);

    if (this.form.invalid) { return; }

    this.findUserByCpfOrRegistration();
  }

  private findUserByCpfOrRegistration(): void {
    this.loadingService.startLoading();

    const cpf = (this.form.value.cpf as string).replace(/[^0-9]/g, '');

    this.evoAuthService.evoResetPassword(cpf).pipe(
      tap(({ result }) => window.open(result, '_self', 'noreferrer')),
      catchError((error) => {
        if (EvoErrorMessages[error.status]) {
          this.alertMessageService.showToastr(MicroserviceErrorMessage[error.status]);
        }

        return throwError(error);
      }),
      finalize(() => this.loadingService.stopLoading()),
    ).subscribe();
  }
}
