import { ActivatedRoute } from '@angular/router';
import { BasicHeaderConfig } from '@models/configs';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { BtCompanyResponse } from '@models';
import {
  BtCompanyPageService,
  HeaderService,
} from '@services';
import { Observable } from 'rxjs';
import { Brand, BrandService } from '@services/brand.service';

@Component({
  templateUrl: './bt-company.component.html',
  styleUrls: ['./bt-company.component.scss'],
})
export class BtCompanyComponent implements OnInit, AfterViewInit {
  public config: BasicHeaderConfig;
  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  jsonPage: Observable<BtCompanyResponse>;
  brand: Brand;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly pageService: BtCompanyPageService,
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
  ) {
    this.config = this.route.snapshot.data.config.company;
    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    this.jsonPage = this.pageService.getInfo();
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
