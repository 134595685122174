<div class="container" *ngIf="data">
  <div class="title-lg text-center">
    {{ data.title }}
  </div>

  <app-gym-search-input [inSearchRoute]="false" (searchClick)="doSearch($event)"></app-gym-search-input>

  <div class="text-right link-margin">
    <a id="home-gym-all" class="title link" routerLink="/academias">
      {{ data.all }}
    </a>
  </div>
</div>
