<table class="table table-striped">
  <thead>
    <tr class="title">
      <th>Ergômetro</th>
      <th>Frequência</th>
      <th>Carga</th>
      <th>Zona Alvo</th>
      <th>Tempo</th>
      <th>Observação</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of jsonData">
      <td>{{ row.exercise }}</td>
      <td>{{ row.frequency }}</td>
      <td>{{ row.load }}</td>
      <td>{{ row.target }}</td>
      <td>{{ row.time }}</td>
      <td>{{ row.observation }}</td>
    </tr>
  </tbody>
</table>
