<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<app-can-not-login
  *ngIf="currentStage === newRecoveryAccountStages.RecoveryEmailByCpfOrRegistration"
></app-can-not-login>

<app-remember-email
  *ngIf="currentStage === newRecoveryAccountStages.FoundAndShowEmail"
  [(currentStage)]="currentStage"
  [(user)]="user"
></app-remember-email>

<app-create-or-recovery-account
  *ngIf="currentStage === newRecoveryAccountStages.CreateNewAccount
          || currentStage === newRecoveryAccountStages.UpdateExistingAccount"
  [(currentStage)]="currentStage"
  [(user)]="user"
></app-create-or-recovery-account>

<app-success-page
*ngIf="currentStage === newRecoveryAccountStages.UpdateOrCreateSuccess"
  buttonName="Login"
  pageTitle="Login"
  title="Sucesso!"
  message="Seus dados foram atualizados com sucesso!"
  redirectTo="/cliente/login"
  secundaryButtonName="VOLTAR PARA A HOME"
  secundaryRedirectTo="/"
></app-success-page>
