<div class="title-section" *ngIf="showTitle">Endereço</div>
<form #formAddress="ngForm" class="row">
  <div class="col-12 col-md address-block">
    <label for="{{name}}-zip-input">CEP</label>
    <div class="input-group-zip input-group">
      <input id="{{name}}-zip-input"
        name="cep"
        class="form-control"
        type="text"
        [(ngModel)]="address.zipCode"
        [appMaskedInput]="zipMask"
        #cep="ngModel"
        autocomplete="postal-code"
        (keydown.enter)="keydownEnter($event)"
        [disabled]="_loadingCities"
        required>

      <div class="input-group-append">
        <span class="input-group-text border-0 p-0">
          <a *ngIf="form.controls['cep'] && form.controls['cep'].valid"
            id="{{name}}-zip-search"
            href="javascript:void(0)"
            class="title link"
            (click)="searchZip(address.zipCode)">
              Consultar
          </a>

          <a *ngIf="form.controls['cep'] && !form.controls['cep'].valid"
            id="{{name}}-zip-search-disabled"
            class="title link disabled">
              Consultar
          </a>
        </span>
      </div>
    </div>
    <app-error-message [control]="cep"
      [messages]="{ minLength: 'CEP inválido' }"></app-error-message>

    <label for="{{name}}-street">Endereço</label>
    <input id="{{name}}-street"
      name="street"
      class="form-control"
      autocomplete="street-address"
      [(ngModel)]="address.street"
      #street="ngModel"
      required>
    <app-error-message [control]="street"></app-error-message>
    <ng-container *ngIf="showStateAndCity; then noComplemento"></ng-container>
  </div>

  <div class="col-12 col-md col-right address-block">
    <label for="{{name}}-neighborhood">Bairro</label>
    <input id="{{name}}-neighborhood"
      name="neighborhood"
      class="form-control"
      autocomplete="address-level3"
      [(ngModel)]="address.neighborhood"
      #neighborhood="ngModel"
      required>
    <app-error-message [control]="neighborhood"></app-error-message>

    <ng-container *ngIf="showStateAndCity; else noComplemento">
      <label for="{{name}}-state">Estado</label>
      <app-select id="{{name}}-state"
        name="{{name}}state"
        class="form-custom"
        [(ngModel)]="state"
        [list]="states"
        #stateC="ngModel"
        (ngModelChange)="selectState($event)"
        [disabled]="_loadingCities"
        ngDefaultControl
        required></app-select>
      <app-error-message [control]="stateC"></app-error-message>

      <label for="{{name}}-city">Cidade</label>
      <app-select id="{{name}}-city"
        name="{{name}}city"
        class="form-custom"
        #cityC="ngModel"
        [(ngModel)]="city"
        [list]="cities"
        textForUndefined="Selecione a cidade"
        [disabled]="!state || !cities.length"
        [textForDisabledUndefined]="_loadingCities ? 'carregando...' : 'Selecione primeiro o estado'"
        ngDefaultControl
        required></app-select>
        <app-error-message [control]="cityC"></app-error-message>

    </ng-container>
  </div>

  <ng-template #noComplemento>
    <div class="row">
      <div class="col-6 col-numero">
        <label for="{{name}}-address-number">Número</label>
        <input id="{{name}}-address-number"
          name="number"
          type="number"
          class="form-control"
          #number="ngModel"
          autocomplete="address-line1"
          required
          [(ngModel)]="address.number">
        <app-error-message [control]="number"></app-error-message>
      </div>
      <div class="col-6 col-complemento">
        <label for="{{name}}-compl">Complemento</label>
        <input id="{{name}}-compl"
          name="compl"
          class="form-control"
          autocomplete="address-line2"
          [(ngModel)]="address.adjunct">
      </div>
    </div>
  </ng-template>
</form>


