
<div class="title-section text-black">Atualize seu e-mail de acesso</div>

<form #form="ngForm" class="form-container" (ngSubmit)="send()">
  <!-- RADIO BUTTONS -->
  <ng-container *ngIf="userForm.oldEmail">
    <div class="radio-item">
      <app-radiobutton
        name="new-ticket-client-true"
        [(check)]="formControls.useOldEmail"
        [value]="true"
        message="Desejo utilizar o e-mail já cadastrado para acessar o site">
      </app-radiobutton>
    </div>

    <div class="radio-item">
      <app-radiobutton
        name="new-ticket-client-false"
        [(check)]="formControls.useOldEmail"
        [value]="false"
        message="Quero utilizar outro e-mail válido">
      </app-radiobutton>
    </div>
  </ng-container>

  <!-- EMAIL PRÉ-CADASTRADO -->
  <div class="form-container text-left" *ngIf="formControls.useOldEmail && userForm.oldEmail">
    <div class="form-group">
      <label for="old-email-email" class="text-left text-uppercase">Email</label>
      <input
        id="old-email"
        name="old-email"
        class="form-control"
        [(ngModel)]="userForm.oldEmail"
        type="email"
        disabled>
    </div>
  </div>

  <!-- EMAIL NOVO -->
  <div class="form-container text-left" *ngIf="!formControls.useOldEmail || !userForm.oldEmail">
    <div class="row">
      <div class="form-group col-12 col-sm-6">
        <label for="new-email-email1" class="text-left text-uppercase">Email</label>
        <input
          id="new-email1"
          name="newemail1"
          #newEmail="ngModel"
          class="form-control"
          [(ngModel)]="userForm.email1"
          type="email" email
          (keyup)="confirmEmail.control.updateValueAndValidity()"
          [required]="!formControls.useOldEmail || !userForm.oldEmail">
        <app-error-message [control]="newEmail"></app-error-message>
      </div>

      <div class="form-group col-12 col-sm-6">
        <label for="new-email-email2" class="text-left text-uppercase">Repetir Email</label>
        <input
          id="new-email2"
          name="new-email2"
          #confirmEmail="ngModel"
          class="form-control"
          [(ngModel)]="userForm.email2"
          type="email" email
          [appMatch]="userForm.email1"
          [required]="!formControls.useOldEmail || !userForm.oldEmail">
        <app-error-message [control]="confirmEmail"
          [messages]="{ match: 'Email digitado não confere' }"></app-error-message>
      </div>
    </div>
  </div>

  <!-- SENHA -->
  <div class="form-container text-left">
    <div class="row">
      <div class="form-group col-12 col-sm-6">
        <label for="new-email-pw1" class="text-left text-uppercase">Senha</label>
        <input
          id="password1"
          name="password1"
          #newPassword="ngModel"
          class="form-control"
          [(ngModel)]="userForm.password1"
          type="password"
          (keyup)="confirmPassword.control.updateValueAndValidity()"
          appPasswordValidator
          required>
        <app-error-message [control]="newPassword"></app-error-message>
      </div>

      <div class="form-group col-12 col-sm-6">
        <label for="new-email-pw2" class="text-left text-uppercase">Repetir Senha</label>
        <input
          id="password2"
          name="password2"
          [(ngModel)]="userForm.password2"
          #confirmPassword="ngModel"
          type="password"
          class="form-control"
          appPasswordValidator
          [appMatch]="userForm.password1"
          required>
        <app-error-message [control]="confirmPassword"
          [messages]="{ match: 'Senhas digitadas não conferem' }"></app-error-message>
      </div>
    </div>
    <div class="row mt-20-">
      <div class="col-12 text-center">
        <span>A senha deve conter pelo menos 6 caracteres e possuir letras e números.</span>
      </div>
    </div>
  </div>

  <!-- BOTÕES DE AÇÃO -->
  <div class="button-container row no-gutters">
    <button
      id="create-form-cancel"
      class="col btn btn-outline-primary"
      (click)="goBack()"
      type="button">
        Voltar
    </button>

    <button
      id="create-form-confirm"
      class="col btn btn-primary"
      type="submit">
        Cadastrar
    </button>
  </div>

</form>
