<main class="main-wrapper container justify-content-around d-md-flex">
  <div class="card small-card row-on-xs no-side-border-xs p-4 p-md-5 mb-4">
    <div class="title-section">Agendar Trancamento</div>

    <div>
      <div>Período disponível para trancamento</div>
      <div class="mb-3">{{ daysRemaining }} dias</div>
      <div>Data Inicial do Trancamento</div>
      <div class="mb-3">{{ initialDate }}</div>
    </div>

    <div [formGroup]="form">
      <label for="lockingPeriod" class="label-input">PERÍODO DE TRANCAMENTO</label>
      <input
        name="initial-data"
        class="form-control select-teacher"
        formControlName="lockingPeriod"
        type="number"

      />
      <p *ngIf="periodError" class="error">O período de trancamento
        {{ daysRemaining < minRequestPeriod
          ? ' disponível é de  '+daysRemaining+' dias'
          : ' deve estar entre '+minRequestPeriod+' e '+daysRemaining+' dias'}}
        </p>
      <app-error-message
        [control]="form.controls.lockingPeriod"
      ></app-error-message>

      <div>
        O período de trancamento é de no mínimo {{ minRequestPeriod }} dias. Caso você possua menos de {{ minRequestPeriod }} dias disponíveis,
        o período de Trancamento deverá ser igual à quantidade de dias restantes, exibida acima.
      </div>

      <div class="flex mt-15">
        <button type="button" class="btn btn-outline-primary w-100" routerLink="/cliente/trancamento"  [state]="{contractCode: contractCode, gymUnitId: gymUnitId}">
          VOLTAR
        </button>
        <button type="submit" class="btn btn-primary w-100 ml-8 ml-md-24" (click)="lockingRegistration()">AGENDAR TRANCAMENTO</button>
      </div>
    </div>
  </div>
</main>
