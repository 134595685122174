import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GetAppsComponent } from './get-apps.component';


@NgModule({
  declarations: [
    GetAppsComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    GetAppsComponent,
  ],
})
export class GetAppsModule { }
