import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// import { AgmCoreModule } from '@agm/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ClickableModule } from '@directives/clickable/clickable.module';
import { DropdownArrowsModule } from '@directives/dropdown-arrows/dropdown-arrows.module';
import { EvoRedirectInterceptor, XAuthorizationInterceptor } from '@interceptors';
import { FilterActivityModule } from '@pipes/filter-activity/filter-activity.module';
import { RemoveBrandModule } from '@pipes/remove-brand/remove-brand.module';
import { EvoGymService, GeolocationService } from '@services';
import { EmptyCardModule } from '@sharedcomponents/empty-card/empty-card.module';
import { ErrorModule } from '@sharedcomponents/error/error.module';
import { GymSearchInputModule } from '@sharedcomponents/gym-search-input/gym-search-input.module';
import { HeaderStaticImageModule } from '@sharedcomponents/header-static-image/header-static-image.module';
import { LoadingContentModule } from '@sharedcomponents/loading-content/loading-content.module';
import { ModalGymActivityModule } from '@sharedcomponents/modal/modal-gym-activity/modal-gym-activity.module';
import { ModalGymBenefitModule } from '@sharedcomponents/modal/modal-gym-benefit/modal-gym-benefit.module';

import { PopoverFadeModule } from 'app/component-modules/popover-fade/popover-fade.module';

import { GymFilterComponent } from './gym-filter/gym-filter.component';
import { GymInfoCardComponent } from './gym-info-card/gym-info-card.component';
import { GymsSharedModule } from './gyms-shared/gyms-shared.module';
import { GymsComponent } from './gyms.component';
import { GymsRoutingModule } from './gyms.routing';
import { ModalGymBenefitsComponent } from './modal-gym-benefits/modal-gym-benefits.component';


@NgModule({
  declarations: [
    GymsComponent,

    GymFilterComponent,
    GymInfoCardComponent,
    ModalGymBenefitsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    // AgmCoreModule,
    ClickableModule,
    DropdownArrowsModule,
    EmptyCardModule,
    ErrorModule,
    FilterActivityModule,
    GymSearchInputModule,
    GymsSharedModule,
    HeaderStaticImageModule,
    LoadingContentModule,
    ModalGymActivityModule,
    ModalGymBenefitModule,
    ModalModule,
    PopoverFadeModule,
    RemoveBrandModule,
    GymsRoutingModule,
  ],
  providers: [
    EvoGymService,
    GeolocationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EvoRedirectInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XAuthorizationInterceptor,
      multi: true,
    },
  ],
})
export class GymsModule { }
