import { Pipe, PipeTransform } from '@angular/core';
import { CouponResponse } from '@models';

/**
 * Pipe utilizado para calcular o valor da parcela no momento da compra(1ª parcela)
 */
@Pipe({
    name: 'calculate',
  })
  export class CalculatePipe implements PipeTransform {

    transform(value: number, adhesion: number = 0, parcels: number, coupon: CouponResponse, canais: boolean): number {

      if (!coupon || coupon.type === 0) {
        return value / parcels + (canais ? adhesion : 0);
      }

      return (value / parcels) + (canais ? coupon.total : 0);
    }

  }
