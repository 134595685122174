<div class="main-wrapper container justify-content-around d-md-flex">
  <div class="card small-card row-on-xs no-side-border-xs">
    <div class="form-container text-left">
      <div class="title-section text-black">DADOS DE ACESSO</div>
      <div class="mb-32">Seu e-mail de acesso é: <strong>{{ user?.email }}</strong>.</div>

      <div class="d-flex flex-column-reverse flex-md-row">
        <button type="button" id="change-email-password" class="btn btn-outline-primary w-100 mt-sm-12" (click)="goToUpdateExistingAccountStage()">
          Alterar e-mail e senha
        </button>
        <button type="button" id="change-login" class="btn btn-primary w-100 ml-md-24" routerLink="/cliente/login" 
          [queryParams]="{userEmail: user?.email, next: queryParams?.next }">
          Login
        </button>
      </div>
    </div>
  </div>
</div>