import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CouponResponse, GymRegistrationResponse, RegistrationValues, TypesOfProducts } from '@models';
import { AlertMessageService } from '@services/alert-message.service';
import { AnalyticsService } from '@services/analytics.service';
import { LoadingService } from '@services/loading.service';
import { RegistrationService } from '@services/registration.service';
import { AppConstants } from '@utils/app-constants';


@Component({
  selector: 'app-card-plan',
  templateUrl: './card-plan.component.html',
  styleUrls: ['./card-plan.component.scss'],
})
export class CardPlanComponent implements OnInit {

  readonly locale = AppConstants.LOCALE;
  readonly A_VISTA = 1;
  readonly TypesOfProducts = TypesOfProducts;
  readonly maxQuantityOfProducts = 100;

  @ViewChild('inputComp', { static: false }) inputComponent: ElementRef;

  @Input() data: GymRegistrationResponse;
  @Input() disableQuantitySelector = false;
  @Input() showCoupon = false;
  @Input() typeOfProduct = TypesOfProducts.Plan;

  @Output() parcelListChange = new EventEmitter<CouponResponse | null>();
  @Output() passQuantity = new EventEmitter<number>();

  canaisData: RegistrationValues;

  isCouponContract = true;
  coupon = '';
  couponData: CouponResponse;

  isDCC = false;
  total = 0;

  cupomType = {
    plan: 0,
    adhesion: 1,
  };

  quantity: number;

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly analyticsService: AnalyticsService,
    private readonly loadingService: LoadingService,
    private readonly registrationService: RegistrationService,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit() {
    if (this.typeOfProduct === TypesOfProducts.Income) {
      this.data.parcels = this.data.maxInstallments;
      this.quantity = +this.route.snapshot.queryParams['quantity'] || 1;
      this.quantity = this.quantity > this.maxQuantityOfProducts ? this.maxQuantityOfProducts : this.quantity;
      this.passQuantity.next(this.quantity);
    } else if (this.typeOfProduct === TypesOfProducts.Plan) {
      this.isDCC = this.data.planDebitPlanId === null;
    }
  }

  changeQuantityOfProducts(quantity: number) {
    this.quantity = quantity;
    this.passQuantity.next(quantity);
  }

  checkCoupon(coupon?: string) {
    if (coupon) {
      this.coupon = coupon;
    }

    if (!this.coupon) {
      return;
    }

    this.loadingService.startLoading();

    return this.applyCoupon().subscribe(
      couponData => {
        this.couponData = couponData;

        this.isCouponContract = true;

        this.emitParcelChange(this.couponData);

        this.analyticsService.trackEvent(
          `Aplica cupom ${this.coupon} (R$${couponData.discount})`,
          'Vendas',
          `Formulário de vendas - ${this.data.gym}`,
        );
      },
      (err: any) => {
        this.alertMessageService.showToastr(err);
        this.loadingService.stopLoading();
      },
      () => this.loadingService.stopLoading(),
    );
  }

  prefillCoupon(coupon: string) {
    this.inputComponent.nativeElement.value = coupon;
  }

  getValuesFromOldCanais() {
    return this.registrationService.checkValues(this.data.purchaseId)
      .pipe(
        tap(data => {
          this.canaisData = data;
          this.data.adhesion = this.canaisData.adhesion;
        }));
  }

  resetCoupon() {
    this.emitParcelChange(null);
    this.couponData = null;
    this.coupon = '';
  }

  applyCoupon() {
    if (this.coupon) {
      return this.registrationService.checkCouponDiscount(this.coupon, this.data.purchaseId);
    } else {
      return of(this.couponData);
    }
  }

  accordionHandler() {
    if (this.couponData) {
      this.resetCoupon();
    }

    this.isCouponContract = !this.isCouponContract;
  }

  private emitParcelChange(coupon: CouponResponse) {
    if (this.couponData.type === this.cupomType.plan || this.data.additionalIncomeId) {
      this.parcelListChange.emit(coupon);
    }
  }

}
