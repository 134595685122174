import { OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { ModalObject, ModalType } from '@models';
import { ModalService } from '@services/modal.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ModalCommonComponent<T = any> implements OnInit, OnDestroy {

  modalObj: ModalObject;
  subsModal: Subscription;
  protected modalService: ModalService;
  @ViewChild('templateModal', { static: false }) modal: ModalDirective;
  protected type: ModalType;
  config: T;

  ngOnInit() {
    this.subsModal = this.modalService.modalEmitter.subscribe((modalObj: ModalObject<T>) => {
      if (modalObj && modalObj.type === this.type) {
        this.modalObj = modalObj;
        this.config = (this.modalObj.config || {}) as T;
        this.modal.show();
      } else {
        this.modal.hide();
      }
    });
  }

  ngOnDestroy() {
    this.subsModal.unsubscribe();
  }

  confirm() {
    this.modal.hide();
    this.safeCall(this.modalObj.confirmCallback);
  }

  cancel() {
    this.modal.hide();
    this.safeCall(this.modalObj.cancelCallback);
  }

  dismiss() {
    this.safeCall(this.modalObj.dismissCallback);
  }

  // tslint:disable-next-line:ban-types
  safeCall(method: Function) {
    if (method) {
      method(this.modalObj.data);
    }
  }

}
