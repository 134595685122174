<div class="title-section">
  INFORMAÇÕES PRINCIPAIS
</div>
<div class="text-help">Os principais dados cadastrais não podem ser alterados pois necessitam de documentos comprovantes.
  Caso você identifique alguma divergência nestas informações, compareça à sua unidade {{ brandService.fullBrandName }} para corrigi-los.
</div>

<div class="main-info row no-gutters border">
  <div class="col-12 col-md border-right">
    <label class="label-input">Nome</label>
    <div>{{ info.fullname | capitalize }}</div>

    <label class="label-input">Nome reduzido</label>
    <div>{{ info.shortname | capitalize }}</div>

    <label class="label-input">Sexo</label>
    <div i18n>{info.gender, select, 1 { Masculino } 2 { Feminino } other { Outro } }</div>

    <hr class="d-md-none mt-3">

    <label class="label-input">CPF</label>
    <div *ngIf="info.cpfDocument; else NA">{{ info.cpfDocument | format:"cpf" }}</div>
  </div>

  <div class="col-12 col-md">
    <label class="label-input">RG</label>
    <div *ngIf="info.rgDocument; else NA">{{ info.rgDocument }}</div>

    <label class="label-input">Número da matrícula</label>
    <div>{{ info.registry }}</div>

    <hr class="d-md-none mt-3">

    <label class="label-input">Nascimento</label>
    <div>{{ info.birthdate | date:'shortDate':'':locale }}</div>

    <label class="label-input">Nacionalidade</label>
    <div>{{ info.nationality?.portal }}</div>
  </div>
</div>
<ng-template #NA>
  <div>NA</div>
</ng-template>