import { Component, Input, OnDestroy } from '@angular/core';
import { BlogPostResponse } from '@models';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ReplaySubject, Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BreakpointsService, screenSizeMap } from '@services/breakpoints.service';

@Component({
  selector: 'app-post-carousel',
  templateUrl: './post-carousel.component.html',
  styleUrls: ['./post-carousel.component.scss'],
})
export class PostCarouselComponent implements OnDestroy {

  private defaultPost: BlogPostResponse = {
    title: { rendered: 'Carregando' },
    content: { rendered: 'Por favor, aguarde' },
    link: '',
  } as BlogPostResponse;

  _listPost: BlogPostResponse[] = [];
  @Input() set listPost(val: BlogPostResponse[]) {
    this._listPost = val;
    this.indexObs.next(0);
  }

  public showingObs = new ReplaySubject<boolean>(1);
  public indexObs = new ReplaySubject<number>(1);
  private lazyLoadSubs: Subscription;
  private subs: Subscription;

  public listPostFallback: BlogPostResponse[] = [
    this.defaultPost, this.defaultPost, this.defaultPost,
  ];

  defaultConfig: SwiperConfigInterface = {
    autoplay: {delay: 6000, disableOnInteraction: true},
    speed: 500,
    loop: false,
    threshold: 10,
    navigation: { prevEl: '.blog-swiper-prev', nextEl: '.blog-swiper-next' },
    pagination: { el: '.blog-swiper-pagination', clickable: true, hideOnClick: false,
      bulletActiveClass: 'swiper-pagination-bullet-dark' },
    spaceBetween: 24,
    slidesPerView: 3,
    slidesPerGroup: 3,
  };

  selectedConfig = this.defaultConfig;

  constructor(
    private readonly breakpointsService: BreakpointsService,
  ) {
    this.subs = this.breakpointsService.emitter.subscribe(size => {
      const w = screenSizeMap(size, [1, 1, 2, 3, 3]);
      Object.assign(this.selectedConfig, { speed: 600, slidesPerView: w, slidesPerGroup: w });
    });
  }

  configurePostCarousel() {
    this.indexObs.next(0);

    this.lazyLoadSubs = combineLatest([
      this.indexObs,
      this.showingObs.pipe(filter(x => x)),
    ]).subscribe(([val, _]) => {
      this._listPost
        .slice(val, val + (+this.selectedConfig.slidesPerView))
        .forEach(p => p.loadImage = true);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    if (this.lazyLoadSubs) {
      this.lazyLoadSubs.unsubscribe();
    }
  }

}
