<div *ngIf="data"
    (deferLoad)="loadImage=true"
    class="img-block d-flex align-items-center"
    [style.background-image]="'url(' + (loadImage ? data.image : '') + ')'">
  <div class="img-caption container">
    <div class="title-lg text-uppercase">{{ data.title }}</div>
    <div class="message default-margin">{{ data.message }}</div>
    <a id="home-concept" class="default-margin-btn btn btn-primary" [routerLink]="data.url">
      {{ data.button }}
    </a>
  </div>
</div>
