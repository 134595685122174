import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { BrandGuard } from '@guards/brand.guard';
import { GymResolveGuard } from '@guards/gym-resolve.guard';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { IncomeResolveGuard } from '@guards/income.guard';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';

import { RegistrationComponent } from 'app/registration/registration.component';

import { BtPassComponent } from './bt-pass.component';


const personalData = 'dados-pessoais';

const btPassRoute: Route[] = [
    {
      path: '',
      component: BtPassComponent,
      resolve: {
        imageHeader: ImageHeaderResolve,
        config: PortalConfigResolveGuard,
      },
      canActivate: [BrandGuard],
      data: {
        brand: 'bt',
        config: {
          gymSearch: 'gym-search',
        },
        slug: 'bt-pass',
        windowTitle: '${data.imageHeader.title}',
        windowContext: 'bt-pass',
      },
    },
    {
      /** @todo: remover esse fluxo quando todas as academias de BT estiverem migradas na EVO. */
      path: 'compra/:gymUnitId/:additionalIncomeId',
      children: [
        {
          path: '',
          redirectTo: personalData,
        },
        {
          path: personalData,
        },
        {
          path: 'dados-adicionais',
          redirectTo: personalData,
          pathMatch: 'full',
        },
        {
          path: 'compra-finalizada',
          redirectTo: personalData,
          pathMatch: 'full',
        },
      ],
      component: RegistrationComponent,
      resolve: {
        gymUnit: GymResolveGuard,
        registration: IncomeResolveGuard,
        config: PortalConfigResolveGuard,
      },
      canActivate: [BrandGuard],
      data: {
        brand: 'bt',
        // to do - adicionar valores bt-pass
        windowTitle: '${data.config.registration.header.title}',
        windowContext: 'registration',
        config: { registration: 'registration' },
      }
    }
];

@NgModule({
    imports: [RouterModule.forChild(btPassRoute)],
    exports: [RouterModule],
})
export class BtPassRoutingModule { }
