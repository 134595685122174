export enum DocusignTransactionStatusEnum {
  Sent = 1,
  Completed,
  Delivered,
  Created,
  Signed,
  Refused,
  Annulled,
  Deleted,
  Error,
}

export const docuSignContractErrorMessages = {
  sentMessage: `Seu contrato está aguardando a assinatura.
  Se você já o assinou, pode levar alguns minutos para que fique disponível.`,
  unavailableMessage: `Seu contrato está indisponível no momento. Procure a unidade para mais informações.`,
  genericUnavailableMessage: `Seu contrato está indisponível no momento, aguarde alguns minutos para que fique disponível.
  Se após algum tempo você não conseguir acessá-lo, procure a unidade para mais informações.`,
};

export const docuSignCompleteMessages = {
  signing_complete: 'A assinatura eletrônica do contrato foi concluída',
  decline: 'A assinatura eletrônica do contrato foi recusada',
  cancel: 'A assinatura eletrônica do contrato foi cancelada',
};

export enum ContractSearchType {
  Plan = 1,
  BtPass,
  ShoppingCartId
}
