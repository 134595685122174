<app-state-gym-filter
  *ngIf="corporateData"
  title="VALORES POR ACADEMIA"
  titleClasses="text-center"
  [gymsToFilter]="corporateData"
  [isPlaceFilter]="true"
  (filteredGyms)="setFilteredCoporateData($event)"
></app-state-gym-filter>
<div class="container">
  <div class="row">
    <!-- TABELA PARA TELA GRANDE -->
      <table class="table table-bordered d-none d-sm-table d-xs-table">
        <thead>
          <tr class="multiple-cell">
            <th class="text-center" colspan="4">
                VALORES DO PLANO FITNESS EXCLUSIVO CORPORATIVO
            </th>
          </tr>
          <tr class="multiple-cell">
            <th class="text-center" colspan="4">
                Este plano só pode ser contratado por funcionários de empresas conveniadas. O acesso será exclusivo na academia em que for feita a matrícula.
            </th>
          </tr>
          <tr class="single-cell">
            <th class="text-center" colspan="4">
              Taxa de adesão <span class="font-weight-light">R$205</span>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th scope="col" class="align-middle text-uppercase">Academia</th>
            <th scope="col" class="align-middle text-uppercase">
              <div class="text-center">DCC</div>
              <div class="text-center subtitle-dcc">(*débito no cartão de crédito)</div>
            </th>
            <th scope="col" class="align-middle text-center text-uppercase">Semestral</th>
            <th scope="col" class="align-middle text-center text-uppercase">Anual</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of filteredCorporateData">
            <th scope="row"><a routerLink="/academia/{{ data.link }}"><b>{{data.city}}</b> - {{data.gym}}</a></th>
            <td class="text-center">R$ {{ data.dcc }}/mês</td>
            <td class="text-center">R$ {{ data.semestral }}/mês</td>
            <td class="text-center">R$ {{ data.anual }}/mês</td>
          </tr>
        </tbody>
      </table>
  </div>
  <div class="row">
    <div class="col-12">
    <!-- TABELA PARA TELA PEQUENA -->
      <table class="table table-bordered d-sm-none d-xs-none">
        <thead>
          <tr class="multiple-cell">
            <th class="text-center" colspan="3">
                  VALORES DO PLANO FITNESS EXCLUSIVO CORPORATIVO
            </th>
          </tr>
          <tr class="multiple-cell">
            <th class="text-center" colspan="3">
              Este plano só pode ser contratado por funcionários de empresas conveniadas. O acesso será exclusivo na academia em que for feita a matrícula.
            </th>
          </tr>
          <tr class="single-cell">
            <th class="text-center" colspan="3">
              Taxa de adesão <span class="font-weight-light">R$205</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of filteredCorporateData">
            <td class="text-center">
              <a routerLink="/academia/{{ data.link }}">
                <b>{{data.city}} - {{data.gym}}</b><br>
              </a>
              DCC - R$ {{ data.dcc }}/mês<br>
              Semestral - R$ {{ data.semestral }}/mês<br>
              Anual - R$ {{ data.anual }}/mês
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12 pb-2">
      <span class="lower-info">
        Para mais informações dos planos Fitness Exclusive Corporativo Total, que dá acesso à natação, nado livre e demais atividades aquáticas, consulte a recepção da sua academia.
      </span>
    </div>
  </div>
</div>
