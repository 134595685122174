import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { HeaderService } from '@services';
import {
  CorporateFormRequest,
  CorporateContact,
  CorporateCompany,
  CorporateResponsible,
} from '@models';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: [],
})
export class CorporateComponent implements OnInit, AfterViewInit {
  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  formSent = false;

  info: CorporateFormRequest = {
    contact: {} as CorporateContact,
    company: { companyState: null } as CorporateCompany,
    responsible: {} as CorporateResponsible,
  };

  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  formSentUpdate(event: boolean) {
    this.formSent = event;
  }
}
