import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ResolveResult } from './type-resolve-bundle';
import { AlertMessage, GymRegistrationResponse, MessageMap } from '@models';
import { AlertMessageService, PlanService } from '@services';
import { map } from 'rxjs/operators';

@Injectable()
export class GymPlanResolveGuard implements Resolve<GymRegistrationResponse> {

  constructor(
    private readonly router: Router,
    private readonly planService: PlanService,
    private readonly alertMessageService: AlertMessageService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<GymRegistrationResponse | null> {
    const unitSlug = route.params.unitSlug;
    const planSlug = route.params.planSlug;
    const periodicitySlug = route.params.periodicitySlug;
    if (unitSlug) {
      return this.planService.getRegistrationIdsFromSlug(unitSlug, planSlug, periodicitySlug)
        .pipe(
          map(result => {

            if (!result || !result.getGymUnitBySlug) {
              this.alertMessageService.showToastr(AlertMessage.error(MessageMap.ACADEMIA_NAO_ENCONTRADA));
              this.router.navigate(['/academias']);
              return;
            }

            const data = result.getPlanFromView;
            if (!data) {
              this.alertMessageService.showToastr(AlertMessage.error(MessageMap.PLANO_NAO_ENCONTRADO));
              this.router.navigate(['/academia', unitSlug]);
              return;
            }

            let adhesionValue: number;
            let adhesionTextValue: string;

            if (data.gymUnitPeriodicityShowChannelSubscriptionFee) {
              adhesionValue = data.gymUnitPeriodicitySubscriptionFee;
              adhesionTextValue = data.gymUnitPeriodicitySubscriptionFeeText;
            } else if (data.gymUnitPeriodicityShowChannelRegistration) {
              adhesionValue = data.gymUnitPeriodicityRegistration;
              adhesionTextValue = data.gymUnitPeriodicityRegistrationText;
            }

            return {
              gymId: data.gymUnitId,
              group: data.gymUnitGroupPlanId,
              planId: data.planId,
              periodicityId: data.gymUnitPeriodicityId,
              frequency: data.frequency || 7,

              gym: result.getGymUnitBySlug,
              gymTag: unitSlug,
              plan: data.planShortName,
              planDebitPlanId: data.planDebitPlanId,
              planTag: planSlug,
              payment: data.periodicityName,
              value: data.gymUnitPeriodicityPlanValue,
              originalValue: data.gymUnitPeriodicityPlanValue,
              months: data.periodicityMonths,
              adhesion: adhesionValue,
              adhesionText: adhesionTextValue,
              terms: result.getSubscriptionTermBySlug.url,
              parcels: data.periodicityMonths,
              portalName: data.gymUnitGroupPlanPortalName,
              coupon: {
                id: result.gymUnitPeriodicityCouponId,
                couponKey: '',
                name: '',
                description: '',
                image: '/assets/icons_custom/ic-default-promo.svg',
              },
            };
          }),
        );
    }
  }

}
