<div
  class="modal fade"
  bsModal
  #templateModal="bs-modal"
  (onHidden)="dismiss()"
  (onShow)="init()"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" id="modal-top" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center w-100 pull-left">
          <div class="m-0 title-section no-bottom-line">
            {{ modalObj.title }}
          </div>
          <div class="m-0">{{ modalObj.event.teacherName | capitalize }}</div>
        </div>
        <button
          class="d-none close pull-right"
          aria-label="Close"
          type="button"
          (click)="templateModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body-wrapper">
        <div class="modal-body">
          <div class="info-content">
            <div class="title">Público</div>
            <div class="d-flex flex-column">
              <div *ngFor="let audience of modalObj.event.audiences">
                {{ audience }}
              </div>
            </div>
          </div>
          <div class="info-content" *ngIf="modalObj.event.targetAudienceLevel">
            <div class="title">Nível</div>
            <div class="list-inline">
              <div *ngFor="let level of modalObj.event.targetAudienceLevelList">
                {{ level }}
              </div>
            </div>
          </div>
          <div class="info-content">
            <div class="title">Local</div>
              <div class="d-flex flex-row">
                <div class="mr-1">{{ modalObj.event.itemGridPlace }}
                  {{modalObj.event.isOutdoorPlace ? ' (Atividade Outdoor)' : '' }}
                </div>
              </div>
          </div>
          <div class="date-container row no-gutters text-center mb-4 border bg-light">
            <div class="col border-right">
              <div class="title">Duração</div>
              <div>{{ dateUtil.getDurationInMinutes(modalObj.event.duration) }} min</div>
            </div>
          </div>
        </div>

        <app-tabs
          name="kids"
          [tabs]="kidsScheduleTabs"
          [(activeTab)]="activeTab"
        ></app-tabs>

        <div class="modal-body" *ngIf="activeTab === kidsScheduleTabs[0]">
          <div class="info-content">
            <div class="title-minor bottom-line py-4 mb-2">AGENDE UMA AULA EXPERIMENTAL</div>

            <p *ngIf="!this.modalObj.event.scheduleOnlyInGym; else gymWithSwimmingDisclaimer">
              Selecione abaixo a data e o horário que deseja agendar a <strong>aula experimental</strong>.
            </p>

            <ng-template #gymWithSwimmingDisclaimer>
              <p>
                <strong>Entre em contato com a unidade</strong> para agendar uma aula experimental.
              </p>
            </ng-template>

            <p class="label-input mb-0" [style.text-transform]="'none'">
              <strong>*Atenção</strong>: não haverá aula experimental em feriados nacionais e municipais
            </p>
          </div>
          <div *ngIf="modalObj.event.availableClassHour.length > 0; else empty">
            <div *ngIf="!this.modalObj.event.scheduleOnlyInGym">
              <div *ngFor="let availableClass of modalObj.event.availableClassHour">
                <div *ngFor="let classInfo of availableClass.classHours"
                  class="d-flex flex-row justify-content-between bottom-line py-3">
                  <div>
                    <div class="text-uppercase class-info">
                      {{dateUtil.dateToISO(availableClass.availableClassDay).toString() | dayToWeekdayName}}
                       - {{availableClass.availableClassDay}}
                    </div>
                    {{classInfo.hora}}
                  </div>
                  <button
                    class="btn btn-primary ml-8 ml-md-24 kids-schedule-button"
                    type="button"
                    (click)="selectClassToSchedule(availableClass.availableClassDay, classInfo.hora, classInfo)"
                  >
                    AGENDAR
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ng-template #empty>
            <div *ngIf="!this.modalObj.event.scheduleOnlyInGym" class="loading-container">
              <app-empty-card
                icon="/assets/icons_custom/ic-empty-list.svg"
                altIcon="Nenhuma vaga disponível"
                message="Não há nenhuma vaga disponível para esta aula no momento.
                 Você pode entrar na lista de espera para ser avisado sobre a disponibilidade."
              ></app-empty-card>
            </div>
          </ng-template>
        </div>
        <div class="modal-body" *ngIf="activeTab === kidsScheduleTabs[1]">
          <div class="info-content">
            <div class="title-minor bottom-line py-4 mb-2">ENTRAR NA LISTA DE ESPERA</div>
            <div>
              Você será notificado quando uma vaga for disponibilizada nesta turma.
            </div>
            <button
              class="btn btn-primary extense-text-button ml-auto mr-auto mt-3 d-flex"
              type="button"
              (click)="enterWaitingList()"
            >
              ENTRAR NA LISTA DE ESPERA
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="col btn btn-outline-primary"
          type="button"
          (click)="cancel()"
        >
          {{ modalObj.cancelButton || 'VOLTAR' }}
        </button>
      </div>
    </div>
  </div>
</div>
