<div class="title-section">Telefones</div>
<form #form="ngForm" class="row">
  <div class="col-12 col-md">
    <label for="profile-phone-home" class="label-input">Residencial</label>
    <input
      id="profile-phone-home"
      name="home"
      class="form-control"
      [appMaskedInput]="phoneMask"
      [(ngModel)]="phones.home">
  </div>

  <div class="col-12 col-md col-right">
    <label for="profile-phone-work" class="label-input">Comercial</label>
    <input
      id="profile-phone-work"
      name="work"
      class="form-control"
      [appMaskedInput]="phoneMask"
      [(ngModel)]="phones.work">
  </div>

  <div class="col-12 col-md col-right">
    <label for="profile-phone-cell" class="label-input">Celular</label>
    <input
      id="profile-phone-cell"
      name="cell"
      class="form-control"
      [appMaskedInput]="phoneMask"
      [(ngModel)]="phones.cell">
  </div>
</form>
