import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { doOnSubscribe } from './observable-pipes';


/**
 * This class will handle most commun task relate to a resquest,
 * like: loading, basic error handling and successful resquest.
 */
export class RequestObject<T, R = T> {
  /** Observable of the request given on constructor or afterward. */
  public observable$: Observable<T>;
  /** Responsable to store the result of the request. */
  public response: R;

  /** Responsable to inform when the request's loading. */
  public loading      = false;
  /**
   * Responsable to inform when the request wasn't successful.
   *
   * The standard for a error are pretty simple, if something happens that
   * trigger a `catchError`, this variable will be `true`.
   */
  public error        = false;
  /**
   * Responsable to inform when the request finished successful without a error.
   *
   * **Use with Caution:**
   * The value of this variable is direct related to `error` variable. Which means
   * if a request finished without a `catchError` being triggered, this variable will be `true`.
   */
  public successful   = false;


  constructor(requestObservable?: Observable<T>) {
    if (!requestObservable) {
      requestObservable = of(null);
    }

    this.observable$ = requestObservable.pipe(
      doOnSubscribe(() => {
        this.loading  = true;
        this.error    = false;
      }),
      finalize(() => {
        this.loading    = false;
        this.successful = !this.error;
      }),
      catchError(error => {
        this.error = true;

        return throwError(error);
      }),
    );
  }
}
