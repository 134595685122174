<div class="container">
  <div class="title-lg text-center title-success">Sucesso!</div>

  <div class="card no-side-border-xs row-on-xs">
    <div class="h-100 d-flex flex-column">
      <div class="image">
        <div class="position-relative backdrop d-flex justify-content-around align-items-center">
          <img src="/assets/icons_custom/ic-success.svg">
        </div>
      </div>

      <div class="card-body text-center d-flex flex-column justify-content-around">
        <div>Seu cadastro foi concluído com sucesso. Seu pedido será analisado e entraremos em contato em breve.</div>
        <div class="data-box border">
          <div class="row text-left">
            <div class="col-12 col-md-6 border-right">
              <label class="label-input">Nome</label>
              <div>{{ formResponse.name }}</div>
            </div>

            <div class="col-12 col-md-6 col-right">
              <label class="label-input">E-mail</label>
              <div>{{ formResponse.email }}</div>
            </div>

            <div class="col-12 col-md-6 border-right padding-top">
              <label class="label-input">Cidade de Interesse</label>
              <div>{{ formResponse.address.city.name }}</div>
            </div>

            <div class="col-12 col-md-6 col-right padding-top">
              <label class="label-input">Estado de Interesse</label>
              <div>{{ formResponse.address.city.state.name }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
