<ng-template #loader>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>

<ng-container *ngIf="!loading; else loader">
    <!-- PRINCIPAIS -->
    <app-profile-data-main [info]="info">
    </app-profile-data-main>
</ng-container>

<ng-template #error>
  <app-empty-card icon="/assets/icons_custom/ic-empty-list.svg" altIcon="Lista vazia"
    [message]="emptyMessage"
  ></app-empty-card>
</ng-template>