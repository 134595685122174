import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { MembershipModel } from '@models';
import { BreakpointsService, ScreenSize } from '@services/breakpoints.service';


@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss'],
})
export class MembershipsComponent implements OnInit, OnDestroy {

  @Input() memberships: MembershipModel[];
  @Input() hasSubscribeButton = true;
  @Input() emptyMessage = 'Esta seção não existe ou encontra-se indisponível. Verifique sua conexão ou tente novamente mais tarde.';

  @Output() clickShowMore = new EventEmitter<number>();

  membershipsLength: number;

  swiperConfig: SwiperConfigInterface = {
    allowSlideNext: true,
    allowSlidePrev: true,
    loop: false,
    navigation: true,
    spaceBetween: 12,
  };

  offersIsContract = true;

  isBreakpointSmOrLower: boolean;
  breakpointServiceSubscription: Subscription;


  constructor(private readonly breakpointsService: BreakpointsService) {}

  ngOnInit(): void {
    this.membershipsLength = this.memberships?.length;

    this.configSlides();
  }

  ngOnDestroy(): void {
    this.breakpointServiceSubscription.unsubscribe();
  }

  private configSlides(): void {
    this.swiperConfig.centeredSlides = this.membershipsLength <= 1;


    this.setSlidesPerView();
  }

  private setSlidesPerView(): void {
    this.breakpointServiceSubscription = this.breakpointsService.emitter.pipe(
      map(breakpoint => breakpoint > ScreenSize.LG ? ScreenSize.LG : breakpoint)
    ).subscribe(breakpoint => {
      this.swiperConfig.slidesPerView = breakpoint - 1 || 1;

      this.isBreakpointSmOrLower = breakpoint <= ScreenSize.SM;

      this.swiperConfig.pagination = this.membershipsLength - breakpoint >= 0 ? this.configSlidesPagination() : false;
    });
  }

  private configSlidesPagination(): SwiperPaginationInterface {
    return {
      bulletActiveClass:  'swiper-pagination-bullet-dark',
      clickable:          true,
      el:                 '.swiper-pagination',
      type:               'bullets',
    };
  }
}
