import { Component, Input } from '@angular/core';

import { AnalyticsService, BrandService } from '@services';


@Component({
  selector: 'app-get-apps',
  templateUrl: './get-apps.component.html',
  styleUrls: ['./get-apps.component.scss'],
})
export class GetAppsComponent {
  @Input() androidDownloadLink =
    this.brandService.getBrand() === 'bt'
      ? `https://play.google.com/store/apps/details?id=br.com.w12.bodytech`
      : `https://play.google.com/store/apps/details?id=br.com.w12.formula`;

  @Input() iosDownloadLink =
    this.brandService.getBrand() === 'bt'
      ? `https://apps.apple.com/app/bodytech-academia/id1659429085`
      : `https://apps.apple.com/us/app/f%C3%B3rmula-academia/id1625366952`;

  @Input() analyticsCategory: string;
  @Input() columnOnMobile = false;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly brandService: BrandService
  ) {}

  clickApp(appOS: string): void {
    this.analyticsService.trackEvent(
      `Aplicativo - ${appOS}`,
      this.analyticsCategory,
      'Aplicativo',
    );
  }
}
