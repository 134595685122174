<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper">
  <app-corporate-header></app-corporate-header>
  <br>
  <ng-container *ngIf="!formSent; else success">
    <app-corporate-form
      [(info)]="info"
      (formSent)="formSentUpdate($event)">
    </app-corporate-form>
  </ng-container>
  <br>
  <app-corporate-gym-filter></app-corporate-gym-filter>

  <ng-template #success>
    <app-corporate-success
      [info]="info">
    </app-corporate-success>
  </ng-template>
</main>
