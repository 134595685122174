<app-state-gym-filter
  *ngIf="btPassData && !btPassPlanLoading"
  analyticsCategory="BT Pass"
  analyticsTag="Filtros"
  title="CONFIRA OS VALORES NA SUA REGIÃO"
  titleClasses="text-center bt-pass-title mb-4 pb-2"
  [gymsToFilter]="btPassData.incomesList"
  [isGymFilter]="true"
  (filteredGyms)="setFilteredBtPassData($event)"
></app-state-gym-filter>

<div class="container" *ngIf="!btPassLoading && !btPassPlanLoading; else loading">
  <div class="row" *ngIf="filteredbtPassData.incomesList.length">
    <table class="table mx-12 mx-sm-0">
      <thead>
        <tr class="text-uppercase">
          <th scope="col" class="font-weight-normal">Academia</th>

          <ng-container *ngIf="isBreakpointUpMd">
            <th
              *ngFor="let plan of btpassPlanList; let indexHeader = index"
              class="text-center font-weight-normal px-1"
              scope="col"
              [id]="'tableHeader' + indexHeader"
            >
              {{ plan.planName }}
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let income of filteredbtPassData.incomesList">
          <tr class="d-flex d-lg-table-row flex-wrap">
            <th
              class="align-middle col-12 col-lg"
              scope="row"
            >
              <a (click)="openGymPage(income.gym.gymUnitId)">
                <b>{{ income.city }}</b> - {{ income.gymName }}
              </a>
            </th>

            <td
              *ngFor="let incomeValue of income.values; let indexTd = index"
              appTemplateHightlight="#tableHeader{{indexTd}}"
              class="Oswald text-center p-0 col-6 col-lg"
              classes="hightlight"
              [class.unavaliable]="!incomeValue.basePrice"
            >
              <div class="d-flex flex-column">
                <div
                  *ngIf="!isBreakpointUpMd"
                  class="pass-type-name d-flex justify-content-center align-items-center Oswald"
                >
                  {{ btpassPlanList[indexTd].planName }}
                </div>

                <div class="bt-pass-price-wrapper d-flex flex-column justify-content-center align-items-center">
                  <ng-container *ngIf="!!incomeValue.basePrice; else unavailableProductElse">
                    <div class="bt-pass-price d-flex justify-content-center align-items-baseline">
                      {{ incomeValue.basePrice | currency:'BRL':'symbol':'':locale }}
                    </div>

                    <a
                      *ngIf="income.isMigrated; else legacyTemplate"
                      class="d-flex flex-column justify-content-center w-100 h-100"
                      target="_blank"
                      [href]="incomeValue.urlSale"
                    >
                      COMPRAR
                    </a>

                    <ng-template #legacyTemplate>
                      <div class="acquire-button link" (click)="openPassInfoModal(incomeValue, income.gym.gymUnitId, income.gym.gymUnitName)">
                        COMPRAR
                      </div>
                    </ng-template>
                  </ng-container>

                  <ng-template #unavailableProductElse>
                    <div class="text-uppercase text-center my-auto">
                      Produto Indisponível
                    </div>
                  </ng-template>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #loading>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>
