import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ContactInfoModel, EvoBaseParamsModel, EvoGymResponse } from '@models';
import { evoBack, EvoBackEndpoints } from '@utils/app-endpoints';


@Injectable()
export class EvoGymService {


  constructor(private readonly http: HttpClient) { }

  /**
   *  @todo: Transformar esse método em uma function genérica da `utils`.
   *  Method to find a single contact info from a gym in an array based on the idBranch of this Gym.
   *
   *  @param list   Array of `ContactInfoModel` objects.
   *  @param key    The `idBranch` that you are searching in the ContactInfoModel array.
   *  @param start  Positon of the array to start the search (must be lower or equal than `end` parametter).
   *  @param end    Positon of the array to finish the search (must be higher or equal than `start` parametter).
   *                Do not use `array.lenght` as end parameter, use `array.lenght - 1` instead.
   *
   *  @return       Returns a single object of the array if there is any object that has the idBranch equals of
   *                the `key` parametter passed, returns `null` otherwise.
   */
  private binarySearch(list: ContactInfoModel[], key: number, start: number, end: number): ContactInfoModel | null {
    /** Base case, no object found */
    if (start > end) { return null; }

    const middle = Math.floor((start + end) / 2);

    if (list[middle].idBranch === key) {
      return list[middle];
    } else if (list[middle].idBranch > key) {
      return this.binarySearch(list, key, start, middle - 1);
    }

    return this.binarySearch(list, key, middle + 1, end);
  }

  public getContactInfoById(list: ContactInfoModel[], id: number): ContactInfoModel | null {
    return list ? this.binarySearch(list, id, 0, list.length - 1) : null;
  }

  public getGymsContactInfo(params?: EvoBaseParamsModel): Observable<EvoGymResponse[]> {
    return this.http.get<EvoGymResponse[]>(evoBack(EvoBackEndpoints.Configuration), { params: { ...params } });
  }
}
