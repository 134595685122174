import { Component, Input } from '@angular/core';
import { AlertMessage, DocuSignStatusDoc, DocuSignTransaction, MessageMap, RegistrationRequest } from '@models';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthService } from '@services/auth.service';
import { BrandService } from '@services/brand.service';
import { DocuSignService } from '@services/docuSign.service';
import { LoadingService } from '@services/loading.service';
import { AppConstants } from '@utils/app-constants';
import { DocusignTransactionStatusEnum } from '@utils/docusign-transaction-status.util';
import { of } from 'rxjs';

@Component({
  selector: 'app-form-success',
  templateUrl: './form-success.component.html',
  styleUrls: ['./form-success.component.scss'],
})
export class FormSuccessComponent {

  @Input() formData: RegistrationRequest;
  @Input() docuSignSearchType: number;
  @Input() isRegistration: boolean;

  public brand: string;

  constructor(
    private readonly brandService: BrandService,
    private readonly docusignService: DocuSignService,
    private readonly authService: AuthService,
    private readonly alertMessageService: AlertMessageService,
    private readonly loadingService: LoadingService
  ) {
    this.brand = this.brandService.getBrand();
  }

  signContract(): void {
    this.getAuthToken().subscribe((tokens: any) => {
      this.loadingService.startLoading();
      const appToken = tokens.data?.authenticateUser?.tokenUser || localStorage.getItem(AppConstants.STOR_APP_USER_TOKEN);
      this.docusignService.checkDocusignTransaction(
        this.formData.contractGymUnit,
        this.formData.contractCode,
        this.docuSignSearchType,
        appToken
      )
      .subscribe((res: DocuSignTransaction) => {
        if (res.CodigoEstadoTransacao === DocusignTransactionStatusEnum.Sent) {
          this.docusignService.getDocuSignStatus(res.CodigoTransacaoDocusign).subscribe((doc: DocuSignStatusDoc) => {
            this.loadingService.stopLoading();
            if (doc.getStatusDoc.embeddedSignURLs) {
              window.open(doc?.getStatusDoc.embeddedSignURLs[0].url);
            } else {
              this.alertMessageService.showToastr(AlertMessage.error(MessageMap.VERIFIQUE_SEU_EMAIL));
            }
          }, () => {
            this.loadingService.stopLoading();
          });
        } else {
          this.loadingService.stopLoading();
          const message = res.CodigoEstadoTransacao === DocusignTransactionStatusEnum.Completed
          ? MessageMap.CONTRATO_ASSINADO
          : MessageMap.CONTRATO_INDISPONIVEL;
          this.alertMessageService.showToastr(AlertMessage.error(message));
        }
      }, () => {
        this.loadingService.stopLoading();
      });
    });
  }

  private getAuthToken() {
    if (localStorage.getItem(AppConstants.STOR_APP_USER_TOKEN)) {
      return of({});
    }

    return this.authService.authenticateUser({
      email: this.formData.personal.email,
      password: this.formData.personal.password
    });
  }
}
