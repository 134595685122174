<ng-template #header>
  <app-header-static-image [headerObj]="config.header"></app-header-static-image>
</ng-template>

<main class="main-wrapper container justify-content-around px-0 mt-0">
  <div class="schedules-container">
    <form [formGroup]="form" class="form-validate card small-card text-center row-on-xs no-side-border-xs">
      <div class="title-section text-black text-left text-uppercase">Agendar horário</div>
      <p class="text-left mb-24">
        Faça aulas online individuais, com os Professores da Bodytech!
        Basta reservar um horário e acessar a sala virtual na data e horário selecionados.
        Mas atenção: existe um limite de agendamentos simultâneos e caso seja necessário, o cancelamento do agendamento deve ser feito com antecedência.
      </p>
      <app-schedules-per-month-info *ngIf="userAvailabilityToSchedule"
        [totalOnlineSchedules]="userAvailabilityToSchedule.totalOnlineSchedules"
        [numberOfSchedulesAlreadyMade]="userAvailabilityToSchedule.numberOfSchedulesAlreadyMade"
        [month]="userAvailabilityToSchedule.month"
        [showSchedulePerMonthLimit]="false"
      ></app-schedules-per-month-info>
      <div class="flex sm-column">
        <div class="w-100 text-left">
          <label for="gym-field" class="label text-uppercase">DATA</label>
          <app-select formControlName="date" name="documentType" [list]="dates" (ngModelChange)="updateAvailableHours()"
            required></app-select>
          <app-error-message [control]="form.controls.date"></app-error-message>
        </div>
        <div class="w-100 text-left ml-md-24">
          <label for="gym-field" class="label text-uppercase">HORÁRIO</label>
          <app-select formControlName="hour" name="documentType" [list]="hours"
            (ngModelChange)="updateAvailableTeachers()" required></app-select>
          <app-error-message [control]="form.controls.hour"></app-error-message>
        </div>
      </div>

      <div class="text-left">
        <label for="teacher-field" class="label text-left text-uppercase">SELECIONE O PROFESSOR</label>
        <input name="teacher" class="form-control select-teacher" placeholder="Selecione uma opção"
          formControlName="teacher" (focus)="showTeacherSelectModal()" required>
        <app-error-message [control]="form.controls.teacher"></app-error-message>
      </div>

      <div class="flex mt-15">
        <button class="btn btn-outline-primary w-100" routerLink="/cliente/treinos">VOLTAR</button>
        <button class="btn btn-primary w-100 ml-8 ml-md-24" (click)="submit()">AGENDAR</button>
      </div>
    </form>
  </div>
</main>

<app-modal-teacher-select></app-modal-teacher-select>
