import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';

import { FuseModule } from '@pipes/fuse/fuse.module';
import { CheckboxModule } from '@shared/components/checkbox/checkbox.module';
import { TabsModule } from '@shared/components/tabs/tabs.module';

import { ModalGymSelectByCityComponent } from './modal-gym-select-by-city.component';
import { SelectCityTabComponent } from './select-city-tab/select-city-tab.component';
import { SelectGymTabComponent } from './select-gym-tab/select-gym-tab.component';


@NgModule({
  declarations: [
    ModalGymSelectByCityComponent,
    SelectCityTabComponent,
    SelectGymTabComponent,
  ],
  imports: [
    CheckboxModule,
    CommonModule,
    FormsModule,
    FuseModule,
    ModalModule,
    TabsModule,
  ],
  exports: [
    ModalGymSelectByCityComponent,
  ]
})
export class ModalGymSelectByCityModule { }
