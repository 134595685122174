<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper container">
  <div class="card no-side-border-xs row-on-xs">
    <div class="card-body">
      <div class="title section mt-0">
        Quer fazer parte do time {{ isBT ? 'BodyTech' : 'Fórmula' }}?
      </div>
      <div class="text-help">
        <br>
        <div class="paragraph">
          Acesse o nosso perfil do Instagram <a href="https://www.instagram.com/bt_vagas/" target="_blank" rel="noopener">@bt_vagas</a>
          e fique sabendo de todas as vagas abertas no momento.
          Se preferir, envie o seu currículo para recrutamento@bodytech.com.br com o nome da vaga no assunto.
        </div>
      </div>
    </div>
  </div>
</main>
