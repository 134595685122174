import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';
import { RegistrationLockingMainComponent } from './registration-locking-main/registration-locking-main.component';
import { RegistrationLockingRequestConfirmComponent } from './registration-locking-request-confirm/registration-locking-request-confirm.component';
import { RegistrationLockingRequestComponent } from './registration-locking-request/registration-locking-request.component';
import { RegistrationLockingComponent } from './registration-locking.component';
import { RegistrationLockingRoutingModule } from './registration-locking.routing';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,

    RegistrationLockingRoutingModule,
  ],
  declarations: [
    RegistrationLockingComponent,
    RegistrationLockingMainComponent,
    RegistrationLockingRequestComponent,
    RegistrationLockingRequestConfirmComponent,
  ],
})
export class RegistrationLockingModule { }
