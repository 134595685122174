import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BodyClassService {
  private readonly body: HTMLElement;
  constructor(@Inject(DOCUMENT) private document: any) {
    this.body = this.document.body;
  }

  setClass(str: string) {
    this.body.classList.add(str);
  }

  removeClass(str: string) {
    this.body.classList.remove(str);
  }

}
