import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class PrivatePartnerService {
  private cachedPrivatePartner: string[];

  constructor(
    private readonly http: HttpClient,
  ) { }

  getPrivatePartner(): Observable<string[]> {
    if (this.cachedPrivatePartner) {
      return of(this.cachedPrivatePartner);
    } else {
      return this.http.get<string[]>('assets/data/private-partner.json').pipe(
        tap(data => this.cachedPrivatePartner = data),
      );
    }
  }
}
