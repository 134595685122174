import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';

import { BtCompanyHeaderComponent } from './bt-company-header/bt-company-header.component';
import { BtCompanyComponent } from './bt-company.component';
import { BtCompanyRoutingModule } from './bt-company.routing';
import { DropdownBlockComponent } from './dropdown-block/dropdown-block.component';
import { FileBlockComponent } from './file-block/file-block.component';
import { FrCompanyHeaderComponent } from './fr-company-header/fr-company-header.component';
import { FrTimelineComponent } from './fr-timeline/fr-timeline.component';
import { GraphicBlockComponent } from './graphic-block/graphic-block.component';
import { GraphicBigComponent } from './graphic-block/graphic/graphic-big/graphic-big.component';
import { GraphicPieComponent } from './graphic-block/graphic/graphic-pie/graphic-pie.component';
import { GraphicComponent } from './graphic-block/graphic/graphic.component';
import { TeamBlockComponent } from './team-block/team-block.component';
import { TextBlockComponent } from './text-block/text-block.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    BtCompanyRoutingModule,
  ],
  declarations: [
    BtCompanyComponent,
    BtCompanyHeaderComponent,
    DropdownBlockComponent,
    FileBlockComponent,
    GraphicBlockComponent,
    GraphicBigComponent,
    GraphicComponent,
    GraphicPieComponent,
    TeamBlockComponent,
    TextBlockComponent,
    FrCompanyHeaderComponent,
    FrTimelineComponent,
  ],
})
export class BtCompanyModule { }
