import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConsultExtensionDataResponse,
  HistoricoBloqueioNaoDebito,
  HistoricoProrrogacaoPorPlanoContrato,
  ModalType,
} from '@models';
import { CustomerService } from '@services/customer.service';
import { GymService } from '@services/gym.service';
import { ModalService } from '@services/modal.service';
import { RegistrationLockingService } from '@services/registration-locking.service';
import { AppConstants } from '@utils/app-constants';
import { catchError } from 'rxjs/operators';
import { DateUtil } from '@utils/date-util';
import { LoadingService } from '@services/loading.service';

export enum DCCEstadoOcorrencia {
  Canceled = 0,
  Current,
  Future,
  Overdue,
}

export enum DMPEstadoProrrogacao {
  Current = 1,
  Overdue,
  Canceled
}
@Component({
  selector: 'app-registration-locking-main',
  templateUrl: './registration-locking-main.component.html',
  styleUrls: ['./registration-locking-main.component.scss'],
})
export class RegistrationLockingMainComponent {
  device = AppConstants.DEVICE;
  locale = AppConstants.LOCALE;
  daysRemaining = 0;
  hasActiveLocking = true;
  isDCC = false;
  extensionData: ConsultExtensionDataResponse;
  filteredScheduleList: any[];
  filteredHistoryList: HistoricoProrrogacaoPorPlanoContrato[] | HistoricoBloqueioNaoDebito[];
  ongoingLockingList: any[];
  gymNames: any[];

  constructor(
    private router: Router,
    private registrationLockingService: RegistrationLockingService,
    private readonly customerService: CustomerService,
    private modalService: ModalService,
    private readonly loadingService: LoadingService,
    private gymService: GymService
  ) {
    if (!history.state.contractCode || !history.state.gymUnitId) {
      this.router.navigate(['cliente/perfil/contratos']);
    } else {
      this.gymService.getGymNames().subscribe((res) => {
        this.gymNames = res;
        this.loadLockingData();
      });
    }
  }

  loadLockingData() {
    this.registrationLockingService
    .consultExtensionData(history.state.gymUnitId, history.state.contractCode)
    .toPromise()
    .then((res) => {
      this.extensionData = res;
      this.hasActiveLocking = !!this.extensionData?.ListaAgendamentoFerias;
      this.isDCC = this.extensionData?.InPlanoDCC;
      this.daysRemaining = this.extensionData?.NrMaxDiasProrrogar;
      this.isDCC ? this.filterArrayDCC() : this.filterArrays();
    });
  }

  filterArrays() {
    if (this.extensionData?.ListaHistoricoProrrogacaoPorPlanoContrato) {
      this.ongoingLockingList = this.extensionData.ListaHistoricoProrrogacaoPorPlanoContrato.filter(
        (item) => {
          if (item.SQTipoProrrogacao === 1 && item.CodigoEstadoProrrogacao === DMPEstadoProrrogacao.Current) {
            item['finalDayVacation'] = this.getFinalDayBasedInDuration(
              item.DataInicioProrrogacao,
              item.NrDiasProrrogacao
            );
            return true;
          }
          return false;
        }
      );

      this.ongoingLockingList = this.sortListsIncreasing(this.ongoingLockingList);

      this.filteredHistoryList = this.extensionData.ListaHistoricoProrrogacaoPorPlanoContrato.filter(
        (item) => {
          const notCanceledToday = item?.NrDiasProrrogacao > 0;

          if (item.SQTipoProrrogacao === 1 && item.NrDiasProrrogacao > 0 &&
            (item.CodigoEstadoProrrogacao === DMPEstadoProrrogacao.Overdue
            || (item.CodigoEstadoProrrogacao === DMPEstadoProrrogacao.Canceled && notCanceledToday))) {
            item['finalDayVacation'] = this.getFinalDayBasedInDuration(
            item.DataInicioProrrogacao,
            item.NrDiasProrrogacao
            );

            const historyGym = this.gymNames.find(gym => gym.id === item.SQUnidade);
            item['gymUnitName'] = historyGym.portalName;
            return true;
          }
          return false;
        }
      );

      this.filteredHistoryList.sort((a: any, b: any) => {
        return DateUtil.formatLegacyDate(b.DataInicioProrrogacao).getTime() - DateUtil.formatLegacyDate(a.DataInicioProrrogacao).getTime();
      });
    }

    if (this.extensionData?.ListaAgendamentoFerias) {
      this.filteredScheduleList = this.extensionData.ListaAgendamentoFerias.filter(
        (item) => {
          item['finalDayVacation'] = this.getFinalDayBasedInDuration(
            item.DataInicioFerias,
            item.NrDiasFerias
          );

          return (
            item.SQEstadoAgendamento === 1
          );
        }
      );

      this.filteredScheduleList = this.sortListsIncreasing(this.filteredScheduleList);
    }
  }

  getCanceledFinalDateDCC(item: any) {
    if (item?.DtCancelamento) {
      const finalDate = DateUtil.formatLegacyDate(item?.DtCancelamento);
      finalDate.setDate(finalDate.getDate() - 1);

      return finalDate;
    }
  }

  sortListsIncreasing(list: any) {
    if (list.length > 1) {
      if (list[0].DataInicioProrrogacao) {
        list.sort((a: any, b: any) => {
        return DateUtil.formatLegacyDate(a.DataInicioProrrogacao).getTime() - DateUtil.formatLegacyDate(b.DataInicioProrrogacao).getTime();
        });
      }

      if (list[0].DataInicioFerias) {
        list.sort((a: any, b: any) => {
          return DateUtil.formatLegacyDate(a.DataInicioFerias).getTime() - DateUtil.formatLegacyDate(b.DataInicioFerias).getTime();
        });
      }

      if (list[0].DtInicioOcorrencia) {
        list.sort((a: any, b: any) => {
          return DateUtil.formatLegacyDate(a.DtInicioOcorrencia).getTime() - DateUtil.formatLegacyDate(b.DtInicioOcorrencia).getTime();
        });
      }
    }
    return list;
  }

  filterArrayDCC() {
    if (this.extensionData?.ListaHistoricoBloqueioNaoDebito) {
      this.filteredHistoryList = this.extensionData.ListaHistoricoBloqueioNaoDebito.filter(
        (item) => {
          const notCanceledToday = item?.DtCancelamento && DateUtil.formatLegacyDate(item?.DtCancelamento) >
            DateUtil.formatLegacyDate(item?.DtInicioOcorrencia);

          if (item.SQTipoOcorrencia === 18 && (item.CdEstadoOcorrencia === DCCEstadoOcorrencia.Overdue ||
            (item.CdEstadoOcorrencia === DCCEstadoOcorrencia.Canceled && notCanceledToday))) {
              const finalDate = this.getCanceledFinalDateDCC(item);

              item['finalDay'] = finalDate;
              item['formattedEndOcurrence'] = DateUtil.formatLegacyDate(item.DtTerminoOcorrencia);
              const historyGym = this.gymNames.find(gym => gym.id === item.SQUnidade);
              item['gymUnitName'] = historyGym.portalName;
              return true;
            }
          return false;
        }
      );

      this.filteredHistoryList.sort((a: any, b: any) => {
        return DateUtil.formatLegacyDate(b.DtInicioOcorrencia).getTime() - DateUtil.formatLegacyDate(a.DtInicioOcorrencia).getTime();
      });


      this.ongoingLockingList = this.extensionData.ListaHistoricoBloqueioNaoDebito.filter(
        (item) => {
          if (item.SQTipoOcorrencia === 18 &&
            (item.CdEstadoOcorrencia === DCCEstadoOcorrencia.Future || item.CdEstadoOcorrencia === DCCEstadoOcorrencia.Current)) {
            item['duration'] = this.getDurationBasedInFinalDay(
              item.DtInicioOcorrencia,
              item.DtTerminoOcorrencia
            );
            return true;
          }
          return false;
        }
      );

      this.ongoingLockingList = this.sortListsIncreasing(this.ongoingLockingList);
    }
  }

  getDurationBasedInFinalDay(initialDate: string, endDate: string): number {
    const formatedInitialDate = DateUtil.formatLegacyDate(initialDate);
    const formatedEndDate = DateUtil.formatLegacyDate(endDate);

    const startMonth = formatedInitialDate.getMonth();
    const startDay = formatedInitialDate.getDate();
    const startYear = formatedInitialDate.getFullYear();
    const endMonth = formatedEndDate.getMonth();
    const endYear = formatedEndDate.getFullYear();

    const caseFirstDay: number = startDay === 1 ? 1 : 0;

    return ((endYear * 12 + endMonth) - (startYear * 12 + startMonth) + caseFirstDay);
  }

  getFinalDayBasedInDuration(initialDate: string, duration: number): Date {
    const formatedInitialDate = DateUtil.formatLegacyDate(initialDate);

    const finalDate = new Date(formatedInitialDate);
    finalDate.setDate(formatedInitialDate.getDate() + duration - 1);
    return finalDate;
  }

  lockingRegistration() {
    if (this.isDCC) {
      this.customerService
        .getCustomerCreditCard(this.extensionData.CodigoUnidadeContrato)
        .pipe(
          catchError(() => {
            this.modalService.show({
              type: ModalType.confirm,
              title: 'ERRO AO SOLICITAR O TRANCAMENTO!',
              message:
              'Cartão de Crédito não cadastrado. Para solicitar o trancamento ou regularizar seu cadastro, vá até a sua Unidade Bodytech.',
            });
            return [];
          })
        )
        .subscribe((res) => {
          if (res?.creditCardNumber) {
            this.navigateToScheduleLocking();
          }
        });
    } else {
      this.navigateToScheduleLocking();
    }
  }

  navigateToScheduleLocking() {
    this.router.navigate(['cliente/trancamento/agendar'], {
      state: {
        contractCode: history.state.contractCode,
        gymUnitId: history.state.gymUnitId,
        daysRemaining: this.daysRemaining,
        minRequestPeriod: this.extensionData.QnMinimoDiasSolicitar,
        maxDate: DateUtil.formatLegacyDate(this.extensionData.DataFimVigenciaContrato),
        isDCC: this.isDCC,
        DCClockingInfo: this.extensionData?.AptoSolicitarBloqueioDCC
      },
    });
  }

  cancelLocking(lockingItem: any, isScheduleLocking: boolean): void {
    this.modalService.show({
      type: ModalType.registrationLocking,
      title: isScheduleLocking
        ? 'CANCELAR AGENDAMENTO?'
        : 'CANCELAR TRANCAMENTO EM CURSO?',
      message: isScheduleLocking
        ? `Deseja cancelar o trancamento agendado para o período entre
      ${new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(lockingItem.DataInicioFerias).toISOString(), 'shortDate')}
      a ${new DatePipe(this.locale).transform(lockingItem.finalDayVacation, 'shortDate')}?`
        : `Deseja cancelar o trancamento em curso? Os dias restantes do período de trancamento
          ficarão disponíveis para trancamentos futuros e os agendamentos pendentes também serão cancelados.`,
      cancelButton: 'NÃO',
      confirmButton: 'SIM',
      confirmCallback: (res: any) => {
        if (res.status) {
          if (isScheduleLocking) {
            this.cancelScheduledLocking(this.extensionData.CodigoUnidadeContrato, lockingItem, res.message);
          } else {
            this.cancelCurrentLocking(this.extensionData.CodigoUnidadeContrato, lockingItem, res.message);
          }
        }
      },
    });
  }

  cancelCurrentLocking(unitGymId: number, lockingItem: any, cancelReason: string) {
    this.loadingService.startLoading();

    this.registrationLockingService
    .cancelCurrentVacation(unitGymId, {
      CodigoContrato: this.extensionData.CodigoContrato,
      Dispositivo: this.device,
      MotivoCancelamento: cancelReason
    })
    .subscribe(
      () => {
        this.loadingService.stopLoading();
        this.cancelLockingSucessful(lockingItem, false);
        this.loadLockingData();
      },
      (error) => {
        this.loadingService.stopLoading();
        this.unsuccessfulLockingRegistration(error?.error.message);
      }
    );
  }

  cancelScheduledLocking(unitGymId: number, lockingItem: any, cancelReason: string) {
    this.loadingService.startLoading();

    this.registrationLockingService
    .cancelScheduledVacation(unitGymId, {
        CodigoContrato: lockingItem.SQContrato,
        CodigoDoAgendamento: lockingItem.SQAgendamentoFerias,
        Dispositivo: this.device,
        MotivoCancelamento: cancelReason,
      })
    .subscribe(
      () => {
        this.loadingService.stopLoading();
        this.cancelLockingSucessful(lockingItem, true);
        this.loadLockingData();
      },
      (error) => {
        this.loadingService.stopLoading();
        this.unsuccessfulLockingRegistration(error?.error.message);
      }
    );
  }

  cancelLockingSucessful(lockingItem: any, isScheduleLocking: boolean): void {
    let message;

    const initialDate = this.isDCC
    ? new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(lockingItem.DtInicioOcorrencia).toISOString(), 'shortDate')
    : new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(
      (lockingItem.DataInicioFerias || lockingItem.DataInicioProrrogacao)
    ).toISOString(), 'shortDate');

    const endDate = this.isDCC
    ? new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(lockingItem.DtTerminoOcorrencia).toISOString(), 'shortDate')
    : new DatePipe(this.locale).transform(lockingItem.finalDayVacation, 'shortDate');

    if (isScheduleLocking) {
      message = `Seu trancamento agendado para o período entre ${initialDate} e ${endDate} foi cancelado.`;
    } else {
      message = this.isDCC
      ? `Seu trancamento para o período entre ${initialDate} e ${endDate} foi cancelado e sua data de débito mensal foi alterada
      para o dia ${new Date().getDate()} de cada mês. Você receberá detalhes da nova cobrança no e-mail cadastrado. `
      : `Seu trancamento para o período entre ${initialDate} e ${endDate} foi cancelado.
      Os dias restantes do trancamento voltarão a ficar disponíveis para futuros trancamentos.`;
    }

    this.modalService.show({
      type: ModalType.confirm,
      title: 'TRANCAMENTO CANCELADO!',
      message,
      confirmButton: 'OK',
    });
  }

  cancelLockingDCC(lockingItem: HistoricoBloqueioNaoDebito): void {
    this.modalService.show({
      type: ModalType.registrationLocking,
      title: lockingItem.CdEstadoOcorrencia === DCCEstadoOcorrencia.Current
        ? 'CANCELAR TRANCAMENTO EM CURSO?'
        : 'CANCELAR AGENDAMENTO?',
      message: lockingItem.CdEstadoOcorrencia === DCCEstadoOcorrencia.Current
        ? `Deseja cancelar o trancamento em curso? Ao optar pelo cancelamento, sua data de débito mensal passa a ser o dia
        ${new Date().getDate()} de cada mês (data do cancelamento) e você receberá uma nova cobrança no Cartão de Crédito cadastrado.
        Deseja prosseguir com o cancelamento?`
        : `Deseja cancelar o trancamento agendado para o período entre
        ${new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(lockingItem.DtInicioOcorrencia).toISOString(), 'shortDate')}
        e ${new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(lockingItem.DtTerminoOcorrencia).toISOString(), 'shortDate')}?`,
      cancelButton: 'NÃO',
      confirmButton: 'SIM',
      confirmCallback: (res: any) => {
        this.loadingService.startLoading();

        if (res.status) {
            this.registrationLockingService
              .cancelVacationDCC(
                this.extensionData.CodigoUnidadeContrato,
                {
                  CodigoContrato: this.extensionData.CodigoContrato,
                  Dispositivo: this.device,
                  MotivoCancelamento: res.message,
                }
              )
              .subscribe(
                () => {
                  this.loadingService.stopLoading();
                  this.cancelLockingSucessful(lockingItem, (lockingItem.CdEstadoOcorrencia !== DCCEstadoOcorrencia.Current));
                  this.loadLockingData();
                },
                (error) => {
                  this.loadingService.stopLoading();
                  this.unsuccessfulLockingRegistration(error?.error.message);
                }
              );
        }
      },
    });
  }

  unsuccessfulLockingRegistration(message: string) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'Não foi possível',
      message,
      confirmButton: 'OK',
    });
  }
}
