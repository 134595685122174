import { Observable } from 'rxjs';

import { GymBenefitModel, KidsActivityDetail, KidsClassTime } from '@models';

import { GymsByCitiesOfState, GymToSelect, Income, IncomeDetails } from './bt-pass.model';
import { PriceRange } from './price.model';
import { AgendaEventDetails } from './responses/agenda-response.model';
import { FlatGymUnitService, GymActivity, GymBenefit, GymFilter, GymPlan, ModalGymSelect } from './responses/gym-response.model';
import { OnlineClassTeacher } from './responses/my-online-class-schedules-response.model';
import { Tab } from './tab.model';


// tslint:disable:ban-types
export interface ModalObject<T = any> {
  type: ModalType;
  title: string;
  message: string;
  confirmButtonClass?: string;
  confirmButton?: string;
  cancelButton?: string;
  confirmCallback?: Function;
  cancelCallback?: Function;
  dismissCallback?: Function;
  data?: any;
  config?: T;
}
// tslint:enable:ban-types

export const enum ModalType {
  _close,
  agenda,
  alert,
  btpassPassInfo,
  confirm,
  creditCardInfo,
  error,
  fullScreenImage,
  gymAge,
  gymActivity,
  gymBenefit,
  gymBenefitList,
  gymBenefits,
  gymPlan,
  gymsApp,
  gymSelect,
  gymSelectByCity,
  kidsDetails,
  kidsSchedule,
  presaleDetails,
  priceRange,
  registrationLocking,
  schedule,
  singleSelect,
  tabSelect,
  teacherSelect,
}

export interface ModalAgendaObject extends ModalObject {
  event: AgendaEventDetails;
}

export interface ModalBtpassPassInfoObject extends ModalObject {
  income: Income;
  gymUnitId: number;
  gymUnitName: string;
  quantity: number;
  incomeDetails: IncomeDetails;
}

export interface ModalGymSelectObject extends ModalObject {
  selected: number;
  gyms: ModalGymSelect;
}

export interface ModalGymSelectByCityObject {
  maxNumberOfGymSelections: number;
  gymsByCitiesOfState: GymsByCitiesOfState[];
  selectedGyms?: GymToSelect[];
}

export interface ModalGymActivityObject extends ModalObject {
  groups: Tab[];
  activities: GymActivity[];
}

export interface ModalAgesObject extends ModalObject {
  ages: GymFilter[];
}

export interface ModalGymBenefitsObject extends ModalObject {
  benefits: GymBenefit[];
}

/** @todo: remover essa interface quando limpar os componentes antigos de academias */
export interface ModalUnitServicesObject extends ModalObject {
  benefits: FlatGymUnitService[];
}

export interface ModalBenefitsObject extends ModalObject {
  benefits: GymBenefitModel[];
}

export interface ModalGymPlanObject extends ModalObject {
  plan: GymPlan;
}

export interface ModalKidsDetailObject extends ModalObject {
  event: KidsActivityDetail;
}

export interface ModalKidsScheduleObject extends ModalObject {
  kidsEvent: KidsActivityDetail;
  selectedDate: {
    hour: string;
    date: string;
    event: KidsClassTime;
  };
  cachedData: any;
}

export interface ModalPriceRangeObject extends ModalObject {
  priceRange: PriceRange;
}

export interface ModalTabSelectObject extends ModalObject {
  tabs: Tab[];
}

export interface ModalTeacherSelectObject extends ModalObject {
  selected: string;
  teachers: OnlineClassTeacher[];
}

export interface ModalGymsApp {
  gymsName$: Observable<string[]>;
}

export enum ModalMethodsLabel {
  confirmCallback = 'confirmCallback',
  cancelCallback = 'cancelCallback',
  dismissCallback = 'dismissCallback',
}

export interface ModalSingleSelectObject extends ModalObject {
  selected: any;
  cities?: any[];
  states?: any[];
}
