import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { CorporateComponent } from './corporate.component';
import { BrandGuard } from '@guards/brand.guard';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';


const corporateRoute: Route[] = [
    {
        path: '',
        component: CorporateComponent,
        resolve: {
            imageHeader: ImageHeaderResolve,
        },
        canActivate: [BrandGuard],
        data: {
            windowTitle: '${data.imageHeader.title}',
            windowContext: 'corporate',
            brand: 'bt',
            slug: 'corporate',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(corporateRoute)],
    exports: [RouterModule],
})
export class CorporateRoutingModule { }
