import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileGeneral, SelectItem } from '@models';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-profile-data-general',
  templateUrl: './profile-data-general.component.html',
  styleUrls: [
    './profile-data-general.component.scss',
    '../../profile.shared.scss',
  ],
})
export class ProfileDataGeneralComponent implements OnInit, OnDestroy {

  @ViewChild('form', { static: false }) form: NgForm;

  @Input() general: ProfileGeneral;
  subsResolve: Subscription;

  professions: SelectItem[];
  civil: SelectItem[];

  constructor(
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subsResolve = this.route.data.subscribe(data => {
      this.professions = data.professions;
      this.civil = data.civilStates;
    });
  }

  ngOnDestroy() {
    this.subsResolve.unsubscribe();
  }

  isValid() {
    FormUtil.touchForm(this.form.control);
    return this.form.valid;
  }

}
