import { Component, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ModalType, Schedule, Gym } from '@models';
import { AppConstants } from '@utils/app-constants';
import { ActivatedRoute } from '@angular/router';
import { BasicHeaderConfig } from '@models/configs';
import { HeaderService, ModalService, LoadingService, AlertMessageService, CustomerService } from '@services';
import { MySchedulesService } from '@services/my-schedules.service';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-schedules-list',
  templateUrl: './schedules-list.component.html',
  styleUrls: ['./schedules-list.component.scss'],
})
export class SchedulesListComponent implements AfterViewInit {
  public config: BasicHeaderConfig;

  @ViewChild('header') headerComponent: TemplateRef<any>;

  gyms: Gym[];

  locale = AppConstants.LOCALE;

  schedules: Schedule[];

  hasSchedule: boolean;

  userRegistration = parseInt(localStorage.getItem(AppConstants.STOR_APP_USER_REGISTRATION), 10);

  imageHeader: string;
  headerTitle: string;

  constructor(
    private readonly mySchedulesService: MySchedulesService,
    private readonly headerService: HeaderService,
    private readonly alertMessageService: AlertMessageService,
    private readonly modalService: ModalService,
    private readonly loadingService: LoadingService,
    private readonly route: ActivatedRoute,
    private readonly customerService: CustomerService,
  ) {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
    this.config = this.route.snapshot.data.config.schedules;
    this.pageInitialization();
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  onCancelClick(obj: Schedule) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'CANCELAR AGENDAMENTO?',
      message: `Deseja realmente cancelar o agendamento do horário ${this.formatScheduleToString(obj)}?`,
      cancelButton: 'Não',
      confirmButton: 'Sim',
      data: obj,
      confirmCallback: (data: Schedule) => {
        this.loadingService.startLoading();
        this.mySchedulesService.cancelSchedule(data.id, data.userRegistration)
          .subscribe(
            _ => { this.showSuccessModal(data); },
            err => this.alertMessageService.showToastr(err),
            () => this.loadingService.stopLoading(),
          );
      },
    });
  }

  private formatScheduleToString(obj: Schedule) {
    const dateStr = new DatePipe(this.locale).transform(obj.scheduledDate, 'shortDate');
    return `entre ${obj.gymUnitAvaiabilityHour.formattedInitialTime} e ${obj.gymUnitAvaiabilityHour.formattedFinalTime} no dia ${dateStr}`;
  }

  private showSuccessModal(obj: Schedule) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'AGENDAMENTO CANCELADO!',
      message: `Seu agendamento do horário ${this.formatScheduleToString(obj)} foi cancelado.`,
      dismissCallback: () => this.mySchedulesService.getAllSchedulingByClient(this.userRegistration)
        .subscribe(schedules => {
          this.schedules = schedules;
        }),
    });
  }

  private pageInitialization() {
    this.loadingService.startLoading();
    forkJoin([
      this.customerService.listCustomerOpenGymUnit(),
      this.mySchedulesService.getAllSchedulingByClient(this.userRegistration)
    ]).subscribe(res => {
      if (res.length > 1) {
        this.gyms = res[0].gyms;
        this.schedules = res[1];
      }
      this.loadingService.stopLoading();
    }, () => {
      this.loadingService.startLoading();
    });
  }

}
