import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { isProduction, environment } from '@env/environment';
import { AlertMessage, FranchiseRequest, ResponseFutureFranchiseeCreate, MessageMap } from '@models';
import { FranchiseService } from '@services/franchise.service';
import { LoadingService } from '@services/loading.service';
import { RecaptchaComponent } from '@sharedcomponents/recaptcha/recaptcha.component';

import { FranchisePersonalComponent } from './franchise-personal/franchise-personal.component';
import { FranchisePropertyComponent } from './franchise-property/franchise-property.component';
import { Brand, BrandService } from '@services/brand.service';
import { AlertMessageService } from '@services';


@Component({
  selector: 'app-franchise-form',
  templateUrl: './franchise-form.component.html',
  styleUrls: [
    '../franchise.component.scss',
    './franchise-form.component.scss',
  ],
})
export class FranchiseFormComponent {

  readonly recaptchaSiteKey = environment.recaptchaKey;

  @ViewChild('personal', { static: false }) personal: FranchisePersonalComponent;
  @ViewChild('property', { static: false }) property: FranchisePropertyComponent;
  @ViewChild('recaptcha', { static: false }) recaptcha: RecaptchaComponent;

  @Input() info: FranchiseRequest;
  @Output() formSentEmitter = new EventEmitter<ResponseFutureFranchiseeCreate>();

  brand: Brand;
  constructor(
    public readonly brandService: BrandService,
    private readonly franchiseService: FranchiseService,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
  ) {
    this.brand = this.brandService.getBrand();
  }

  send() {

    const valid = [
      this.personal.isValid(),
      this.property.isValid(),
    ];

    if (valid.some(isValid => !isValid)) {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
      return;
    }

    // if (!this.recaptcha.isVerified()) {
    //   this.messagesArr.push(AlertMessage.error('É necessário marcar o campo \'Não sou um robô\' para enviar o formulário'));
    //   return;
    // }

    this.loadingService.startLoading();

    this.info.address.city.id = this.personal.formAddress.city;

    this.franchiseService.registerFranchisee(this.info).subscribe(
      response => {
        this.loadingService.stopLoading();
        if (response.errors.length) {
          for (const err of response.errors) {
            this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.ERRO_PROCESSAMENTO), [err.message]);
          }
        } else {
          this.formSentEmitter.emit(response);
        }
      },
    );
  }

  @HostListener('keydown', ['$event'])
  autoFill(event: KeyboardEvent) {
    if (!isProduction && event.key === '/' && event.ctrlKey) {
      Object.assign(this.info, {
        name: 'Nome do Futuro Franqueado',
        email: 'futurofraqueado@gmail.com',
        birthDate: '11/11/2000',
        gender: 'M',
        cellPhone: '(16) 98765-4321',
        landlinePhone: '(16) 3371-2030',
        identityDocument: '1234567890',
        cpf: '56330018340',
        maritalStatus: { id: 1 },
        address: {
          street: 'Comendador Alfredo Maffei',
          number: '1489',
          neighborhood: 'Jardim Nova São Carlos',
          adjunct: 'Complemento - Não é obrigatório',
          zipCode: '13561-270',
          city: { id: -1 },
          publicPlace: { id: 1 },
        },

        franchiseInformation: {
          investmentCapital: 1000,
          availableProperty: true,
          propertyAddress: {
            street: 'Comendador Alfredo Maffei',
            number: '1489',
            neighborhood: 'Jardim Nova São Carlos',
            adjunct: 'Complemento - Não é obrigatório',
            zipCode: '13561-270',
            city: { id: -1 },
            publicPlace: { id: 1 },
          },
        },
      });

      this.personal.isValid();
      this.property.isValid();
    }
  }
}
