<div class="d-flex flex-column">
  <div class="search-container position-relative mx-auto">
    <label for="modal-select-gym-search">
      <img src="assets/icons/ic-search.svg">
    </label>
    <input
      id="modal-select-gym-search"
      class="form-control text-center auto-height adjust-on-ie--h50"
      placeholder="Buscar unidade"
      type="text"
      [(ngModel)]="searchGym"
      >
  </div>
  <div
    id="gym-filter-clear"
    class="d-flex align-items-center title-md collapsed text-secondary my-2 cursor-pointer"
    (click)="clearFilter()"
  >
    <img alt="Ícone com um 'X'" class="clear-icon mr-2" src="assets/icons_custom/ic-recycle-bold.svg">
    <span class="font-weight-normal text-primary">
      Limpar Filtros
    </span>
  </div>

  <div>
    <div *ngFor="let state of gymsByState; let index = index" class="my-3">
      <div class="title modal-title mb-3 my-2 state-name">{{ selectedCity || state.stateName }}</div>
      <div *ngFor="let gym of (state.gyms | fuse:searchGym:{ keys: ['cityName', 'gymUnitName'] })" class="my-3">
        <app-checkbox
          class="modal-title mb-1 font-weight-normal"
          message="{{ selectedCity ? '' : gym.cityName + ' - ' }}{{gym.gymUnitName}}"
          [class.disabled]="selectedGyms && !gym.selected && selectedGyms.length === maxNumberOfGymSelections"
          (click)="toogleGymSelection(gym)"
          [(check)]="gym.selected"
        ></app-checkbox>
      </div>
    </div>
  </div>
</div>
