<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="eventTable.length; else empty">
    <app-agenda-header [left]="_left" (leftChange)="left = $event" [days]="daysColHeader" today="{{today}}"
      [weekstart]="weekstart"></app-agenda-header>
    <div (scroll)="left = scrollContainer.scrollLeft"
        #scrollContainer
        [scrollLeft]="_left"
        class="light-shadow table-container today-index-{{today}}">
      <div class="time-entry row true-columns"
          #timeEntry
          *ngFor="let row of eventTable; let lineIndex = index">
        <div class="col time-col title-md text-light p-absolute-md-down">
          <div class="time">
            {{ row.hour }}
          </div>
        </div>
        <div class="col time-col d-lg-none"></div>
        <div class="col day-of-week"
            *ngFor="let cell of row.dates; let cellIndex = index">

          <app-day-of-week [eventTable]="originalTimetable" [cell]="cell"></app-day-of-week>

        </div>
      </div>

    </div>
  </ng-container>

  <ng-template #empty>
    <div class="loading-container">
      <app-empty-card
        icon="/assets/icons_custom/ic-empty-list.svg"
        altIcon="Agenda não encontrada"
        message="Nenhuma aula foi encontrada com os filtros selecionados.<br>
        Faça uma pesquisa com filtros vazios para ver a agenda completa."
      ></app-empty-card>
    </div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <div class="title text-center title-md loading-container">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>
