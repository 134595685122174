<h3 class="title section">Informações sobre a franquia</h3>

<form #form="ngForm" class="row">
  <div class="col-12 col-md-6 col-left no-maring-top">
    <label for="franchise-property-state">Estado de interesse</label>
    <app-select id="franchise-property-state"
      name="state-prop"
      class="form-custom"
      [(ngModel)]="state"
      [list]="states"
      (ngModelChange)="selectState($event)"
      [disabled]="_loadingCities"
      ngDefaultControl
      required></app-select>
  </div>

  <div class="col-12 col-md-6 col-right no-maring-top">
    <label for="franchise-property-name">Cidade de interesse</label>
    <app-select id="franchise-property-city"
      name="city-prop"
      class="form-custom"
      [(ngModel)]="property.propertyAddress.city.id"
      [list]="cities"
      textForUndefined="Selecione a cidade"
      [textForDisabledUndefined]="_loadingCities ? 'carregando...' : 'Selecione primeiro o estado'"
      [disabled]="!state || !cities.length"
      ngDefaultControl
      required></app-select>
  </div>

  <div class="col-12 col-md-6 col-left">
    <label for="franchise-property-money">Capital para investimento</label>
    <input id="franchise-property-money" name="money" type="number" class="form-control capital"
      [(ngModel)]="property.investmentCapital" required min="0">
  </div>

  <div class="col-12 col-md-6 col-right">
    <label for="franchise-property-already-own">Possui imóvel disponível</label>
    <div id="franchise-property-already-own" name="own" class="group-control form-custom"
      [ngModel]="property.availableProperty" required ngDefaultControl>
      <div class="d-inline-block radio-item">
        <app-radiobutton name="property-own-true"
          [(check)]="property.availableProperty" [value]="true" message="Sim"></app-radiobutton>
      </div>
      <div class="d-inline-block radio-item">
        <app-radiobutton name="property-own-false"
          [(check)]="property.availableProperty" [value]="false" message="Não"></app-radiobutton>
      </div>
    </div>
  </div>
</form>

<div class="section address-container collapsable"
  [style.height]="property.availableProperty ? 'unset' : '0'">
    <app-data-address #formAddress name="prop"
      [address]="property.propertyAddress"
      [showTitle]="false"
      [showStateAndCity]="false">
    </app-data-address>
</div>
