import { Injectable } from '@angular/core';
import { FooterPartner } from '@models';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { AppGraphql } from '@utils/app-graphql';
import { map } from 'rxjs/operators';
import { JSONUtil } from '@utils/json-util';

@Injectable()
export class PartnersService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getPartnerList(): Observable<FooterPartner[]> {
    return this.apollo.query<any>({
      query: AppGraphql.queryListActivePartners,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<FooterPartner[]>('listActivePartners')),
    );
  }
}
