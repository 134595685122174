<div class="text-size text-center mb-4">
    <div *ngIf="showSchedulePerMonthLimit">
        Podem ser realizados até {{ totalOnlineSchedules.currentMonth }} agendamentos mensais.
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-center">
        <div
            *ngIf="numberOfSchedulesAlreadyMade.currentMonth < totalOnlineSchedules.currentMonth; else reachedCurrentMonthScheduleLimit">
            <strong>Agendamentos disponíveis para {{ month.currentMonth }}:</strong>
            <strong class="green"> {{ totalOnlineSchedules.currentMonth - numberOfSchedulesAlreadyMade.currentMonth }} de {{ totalOnlineSchedules.currentMonth }}</strong>
            <strong>.</strong>
        </div>
        <ng-container *ngIf="totalOnlineSchedules?.nextMonth !== null">
            <div class="d-none d-md-flex mr-3 ml-3">|</div>
            <div
                *ngIf="totalOnlineSchedules?.nextMonth && numberOfSchedulesAlreadyMade.nextMonth < totalOnlineSchedules.nextMonth; else reachedNextMonthScheduleLimit">
                <strong>Agendamentos disponíveis para {{ month.nextMonth }}:</strong>
                <strong class="green"> {{ totalOnlineSchedules.nextMonth - numberOfSchedulesAlreadyMade.nextMonth }} de {{ totalOnlineSchedules.nextMonth }}</strong>
                <strong>.</strong>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #reachedCurrentMonthScheduleLimit>
    <div class="text-size">
        <strong>Você atingiu o limite de agendamentos para {{ month.currentMonth }}.</strong>
    </div>
</ng-template>

<ng-template #reachedNextMonthScheduleLimit>
    <div class="text-size">
        <strong>Você atingiu o limite de agendamentos para {{ month.nextMonth }}.</strong>
    </div>
</ng-template>