import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EvoRedirectInterceptor, XAuthorizationInterceptor } from '@interceptors';

import { RegistrationModule } from 'app/registration/registration.module';
import { AuthInterceptor } from 'app/shared/auth.interceptor';
import { SharedModule } from 'app/shared/shared.module';

import { AreaUserComponent } from './area-user.component';
import { UserRoutingModule } from './area-user.routing';
import { ContractCancelComponent } from './contract-cancel/contract-cancel.component';
import { ContractComponent } from './finances/contract/contract.component';
import { FinancesComponent } from './finances/finances.component';
import { PresalePurchaseItemComponent } from './finances/purchases/presale-purchase-item/presale-purchase-item.component';
import { PurchaseItemComponent } from './finances/purchases/purchase-item/purchase-item.component';
import { PurchasesComponent } from './finances/purchases/purchases.component';
import { CreateAccountCpfComponent } from './login/create-account/create-account-cpf/create-account-cpf.component';
import { CreateAccountFormComponent } from './login/create-account/create-account-form/create-account-form.component';
import { CreateAccountComponent } from './login/create-account/create-account.component';
import { ChangeEmailComponent } from './login/forgot-email/change-email.component';
import { ResetAccessComponent } from './login/forgot-email/reset-access/reset-access.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { RecoveryEmailComponent } from './login/recovery-email/recovery-email.component';
import { CanNotLoginComponent } from './new-recovery-account/can-not-login/can-not-login.component';
import { CreateOrRecoveryAccountComponent } from './new-recovery-account/create-or-recovery-account/create-or-recovery-account.component';
import { NewRecoveryAccountComponent } from './new-recovery-account/new-recovery-account.component';
import { RememberEmailComponent } from './new-recovery-account/remember-email/remember-email.component';
import { MyWorkoutsComponent } from './online-class/my-workouts/my-workouts.component';
import { OnlineClassMainComponent } from './online-class/online-class-main/online-class-main.component';
import { OnlineClassSchedulesCreateComponent } from './online-class/online-class-schedules-create/online-class-schedules-create.component';
import { OnlineClassSchedulesListComponent } from './online-class/online-class-schedules-list/online-class-schedules-list.component';
import { OnlineClassScheduleItemComponent } from './online-class/online-class-schedules-list/schedule-item/schedule-item.component';
// tslint:disable-next-line:max-line-length
import {
  SchedulesPerMonthInfoComponent,
} from './online-class/online-class-schedules-list/schedules-per-month-info/schedules-per-month-info.component';
import { OnlineGroupClassesComponent } from './online-class/online-group-classes/online-group-classes.component';
import { ClassListItemComponent } from './online-class/templates/class-list-item/class-list-item.component';
import { DaysFilterComponent } from './online-class/templates/days-filter/days-filter.component';
import { NextClassesComponent } from './online-class/templates/next-classes/next-classes.component';
import { TimerComponent } from './online-class/templates/timer/timer.component';
import { ProfileAccountComponent } from './profile/profile-account/profile-account.component';
import { ProfileContactComponent } from './profile/profile-contact/profile-contact.component';
import { ProfileDataEmergencyComponent } from './profile/profile-contact/profile-data-emergency/profile-data-emergency.component';
import { ProfileDataGeneralComponent } from './profile/profile-contact/profile-data-general/profile-data-general.component';
import { ProfileDataPhonesComponent } from './profile/profile-contact/profile-data-phones/profile-data-phones.component';
import { ProfileDataMainComponent } from './profile/profile-data/profile-data-main/profile-data-main.component';
import { ProfileDataComponent } from './profile/profile-data/profile-data.component';
import { ProfilePaymentComponent } from './profile/profile-payment/profile-payment.component';
import { ProfileComponent } from './profile/profile.component';
import { SchedulesCreateComponent } from './schedules/schedules-create/schedules-create.component';
import { ScheduleItemComponent } from './schedules/schedules-list/schedule-item/schedule-item.component';
import { SchedulesListComponent } from './schedules/schedules-list/schedules-list.component';
import { ErgometerTableComponent } from './training-dashboard/ergometer-table/ergometer-table.component';
import { TrainingDashboardComponent } from './training-dashboard/training-dashboard.component';
import { TrainingTableComponent } from './training-dashboard/training-table/training-table.component';


registerLocaleData(localeBr, 'pt');
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    UserRoutingModule,

    RegistrationModule,
  ],
  declarations: [
    AreaUserComponent,
    LoginComponent,
    ChangeEmailComponent,
    ForgotPasswordComponent,
    RecoveryEmailComponent,
    ResetAccessComponent,
    NewPasswordComponent,
    CanNotLoginComponent,
    CreateAccountComponent,
    CreateAccountFormComponent,
    CreateAccountCpfComponent,
    RememberEmailComponent,
    CreateOrRecoveryAccountComponent,
    NewRecoveryAccountComponent,

    TrainingDashboardComponent,
    TrainingTableComponent,
    ErgometerTableComponent,

    FinancesComponent,
    ContractComponent,
    PurchasesComponent,
    ContractCancelComponent,

    ProfileComponent,
    ProfileContactComponent,
    ProfileDataComponent,
    ProfilePaymentComponent,
    ProfileAccountComponent,
    ProfileDataMainComponent,
    ProfileDataGeneralComponent,
    ProfileDataPhonesComponent,
    ProfileDataEmergencyComponent,
    PurchaseItemComponent,
    PresalePurchaseItemComponent,

    SchedulesListComponent,
    ScheduleItemComponent,
    SchedulesCreateComponent,
    SchedulesPerMonthInfoComponent,
    OnlineClassSchedulesListComponent,
    OnlineClassSchedulesCreateComponent,
    OnlineClassScheduleItemComponent,

    OnlineClassMainComponent,
    NextClassesComponent,
    ClassListItemComponent,
    OnlineGroupClassesComponent,
    DaysFilterComponent,
    MyWorkoutsComponent,
    TimerComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EvoRedirectInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XAuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AreaUserModule { }
