import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ClientData, GymRegistrationResponse, RegistrationRequest, TypesOfProducts } from '@models';
import { BrandService } from '@services/brand.service';
import { AppConstants } from '@utils/app-constants';
import { JSONUtil } from '@utils/json-util';
import { FormAdditionalDataComponent } from './form-additional-data/form-additional-data.component';


@Injectable()
export class MapRegistrationInfoService {

  constructor(
    private readonly brandService: BrandService,
  ) { }

  mapAdditionalDataToOldCanais(
    formData: RegistrationRequest,
    additionalForm: FormAdditionalDataComponent,
    data: GymRegistrationResponse
  ): any {
    const personal = formData.personal;
    const address = formData.additional.address;
    const phone = personal.phone.replace(/\D+/g, '');

    const clientData: ClientData = {
      Cliente: {
        Ativo: false,
        CPF: personal.cpf,
        CPFResponsavel: 0,
        EmailPrincipal: null,
        ConfirmacaoEmailPrincipal: null,
        DadosCadastro: {
          EstadoCivil: null,
          Nacionalidade: 1,
          Naturalidade: null,
          NomeMae: null,
          NomePai: null,
          Profissao: null,
          RG: 'NA',
          OrgaoExpeditor: 'NA',
          Sexo: personal.gender,
        },
        DadosContato: {
          Bairro: address.neighborhood,
          CEP: address.zipCode.replace(/-/g, ''),
          Complemento: address.adjunct,
          EmailPublicidade: personal.newsletter,
          Endereco: address.street,
          IdCidade: additionalForm.formAddress.city,
          Numero: address.number,
          SQTipoLogradouro: 1,
          ListaTelefones: [
            {
              CodigoDDD: phone.substring(0, 2),
              CodigoDDI: 55,
              IdTipoTelefone: 3,
              NomeTipoTelefone: null,
              NumeroTelefone: phone.substring(2, phone.length),
              TelefonePreferencial: true,
            },
          ],
        },
        Editavel: false,
        Estrangeiro: false,
        IdEstado: additionalForm.formAddress.state,
        IdPais: 1,
        IdTipoCliente: 0,
        Matricula: 0,
        IdUsuario: '00000000-0000-0000-0000-000000000000',
        Nome: personal.name,
        NomeReduzido: personal.name,
        NomeResponsavel: null,
        UnidadePrincipal: 0,
      },
      Senha: personal.password,
      SenhaConfirmacao: personal.passwordCheck,
    };

    return {
      personal: clientData,
      address: clientData,
      payment: data.gym.preSale
        ? this.mapPurchasePreSaleDataToOldCanais(formData)
        : this.mapPurchaseDataToOldCanais(formData),
    };
  }

  private mapPurchaseDataToOldCanais(formData: RegistrationRequest): any {
    const payment = formData.additional.payment;

    return {
      DadosCartaoCliente: {
        CodigoSeguranca: payment.cardCVV,
        DataValidade: `${payment.cardExpiringMonth}/${payment.cardExpiringYear}`,
        IdBandeira: payment.cardFlag,
        IdFormaPagamento: payment.parcels,
        NomeCliente: payment.cardOwnerName,
        NomeProprietarioCartao: payment.cardOwnerName,
        NumeroCartao: payment.cardNumber,
        NumeroCartaoMascarado: null,
      },
      JustClick: false,
    };
  }

  private mapPurchasePreSaleDataToOldCanais(formData: RegistrationRequest): any {
    const payment = formData.additional.payment;

    return {
      CodigoSeguranca: payment.cardCVV,
      DataValidade: `${payment.cardExpiringMonth}/${payment.cardExpiringYear}`,
      IdBandeira: payment.cardFlag,
      IdFormaPagamento: payment.parcels,
      NomeCliente: payment.cardOwnerName,
      NomeProprietarioCartao: payment.cardOwnerName,
      NumeroCartao: payment.cardNumber,
      NumeroCartaoMascarado: null,
    };
  }


  mapLoggedPurchaseToOldCanais(formData: RegistrationRequest): any {
    const payment = formData.additional.payment;

    return {
      DadosCartaoCliente: {
        DataValidade: `${payment.cardExpiringMonth}/${payment.cardExpiringYear}`,
        NomeCliente: payment.cardOwnerName,
        NumeroCartao: payment.cardNumber,
        NumeroCartaoMascarado: null,

        CodigoSeguranca: payment.cardCVV,
        IdBandeira: payment.cardFlag,
        IdFormaPagamento: payment.parcels,
      },
    };
  }


  mapLoggedPreSalePurchaseToOldCanais(formData: RegistrationRequest): any {
    const payment = formData.additional.payment;

    return {
      DataValidade: `${payment.cardExpiringMonth}/${payment.cardExpiringYear}`,
      NomeCliente: payment.cardOwnerName,
      NomeProprietarioCartao: payment.cardOwnerName,
      NumeroCartao: payment.cardNumber,
      NumeroCartaoMascarado: null,

      CodigoSeguranca: payment.cardCVV,
      IdBandeira: payment.cardFlag,
      IdFormaPagamento: payment.parcels,
    };
  }


  mapPersonalDataToOldCanais(formData: RegistrationRequest, data: GymRegistrationResponse, typeOfProduct: TypesOfProducts) {
    const personal = formData.personal;

    const personalData = {
      nome: personal.name,
      dataNascimento: personal.birthdate,
      unidade: data.gymId || data.gym.id,
      agrupamentoPlano: data.group,
      email: personal.email,
    };

    let itemCompraSelecionado = null;

    if (typeOfProduct === TypesOfProducts.Plan) {
      itemCompraSelecionado = {
        IdFrequencia: data.frequency,
        IdItemCompra: data.planId,
        IdPeriodicidade: data.periodicityId,
      };
    } else if (typeOfProduct === TypesOfProducts.Income) {
      itemCompraSelecionado = {
        IdItemCompra: data.additionalIncomeId,
        Quantidade: data.quantity || 1
      };
    }

    const planData = {
      TipoCesta: typeOfProduct,
      ItensCompraSelecionados: [
        itemCompraSelecionado
      ],
    };

    return {
      personal: personalData,
      plans: planData,
    };
  }


  mapFlagOptionsFromOldCanais(payment: any) {
    const currencyPipe: CurrencyPipe = new CurrencyPipe(AppConstants.LOCALE);

    return {
      id: JSONUtil.get(payment, 'IdBandeira'),
      text: JSONUtil.get(payment, 'NomeBandeira'),
      parcels: (JSONUtil.get(payment, 'ListaParcelas') || []).map((p: any) => ({
        id: JSONUtil.get(p, 'Id'),
        text:
          JSONUtil.getNumber(p, 'Quantidade') === 1
            ? `À vista - ${currencyPipe.transform(JSONUtil.getNumber(p, 'Valor'), 'BRL')}`
            : `${JSONUtil.getNumber(p, 'Quantidade')}x de ${currencyPipe.transform(
                JSONUtil.get(p, 'Valor'),
                'BRL',
              )}`,
        parcel: JSONUtil.getNumber(p, 'Quantidade'),
      })),
    };
  }


  sendDataLayer(payment: any, data: GymRegistrationResponse, isRenew: boolean) {
    if (payment.CodigoCompra) {
      const totalPayed = '' + (data.adhesion + data.value);
      window.dataLayer.push({
        event : isRenew ? 'renovacao' : 'compra',
        transactionId:  payment.CodigoCompra,
        transactionAffiliation: '',
        transactionTotal: totalPayed,
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: [{
          sku: data.planId,
          name: data.plan,
          category: data.payment,
          price: totalPayed,
          quantity: 1,
        }],
      });
    }
  }

  mapUserDataToUserCreateUserMicrosservice(
    formData: RegistrationRequest, data: GymRegistrationResponse,
    userData: any, userBirthDate: string) {
    return {
      email: formData.personal.email,
      cpfUser: formData.personal.cpf,
      registration: JSONUtil.get(userData, 'Matricula'),
      password: formData.personal.password,
      unity: data.gymId || data.gym.id,
      brand: this.brandService.getBrand().toUpperCase(),
      dateOfBirth: userBirthDate,
    };
  }
}

