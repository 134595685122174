<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper container">
  <div class="row card no-side-border-xs p-4 p-md-5 mb-4">
    <div class="section mt-0 text-center">
      <p class="mb-4">
        Antes de abrir um chamado, <a routerLink="/faq">clique aqui</a> para ver se sua dúvida não está no nosso
        FAQ (Perguntas e Respostas Frequentes).
      </p>
      <p class="mb-2">Fale com a gente pelo Whatsapp.</p>
      <button type="button" id="open-whatsapp-app" class="btn btn-primary btn-confirm" (click)="openWhatsapp()">
        <div class="d-flex justify-content-center align-items-center">
          <img src="assets/icons/ic-general-wp.svg" alt="whatsapp icon">
          <div class="ml-2">iniciar conversa</div>
        </div>
      </button>
      <p class="mt-4">Se preferir, envie um e-mail para:
        <a href="mailto:{{contactEmail}}">
          {{ contactEmail }}
        </a>
      </p>
    </div>
  </div>
</main>