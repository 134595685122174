import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMaxLines]',
})
export class MaxLinesDirective implements OnInit {

  @Input() appMaxLines: number;

  constructor(
    private readonly elem: ElementRef,
    private readonly renderer: Renderer2,
  ) { }

  ngOnInit() {
    if (this.appMaxLines) {
      this.renderer.setStyle(this.elem.nativeElement, 'display', '-webkit-box');
      this.renderer.setStyle(this.elem.nativeElement, '-webkit-box-orient', 'vertical');
      this.renderer.setStyle(this.elem.nativeElement, '-webkit-line-clamp', this.appMaxLines);
    } else {
      /* Single line */
      this.renderer.setStyle(this.elem.nativeElement, 'white-space', 'nowrap');
    }

    this.renderer.setStyle(this.elem.nativeElement, 'overflow', 'hidden');
    this.renderer.setStyle(this.elem.nativeElement, 'text-overflow', 'ellipsis');
  }

}
