import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TrainingsResponse, User, MessageMap } from '@models';
import { AuthService, HeaderService, UserTrainingService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { BrandService } from '@services/brand.service';

@Component({
  templateUrl: './training-dashboard.component.html',
  styleUrls: ['./training-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingDashboardComponent implements OnInit, AfterViewInit {
  public config: BasicHeaderConfig;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  locale = AppConstants.LOCALE;

  user: User;
  showError = false;
  activeTraining = -1;

  hasAccessToOnlineClass = false;
  trainings: any;

  loading = true;

  emptyMessage = 'Você ainda não possui nenhum treino.';

  isBT: boolean;

  constructor(
    private readonly trainingService: UserTrainingService,
    private readonly headerService: HeaderService,
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly ref: ChangeDetectorRef,
    private readonly brandService: BrandService,
    ) {
    this.config = this.route.snapshot.data.config.training;
  }

  ngOnInit() {
    this.getDashBoard();
    this.user = this.authService.getUser();
    this.isBT = this.brandService.getBrand() === 'bt';
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  callPrint() {
    (window as any).print();
  }

  scrollToElement(elem: HTMLElement, index = 0) {
      if (elem.classList.contains('collapsed')) {
        const parent = elem.offsetParent as HTMLElement;
        scroll({
          top: parent.offsetTop + elem.scrollHeight * index,
          behavior: 'smooth',
        });
      }
  }

  updatePage() {
    this.ref.markForCheck();
  }

  private getDashBoard() {
    this.trainingService.getDashboard().subscribe((res: TrainingsResponse[]) => {
      this.trainings = res;
      this.loading = false;
      this.emptyMessage = 'Você ainda não possui nenhum treino.';
      this.updatePage();
    }, (res) => {
      if (res.status === AppConstants.HTTP_FORBIDDEN) {
        this.emptyMessage =  MessageMap.INFO_NAO_DISPONIVEIS;
      }
      this.loading = false;
      this.updatePage();
    });
  }

}
