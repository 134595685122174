import gql from 'graphql-tag';


export class AppMicrosservicesGraphql {

  public static readonly queryfindUserByCpf = gql`
    query findUserByCpf($params: FindUserInput!) {
      findUser(params: $params) {
        code,
        email,
        message,
        name,
        success,
        unit,
      }
    }
  `;

  public static readonly queryfindUserByRegistration = gql`
    query findUserByRegistration($params: FindUserInput!) {
      findUser(params: $params) {
        email,
        success,
        unit,
      }
    }
  `;

  public static readonly mutationUpdateUserByRegistrationOrCpf = gql`
    mutation updateUserByRegistration($params: UpdateUserCredentialsInput!) {
      updateUserCredentials(params: $params) {
        code
        name
        message
        success
      }
  }`;

  // esse serviço vai ser chamado depois do usuario finalizar uma compra
  public static readonly mutationCreateUser = gql`
    mutation createUser($params: CreateUserInput!) {
      createUserAuthentication(user: $params){
        email,
        unity,
        brand,
        success,
        message,
        code,
      }
    }
  `;


  public static readonly mutationAuth = gql`
    mutation auth($params: AuthenticateUserInput!) {
      authenticateUser(params: $params) {
        code,
        name,
        message,
        success,
        brand,
        tokenUser,
        tokenLegacy,
        tokenUserLegacy
      }
    }
  `;

  public static readonly mutationRefreshLegacyToken = gql`
    mutation refreshLegacyToken($params: RefreshLegacyTokenInput!) {
      refreshLegacyToken(params: $params){
        code,
        name,
        message,
        success,
        brand,
        tokenLegacy
      }
    }
  `;
}

