<div *ngIf="data"
    (deferLoad)="loadImage=true"
    class="img-block d-flex justify-content-center align-items-center"
    [style.background-image]="'url(' + (loadImage ? data.image : '') + ')'">
  <div class="img-caption d-flex flex-column h-100 justify-content-around">
    <div class="container">
      <div class="title-footer text-uppercase">
        {{ data.title }}
      </div>
      <div class="buttons-container w-100">
        <a *ngFor="let link of data.links; let index = index"
            id="help-{{index}}"
            class="btn btn-primary"
            [routerLink]="link.url"
            routerLinkActive="d-none">
          {{ link.text }}
        </a>
      </div>
    </div>
  </div>
</div>
