<div class="text-center">
  <small class="pr-1"><i class="fas fa-share"></i></small>
  <div class="badge alert-success" popoverFade="Load all routes" appClickable
    (click)="navigateToIndex(0)"><i class="fas fa-tasks"></i></div>
  <div class="badge alert-danger" popoverFade="Load not found" appClickable
    (click)="navigateToNotFound()"><i class="fas fa-bug"></i></div>
  <div class="badge alert-danger" popoverFade="Request file error" appClickable
    (click)="getUnknownFile()"><i class="fas fa-question"></i></div>
  <div class="badge alert-success" popoverFade="Request app token" appClickable
    (click)="getToken()"><i class="fas fa-shield-alt"></i></div>
  <div class="badge alert-info" popoverFade="Request agenda" appClickable
    (click)="getAgenda()"><i class="fas fa-calendar-alt"></i></div>
  <div class="badge alert-info" popoverFade="Test apollo" appClickable
    (click)="getApollo()"><i class="fas fa-ticket-alt"></i></div>
</div>
