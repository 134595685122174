<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper container">

  <app-video-card 
    url="https://www.youtube.com/embed/jw3OYnz3dII"
    title="Uma nova realidade"
    [firstParagraph]="'Entenda como a ' + companyName + ' se preparou para a nova realidade neste vídeo.'"
    secondParagraph="Abaixo, confira informações específicas para as academias da sua cidade."
  >
  </app-video-card>

  <div class="filter-container border card no-side-border-xs px-4 p-md-0 mx-md-auto row-on-xs">
    <div class="row no-gutters filter tabs">
      <div id="select-city"
          appClickable
          (click)="showCitySelectModal()"
          appDropdownArrows
          class="col title-md font-weight-normal collapsed p-0">
        <img alt="Filtro por cidade" class="filter-icon" src="assets/icons/ic-city.svg">
        <span class="text-center px-2">{{ displayCityAndState(selectedCity) || 'Selecione a cidade' }}</span>
      </div>
    </div>
  </div>

  <div class="card no-side-border-xs mt-4 row-on-xs">
    <ng-container *ngIf="!!selectedCity; else noCity">
      <ng-container  *ngIf="(doubts | async); let json; else loading">
        <div id="accordion" class="tab-content" *ngIf="!!json.length; else empty">
          <div class="text-disclaimer text-center">
            <b>ATENÇÃO:</b> As informações a seguir valem apenas para as academias {{brand}} da cidade selecionada. Para acessar informações de outros locais, selecione outra cidade.
          </div>
          <div class="title-section">{{ displayCityAndState(selectedCity) }}</div>
          <div class="accordion">
            <ng-container *ngFor="let doubt of json; let doubtIndex = index;">
              <div id="heading{{ doubt.id }}"
                  appClickable
                  appDropdownArrows
                  (click)="collapse.toggle()"
                  class="question card-header"
                  [class.collapsed]="!collapse.expanded"
                  [attr.aria-expanded]="collapse.expanded"
                  attr.aria-controls="question{{ doubt.id }}">
                <div class="question-text">
                  <span class="font-weight-bold">{{ doubtIndex + 1 }}.</span> {{ doubt.question }}
                </div>
              </div>
              <div id="question{{ doubt.id }}" #collapse
                  app-collapse
                  class="answer border-top-0"
                  attr.aria-labelledby="heading{{ doubt.id }}">
                <div class="card-body" [innerHTML]="doubt.answer"></div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</main>
<ng-template #noCity>
  <app-empty-card
    icon="/assets/icons_custom/ic-empty-list.svg"
    altIcon="Selecione a cidade"
    message="Selecione uma cidade."
  ></app-empty-card>
</ng-template>
<ng-template #empty>
  <app-empty-card
    icon="/assets/icons_custom/ic-empty-list.svg"
    altIcon="Selecione a cidade"
    message="Não há informações publicadas para a cidade selecionada."
  ></app-empty-card>
</ng-template>
<ng-template #loading>
  <div class="title text-center pt-2 pb-2">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>
<app-modal-single-select></app-modal-single-select>
