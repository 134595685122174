<div class="modal fade" bsModal #templateModal="bs-modal"
    (onShow)="init()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog text-center" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header flex-column justify-content-center align-items-center">
        <div class="modal-header-title title">{{ title }}</div>
      </div>
      <div class="modal-body text-left">
        <div [innerHTML]="modalObj.message | safeHtml"></div>

        <div class="title modal-title section">Você também pode treinar em:</div>
        <div *ngIf="gymsAvailableOnPlan.length <= 1; else hasMoreGymAvailable">
          Plano exclusivo para esta academia.
        </div>
        <ng-template #hasMoreGymAvailable>
          <div class="row no-gutters">
            <img class="icon" src="assets/icons/ic-filter-place.svg">
            <div class="list border-left">
              <div *ngFor="let gymAvailableOnPlan of gymsAvailableOnPlan | orderBy : 'portalName' : true">
                {{ gymAvailableOnPlan.portalName | removeBrand }}
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="availableActivities">
          <div class="title modal-title section">Atividades disponíveis</div>
          <div>{{ availableActivities }}</div>
        </div>

      </div>
      <div class="modal-footer">
        <button id="modal-plan-confirm" type="button"
          class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || "OK" }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #combinated>
  <div class="row no-gutters">
      <img class="icon" src="assets/icons/ic-filter-place.svg">
      <div class="list border-left">
        <div *ngFor="let destination of (plan.gymRegion || gym.gymUnitDestinations) | orderBy : 'portalName' : true">
          {{ destination.portalName }}
        </div>
      </div>
  </div>
</ng-template>
