import { Component, Input } from '@angular/core';

import { DropdownBlock } from '@models';


@Component({
  selector: 'app-dropdown-block',
  templateUrl: './dropdown-block.component.html',
  styleUrls: ['../bt-company.component.scss'],
})
export class DropdownBlockComponent {

  @Input() block: DropdownBlock;

}
