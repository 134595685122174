import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SwiperModule } from 'ngx-swiper-wrapper';

import { AccordionModule } from '@directives';
import { IsOverflownModule } from '@pipes';
import { ActivityService } from '@services/activity.service';
import { EvoGymExperienceService } from '@services/evo';
import { LoadingContentModule } from '@shared/components/loading-content/loading-content.module';
import { TabsModule } from '@shared/components/tabs/tabs.module';

import { GymActivitiesGroupComponent } from './gym-activities-group/gym-activities-group.component';
import { GymExperienceDetailsComponent } from './gym-experience-details/gym-experience-details.component';
import { GymExperienceComponent } from './gym-experience.component';


@NgModule({
  declarations: [
    GymActivitiesGroupComponent,
    GymExperienceDetailsComponent,
    GymExperienceComponent,
  ],
  exports: [
    GymExperienceComponent,
  ],
  imports: [
    CommonModule,

    AccordionModule,
    IsOverflownModule,
    LoadingContentModule,
    SwiperModule,
    TabsModule,
  ],
  providers: [
    ActivityService,
    EvoGymExperienceService,
  ]
})
export class GymExperienceModule { }
