import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ResolveResult } from './type-resolve-bundle';
import { MyOnlineClassSchedulesService } from '@services/my-online-class-schedules.service';


@Injectable()
export class OnlineClassAccessResolve implements Resolve<any> {
  constructor(
    private readonly myOlineClassService: MyOnlineClassSchedulesService,
  ) {}

  private verificarAcesso() {
    return this.myOlineClassService.userHasAccessToOnlineClass();
  }

  resolve(): ResolveResult<any> {
    return this.verificarAcesso();
  }

  canActivate(_: ActivatedRouteSnapshot): ResolveResult<boolean> {
    return this.verificarAcesso();
  }

}
