<div class="modal fade" bsModal #templateModal="bs-modal" (onShow)="init()"
  (onHidden)="dismiss()"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
     <div class="modal-dialog text-center" *ngIf="modalObj">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header-title title w-100">{{ modalObj.title }}</div>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div id="modal-tab-{{index}}" class="tab border" appClickable
              *ngFor="let tab of tabs; let index = index" (click)="select(tab)">
              <span class="title modal-title font-weight-normal">
                {{ tab.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button id="modal-tab-cancel" type="button" class="col btn btn-primary"
            (click)="cancel()">
            {{ modalObj?.cancelButton || "Cancelar" }}
          </button>
        </div>
      </div>
    </div>
</div>
