<div class="text-center" *ngIf="data">
  <div class="title d-none d-md-block font-weight-light">{{ data.title }}</div>
  <div class="social-bar">
    <a id="social-{{index}}" class="title-md icon"
      *ngFor="let link of data.links; let index = index"
      (click)="clickSocial(link.text)"
      (auxclick)="clickSocial(link.text)"
      [href]="link.url"
      rel="noopener"
      target="_blank">
      <img src="{{ link.iconUrl | bucket }}" alt="{{ link.text }}">
    </a>
  </div>
</div>
