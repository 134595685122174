import { Component, Input } from '@angular/core';
import { FileDownloadBlock } from '@models';

@Component({
  selector: 'app-file-block',
  templateUrl: './file-block.component.html',
  styleUrls: ['./file-block.component.scss'],
})
export class FileBlockComponent {

  @Input() block: FileDownloadBlock = {title: '', type: 'file', content: { id: '', file: '' }};

}
