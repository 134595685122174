import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CorporateRoutingModule } from './corporate.routing.module';
import { SharedModule } from 'app/shared/shared.module';

import { CorporateComponent } from './corporate.component';
import { CorporateFormComponent } from './corporate-form/corporate-form.component';
import { CorporateHeaderComponent } from './corporate-header/corporate-header.component';
import { CorporateSuccessComponent } from './corporate-success/corporate-success.component';
import { CorporateGymFilterComponent } from './corporate-gym-filter/corporate-gym-filter.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,

    CorporateRoutingModule,
  ],
  declarations: [
    CorporateComponent,
    CorporateFormComponent,
    CorporateHeaderComponent,
    CorporateSuccessComponent,
    CorporateGymFilterComponent,
  ],
})
export class CorporateModule { }
