<div class="row text-justify" *ngFor="let section of sections">
  <div class="col-12 text-container" [ngClass]="section.subsections?.length ? 'col-lg-6' : 'text-paragraph'">
    <h2 class="title-lg">{{ section.title }}</h2>
    <p [innerHTML]="section.body"></p>
  </div>

  <div *ngIf="section.subsections?.length" class="col-12 col-lg-6 list-col text-container">
    <div class="row">
      <div class="col-12 d-flex text-container" *ngFor="let subsection of section.subsections">
        <div class="image-col">
          <img [src]="subsection.src" [alt]="subsection.alt">
        </div>
        <div class="text-col">
          <h3 class="title-md">{{ subsection.title }}</h3>
          <p>{{ subsection.body }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
