import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GymPlanResolveGuard } from '@guards/gym-plan-resolve.guard';
import { RegistrationComponent } from './registration.component';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';
import { AuthGuard } from '@guards/auth.guard';

const personalData = 'dados-pessoais';

const registrationRoute: Routes = [
    {
      path: '',
      children: [
        {
          path: '' ,
          children: [
            {
              path: '',
              redirectTo: personalData,
            },
            {
              path: personalData,
            },
            {
              path: 'renovacao',
              canActivate: [AuthGuard],
              canLoad: [AuthGuard],
            },
            {
              path: 'atualizacaodcc',
              canActivate: [AuthGuard],
              canLoad: [AuthGuard],
            },
            {
              path: 'dados-adicionais',
              redirectTo: personalData,
              pathMatch: 'full',
            },
            {
              path: 'compra-finalizada',
              redirectTo: personalData,
              pathMatch: 'full',
            },
          ],
        },
      ],
      component: RegistrationComponent,
      resolve: {
        registration: GymPlanResolveGuard,
        config: PortalConfigResolveGuard,
      },
      data: {
        windowTitle: '${data.config.registration.header.title}',
        windowContext: 'registration',
        config: { registration: 'registration' },
      },
    },
];

@NgModule({
    imports: [RouterModule.forChild(registrationRoute)],
    exports: [RouterModule],
})
export class RegistrationRoutingModule { }
