import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';

import { BtPassPlanInfo } from '@models/bt-pass.model';
import { GymSearchConfig } from '@models/configs';
import { BreakpointsService, screenSizeMap } from '@services/breakpoints.service';


@Component({
  selector: 'app-bt-pass-plans-list',
  templateUrl: './bt-pass-plans-list.component.html',
  styleUrls: ['./bt-pass-plans-list.component.scss']
})
export class BtPassPlansListComponent implements OnInit, OnDestroy {
  @Input() btpassPlanList?: BtPassPlanInfo[];
  @Input() btPassPlanLoading?: boolean;

  private subs: Subscription;

  swiperConfig: SwiperConfigInterface = {
    allowSlideNext: true,
    allowSlidePrev: true,
    loop: false,
    navigation: true,
    spaceBetween: 16,
    speed: 500,
    threshold: 10,
  };

  loadingLabel: string;


  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly breakpointsService: BreakpointsService,
  ) { }

  ngOnInit(): void {
    this.loadingLabel = (this.activatedRoute.snapshot.data.config.gymSearch as GymSearchConfig).results.loading;

    this.listenToBreakpointsService();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  private listenToBreakpointsService(): void {
    const maxSlidesPerView = 4;

    this.subs = this.breakpointsService.emitter.subscribe(size => {
      const slidesPerView = screenSizeMap(size, [1, 1, 2, 3, 4]);

      Object.assign(this.swiperConfig, { slidesPerView, slidesPerGroup: 1 });

      if (this.btpassPlanList?.length > maxSlidesPerView || slidesPerView < maxSlidesPerView) {
        this.swiperConfig.pagination = this.getSwiperPagination();
        this.swiperConfig.navigation = true;
      } else {
        this.swiperConfig.pagination = false;
        this.swiperConfig.navigation = false;
      }
    });
  }

  private getSwiperPagination(): SwiperPaginationInterface {
    return {
      el: '.swiper-pagination',
      clickable: true,
      hideOnClick: false,
      bulletActiveClass: 'swiper-pagination-bullet-dark',
    };
  }
}
