<div class="container formula-only d-flex">
    <div class="row">
      <div class="card no-side-border-xs w-100">
        <div class="limit">
          <div class="title">{{ json.title }}</div>
          <div class="section text-justify">{{ json.text }}</div>
          <app-concept-fr-card [card]="json.cards"></app-concept-fr-card>
          <div class="mb-5">
            <div class="title">ENCONTRE A SUA FÓRMULA E MATRICULE-SE</div>
            <div class="section text-justify">
              Nas nossas academias, atividade física, com orientação profissional séria e segura, aulas coletivas e equipamentos de ponta, é possível por um valor justo. Invista em você e encontre na Fórmula o ambiente perfeito para ver resultados em pouco tempo.
            </div>
            <div class="text-center">
              <a id="find-academy" class="btn btn-primary" routerLink="/academias">
                ENCONTRE SUA ACADEMIA
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>