import { Pipe, PipeTransform } from '@angular/core';
import { CouponResponse } from '@models';

@Pipe({
  name: 'calculateOtherCharges',
})
export class CalculateOtherChargesPipe implements PipeTransform {

  transform(value: any, parcels: number, coupon: CouponResponse): number {

    // cupons de receita nao devem aplicar novamente o desconto no calculo das demais parcelas
    if (!coupon || coupon.monthDiscountCupom || coupon.type === 1) {
      return value / parcels;
    }

    return (value - coupon.discount) / parcels;
  }

}
