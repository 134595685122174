import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClickableModule } from '@directives/clickable/clickable.module';
import { DropdownArrowsModule } from '@directives/dropdown-arrows/dropdown-arrows.module';
import { EvoGeneralService } from '@services';
import { LoadingContentModule } from '@sharedcomponents/loading-content/loading-content.module';

import { PopoverFadeModule } from 'app/component-modules/popover-fade/popover-fade.module';

import { ModalGymActivityModule, ModalGymAgeModule, ModalGymSelectModule } from '../modal';
import { AgendaFilterComponent } from './agenda-filter.component';


@NgModule({
  declarations: [ AgendaFilterComponent ],
  imports: [
    CommonModule,

    ClickableModule,
    DropdownArrowsModule,
    LoadingContentModule,
    PopoverFadeModule,

    ModalGymActivityModule,
    ModalGymAgeModule,
    ModalGymSelectModule,
  ],
  exports: [ AgendaFilterComponent ],
  providers: [ EvoGeneralService ],
})
export class AgendaFilterModule { }
