import gql from 'graphql-tag';
import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Params } from '@angular/router';

function interpolateParams(str: string, params: Params): string {
  try {
    // tslint:disable-next-line:no-eval
    return templateString ? templateString(str, { params }) : eval(`\`${str}\``);
  } catch {
    console.warn('Interpolate error on PortalConfigService.\nEval failed for: ' + str);
    return str;
  }
}

@Injectable()
export class PortalConfigService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getPortalConfiguration<T>(tag: string): Observable<T> {
    return this.apollo.query<any>({
      query: AppGraphql.queryGetPortalConfiguration,
      variables: { tag },
    }).pipe(
      map(JSONUtil.turnApolloMutable<{configValue: string}>('getPortalConfiguration')),
      map(json => {
          try {
            return JSON.parse(json['configValue']) as T;
          } catch {
            return {} as T;
          }
        },
      ),
    );
  }

  getPortalConfigurations<T = {}>(config: {[key in keyof T]: string}, params: Params = {}): Observable<T> {
    const body = Object.entries<string>(config).map(([key, value]) => {
      return `${key}: getPortalConfiguration(tag: "${interpolateParams(value, params)}") { configValue }`;
    }).join('\n');

    return this.apollo.query<any>({
      query: gql`query { ${body} }`,
    }).pipe(
      map(json => {
        const result = {};
        Object.keys(config).forEach(k => {
          try {
            result[k] = JSON.parse(json.data[k].configValue);
          } catch {
            result[k] = {};
          }
        });
        return result as T;
      }),
    );
  }
}
