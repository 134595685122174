import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { EvoGymMigratingRequest, EvoGymMigrationResponse, EvoMigratedUnitsListResponse } from '@models';
import { HttpCacheService } from '@services/http-cache.service';
import { back, BackEndpoints, external } from '@utils/app-endpoints';
import { EvoBaseUrls } from '@utils/evo';


@Injectable()
export class EvoGeneralService {


  constructor(
    private readonly http: HttpClient,
    private readonly httpCacheService: HttpCacheService,
  ) { }

  openEvoSchedule(gymCode: number): void {
    const interpolatedUrl = external(environment.evo.scheduleBaseUrl + EvoBaseUrls.schedule, gymCode);

    window.open(interpolatedUrl);
  }

  checkMigratingGym(unit: number): Observable<EvoGymMigrationResponse> {
    const evoGymRequest: EvoGymMigratingRequest = { unit };

    return this.http.post<EvoGymMigrationResponse>(back(BackEndpoints.EvoCheckMigratingGym), evoGymRequest);
  }

  getMigratedUnitsList(): Observable<string[]> {
    return this.httpCacheService.get<EvoMigratedUnitsListResponse>(back(BackEndpoints.EvoMigratedUnitsList)).pipe(
      map(response => response.evoMigratedUnits),
    );
  }
}
