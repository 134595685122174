<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<section class="container">
  <app-video-card
    url="https://www.youtube.com/embed/dv1xu3ME8cc"
    title="UM MUNDO DE POSSIBILIDADES"
    firstParagraph="Na Bodytech, as famílias encontram a estrutura, os profissionais e o ambiente perfeitos
    para bebês e crianças crescerem e se desenvolverem com acolhimento e saúde."
  ></app-video-card>

  <app-activities-list></app-activities-list>

  <div id="gym-detail-plans" class="container mt-4">
    <h1 class="text-center title">CONHEÇA NOSSOS PLANOS</h1>
    <div class="minor-text ml-auto mr-auto mt-4 mb-4 text-center">
      Os responsáveis devem contratar o plano presencialmente na recepção da academia escolhida.
    </div>
  </div>

  <app-memberships
    *ngIf="membershipsRO && !membershipsRO.loading else loadingTemplate"
    emptyMessage="Não existe planos Kids nesta unidade. Selecione outra unidade para visualizar os planos."
    [memberships]="membershipsRO?.response"
    [hasSubscribeButton]="false"
  ></app-memberships>

  <div class="container mt-4" id="timeline-title">
    <h1 class="text-center title">ENCONTRE A ATIVIDADE IDEAL PARA O SEU PEQUENO</h1>
    <div class="minor-text ml-auto mr-auto mt-4 mb-4 text-center">
      Selecione a unidade para visualizar a agenda. Algumas unidades permitem agendar uma aula experimental online.
      Se não for possível, contate a unidade para agendar a aula.
    </div>
  </div>

  <app-agenda-filter
    analyticsCategory="Agenda"
    analyticsTag="Filtros"
    [hideActivities]="selectedGymIsMigrated"
    [filter]="filter"
    [gym]="filter.gym"
    [isKids]="true"
    [showPlace]="true"
    (filterChanges)="onFilterChange($event)"
  ></app-agenda-filter>

  <div id="kids-timeline" class="timeline-container" [hidden]="hideAgenda">
    <app-timeline #timeline [filter]="filter"></app-timeline>
  </div>
</section>

<ng-template #showBtn>
  <div class="text-center">
    <button id="agenda-search" (click)="showGymSelectModal()" class="btn btn-primary btn-confirm">
      Selecione uma academia
    </button>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <app-loading-content classes="text-center"></app-loading-content>
</ng-template>

<app-modal-kids-details></app-modal-kids-details>
<app-modal-kids-schedule></app-modal-kids-schedule>
