<div
  bsModal
  #templateModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
  (onShow)="init()"
  (onHidden)="hidden()"
>
  <div class="modal-dialog modal-lg text-center" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title w-100">{{ modalObj.title }}</div>
      </div>

      <div class="modal-body">
        <div class="row no-gutters justify-content-center">
          <div class="benefit border position-relative" *ngFor="let benefit of benefits">
            <div
              class="free-badge title badge-primary text-white position-absolute"
              *ngIf="benefit.free"
            >
              Grátis
            </div>
            <div class="w-100">
              <img [src]="benefit.icon">
            </div>
            <span class="title name font-weight-normal">{{ benefit.name }}</span>
            <div class="text">{{ benefit.description || '' }}</div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="col btn btn-primary"
          (click)="confirm()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
