<div class="modal fade" bsModal #templateModal="bs-modal"
    (onHidden)="dismiss()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center w-100 pull-left">
          <div class="m-0 title-section no-bottom-line">
            {{ modalObj.title }}
          </div>
          <div class="m-0">{{ modalObj.gymUnitName | capitalize }}</div>
        </div>
        <button type="button" class="d-none close pull-right" aria-label="Close" (click)="templateModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          {{ modalObj.incomeDetails.planLongDescription }}
        </div>
        <div class="date-container row no-gutters text-center border">
          <div class="col border-right">
            <div class="title">Diárias</div>
            <div>{{ modalObj.incomeDetails.numberOfPasses }} {{ modalObj.incomeDetails.numberOfPasses === 1 ? 'diária' : 'diárias' }}</div>
          </div>
          <div class="col">
            <div class="title">Prazo para uso</div>
            <div>{{ modalObj.incomeDetails.durationInDays }} {{ modalObj.incomeDetails.durationInDays === 1 ? 'dia' : 'dias' }}</div>
          </div>
        </div>
        <div class="text-center">
          <div class="title font-weight-normal pay-name">BT PASS • {{ modalObj.incomeDetails.numberOfPasses * selectedQuantity }} DIÁRIAS</div>
          <div class="title pay-value">{{ modalObj.incomeDetails.basePrice *  selectedQuantity | currency:'BRL':'symbol':'':locale }}</div>
        </div>
      </div>
      <app-product-quantity-choicer (changeQuantityOfProducts)="changeQuantityOfProducts($event)"></app-product-quantity-choicer>
      <div class="modal-footer">
        <button type="button" class="col btn btn-outline-primary" (click)="cancel()">VOLTAR</button>
        <button type="button" class="col btn btn-primary" (click)="confirm()">COMPRAR</button>
      </div>
    </div>
  </div>
</div>
