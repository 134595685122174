<div class="card no-side-border-xs">
  <app-tabs
    *ngIf="isBT"
    [tabs]="tabs"
    [(activeTab)]="activeTab"
  ></app-tabs>

  <div class="wrapper position-relative">
    <div [ngClass]="{ 'hidden position-absolute w-100': activeTab != tabs[gymExperienceTabs.Activities] }">
      <ng-container *ngIf="!tabContent[gymExperienceTabs.Activities].loading; else loadingTemplate">
        <swiper
          *ngIf="tabContent[gymExperienceTabs.Activities].response?.length; else emptyTemplate"
          class="position-relative"
          [config]="swiperConfig"
          #swiper
        >
          <app-gym-activities-group
            *ngFor="let activitiesGroup of tabContent[gymExperienceTabs.Activities].response"
            class="d-flex flex-column px-0 px-md-3 h-auto"
            [activities]="activitiesGroup.activities"
            [groupName]="activitiesGroup.groupName"
            (showDescriptionModal)="showDescriptionModal($event)"
          ></app-gym-activities-group>
        </swiper>
      </ng-container>
    </div>

    <div [ngClass]="{ 'hidden position-absolute w-100': activeTab != tabs[gymExperienceTabs.Services] }">
      <swiper
        *ngIf="tabContent[gymExperienceTabs.Services]?.length; else emptyTemplate"
        class="position-relative"
        [config]="swiperConfig"
        #swiper
      >
        <app-gym-experience-details
          *ngFor="let service of tabContent[gymExperienceTabs.Services]"
          class="d-flex flex-column px-0 px-md-3 h-auto"
          [experienceContent]="service"
          [visible]="activeTab === tabs[gymExperienceTabs.Services]"
          (showDescriptionModal)="showDescriptionModal($event)"
        ></app-gym-experience-details>
      </swiper>
    </div>

    <div [ngClass]="{ 'hidden position-absolute w-100': activeTab != tabs[gymExperienceTabs.Conveniences] }">
      <swiper
        *ngIf="tabContent[gymExperienceTabs.Conveniences]?.length; else emptyTemplate"
        class="position-relative"
        [config]="swiperConfig"
        #swiper
      >
        <app-gym-experience-details
          *ngFor="let convenience of tabContent[gymExperienceTabs.Conveniences]"
          class="d-flex flex-column px-0 px-md-3 h-auto"
          [experienceContent]="convenience"
          [visible]="activeTab === tabs[gymExperienceTabs.Conveniences]"
          (showDescriptionModal)="showDescriptionModal($event)"
        ></app-gym-experience-details>
      </swiper>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading-content [loadingLabel]="loadingLabel" classes="text-center"></app-loading-content>
</ng-template>

<ng-template #emptyTemplate>
  <div class="empty align-items-center d-flex justify-content-center text-muted title">
    Nenhum item encontrado
  </div>
</ng-template>
