<div
  class="modal fade"
  bsModal
  #templateModal="bs-modal"
  (onShown)="init()"
  (onHide)="close()"
  (onHidden)="clear()"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg text-center d-flex">
    <div class="modal-content" *ngIf="modalObj" [class.starting]="!ready">
      <button class="close-button border-0" (click)="modal.hide()">
        <img alt="Ícone para fechar o modal de imagens" src="assets/icons/ic-close.svg">
      </button>

      <gallery
        id="mixed"
        imageSize="contain"
        [items]="galleryimages"
        [counter]="false"
        [panSensitivity]="1000"
        thumbMode="free"
        (indexChange)="currentImage = $event.currIndex"
        [itemTemplate]="itemTemplate"
      ></gallery>
    </div>
  </div>
</div>

<ng-template
  #itemTemplate
  let-index="index"
  let-type="type"
  let-data="data"
  let-currIndex="currIndex"
>
  <span [@slideAnimation] class="item-text">
    {{ data.title }}
  </span>
</ng-template>
