import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SafeHtmlModule } from '@pipes/safe-html/safe-html.module';

import { ErrorComponent } from './error.component';


@NgModule({
  declarations: [ErrorComponent],
  imports: [
    CommonModule,

    SafeHtmlModule,
  ],
  exports: [ErrorComponent],
})
export class ErrorModule { }
