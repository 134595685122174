<div class="d-flex flex-column align-items-center position-relative">
  <span *ngIf="experienceContent.isFree" class="free-badge badge-primary title text-white">Grátis</span>

  <img class="experience-icon" [src]="experienceContent.icon">

  <h5 class="title my-3">{{ experienceContent.name }}</h5>

  <p class="experience-description mb-2" #descriptionTemplate>
    {{ experienceContent.description }}
  </p>

  <button
    *ngIf="descriptionTemplate | isOverflown: visible"
    class="btn no-btn link title text-primary align-self-end"
    (click)="showDescriptionModal.emit(experienceContent)"
  >
    Ler mais
  </button>
</div>