export interface UserAvailabilityToSchedule {
    canSchedule: boolean;
    maxCapacity: number;
    currentMonth: ScheduleMonthInfo;
    nextMonth: ScheduleMonthInfo;
}

export interface ScheduleMonthInfo {
    monthText: string;
    numberOfAvailableClasses: number;
}

export interface WeekDaysWithGroupClassesByClient {
    id: number;
    code: number;
    name: string;
    groupClassClientCustomResponse: GroupClassClientCustom[];
}

export interface GroupClassClientCustom {
    id: number;
    activity: string;
    formattedHour: string;
}

export interface DayFilter {
    name: string;
    shortName: string;
    code: DaysOfTheWeek;
    selected: boolean;
}

export enum ButtonType {
    play,
    favorite
}

export interface BTHomeClassInfo {
    className: string;
    teacherName: string;
}

export enum DaysOfTheWeek {
    Domingo = 1,
    Segunda,
    Terca,
    Quarta,
    Quinta,
    Sexta,
    Sabado
}
