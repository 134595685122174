import { Component, OnInit } from '@angular/core';

import { HeaderService } from '@services/header.service';
import { ActivatedRoute } from '@angular/router';
import { MyOnlineClassSchedulesService } from '@services/my-online-class-schedules.service';
import { AppConstants } from '@utils/app-constants';
import { AlertMessageService, CustomerService, LoadingService, ModalService } from '@services';
import { AlertMessage, MessageMap, OnlineClassSchedule } from '@models';


@Component({
  selector: 'app-my-workouts',
  templateUrl: './my-workouts.component.html',
  styleUrls: [
    '../../area-user.component.scss',
    './my-workouts.component.scss',
    '../online-class-main/online-class-main.component.scss'
  ],
})
export class MyWorkoutsComponent implements OnInit {

  locale = AppConstants.LOCALE;

  userRegistration: number;

  onlineGroupClasses: any[];
  schedules: OnlineClassSchedule[];
  gyms: any[];

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly headerService: HeaderService,
    protected readonly myOnlineClassSchedulesService: MyOnlineClassSchedulesService,
    protected readonly customerService: CustomerService,
    protected readonly myOnlineScheduleService: MyOnlineClassSchedulesService,
    protected readonly loadingService: LoadingService,
    protected readonly modalService: ModalService,
    protected readonly alertMessageService: AlertMessageService,
  ) {

  }

  ngOnInit() {
    this.userRegistration = +localStorage.getItem(
      AppConstants.STOR_APP_USER_REGISTRATION,
    );
    this.pageInitialization();
  }

  deleteFavoriteGroupClass(classInfo: any) {
    this.myOnlineClassSchedulesService.deleteFavoriteGroupClass(classInfo.id).subscribe((res: any) => {
      if (res) {
        this.onlineGroupClasses = this.onlineGroupClasses.filter(groupClass => groupClass.id !== classInfo.id);
      } else {
        this.errorWhenSetOrDeleteFavorite();
      }
    });
  }

  openClass(classInfo: any) {
    window.open(classInfo.liveClassUrl);
  }

  private errorWhenSetOrDeleteFavorite() {
    this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SERVIDOR_INDISPONIVEL));
  }

  private pageInitialization() {
    this.loadingService.startLoading();
    this.myOnlineClassSchedulesService.listAllFavoriteGroupClassByClient(this.userRegistration)
    .subscribe((res: any) => {
      this.loadingService.stopLoading();
      if (res.length > 0) {
        this.onlineGroupClasses = res.map((a: any) => {
          return {...a.groupClass, id: a.id};
        });
      }
    }, () => {
      this.loadingService.stopLoading();
    });
  }
}
