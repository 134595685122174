export enum MembershipStatus {
  Available = 1,
  Unavailable,
}

export enum MembershipOfferFeeType {
  'MATRÍCULA' = 'Taxa de Matrícula',
  'TAXA DE ADESÃO' = 'Taxa de Adesão',
  'TAXA DE CADASTRO' = 'Taxa de Cadastro',
}

export enum GymExperienceTabs {
  Activities,
  Services,
  Conveniences,
}

export enum GymSelectByCityTabs {
  Cities,
  Gyms
}
