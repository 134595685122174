<div class="outside-container swiper-arrow-container position-relative" (deferLoad)="showingObs.next(true)">
  <div id="blog-prev" class="invert blog-swiper-prev swiper-button-prev swiper-arrow d-none d-md-block"></div>
  <div id="blog-next" class="invert blog-swiper-next swiper-button-next swiper-arrow d-none d-md-block"></div>

  <div class="container">
    <div *ngIf="_listPost.length; else emptySwiper"
        class="swiper-container"
        [swiper]="selectedConfig"
        (indexChange)="indexObs.next($event)">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let post of _listPost">
          <app-post-carousel-item [post]="post"></app-post-carousel-item>
        </div>
      </div>
    </div>
    <ng-template #emptySwiper>
      <div
        class="swiper-container"
        [swiper]="selectedConfig">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let post of listPostFallback">
          <app-post-carousel-item [post]="post"></app-post-carousel-item>
        </div>
      </div>
    </div>
    </ng-template>
  </div>
</div>
<div class="blog-swiper-pagination swiper-pagination"></div>
