<table class="table table-flush table-striped mb-0">
  <tbody>
    <tr class="border-top border-bottom" *ngFor="let item of schedules">
      <td class="d-flex d-md-table-cell align-middle pb-md-2 pb-0 mb-6 border-0">{{ item.scheduledDate | date:'shortDate':'':locale }}</td>
      <td class="d-flex d-md-table-cell align-middle pb-md-2 pb-0 mb-6 pt-md-2 pt-0 border-0">
        {{ item.gymUnitAvaiabilityHour.formattedInitialTime }} - {{ item.gymUnitAvaiabilityHour.formattedFinalTime }}
      </td>
      <td class="d-flex d-md-table-cell align-middle w-md-25 pb-md-2 pb-0 mb-6 pt-md-2 pt-0 border-0">{{ item.gymUnit.name }}</td>
      <td class="d-flex d-md-table-cell align-middle pb-md-2 pb-0 mb-6 border-0 pt-0">
        Ida com Personal:&nbsp; <span class="font-weight-bold">{{ item.hasPersonal ? 'Sim' : 'Não' }}</span>
      </td>
      <td class="d-flex d-md-table-cell border-0"><a class="cancel-action" [hidden]="!item.isCancelable" href="javascript:void(0)" (click)="onCancelClick(item)">CANCELAR</a></td>
    </tr>
  </tbody>
</table>
