import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalModule } from 'ngx-bootstrap/modal';

import { CheckboxModule } from '@shared/components/checkbox/checkbox.module';

import { ModalGymAgeComponent } from './modal-gym-age.component';


@NgModule({
  declarations: [ ModalGymAgeComponent ],
  imports: [
    CommonModule,

    ModalModule,

    CheckboxModule,
  ],
  exports: [ ModalGymAgeComponent ],
})
export class ModalGymAgeModule { }
