<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container justify-content-around d-md-flex">

  <form #form="ngForm" class="form-validate card small-card text-center row-on-xs no-side-border-xs" (submit)="login()">

    <!-- <div class="button-container">
      <app-social-login-buttons (loginClicked)="login()"></app-social-login-buttons>
    </div> -->

    <div class="form-container text-left">
      <div class="title-section text-black">Login</div>

      <label for="login-email" class="text-left text-uppercase first-label">E-mail</label>
      <input id="login-email"
        #email="ngModel"
        name="email"
        [(ngModel)]="user.email"
        type="text"
        class="form-control"
        autocomplete="username email"
        email
        required>
      <app-error-message [control]="email"></app-error-message>

      <label for="login-password" class="text-left text-uppercase">Senha</label>
      <input id="login-password"
        #password="ngModel"
        name="password"
        [(ngModel)]="user.password"
        type="password"
        class="form-control"
        autocomplete="current-password"
        required>
      <app-error-message [control]="password"></app-error-message>
    </div>

    <div class="button-container">
      <button id="login-confirm" class="btn btn-primary btn-confirm submit-btn">
        Entrar
      </button>
    </div>
    <a id="forgot-email-password" routerLink="/cliente/nao-consigo-logar" class="title link">Não consegue logar?</a>
  </form>

</div>
