<ul class="tabs-container border-0">
  <li class="tab-item text-center" [style.width.%]="100/tabs.length" *ngFor="let tab of tabs; let index = index">
    <a
      id="tab-{{name}}-{{index}}"
      class="tab d-block text-dark h-100 cursor-pointer"
      *ngIf="tab.url; else internal"
      [routerLink]="[tab.url]"
      routerLinkActive="active"
    >
      <div class="title-section no-bottom-line">
        {{ tab[textKey] }}
      </div>
    </a>
    <ng-template #internal>
      <a
        id="tab-{{name}}-{{index}}"
        class="tab d-block text-dark h-100 cursor-pointer"
        [class.active]="activeTab == tab"
        [class.has-icon]="hasIcon"
        (click)="activeTabChange.emit(tab)"
      >
        <img class="icon" *ngIf="hasIcon" [src]="tab.icon || 'assets/icons/ic-activities-class.svg'">
        <div class="title-section no-bottom-line">
          {{ tab[textKey] }}
        </div>
      </a>
    </ng-template>
  </li>
</ul>
