<table class="table table-striped">
  <thead>
    <tr class="title">
      <th>Ordem</th>
      <th>Máquina</th>
      <th>Exercício</th>
      <th>Série</th>
      <th>Repetição</th>
      <th>Carga</th>
      <th>Regulagem</th>
      <th>Intervalo</th>
      <th>Método</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of jsonData">
      <td>{{ row.order }}</td>
      <td>{{ row.machine }}</td>
      <td>{{ row.exercise }}</td>
      <td>{{ row.series }}</td>
      <td>{{ row.repetition }}</td>
      <td>{{ row.load }}</td>
      <td>{{ row.regulate }}</td>
      <td>{{ row.interval }}</td>
      <td>{{ row.method }}</td>
    </tr>
  </tbody>
</table>
