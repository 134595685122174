<div class="modal fade" bsModal #templateModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}"
    (onShow)="init()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog text-center" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title w-100">{{ modalObj.title }}</div>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center">
          <div id="modal-gym-{{index}}" class="gym border"
            [class.selected]="item == selected" appClickable
            *ngFor="let item of (data | fuse:search); let index = index" (click)="select(item)">
            <span class="title modal-title font-weight-normal">{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="modal-gym-cancel" type="button" class="col btn btn-primary"
          (click)="cancel()">
          {{ modalObj?.cancelButton || "Cancelar" }}
        </button>
      </div>
    </div>
  </div>
</div>
