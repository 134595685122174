import { Component, OnInit } from '@angular/core';
import { BrandService } from '@services/brand.service';


@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss']
})
export class WhatsappButtonComponent implements OnInit {
  phoneNumber: string;
  isFormula = false;

  constructor (protected readonly brandService: BrandService) {}

  ngOnInit(): void {
    this.isFormula = this.brandService.getBrand() === 'fr';

    if (this.isFormula) {
      this.phoneNumber = '5521978763147';
    } else {
      this.phoneNumber = '5521993410670';
    }
  }
}
