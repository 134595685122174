<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper">
  <ng-container *ngIf="brand === 'bt'; else conceptFr">
      <app-concept-objectives></app-concept-objectives>
      <app-concept-gyms></app-concept-gyms>
      <app-concept-results></app-concept-results>
  </ng-container>

</main>
<ng-template #conceptFr>
  <app-concept-fr [json]="config?.blocks[0]"></app-concept-fr>
</ng-template>
