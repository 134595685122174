import { Component } from '@angular/core';

@Component({
  selector: 'app-debug-banner',
  template: `<div class="text-danger text-center" style="margin-top: -24px">
      <i class="fab fa-angular"></i>
      In development environment
    </div>`,
})
export class DebugBannerComponent {
}
