<div class="section background-image-section">
  <ng-container *ngIf="brand === 'bt'">
      <div class="background-image"></div>

      <h2 class="title-footer">Deixe seu contato para marcarmos uma conversa</h2>
  </ng-container>

  <div class="container">
    <div class="card no-side-border-xs row-on-xs">
      <div class="form-validate">
        <app-franchise-personal #personal [(info)]="info"></app-franchise-personal>
        <app-franchise-property #property [(property)]="info.franchiseInformation"></app-franchise-property>
      </div>

      <div class="section row button-container">

        <!-- <div class="col-12 text-center">
          <div class="g-container d-inline-block">
            <app-recaptcha #recaptcha name="franchise"></app-recaptcha>
          </div>
        </div> -->

        <div class="col-12 text-center">
          <button id="franchise-confirm" class="btn btn-primary btn-confirm" (click)="send()">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</div>
