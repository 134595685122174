<section class="bg-white py-4 pt-md-3 pb-md-0">
  <div class="container mt-md-3 mb-3 pb-3 pb-md-0">
    <h1 class="text-center title">SAIBA MAIS SOBRE OS PASSES</h1>
  </div>

  <div class="container bt-pass-content position-relative px-0">
    <app-loading-content
      *ngIf="btPassPlanLoading"
      [loadingLabel]="loadingLabel"
      classes="text-center"
    ></app-loading-content>

    <swiper *ngIf="btpassPlanList?.length else errorTemplate" [config]="swiperConfig">
      <app-bt-pass-plan-card
        *ngFor="let plan of btpassPlanList"
        [btpassPlan]="plan"
      ></app-bt-pass-plan-card>
    </swiper>

    <ng-template #errorTemplate>
      <app-error
        *ngIf="!btPassPlanLoading"
        class="section-error text-center"
        display="column"
      ></app-error>
    </ng-template>
  </div>
</section>
