import { Component, Input, AfterViewInit } from '@angular/core';
import { CorporateFormRequest } from '@models';

@Component({
  selector: 'app-corporate-success',
  templateUrl: './corporate-success.component.html',
  styleUrls: ['./corporate-success.component.scss'],
})
export class CorporateSuccessComponent implements AfterViewInit {

  @Input() info: CorporateFormRequest = {
    contact: {},
    company: {},
    responsible: {},
  } as CorporateFormRequest;

  ngAfterViewInit() {
    const element = document.getElementsByClassName('title-success')[0];
    element.scrollIntoView({block: 'start', behavior: 'smooth'});
  }
}
