import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subject, Subscription } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import {
  EvoGymResponse,
  GymBasicInfoModel,
  GymFilterType,
  GymResponseModel,
  GymSearchFilterModel,
  GymSearchQueryModel,
  Location,
} from '@models';
import { GymSearchConfig } from '@models/configs';
import { AnalyticsService, EvoGymService, GeolocationService, GymService, HeaderService } from '@services';
import { RequestObject } from '@utils/request-object.class';


@Component({
  selector: 'app-gyms',
  templateUrl: './gyms.component.html',
})
export class GymsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  private readonly destroy$ = new Subject<boolean>();
  private readonly gymsPerPage = 3;

  private filterElement: HTMLElement;

  private location: Location;

  /** Attribute used to cancel the request when the geolocation api returns after 1.2 secs */
  private searchSubscription: Subscription;
  private contactInfoMapped = false;

  public config: GymSearchConfig;
  public imageHeader: string;
  public headerTitle: string;

  public searchInput: string;
  public filter: GymSearchFilterModel = {
    activities: [],
    benefits: [],
  };

  public gymSearchSubheading$: Observable<string>;

  public searchedGymsRO = new RequestObject<GymResponseModel>();
  public contactInfoRO  = new RequestObject<EvoGymResponse[]>();

  public searchedGyms: GymBasicInfoModel[] = [];
  public bufferGyms: GymBasicInfoModel[] = [];


  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly evoGymService: EvoGymService,
    private readonly geolocationService: GeolocationService,
    private readonly gymService: GymService,
    private readonly headerService: HeaderService,
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
    setTimeout(() => this.scrollToFilter(), 500);

    this.filterElement = document.getElementById('filter-component');
  }

  ngOnInit(): void {
    this.config      = this.activatedRoute.snapshot.data.config.gymSearch;
    this.imageHeader = this.activatedRoute.snapshot.data.imageHeader.image;
    this.headerTitle = this.activatedRoute.snapshot.data.imageHeader.title;

    this.setupGeolocation();
    this.getContactInfo();

    /** If the geolocation API doesnt returns anything after 1.2 secs this will trigger the search without geolocation.
     *  This also happens when the user is waiting to click on 'allow' or 'block' at the geolocation permission alert. */
    this.searchedGymsRO.loading = true;
    setTimeout(() => this.getGyms(), 1200);

    this.setupRouteParamChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setupGeolocation(): void {
    /** If the user changes the page geolocation permission manually, without clicking at the geolocation permission alert, this
     *  subscription will not be triggered. */
    this.geolocationService.getCurrentGeolocation().pipe(
      takeUntil(this.destroy$),
    ).subscribe(userCurrentLocation => {
      if (userCurrentLocation) {
        this.location = userCurrentLocation;
        this.getGyms();
      }
    });
  }

  private setupRouteParamChanges(): void {
    /** The first value, when the page loads will be ignored to prevent one more unnecessary request */
    this.activatedRoute.queryParamMap.pipe(
      takeUntil(this.destroy$),
      skip(1),
    ).subscribe(_ => this.getGyms());
  }

  private mapBenefitToFilter(array: GymFilterType[]): string | undefined {
    return array.length
      ? array.map(item => item.id).join(',')
      : undefined;
  }

  private getQuery(): GymSearchQueryModel {
    return {
      offset:     this.searchedGyms.length,
      search:     this.searchInput ?? undefined,
      activities: this.mapBenefitToFilter(this.filter.activities),
      services:   this.mapBenefitToFilter(this.filter.benefits),
      lat:        this.location?.latitude ?? undefined,
      lon:        this.location?.longitude ?? undefined,
    };
  }

  private scrollToFilter(): void {
    this.filterElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  private getContactInfo(): void {
    this.contactInfoRO = new RequestObject<EvoGymResponse[]>(
      this.evoGymService.getGymsContactInfo(),
    );

    this.contactInfoRO.observable$.subscribe(response => {
      this.contactInfoRO.response = response;

      if (!this.contactInfoMapped) {
        this.searchedGyms = this.mapGymsContactInfo(this.searchedGyms);
        this.contactInfoMapped = true;
      }
    });
  }

  private mapGymsContactInfo(gyms: GymBasicInfoModel[]): GymBasicInfoModel[] {
    return gyms.map(gym => ({
      ...gym,
      contactInfo: gym.evoMigratedUnit
        ? this.evoGymService.getContactInfoById(this.contactInfoRO.response, gym.code)
        : gym.contactInfo,
    }));
  }

  private getGyms(): void {
    this.searchedGyms = [];

    setTimeout(() => this.scrollToFilter());

    this.searchForGyms();
  }

  private searchForGyms(max = this.gymsPerPage * 2, page = 0): void {
    const query = this.getQuery();
    /** Identifies if it's the search when the page loads or when a filter is applied */
    const resetedSearch = max === this.gymsPerPage * 2;

    this.searchedGymsRO = new RequestObject(
      this.gymService.searchByQuery(query, max, page),
    );

    /** If the geolocation API returned after 1.2 secs the response from the request without geolocation will be ignored */
    this.searchSubscription?.unsubscribe();
    this.searchSubscription = this.searchedGymsRO.observable$.subscribe(response => {
      this.searchedGymsRO.response = response;

      const mappedGyms = this.contactInfoRO.response
        ? this.mapGymsContactInfo(response.results)
        : response.results;

      if (resetedSearch) {
        this.searchedGyms.push(...mappedGyms.slice(0, this.gymsPerPage));
        this.bufferGyms = mappedGyms.slice(this.gymsPerPage);
      } else {
        this.bufferGyms = mappedGyms;
      }

      this.gymSearchSubheading$ = this.gymService.getGymSearchMessage(response.resultType, response.orderType);
    });
  }

  public loadMore(): void {
    this.searchedGyms.push(...this.bufferGyms);

    const currentPage = this.searchedGyms.length / this.gymsPerPage;
    this.searchForGyms(this.gymsPerPage, currentPage);
  }

  public updateSearch(search: string): void {
    this.scrollToFilter();
    this.searchInput = search;

    this.analyticsService.trackEvent(
      `Busca: "${this.searchInput}"`,
      'Academias',
      'Busca',
    );
  }

}
