<div class="container-margin">
  <ng-container *ngIf="onlineGroupClasses?.length">
    <div class="title-section">
      AULAS COLETIVAS
    </div>

    <div class="classes-list">
      <ng-container *ngFor="let class of onlineGroupClasses">
        <div listItem [classInfo]="class" [userRegistration]="userRegistration" myWorkoutsPage="true"
            (favoriteEventEmitter)="deleteFavoriteGroupClass($event)" class="item-container"
        ></div>
      </ng-container>
    </div>
  </ng-container>

  <div class="content-margin-top" *ngIf="!onlineGroupClasses?.length">
    <app-empty-card icon="/assets/icons_custom/ic-empty-list.svg" altIcon="Lista vazia"
      message="Nenhum favorito para exibir."></app-empty-card>
  </div>
</div>
