<div class="mb-3">
  <div [class.collapsed]="!itemsArea.expanded"
    appDropdownArrows
    appClickable
    (click)="itemsArea.toggle()"
    class="list-group list-group-flush title table-header border-bottom flex-row"
    [attr.aria-expanded]="itemsArea.expanded"
    attr.aria-controls="itemsArea">
    <div class="d-flex flex-column flex-md-row w-100 justify-content-between">
      <span class="plan">{{ block?.content.text[0] }}</span>
    </div>
  </div>

  <div id="itemsArea" #itemsArea app-collapse [expanded]="false">
    <ng-container *ngFor="let item of block?.content.items">
      <ng-container [ngSwitch]="item.type">
        <div class="block-content block-{{item.type}}" *ngIf="item.type != 'graphic'">
          <app-text-block [block]="item" *ngSwitchCase="'text'"></app-text-block>
          <app-team-block [block]="item" *ngSwitchCase="'team'"></app-team-block>
          <app-file-block [block]="item" *ngSwitchCase="'file'"></app-file-block>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
