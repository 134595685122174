import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';

import { ValidateCpfResponse, NewAccessForm, NewAccessFormControls } from '@models';
import { HeaderService, AuthService, AlertMessageService, LoadingService } from '@services';
import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './create-account.component.html',
  styleUrls: [
    '../../area-user.component.scss',
  ],
})
export class CreateAccountComponent implements OnInit, AfterViewInit {

  public config: BasicHeaderConfig;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  formControls: NewAccessFormControls = {} as NewAccessFormControls;
  userForm: NewAccessForm = {} as NewAccessForm;
  validateCpfResponse: ValidateCpfResponse = {} as ValidateCpfResponse;


  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly alertMessageService: AlertMessageService,
    private readonly headerService: HeaderService,
    private readonly loadingService: LoadingService,
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
  }

  ngOnInit() {
    this.formControls.currentStep = 1;
    this.formControls.useOldEmail = true;

    const cod = this.activatedRoute.snapshot.queryParamMap.get('codAcesso');

    if (cod) {
      this.validateTokenForeignMinor(cod);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
   }

  validateTokenForeignMinor(token: string) {
    this.loadingService.startLoading();

    this.authService.validateTokenForeignMinor(token)
      .subscribe(
        response => {
          this.userForm.oldEmail = response['EmailPrincipal'];
          this.userForm.cpf = '000.000.000-00';

          Object.entries(response).forEach(v => this.validateCpfResponse[v[0]] = v[1]);

          this.formControls.currentStep++; // Avança à página de email e senha
        },
        err => this.alertMessageService.showToastr(err),
        () => this.loadingService.stopLoading(),
      );
  }

}
