import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export type Brand = 'bt' | 'fr';

@Injectable()
export class BrandService {
  fullBrandName: string;

  constructor() {
    this.fullBrandName = this.getFullTitleBrand();
  }

  getFullBrandName(): string {
    return environment.seo.DEFAULT_TITLE;
  }

  getFullTitleBrand(): string {
    return environment.seo.DEFAULT_FULL_TITLE;
  }

  getBrand(): Brand {
    return environment.brand as Brand;
  }

}
