import { Component, OnInit } from '@angular/core';
import { ModalType } from '@models';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-registry-hint-modal',
  templateUrl: './registry-hint-modal.component.html',
  styleUrls: ['./registry-hint-modal.component.scss'],
})
export class RegistryHintModalComponent implements OnInit {

  constructor(
    private readonly modalService: ModalService,
  ) { }

  ngOnInit() {
  }

  whereRegistryNumber() {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'Onde encontrar seu número de matrícula',
      message: `O número da sua matrícula está na primeira folha do seu contrato. Caso não tenha mais acesso,
      ligue para a sua unidade e informe seus dados para solicitá-lo.`,
    });
  }
}
