import { Pipe, PipeTransform } from '@angular/core';

import Fuse from 'fuse.js';


@Pipe({
  name: 'fuse',
})
export class FusePipe implements PipeTransform {

  transform(list: unknown[], searchString: string, options: Fuse.IFuseOptions<unknown> = {}): typeof list {
    if (!searchString) {
      return list;
    }

    const fuseOptions: typeof options = {
      ...options,
      threshold: 0.4,
      distance: 80,
    };

    const fuse = new Fuse(list, fuseOptions);

    return fuse.search(searchString).map(res => res.item);
  }

}
