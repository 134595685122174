import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AgeRangeResolveGuard } from '@guards/age-range-resolve.guard';
import { GymActivitiesResolveGuard } from '@guards/gym-activities-resolve.guard';
import { GymGroupActivitiesResolveGuard } from '@guards/gym-group-activities-resolve.guard';
import { GymNameListResolveGuard } from '@guards/gym-name-list-resolve.guard';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';

import { AgendaComponent } from './agenda.component';


const agendaRoute: Route[] = [
    {
      path: '',
      children: [
        {
          path: ':slug',
          component: AgendaComponent,
        },
        {
          path: '',
          pathMatch: 'full',
          component: AgendaComponent,
        },
      ],
      resolve: {
        activities: GymActivitiesResolveGuard,
        groups: GymGroupActivitiesResolveGuard,
        ages: AgeRangeResolveGuard,
        gyms: GymNameListResolveGuard,
        config: PortalConfigResolveGuard,
        imageHeader: ImageHeaderResolve,
      },
      data: {
        windowContext: 'agenda',
        windowTitle: '${data.config.agenda.header.title}',
        config: {
          activitiesModal: 'modal-activities',
          agenda: 'agenda',
          agendaFilter: 'agenda-filter',
        },
        slug: 'agenda',
      },
    },
];

@NgModule({
    imports: [RouterModule.forChild(agendaRoute)],
    exports: [RouterModule],
})
export class AgendaRoutingModule { }
