<ng-template #header>
  <app-header-static-image
    [pageTitle]="headerTitle"
    [imageUrl]="imageHeader"
  ></app-header-static-image>
</ng-template>

<main class="main-wrapper container">
  <div class="title-lg text-center">
    {{ config.search.title }}
  </div>

  <app-gym-search-input
    (queryChange)="searchInput = $event"
    (searchClick)="updateSearch($event)"
  ></app-gym-search-input>

  <app-gym-filter (filterChange)="filter = $event"></app-gym-filter>

  <div class="mt-3 mt-sm-5 pt-3">
    <h1 class="title-lg text-center">{{ config.results.title }}</h1>

    <ng-container *ngIf="searchedGyms.length">
      <p class="text-center font-weight-normal m-0">{{ gymSearchSubheading$ | async }}</p>

      <div class="row-on-xs py-2">
        <div class="my-4" *ngFor="let gym of searchedGyms">
          <app-gym-info-card [gym]="gym" [contactRO]="contactInfoRO"></app-gym-info-card>
        </div>
      </div>
    </ng-container>

    <app-empty-card
      *ngIf="!searchedGyms.length && !searchedGymsRO.loading"
      icon="assets/icons_custom/ic-empty-list.svg"
      altIcon="Lista vazia"
      [message]="config.results.empty"
    ></app-empty-card>

    <div class="link text-center">
      <app-loading-content
        *ngIf="searchedGymsRO.loading"
        [loadingLabel]="config.results.loading"
        classes="text-center"
      ></app-loading-content>

      <a
        id="gym-load-more"
        appClickable
        class="title text-primary"
        (click)="loadMore()"
        *ngIf="(searchedGymsRO.response?.more || bufferGyms.length) && searchedGymsRO.successful"
      >
        {{ config.results.loadMore }}
      </a>
    </div>
  </div>
</main>

<!-- Modal opens into the 'gym-info-card' component -->
<app-modal-gym-benefits></app-modal-gym-benefits>
