import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { mergeMap, retry, tap } from 'rxjs/operators';

import { AlertMessage, MessageMap, ModalType, RegistrationSuccessRequest, UpdatePasswordRequest, UpdateUserCredentials } from '@models';
import { AppConstants } from '@utils/app-constants';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { AppMicrosservicesGraphql } from '@utils/app-microsservices-graphql';
import { FormatUtils } from '@utils/format-utils';

import { Apollo } from 'apollo-angular';

import { AlertMessageService } from './alert-message.service';
import { ModalService } from './modal.service';


@Injectable()
export class UserService {

  constructor(
    private readonly alertMessage: AlertMessageService,
    private readonly http: HttpClient,
    private readonly modalService: ModalService,
    private readonly apollo: Apollo,
  ) { }

  updateUserPassword(updatePasswordRequest: UpdatePasswordRequest) {
    return this.http.post<any>(sur(SurEndpoints.UrlUpdateUserPassword),
      {
        password: updatePasswordRequest.password,
        confirm: updatePasswordRequest.confirm,
        token: updatePasswordRequest.token,
      });
  }

  sendForgotPasswordEmail(email: string) {
    return this.http.post<any>(sur(SurEndpoints.UrlSendForgotPasswordEmail), { email });
  }

  unblockEmail(confirmation: string) {
    return this.http.get<any>(sur(SurEndpoints.UrlUnblockEmail, confirmation));
  }

  sendUnblockEmail(email: string) {
    return this.http.post<any>(sur(SurEndpoints.UrlSendUnblockEmail), { email });
  }

  sendRandomPasswordEmail(registrationSuccessRequest: RegistrationSuccessRequest) {
    return this.http.post<any>(sur(SurEndpoints.UrlSendRandomPasswordEmail),
      {
        email: registrationSuccessRequest.email,
        password: registrationSuccessRequest.password,
      });
  }

  modalResendEmailConfirm(email: string) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'CONTA DE ACESSO BLOQUEADA',
      message: 'Sua conta de acesso ainda não foi desbloqueada. ' +
        'Por favor, realize o desbloqueio clicando no link enviado para seu e-mail. ' +
        'Se você não recebeu o e-mail de confirmação, clique no botão Reenviar abaixo, para envio do e-mail de confirmação. ',
      cancelButton: 'OK',
      confirmButton: 'REENVIAR',
      confirmCallback: () => this.resendemail(email),
    });
  }

  resendemail(email: string) {
    this.sendUnblockEmail(email).subscribe(
      () => this.alertMessage.showToastr(AlertMessage.success(MessageMap.EMAIL_ENVIADO)),
      () => this.alertMessage.showToastr(AlertMessage.error(MessageMap.PROBLEMA_ENVIO_EMAIL)),
    );
  }

  findUserByCpfOrRegistration(params: any, document: any): Observable<any> {
    if (params.registration) {
      params.registration = FormatUtils.addZerosToTheLeft(params.registration, AppConstants.REGISTRATION_LENGTH);
    }
    return this.apollo.use('second').query({
      query: document === 0 ? AppMicrosservicesGraphql.queryfindUserByCpf : AppMicrosservicesGraphql.queryfindUserByRegistration,
      variables: {
        params,
      },
    }).pipe(
      tap(res => {
        if (res.errors?.length) {
          throw res.errors;
        }
      }),
    );
  }

  updateUserByRegistrationOrCpf(params: UpdateUserCredentials): Observable<any> {
    if (params.registration) {
      params.registration = FormatUtils.addZerosToTheLeft(params.registration, AppConstants.REGISTRATION_LENGTH);
    }
    return this.apollo.use('second').mutate({
      mutation: AppMicrosservicesGraphql.mutationUpdateUserByRegistrationOrCpf,
      variables: {
        params,
      },
    });
  }

  createUser(params: any): Observable<any> {
    if (params.registration) {
      params.registration = FormatUtils.addZerosToTheLeft(params.registration, AppConstants.REGISTRATION_LENGTH);
    }
    return this.apollo.use('second').mutate({
      mutation: AppMicrosservicesGraphql.mutationCreateUser,
      variables: {
        params,
      },
    }).pipe(
      mergeMap(res => {
        if (res.errors?.length) {
          return throwError(res.errors);
        }
        return of(res);
      }),
      retry(3)
    );
  }

  microsserviceAuth(params: any): Observable<any> {
    return this.apollo.use('second').mutate({
      mutation: AppMicrosservicesGraphql.mutationAuth,
      variables: {
        params,
      },
    });
  }
}
