import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

interface SchedulePerMonthInfoObject {
  currentMonth: any;
  nextMonth: any;
}
@Component({
  selector: 'app-schedules-per-month-info',
  templateUrl: './schedules-per-month-info.component.html',
  styleUrls: ['../online-class-schedules-list.component.scss'],
})
export class SchedulesPerMonthInfoComponent implements OnInit {

  @Input() totalOnlineSchedules: SchedulePerMonthInfoObject;
  @Input() numberOfSchedulesAlreadyMade: SchedulePerMonthInfoObject;
  @Input() month: SchedulePerMonthInfoObject;
  @Input() numberOfActiveSchedules: number;
  @Input() showSchedulePerMonthLimit = true;

  @Output() hasSchedulesLeft = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
    this.hasSchedulesLeft.emit(
      this.numberOfSchedulesAlreadyMade.currentMonth < this.totalOnlineSchedules.currentMonth
      || (this.totalOnlineSchedules.nextMonth && this.numberOfSchedulesAlreadyMade.nextMonth < this.totalOnlineSchedules.nextMonth),
    );
  }

}
