import { Component, OnInit } from '@angular/core';
import { ModalType } from '@models';
import { ModalService } from '@services';

import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent extends ModalCommonComponent implements OnInit {

  constructor(
    protected modalService: ModalService,
  ) {
    super();
    this.type = ModalType.error;
  }

}
