<app-navbar-top
  [showBackground]="opacity <= 0"
  [menuOpenImagePath]="'assets/icons/ic-drawer.svg'"
  [menuCloseImagePath]="'assets/icons/ic-close.svg'"
  [navbarItems]="links"
  [navbarOpenItems]="linksNavOpen"
  [navbarItemsLogged]="linksLogged"
></app-navbar-top>

<div [style.height.px]="headerHeight"></div>

<div
  appOpenModalPadding
  #headerContainer
  class="header-container"
  [class.hidden]="!opacity"
>
  <div class="header-content" [style.opacity]="opacity">
    <ng-container #headerContent></ng-container>
  </div>
</div>
