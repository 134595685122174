<div class="modal fade" bsModal #templateModal="bs-modal" (onShow)="init()" (onHidden)="hidden()">
  <div class="modal-dialog modal-lg" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-title-text">
        <div class="modal-header justify-content-around">
          <div class="modal-header-title title text-center">{{ modalObj.title }}</div>
        </div>
      </div>

      <div class="modal-body">
        <table class="table table-flush table-striped">
          <thead>
            <tr class="title">
              <th>Tipo da Compra</th>
              <th>Periodicidade</th>
              <th>Horário</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of preSalePurchase.details">
              <td>{{ detail.type }}</td>
              <td>{{ detail.periodicity }}</td>
              <td>{{ detail.schedule }}</td>
              <td>{{ detail.value | currency:'BRL':'symbol':'':locale }}</td>
            </tr>
          </tbody>
        </table>
        <div class="list-group-item d-flex justify-content-between">
          <span>Termo de adesão</span>
          <span class="file-icon-container">
            <a *ngIf="preSalePurchase?.term; else loadingTermUrl"
                [href]="preSalePurchase?.term"
                target="_blank"
                rel="noopener">
              <img alt="Arquivo pdf" src="/assets/icons_custom/ic-pdf.svg">
            </a>
            <ng-template #loadingTermUrl>
              <i class="fas fa-spinner fa-pulse"></i>
            </ng-template>
          </span>
        </div>
      </div>
      <ng-container *ngIf="cancelConfirm">
        <form #formData="ngForm" class="text-center form-validate">
          <h4 class="title subtitle"> Por favor, confirme os seus dados para cancelar:</h4>
          <div class="justify-content-around text-center row">
            <div class="col-12 col-md-6">
              <label for="login-email" class="label-input">E-mail</label>
              <input id="login-email"
                class="form-control"
                [value]="user.login"
                autocomplete="off"
                disabled>
            </div>
          </div>
          <div class="justify-content-around row">
            <div class="col-12 col-md-6">
              <label for="login-password" class="label-input">Senha</label>
              <input #password="ngModel" id="login-password" name="password"
                type="password"
                class="form-control"
                autocomplete="current-password"
                required
                [(ngModel)]="user.password">
              <app-error-message [control]="password"></app-error-message>
            </div>
          </div>

          <div class="text-center button-container">
            <button id="confirm-cancel" class="btn btn-outline-danger btn-confirm"
              (click)="confirmCancel()">
              Confirmar cancelamento
            </button>
          </div>
        </form>

      </ng-container>
      <div class="modal-footer">
        <button id="modal-benefit-confirm" type="button" class="col btn btn-primary"
          (click)="confirm()"> OK
        </button>
        <button type="button"
          class="col btn btn-outline-secondary" (click)="preCancelPlan()">
            Cancelar Plano
        </button>
      </div>
    </div>
  </div>
</div>
