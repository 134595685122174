import { Component, OnInit } from '@angular/core';
import { MyOnlineClassSchedulesService } from '@services/my-online-class-schedules.service';
import { AppConstants } from '@utils/app-constants';
import { DaysOfTheWeek } from '@models/bt-home.model';
import { StringUtil } from '@utils/string-util';
import { AlertMessage, MessageMap } from '@models';
import { AlertMessageService, LoadingService } from '@services';


@Component({
  selector: 'app-online-group-classes',
  templateUrl: './online-group-classes.component.html',
  styleUrls: [
    '../../area-user.component.scss',
    './online-group-classes.component.scss',
    '../online-class-main/online-class-main.component.scss'
  ],
})
export class OnlineGroupClassesComponent implements OnInit {

  userRegistration: number;

  classesOfAllDays: any[];
  classesOfSelectedDay: any;
  selectedDay: DaysOfTheWeek;

  nextClasses: any[] = [];

  settingFavorites = false;

  constructor(
    private readonly myOnlineClassSchedulesService: MyOnlineClassSchedulesService,
    private readonly alertMessageService: AlertMessageService,
    protected readonly loadingService: LoadingService,
  ) {

  }

  ngOnInit() {
    this.userRegistration = +localStorage.getItem(
      AppConstants.STOR_APP_USER_REGISTRATION,
    );
    this.pageInitialization();
  }

  changeSelectedDay(selectedDay: DaysOfTheWeek) {
    this.classesOfSelectedDay = this.classesOfAllDays.find(day => day.code === selectedDay) || null;
  }

  favoriteButtonAction(classInfo: any) {
    this.settingFavorites = true;
    classInfo.favoriteGroupClass ? this.deleteFavoriteGroupClass(classInfo) : this.setFavoriteGroupClass(classInfo);
  }

  private setFavoriteGroupClass(classInfo: any) {
    this.myOnlineClassSchedulesService.createFavoriteGroupClass(classInfo.id, this.userRegistration).subscribe((res: any) => {
      if (res) {
        classInfo.favoriteGroupClass =  {
          id: res.id,
        };
      } else {
        this.errorWhenSetOrDeleteFavorite();
      }
      this.settingFavorites = false;
    });
  }

  private deleteFavoriteGroupClass(classInfo: any) {
    this.myOnlineClassSchedulesService.deleteFavoriteGroupClass(classInfo.favoriteGroupClass.id).subscribe((res: any) => {
      if (res) {
        classInfo.favoriteGroupClass = null;
      } else {
        this.errorWhenSetOrDeleteFavorite();
      }
      this.settingFavorites = false;
    });
  }

  private errorWhenSetOrDeleteFavorite() {
    this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SERVIDOR_INDISPONIVEL));
  }

  private pageInitialization() {
    this.loadingService.startLoading();
    const today = new Date().getDay() + 1;
    this.selectedDay = today;

    this.myOnlineClassSchedulesService.listAllWeekDaysWithGroupClassesByClient(this.userRegistration).subscribe((res: any[]) => {
      this.loadingService.stopLoading();
      this.classesOfAllDays = res;
      this.classesOfSelectedDay = this.classesOfAllDays.find(day => day.code === today) || null;

      let allClasses: any[] = [];
      this.classesOfAllDays.forEach(day => {
        day.groupClassClientCustomResponse.forEach((_class: { weekDayName: any; }) => {
          _class.weekDayName = day.name;
        });

        allClasses = [...allClasses, ...day.groupClassClientCustomResponse];
      });
      allClasses.sort((a, b) => StringUtil.strcmp(a.formattedHour, b.formattedHour));
      this.filterNextClasses(allClasses);
    }, () => {
      this.loadingService.stopLoading();
    });
  }

  private filterNextClasses(allClasses: any[]) {
    this.nextClasses = allClasses.filter(c => {
      const now = new Date();
      const classDate = new Date(c.formattedHour);

      const diff = now.getTime() - classDate.getTime();
      const classInProgress = diff > 0 && diff <= c.duration * 60000;

      return (classDate.getTime() > now.getTime())
      || ((classDate.getTime() <= now.getTime()) && classInProgress);
    }).sort((a, b) => StringUtil.strcmp(a.formattedHour, b.formattedHour)).splice(0, 3);

    setTimeout(() => this.filterNextClasses(allClasses), 60000);
  }
}
