import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-agenda-header',
  templateUrl: './agenda-header.component.html',
  styleUrls: ['./agenda-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgendaHeaderComponent {
  // REMOVE_INTENSITY
  // intensities = [ 'Moderado', 'Moderado-Forte', 'Forte', 'Mix-Training' ];

  @Input() today = '0';
  @Input() highlight: number;
  @Input() left = 0;
  @Output() leftChange = new EventEmitter<number>();

  @Input() weekstart = 1;
  @Input() days: string[];

  onScroll(value: number) {
    this.left = value;
    this.leftChange.emit(value);
  }
}
