import { Directive, ElementRef, HostListener, Inject, Input,
  OnChanges, Optional, Renderer2, SimpleChanges, forwardRef } from '@angular/core';
import { COMPOSITION_BUFFER_MODE, NG_VALUE_ACCESSOR, Validator,
    FormControl, NG_VALIDATORS } from '@angular/forms';
import { ɵgetDOM } from '@angular/platform-browser';
import { createTextMaskInputElement } from './masked-input/textMaskCore';

export interface TextMaskConfig {
  mask: (string | RegExp)[] | ((raw: string) => (string | RegExp)[]) | false;
  guide?: boolean;
  placeholderChar?: string;
  pipe?: (conformedValue: string, config: TextMaskConfig) => false | string | object;
  keepCharPositions?: boolean;
  showMask?: boolean;
}

/**
 * We must check whether the agent is Android because composition events
 * behave differently between iOS and Android.
 */
function _isAndroid(): boolean {
  const userAgent = ɵgetDOM() ? ɵgetDOM().getUserAgent() : '';
  return /android (\d+)/.test(userAgent.toLowerCase());
}

@Directive({
  selector: '[appMaskedInput]',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MaskedInputDirective),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    useExisting: MaskedInputDirective,
    multi: true,
  } ],
})
export class MaskedInputDirective implements OnChanges, Validator {

  defaultConfig: TextMaskConfig = {
    mask: [],
    guide: false,
    placeholderChar: '\u2000', // whitespace
    pipe: undefined,
    keepCharPositions: false,
  };

  @Input() appMaskedInput: TextMaskConfig = this.defaultConfig;

  private textMaskInputElement: any;
  private inputElement: HTMLInputElement;

  /** Whether the user is creating a composition string (IME events). */
  private _composing = false;
  private maskLength = 0;
  private maskPlaceholders = '';

  onChange = (_: any) => {};

  @HostListener('blur')
  onTouched = () => {}

  validate(c: FormControl) {
    const value: string = c.value || '';
    if (!value.replace) {
      return null;
    }

    let numbers;

    if (this.appMaskedInput.mask['name'] === 'phone') {
      numbers = value.replace(/[\D]+/g, '');
    } else if (this.appMaskedInput.mask['name'] === 'dateFull' || this.appMaskedInput.mask['name'] === 'expire') {
      numbers = value.replace(/[\/]+/g, '');
    } else {
      numbers = value.replace(new RegExp(this.maskPlaceholders, 'g'), '');
    }

    return numbers && numbers.length < this.maskLength
      ? { minLength: this.maskLength, actualSize: numbers.length }
      : null;
  }

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) private readonly _compositionMode: boolean,
  ) {
    if (this._compositionMode == null) {
      this._compositionMode = !_isAndroid();
    }
  }
  // @ts-ignore
  ngOnChanges(changes: SimpleChanges) {
    this._setupMask(true);
    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(this.inputElement.value);
    }
  }

  // @ts-ignore
  writeValue(value: any) {
    this._setupMask();

    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(value);
    }
  }

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('input', ['$event.target.value'])
  _handleInput(value: any) {
    if (!this._compositionMode || (this._compositionMode && !this._composing)) {
      this._setupMask();

      if (this.textMaskInputElement !== undefined) {
        this.textMaskInputElement.update(value);

        // get the updated value
        value = this.inputElement.value;
        this.onChange(value);
      }
    }
  }

  _setupMask(create = false) {
    if (!this.inputElement) {
      if (this._elementRef.nativeElement.tagName.toUpperCase() === 'INPUT') {
        // `textMask` directive is used directly on an input element
        this.inputElement = this._elementRef.nativeElement;
      } else {
        // `textMask` directive is used on an abstracted input element, `md-input-container`, etc
        this.inputElement = this._elementRef.nativeElement.getElementsByTagName('INPUT')[0];
      }
    }

    if (this.inputElement && create) {
      this.textMaskInputElement = createTextMaskInputElement(
        Object.assign({inputElement: this.inputElement}, this.defaultConfig, this.appMaskedInput),
      );

      let mask = [];
      if (this.appMaskedInput.mask instanceof Function) {
        mask = this.appMaskedInput.mask('');
      } else {
        mask = (this.appMaskedInput.mask as any[]);
      }

      this.maskPlaceholders = mask.filter(item => !(item instanceof RegExp)).join('');
      this.maskLength = mask.length - this.maskPlaceholders.length;
    }

  }

  @HostListener('compositionstart')
  _compositionStart(): void { this._composing = true; }

  @HostListener('compositionend', ['$event'])
  _compositionEnd(value: any): void {
    this._composing = false;
    if (this._compositionMode) {
      this._handleInput(value);
    }
  }

}
