import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { BrandService } from '@services/brand.service';

import { ResolveResult } from './type-resolve-bundle';


@Injectable()
export class BrandGuard implements CanActivate {


  constructor(
    private readonly brandService: BrandService,
    private readonly router: Router,
  ) { }

  canActivate(activatedRoute: ActivatedRouteSnapshot): ResolveResult<boolean> {
      if (activatedRoute.data?.brand !== this.brandService.getBrand()) {
        this.router.navigate(['/']);
        return false;
      } else {
        return true;
      }
  }

}
