import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { GymActivity } from '@models';
import { ActivityService } from '@services/activity.service';

import { ResolveResult } from './type-resolve-bundle';


@Injectable()
export class GymActivitiesResolveGuard implements Resolve<GymActivity[]> {


  constructor(private readonly activityService: ActivityService) { }

  resolve(): ResolveResult<GymActivity[]> {
    return this.activityService.getActiveActivities();
  }

}
