import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

import { EvoLoginRequestBody, EvoLoginResponse, EvoResetPasswordResponse } from '@models';
import { evoBack, EvoBackEndpoints } from '@utils/app-endpoints';
import { MicroserviceErrorEnum } from '@utils/app-errors';
import { EvoErrorMessages, EvoErrorsCode } from '@utils/evo';

import { AlertMessageService } from '../alert-message.service';
import { EvoGeneralService } from './evo-general.service';


@Injectable()
export class EvoAuthService {


  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly evoGeneralService: EvoGeneralService,
    private readonly http: HttpClient,
  ) { }

  /** @todo When migration finish remove gym comparison logic. */
  login(body: EvoLoginRequestBody, loginMicroServiceResponse: any): Observable<EvoLoginResponse | any> {
    const tokenParsed = JSON.parse(window.atob(loginMicroServiceResponse.data.authenticateUser.tokenUser.split('.')[1]));

    return this.evoGeneralService.checkMigratingGym(tokenParsed.gymUnit).pipe(
      mergeMap(migrationGymResponse => {
        if (migrationGymResponse.isAlreadyMigrated) {
          return this.evoAuthenticateUser(body);
        }

        return of(loginMicroServiceResponse);
      }),
    );
  }

  evoAuthenticateUser(body: EvoLoginRequestBody): Observable<EvoLoginResponse> {
    // tslint:disable-next-line
    return this.http
      .post<EvoLoginResponse>(
        evoBack(EvoBackEndpoints.Login),
        {},
        { params: { ...body } }
      )
      .pipe(
        tap((evoAuthenticationResponse) => {
          if (!evoAuthenticationResponse.successAuthenticate) {
            throw {
              status: EvoErrorsCode.WrongPassword,
              message: EvoErrorMessages[EvoErrorsCode.WrongPassword],
            };
          }
        }),
        catchError((error) => {
          if (EvoErrorMessages[error.status]) {
            this.alertMessageService.showToastr(EvoErrorMessages[error.status]);
          }

          return throwError(error);
        })
      ) as Observable<EvoLoginResponse>;
  }

  /** @param userCredentials - can be the user CPF or idMember or Email, usually it will be the email **/
  evoResetPassword(userCredentials: string): Observable<EvoResetPasswordResponse> {
    const params = new HttpParams({
      fromObject: {
        signIn: 'true',
        user: userCredentials,
      }
    });

    return this.http.get<EvoResetPasswordResponse>(evoBack(EvoBackEndpoints.ResetPassword), { params });
  }

  verifyEvoResetPasswordToCreateAccount(errorCode: number, userCredentials: string) {
    return this.evoResetPassword(userCredentials).pipe(
      catchError((error) => {
        if (errorCode === MicroserviceErrorEnum.ApiUserNotRegisteredError) {
          return throwError(errorCode);
        }

        return throwError(error);
      })
    );
  }
}
