export * from './income.guard';
export * from './age-range-resolve.guard';
export * from './all-gyms-customer-resolve.guard';
export * from './auth.guard';
export * from './contract-cancel-resolve.guard';
export * from './franchise-resolve.guard';
export * from './gym-activities-resolve.guard';
export * from './gym-benefits-resolve.guard';
export * from './gym-commodities-resolve.guard';
export * from './gym-group-activities-resolve.guard';
export * from './gym-name-list-resolve.guard';
export * from './gym-plan-resolve.guard';
export * from './gym-resolve.guard';
export * from './gym-search-param.guard';
export * from './portal-config-resolve.guard';
export * from './presale-purchase-resolve.guard';
export * from './price-range-resolve.guard';
export * from './profile-civil-resolve.guard';
export * from './profile-professions-resolve.guard';
export * from './profile-relationship-resolve.guard';
export * from './purchase-resolve.guard';
export * from './ticket-gym-resolve.guard';

import { IncomeResolveGuard } from './income.guard';
import { AgeRangeResolveGuard } from './age-range-resolve.guard';
import { AllGymsCustomerResolve } from './all-gyms-customer-resolve.guard';
import { AuthGuard } from './auth.guard';
import { BrandGuard } from './brand.guard';
import { ContractCancelResolve } from './contract-cancel-resolve.guard';
import { FranchiseResolveGuard } from './franchise-resolve.guard';
import { GymActivitiesResolveGuard } from './gym-activities-resolve.guard';
import { GymBenefitResolveGuard } from './gym-benefits-resolve.guard';
import { GymCommoditiesResolveGuard } from './gym-commodities-resolve.guard';
import { GymGroupActivitiesResolveGuard } from './gym-group-activities-resolve.guard';
import { GymNameListResolveGuard } from './gym-name-list-resolve.guard';
import { GymNameWithKidsListResolveGuard } from './gym-name-with-kids-list-resolve.guard';
import { GymPlanResolveGuard } from './gym-plan-resolve.guard';
import { GymResolveGuard } from './gym-resolve.guard';
import { GymSearchParamString } from './gym-search-param.guard';
import { ImageHeaderResolve } from './image-header-resolve.guard';
import { OnlineClassAccessResolve } from './online-class-access-resolve.guard';
import { OnlineClassHoursResolve } from './online-class-hours-resolve.guard';
import { PortalConfigResolveGuard } from './portal-config-resolve.guard';
import { PreSalePurchaseResolveGuard } from './presale-purchase-resolve.guard';
import { PriceRangeResolveGuard } from './price-range-resolve.guard';
import { ProfileCivilResolveGuard } from './profile-civil-resolve.guard';
import { ProfileProfessionsResolveGuard } from './profile-professions-resolve.guard';
import { ProfileRelationshipResolveGuard } from './profile-relationship-resolve.guard';
import { PurchaseResolveGuard } from './purchase-resolve.guard';
import { TicketGymResolveGuard } from './ticket-gym-resolve.guard';

export const guards = [
  IncomeResolveGuard,
  AgeRangeResolveGuard,
  AllGymsCustomerResolve,
  AuthGuard,
  BrandGuard,
  ContractCancelResolve,
  FranchiseResolveGuard,
  GymActivitiesResolveGuard,
  GymBenefitResolveGuard,
  GymCommoditiesResolveGuard,
  GymGroupActivitiesResolveGuard,
  GymNameListResolveGuard,
  GymNameWithKidsListResolveGuard,
  GymPlanResolveGuard,
  GymResolveGuard,
  GymSearchParamString,
  ImageHeaderResolve,
  OnlineClassAccessResolve,
  OnlineClassHoursResolve,
  PortalConfigResolveGuard,
  PreSalePurchaseResolveGuard,
  PriceRangeResolveGuard,
  ProfileCivilResolveGuard,
  ProfileProfessionsResolveGuard,
  ProfileRelationshipResolveGuard,
  PurchaseResolveGuard,
  TicketGymResolveGuard,
];
