<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="eventTable.length; else empty">
    <!-- REMOVE_INTENSITY -->
    <!-- <div class="intensities-header legend-container">
      <div class="table-legend d-flex flex-wrap d-print-none">
        <div class="col-6 col-md-3 text-container" *ngFor="let intensity of intensities; let index = index">
          <div class="legend-{{ index + 1 }} title text-left text-content">{{ intensity }}</div>
        </div>
      </div>
    </div> -->
    <div class="light-shadow table-container">
      <table class="table table-content table-bordered">
        <thead *ngIf="daysColHeader?.length">
          <tr>
            <th scope="col" class="p-0 align-top time">
              <div class="time border-bottom border-right">
                <img alt="relógio" class="clock-icon" src="/assets/icons/ic-time.svg">
              </div>
            </th>
            <th scope="col" class="p-0 align-middle title cell" *ngFor="let header of daysColHeader">
              <div class="cell-day">{{ header }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of eventTable; let lineIndex = index">
            <td scope="row" class="title-md time text-light p-0">
              <div class="time">{{ timesRowHeader[lineIndex] }}</div>
            </td>
            <td scope="row" class="cell" *ngFor="let cell of row">
              <ng-container *ngFor="let event of cell">
                <div class="event-container event"
                  *ngIf="event.itemGridTitle; else eventPlaceholder"
                  appClickable
                  id="agenda-{{event.id}}"
                  (click)="showEvent(event)" >
                    <!-- REMOVE_INTENSITY -->
                    <!-- <div class="legend legend-{{ event.intensityColor || 0 }}"></div> -->
                    <div class="title" appMaxLines="1">{{ event.itemGridTitle }}</div>
                    <span class="instructor" appMaxLines="2">{{ event.teacherName | capitalize }}</span>
                    <img *ngIf="event.isOutdoorPlace" [popoverFade]="popTemplate"
                    class="ml-1 mt-1" src="assets/icons/outdoor.svg" alt="Aula ao ar Livre">
                  </div>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-template #popTemplate>  
    <div class="title font-weight-normal">ATIVIDADE OUTDOOR</div>
  </ng-template>
  <ng-template #eventPlaceholder>
    <div class="event-container"></div>
  </ng-template>
  <ng-template #empty>
    <div class="loading-container">
      <app-empty-card
        icon="/assets/icons_custom/ic-empty-list.svg"
        altIcon="Agenda não encontrada"
        message="Nenhuma aula foi encontrada com os filtros selecionados.<br>
        Faça uma pesquisa com filtros vazios para ver a agenda completa."
      ></app-empty-card>
    </div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <div class="title text-center title-md loading-container">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>
