import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fr-company-header',
  templateUrl: './fr-company-header.component.html',
  styleUrls: ['./fr-company-header.component.scss'],
})
export class FrCompanyHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
