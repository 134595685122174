import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  // tslint:disable-next-line:component-selector
  selector: '[listItem]',
  templateUrl: './class-list-item.component.html',
  styleUrls: [
    '../../../area-user.component.scss',
    './class-list-item.component.scss',
  ],
})
export class ClassListItemComponent implements OnInit {

  @Input() classInfo: any;
  @Input() userRegistration: number;
  @Input() myWorkoutsPage = false;

  @Output() favoriteEventEmitter = new EventEmitter<any>();

  classHour: number;

  constructor() { }

  ngOnInit() {
    const date = new Date(this.classInfo.formattedHour);
    this.classHour = date.getHours();
  }

  favoriteButtonAction(classInfo: any) {
    this.favoriteEventEmitter.emit(classInfo);
  }

}

export interface ClassInfo {
  id: number;
  activity: string;
  initialHour: number;
  initialMinute: number;
  formattedHour: string;
  favoriteGroupClass: {
    id: number;
  };
}
