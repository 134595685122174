import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { BaseUserCredentials, NewAccessRequest, RecoveryEmailRequest, ResetUserAccessRequest, User } from '@models';
import { AppConstants } from '@utils/app-constants';
import { back, BackEndpoints, external, ExternalEndpoints, sur, SurEndpoints } from '@utils/app-endpoints';
import { AppMicrosservicesDocmodGraphql } from '@utils/app-microservices-docmod-graphql';
import { AppMicrosservicesGraphql } from '@utils/app-microsservices-graphql';
import { FormatUtils } from '@utils/format-utils';

import { Apollo } from 'apollo-angular';

import { BrandService } from './brand.service';


@Injectable()
export class AuthService {

  private readonly loginEmitter = new EventEmitter<boolean>();

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly apollo: Apollo,
    private readonly brandService: BrandService,
  ) { }

  authenticateUser(login: {email: string, password: string}) {
    const params = this.generateAuthParams(login);
    return this.getIp()
    .pipe(
      tap(ip => params.ip = ip),
      mergeMap(() => {
        return this.apollo.use('second').mutate({
          mutation: AppMicrosservicesGraphql.mutationAuth,
          variables: {
            params,
          },
        });
      }),
      tap(res => {
        if (!res.data) {
          throw throwError(res.errors);
        }
      })
    );
  }

  checkAuth(): boolean {
    return localStorage.getItem(AppConstants.STOR_APP_USER_TOKEN) != null;
  }

  clearAppToken(key: string) {
    localStorage.removeItem(key);
  }

  confirmNewUserLegacy(confirmation: string) {
    return this.http.get<any>(back(BackEndpoints.ConfirmationNewAccount, confirmation));
  }

  getAppToken(key: string): Observable<string> {
    const token = localStorage.getItem(key);
    if (token) {
      return of(token);
    } else {
      let tokenRequest = null;

      if (key === AppConstants.STOR_DOCUSIGN_TOKEN) {
        tokenRequest = this.getDocuSignAuth().pipe(map((json: any) => json.data.getAuth.token));
      } else if (key === AppConstants.STOR_COGNITO_TOKEN) {
        tokenRequest = this.http.get<any>(back(BackEndpoints.CognitoToken)).pipe(map(json => json.token));
      } else {
        tokenRequest = this.http.get<any>(sur(SurEndpoints.AppToken))
        .pipe(map(json => json.jwt));
      }
      return tokenRequest.pipe(
        tap((tokenResult: string) => {
          localStorage.setItem(key, tokenResult);
        }),
      );
    }
  }

  getDocuSignAuth() {
    const params = {
      key: environment.docmod.key,
      system: environment.docmod.system
    };

    return this.apollo.use('docModService').mutate({
      mutation: AppMicrosservicesDocmodGraphql.getAuth,
      variables: {
        params,
      },
    });
  }

  getAppUserToken(): string {
    return localStorage.getItem(AppConstants.STOR_APP_USER_TOKEN) || null;
  }

  getDocSignToken(): string {
    return localStorage.getItem(AppConstants.STOR_DOCUSIGN_TOKEN) || null;
  }
  getCognitoToken(): string {
    return localStorage.getItem(AppConstants.STOR_COGNITO_TOKEN) || null;
  }

  getListener(): Observable<boolean> {
    return this.loginEmitter;
  }

  getResetUserAccessToken(recoveryEmail: RecoveryEmailRequest) {
    return this.http.get<any>(back(BackEndpoints.ResetUserAccessToken, recoveryEmail.cpf, recoveryEmail.registration));
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem(AppConstants.STOR_USER));
  }

  getUserData() {
    return this.http.get<any>(back(BackEndpoints.CustomerData));
  }

  loginMicrosservice(login: BaseUserCredentials) {
    const params = this.generateAuthParams(login);
    return this.getIp()
      .pipe(
        tap(ip => params.ip = ip),
        mergeMap(() => {
          return this.apollo.use('second').mutate({
            mutation: AppMicrosservicesGraphql.mutationAuth,
            variables: {
              params,
            },
          }).pipe(
            tap((res: any) => {
              if (!res.data) {
                throw res.errors;
              }
            }),
          );
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  finalizeAuthentication(loginMicroServiceResponse: any, login: BaseUserCredentials, urlRedirectAfterLogin?: string) {
    return of(loginMicroServiceResponse).pipe(
      mergeMap(userAuth => {
        this.saveToken(userAuth.data.authenticateUser);

        return userAuth.data ? this.getUserData() : of(null);
      }),
      map((data: any) => {
        localStorage.setItem(AppConstants.STOR_APP_USER_REGISTRATION, data.userChannel.registration);
        this.saveLastLoginEmail(login.email);

        return this.mapUserData(data, this.generateAuthParams(login));
      }),
      map((user: User) => {
        this.setUser(user);
        this.loginEmitter.emit(true);
      }),
      tap(() => {
        if (urlRedirectAfterLogin) {
          this.router.navigateByUrl(decodeURIComponent(urlRedirectAfterLogin));
        } else {
          this.router.navigate(['/cliente']);
        }
      }),
    );
  }

  logout(next = location.pathname) {
    if (this.checkAuth()) {
      localStorage.removeItem(AppConstants.STOR_USER);
      localStorage.removeItem(AppConstants.STOR_APP_USER_TOKEN);
    }

    if (next) {
      this.router.navigate(['/cliente/login'], { queryParams: { next } });
    } else {
      this.router.navigate(['/login']);
    }
    this.loginEmitter.emit(false);
  }

  logoutAndStay() {
    if (this.checkAuth()) {
      localStorage.removeItem(AppConstants.STOR_USER);
      localStorage.removeItem(AppConstants.STOR_APP_USER_TOKEN);
    }

    this.loginEmitter.emit(false);
  }

  registerNewAccess(noUnidade: number, newAccessData: NewAccessRequest) {
    const cleanCPF = newAccessData.cpfCliente.replace(/[.-]/g, '');
    newAccessData.cpfCliente = cleanCPF;

    return this.http.post<any>(
      back(BackEndpoints.NewAccess, noUnidade.toString()),
      newAccessData,
    );
  }

  resetUserAccess(resetUserAccess: ResetUserAccessRequest, resetToken: string) {
    return this.http.post<any>(
      back(BackEndpoints.ResetUserAccess, resetToken),
      {
        email: resetUserAccess.email,
        confirmacaoemail: resetUserAccess.confirmEmail,
        senha: resetUserAccess.password,
        confirmacaosenha: resetUserAccess.confirmPassword,
      },
    );
  }

  searchEmail(recoveryEmail: RecoveryEmailRequest) {
    return this.http.post<any>(sur(SurEndpoints.UrlRecoveryEmail), recoveryEmail);
  }

  setUser(user: User) {
    localStorage.setItem(AppConstants.STOR_USER, JSON.stringify(user));
    this.saveLastLoginEmail(user.login);
  }

  updateUserEmail(email: string) {
    const user = this.getUser();
    user.login = email;
    this.setUser(user);
  }

  validateCpf(cpf: string) {
    return this.http.get<any>(back(BackEndpoints.ValidateCPF, FormatUtils.getCPFWithoutSpecialCharacters(cpf)));
  }

  // Valida o token para cadastro de estrangeiro e menor
  validateTokenForeignMinor(token: string) {
    return this.http.get<any>(back(BackEndpoints.ValidateTokenForeignMinor, token));
  }

  /** Save in `local storage` the last e-mail used to login. */
  saveLastLoginEmail(email: string) {
    localStorage.setItem(AppConstants.STOR_LAST_LOGIN, email);
  }

  /** Save in `local storage` the actual gym unit user is logged in */
  saveLastUserMainGymId(gymId: string) {
    localStorage.setItem(AppConstants.STOR_MAIN_GYM_ID, gymId);
  }

  private createFacebookUrl(facebookID: string) {
    return facebookID ? external(ExternalEndpoints.backFacebook, facebookID) : null;
  }

  private generateAuthParams(login: BaseUserCredentials) {
    return  {
      email: login.email,
      password: login.password,
      brand: this.brandService.getBrand().toUpperCase(),
      apiKey: '123',
      channelName: 'SITE',
      ip: ''
    };
  }

  private getIp(): Observable<string> {
    if (environment.production) {
      return this.http.get<any>('https://api.ipify.org?format=json')
        .pipe(
          map(json => json.ip),
        );
    } else {
      return of('177.35.42.12');
    }
  }

  private mapUserData(data: any, params: any) {
    return {
      id: data.customer?.id,
      login: params.email,
      maingym: data.gymUnit.id,
      name: data.customer?.reducedName,
      avatar: this.createFacebookUrl(''),
      fullname: data.customer?.name,
      gender: 0,
      birthdate: data.customer?.birthdate
    };
  }

  private saveToken(authenticateUser: any) {
    // token do nosso backend
    localStorage.setItem(AppConstants.STOR_APP_USER_TOKEN, authenticateUser.tokenUser);
  }
}
