export interface ErrorMessage {
  code: string;
  message: string;
  long?: string;
}

export interface MicroserviceErrorHighlight {
  errorType: string;
  title: string;
}

// tslint:disable:max-line-length
export const oldCanaisErrorMessages: ErrorMessage[] = [
  {
    code: 'Unknown',
    message: 'Erro inexperado. Tente novamente mais tarde',
  },
  {
    code: 'Bad Request',
    message: 'Servidor está atualmente indisponível. Tente novamente mais tarde',
  },
  {
    code: '4000000',
    message: 'Ocorreu um erro no servidor. Tente novamente mais tarde',
  },
  {
    code: '4030003',
    message: 'E-mail e/ou senha inválido(s)',
  },
  {
    code: '4000018',
    message: 'Senha em branco',
  },
  {
    code: '4000020',
    message: 'E-mail em branco',
  },
  {
    code: '4120021',
    message: 'O plano selecionado não está disponível para essa faixa etária',
  },
  {
    code: '4120049',
    message: 'Contrato não está ativo',
    long: 'A situação desse contrato impossibilita o cancelamento pelo site.\nPor favor, dirija-se à sua Academia para solicitar o cancelamento do seu plano.',
  },
  {
    code: '4120053',
    message: 'Este contrato não pode ser cancelado pelo site',
    long: 'Nosso sistema identificou que o contrato selecionado foi originado e/ou alterado na Academia. O cancelamento online está disponível apenas para clientes que realizaram sua matrícula pelo site Bodytech e que não tiveram nenhuma alteração contratual implementada (desconto, renovação, alteração de forma de pagamento etc)\n\nPor favor, dirija-se à sua Academia para solicitar o cancelamento do seu plano. Nossa equipe estará à sua disposição para lhe atender.',
  },
];

export enum MicroserviceErrorEnum {
  ApiValidationError = 1,
  ApiNumberOfFieldsError,
  ApiInvalidAuthenticationTokenError,
  ApiUserNotFoundError,
  ApiUserNotRegisteredError,
  ApiUpdateUserError,
  ApiHashTokenNotFoundError,
  ApiGenerateHashError,
  ApiInvalidHashError,
  ApiTokenTimestampError,
  ApiHashExpiredError,
  ApiParseSnsMessageBodyError,
  ApiNotDataError,
  ApiInvalidDataError,
}

export const MicroserviceErrorMessage = {
  400: 'Não foi possível encontrar um cliente com os dados informados',
};

export const MicroserviceErrorsToHighlight: MicroserviceErrorHighlight[] = [
  {
    errorType: 'ApiFindUserMaxTriesExceededError',
    title: 'Acesso Bloqueado',
  },
];
