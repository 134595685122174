<div class="step-container d-flex align-items-center justify-content-center">
  <ng-container *ngFor="let step of steps; let index = index; let last = last">
    <div id="step-{{index}}" (click)="selectClick(index)"
      class="d-inline-block circle" [appClickable]="index < currentStep"
      [class.full]="index <= currentStep">
      <div class="label title-md font-weight-normal position-absolute">{{ step }}</div>
      <div class="bullet"></div>
    </div>

    <div *ngIf="!last" [style.width.%]="100 / steps.length"
      class="connect" [class.full]="index < currentStep"></div>
  </ng-container>
</div>
