import {
    Component,
    OnInit,
    OnChanges,
    Input,
    forwardRef,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from '@models';

function _getValue(item: SelectItem, key: string): any | undefined {
    return item && key ? item[key] : item;
}

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectComponent),
        multi: true,
    }],
})
export class SelectComponent implements OnInit, OnChanges, ControlValueAccessor {
    value: any;
    _disabled = false;
    _highlighted: SelectItem;
    _textSearch = '';
    selectedClearable = false;

    @Input() name: string;
    @Input() list: SelectItem[] = [];
    @Input() key = 'id';
    @Input() textForUndefined = 'Selecione uma opção';
    @Input() textForDisabledUndefined = 'Selecione uma opção';
    @Input() enableFilter = false;
    @Input() searchable = true;
    @Input() isOpen: boolean = null;

    @Output() focus: EventEmitter<{}> = new EventEmitter();

    ngOnInit() {
        if (!this.name) {
            this.name = 'select_' + Math.random().toString().substr(2);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        setTimeout(() => {
            if (changes.list && (
                !this.list ||
                ( this.list && this.list.every(i => this.value !== _getValue(i, this.key)) )
            )) {
                this.selectObj(null);
            }
        }, 0);
    }

    onChange = (_: any) => { };
    onTouched = () => { };

    onBlur = () => {};

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: (value: any) => any): void { this.onChange = fn; }
    registerOnTouched(fn: () => any): void { this.onTouched = fn; }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

    selectObj(obj: SelectItem) {
        this.value = _getValue(obj, this.key);
        this.onChange(this.value);
    }

}
