import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ModalType, SelectItem, AlertMessage, MessageMap, ModalTeacherSelectObject, User } from '@models';
import { OnlineClassTeacher, OnlineClassDateAvailableToSchedule } from '@models';
import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@services/header.service';
import { ModalService } from '@services/modal.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormUtil } from '@utils/form-util';
import { DatePipe } from '@angular/common';
import { AppConstants } from '@utils/app-constants';
import { LoadingService } from '@services/loading.service';
import { AlertMessageService } from '@services/alert-message.service';
import { MyOnlineClassSchedulesService } from '@services/my-online-class-schedules.service';
import { CapitalizePipe } from '@pipes';
import { OnlineClassRequest } from '@models/requests/online-class-request.model';
import { BrandService } from '@services/brand.service';
import { AuthService } from '@services';
import { StringUtil } from '@utils/string-util';

@Component({
  selector: 'app-online-class-schedules-create',
  templateUrl: './online-class-schedules-create.component.html',
  styleUrls: [
    '../../area-user.component.scss',
    './online-class-schedules-create.component.scss',
  ],
  providers: [ CapitalizePipe ],
})
export class OnlineClassSchedulesCreateComponent implements OnInit, AfterViewInit {


  user: User;

  form: FormGroup;

  config: BasicHeaderConfig;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  teachers: OnlineClassTeacher[];
  selectedTeacher: OnlineClassTeacher;

  availableSchedules: OnlineClassDateAvailableToSchedule[];
  dates: SelectItem<any>[];
  hours: SelectItem<any>[];

  featureName = 'Home';
  dateFormat = 'dd/MM/yyyy';

  userRegistration: number;

  userAvailabilityToSchedule: any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly modalService: ModalService,
    private readonly myOnlineClassSchedulesService: MyOnlineClassSchedulesService,
    private readonly datePipe: DatePipe,
    private readonly capitalizePipe: CapitalizePipe,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
    private readonly router: Router,
    private readonly brandService: BrandService,
    private readonly authService: AuthService,
    private readonly myOnlineScheduleService: MyOnlineClassSchedulesService,
  ) {
    this.config = this.activatedRoute.snapshot.data.config.training;
    this.availableSchedules = this.activatedRoute.snapshot.data.availableHours;
    this.availableSchedules.sort((a, b) => new Date(a.dateToSchedule).getTime() - new Date(b.dateToSchedule).getTime());
    this.availableSchedules.forEach(schedule => {
      schedule.availableHours.sort((a, b) => StringUtil.strcmp(this.formatHour(a), this.formatHour(b)));
    });
  }

  ngOnInit() {

    this.userRegistration = +localStorage.getItem(
      AppConstants.STOR_APP_USER_REGISTRATION,
    );

    this.getUserAvailabilityToSchedule();

    this.user = this.authService.getUser();
    this.form = this.formBuilder.group({
      date: new FormControl({ value: null }, Validators.required),
      hour: new FormControl({ value: null, disabled: true }, Validators.required),
      teacher: new FormControl({ value: null, disabled: true }, Validators.required),
    });
    this.featureName = (this.brandService.getBrand() === 'bt') ? 'Bodytech Home' : 'Fórmula Home';
    this.updateAvailableDates();
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  updateAvailableDates() {
    this.dates = this.availableSchedules
      .map((schedule: any) => {
        return {
          id: schedule.dateToSchedule,
          text: this.datePipe.transform(schedule.dateToSchedule, this.dateFormat),
        };
      });

    if (this.dates.length) {
      this.form.get('date').enable();
    } else {
      this.form.get('date').disable();
      this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.AGENDAMENTO_INDISPONIVEL), ['academia']);
    }
  }

  updateAvailableHours() {
    const schedule = this.availableSchedules.find(
      _schedule => _schedule.dateToSchedule === this.form.get('date').value,
    );
    if (!schedule) { return; }

    this.hours = schedule.availableHours.map((hour: any, index: any) => {
      return {
        id: index,
        text: this.formatHour(hour),
        value: hour,
      };
    });

    this.form.get('hour').reset();
    if (this.hours.length) {
      this.form.get('hour').enable();
    } else {
      this.form.get('hour').disable();
      this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.AGENDAMENTO_INDISPONIVEL), ['data']);
    }

    this.form.get('teacher').disable();
    this.form.get('teacher').reset();
  }

  updateAvailableTeachers() {
    const hours = this.hours;
    if (!this.form.get('hour').valid) { return; }

    const formValue = this.form.value;
    const date = formValue.date;
    const hour = hours[formValue.hour].value.id;
    this.form.get('teacher').reset();
    this.selectedTeacher = null;

    this.myOnlineClassSchedulesService.listTeachersAvaliable(date, hour)
      .subscribe(teachers => {
        if (this.form.get('hour').valid && teachers) {
          this.teachers = teachers;
          this.form.get('teacher').enable();
        } else {
          this.form.get('teacher').disable();
        }
      });
  }

  showTeacherSelectModal() {
    this.modalService.show({
      type: ModalType.teacherSelect,
      title: 'Selecione um professor',
      message: '',
      confirmCallback: (data: OnlineClassTeacher) => {
        this.selectedTeacher = data;
        this.form.get('teacher').setValue(`${this.capitalizePipe.transform(data.teacher.name)} - ${data.totalSimultaneousClientsText}`);
      },
      teachers: this.teachers,
      selected: this.selectedTeacher ? this.selectedTeacher.teacher.id : null,
    } as ModalTeacherSelectObject);
  }

  submit() {
    FormUtil.touchForm(this.form);

    if (this.form.valid) {
      const formValue = this.form.value;
      const schedule: OnlineClassRequest = {
        teacherId: this.selectedTeacher.teacher.id,
        availableHourId: this.hours[formValue.hour].value.id,
        scheduledDate: formValue.date,
        userRegistration: this.userRegistration,
      };

      const obj = this.hours[formValue.hour].value;
      obj.scheduledDate = schedule.scheduledDate;
      obj.teacher = this.selectedTeacher.teacher.name;

      this.modalService.show({
        type: ModalType.confirm,
        title: 'CONFIRMAR AGENDAMENTO?',
        message: `
          <p class="text-center"><strong>Data:</strong> ${this.datePipe.transform(schedule.scheduledDate, this.dateFormat)}</p>
          <p class="text-center"><strong>Horário:</strong> ${this.hours[formValue.hour].text}</p>
          <p class="text-center"><strong>Professor:</strong>
            ${this.capitalizePipe.transform(this.selectedTeacher.teacher.name)}
          </p>
          <p class="text-center"><strong>Alunos por aula:</strong> ${this.selectedTeacher.totalSimultaneousClients > 1 ? 'até' : ''}
          ${this.selectedTeacher.totalSimultaneousClients} aluno${this.selectedTeacher.totalSimultaneousClients > 1 ? 's' : ''}</p>
        `,
        cancelButton: 'Não',
        confirmButton: 'Sim',
        confirmCallback: () => {
          this.loadingService.startLoading();
          this.myOnlineClassSchedulesService.createScheduling(schedule)
            .subscribe(
              result => {
                if (result.errors.length) {
                  result.errors.forEach((error: any) => {
                    this.alertMessageService.showToastr(AlertMessage.error(error.message));
                  });
                  this.loadingService.stopLoading();
                  return;
                }
                this.sendDataLayer(+this.userRegistration, formValue.date);
                this.showSuccessModal();
              },
              err => {
                this.alertMessageService.showToastr(err);
                this.loadingService.stopLoading();
              },
              () => this.loadingService.stopLoading(),
            );
        },
      });
    }
  }

  private showSuccessModal() {
    this.router.navigate(['/cliente/treinos']);
    this.modalService.show({
      type: ModalType.confirm,
      title: 'AGENDAMENTO REALIZADO!',
      message: `<div class="text-center">
      No dia e horário agendados compareça à sala virtual no link disponibilizado na aba Minhas Aulas.
      </div>
      `,
    });
  }

  private formatHour(hour: any) {
    return hour.formattedInitialTime + ' - ' + hour.formattedFinalTime;
  }


  private sendDataLayer(userRegistration: number, scheduledDate: string) {
    const dataLayer = {
      teacher: {
        id: this.selectedTeacher.teacher.id,
        name: this.selectedTeacher.teacher.name,
      },
      scheduledDate,
      user: {
        id: this.user.id,
        registration: userRegistration,
        name: this.user.fullname,
        email: this.user.login,
      },
    };
    window.dataLayer.push(dataLayer);
  }

  private getUserAvailabilityToSchedule() {
    this.myOnlineScheduleService.getUserAvailabilityToSchedule(this.userRegistration).subscribe(res => {
      if (!res.canSchedule) {
        this.router.navigate(['cliente/treinos']);
      }
      this.userAvailabilityToSchedule = res.userAvailabilityToSchedule;
    });
  }
}
