<ng-template #header>
  <app-header-static-image [headerObj]="config.header"></app-header-static-image>
</ng-template>
<div class="main-wrapper container">

  <div class="card border-bottom-0 pb-0 row-on-xs no-side-border-xs">
    {{ user?.gender == 2 ? 'Bem-vinda, ' : 'Bem-vindo, '}}
    <div class="title name">
      {{ user?.fullname }}
    </div>
  </div>
  <ng-container *ngIf="!loading; else loader">
    <div class="card border-top-0 pt-0 row-on-xs no-side-border-xs" *ngIf="trainings?.length; else error">
      <div>
        <div class="title title-section">Meus treinos</div>
        <div id="training-list" class="list-group list-group-flush">
          <div class="accordion">
            <ng-container *ngFor="let training of trainings; let index = index">

              <div appClickable appDropdownArrows
                class="list-group-item d-flex flex-column flex-md-row justify-content-between"
                [class.collapsed]="!collapse.expanded"
                (click)="activeTraining = (activeTraining === index) ? -1 : index; scrollToElement($event.target, index); updatePage()"
                [attr.aria-expanded]="collapse.expanded" attr.aria-controls="train-{{ index }}">
                <span class="title">Treino {{ training.letter }}</span>
              </div>

              <div id="train-{{ index }}" #collapse app-collapse [expanded]="activeTraining === index"
                class="list-group-item pt-0 training-container">
                <div class="row">
                  <div class="col-12 col-md-6 info-cell">
                    <span class="title">Unidade</span>
                    <div>{{ training.gym }}</div>
                  </div>
                  <div class="col-12 col-md-6 info-cell no-padding-left-md-up">
                    <span class="title">Ficha de treino</span>
                    <div>{{ training.start | date:'shortDate':'':locale }} a
                      {{ training.end | date:'shortDate':'':locale }}</div>
                  </div>
                  <div class="col-6 col-md-3 info-cell">
                    <span class="title">Sequência</span>
                    <div>{{ training.sequence }}</div>
                  </div>
                  <div class="col-6 col-md-3 info-cell no-padding-left-sm-down no-padding-left-md-up">
                    <span class="title">Frequência</span>
                    <div>{{ training.frequency }}</div>
                  </div>
                  <div class="col-12 col-md-6 info-cell no-padding-left-md-up">
                    <div class="title row no-gutters">
                      <div class="col">
                        <span>Progresso</span>
                      </div>
                      <div class="col-auto">
                        <span class="text-primary">{{ training.progress.current }}/{{ training.progress.total }}</span>
                      </div>
                    </div>
                    <div class="progress">
                      <div class="progress-bar" role="progressbar"
                        [style.width.%]="100*training.progress.current/training.progress.total" aria-valuemin="0"
                        [attr.aria-valuenow]="training.progress.current" [attr.aria-valuemax]="training.progress.total">
                      </div>
                    </div>
                  </div>

                  <div class="table-container">
                    <app-training-table [jsonData]="training.trainings"></app-training-table>
                  </div>

                  <div class="table-container">
                    <app-ergometer-table [jsonData]="training.ergometer"></app-ergometer-table>
                  </div>

                  <div class="row w-100">
                    <div class="col-12 observation-cell">
                      <span class="title">Observações</span>
                      <div>{{ training.observation }}</div>
                    </div>
                    <div class="col-12 col-md-6 bottom-info-cell">
                      <span class="title">Alongamento</span>
                      <div>{{ training.streching }}</div>
                    </div>
                    <div class="col-12 col-md-6 bottom-info-cell">
                      <span class="title">Professor</span>
                      <div>{{ training.teacher }}</div>
                    </div>
                  </div>
                </div>
                <div class="btn-container d-flex justify-content-around d-print-none">
                  <button id="print-button" class="btn btn-primary btn-confirm" (click)="callPrint()">Imprimir</button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #error>
    <app-empty-card icon="/assets/icons_custom/ic-empty-list.svg" altIcon="Lista vazia" hideBorderTop="true"
      [message]="emptyMessage">
      <ng-template #cardHeader>
        <div class="name-container">
          <div class="title title-section mt-0">Meus treinos</div>
        </div>
      </ng-template>
    </app-empty-card>
  </ng-template>

  <ng-template #loader>
    <div class="card border-top-0 row-on-xs no-side-border-xs">
      <div class="title text-center">
        <i class="fas fa-spinner fa-pulse"></i> Carregando
      </div>
    </div>
  </ng-template>

</div>
