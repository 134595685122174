import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BucketModule } from '@pipes/bucket/bucket.module';

import { WidthFromWindowModule } from 'app/component-modules/open-modal-padding/width-from-window/width-from-window.module';

import { HeaderStaticImageComponent } from './header-static-image.component';


@NgModule({
  declarations: [
    HeaderStaticImageComponent,
  ],
  imports: [
    CommonModule,

    BucketModule,
    WidthFromWindowModule,
  ],
  exports: [
    HeaderStaticImageComponent,
  ]
})
export class HeaderStaticImageModule { }
