import { Component, Input } from '@angular/core';
import { HomeConcept } from '@models';

@Component({
  selector: 'app-main-concept',
  templateUrl: './main-concept.component.html',
  styleUrls: ['./main-concept.component.scss'],
})
export class MainConceptComponent {

  @Input() data: HomeConcept;
  public loadImage = false;

}
