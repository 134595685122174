import { AppConstants } from '@utils/app-constants';
import { Component, Input, OnInit } from '@angular/core';

import { ModalService } from '@services/modal.service';

import { ModalType, PreSalePurchase } from '@models';

@Component({
  selector: 'app-presale-purchase-item',
  templateUrl: './presale-purchase-item.component.html',
  styleUrls: ['../purchases.component.scss'],
})
export class PresalePurchaseItemComponent implements OnInit {
  readonly locale = AppConstants.LOCALE;

  @Input() item: PreSalePurchase[];

  constructor(
    private readonly modalService: ModalService,
  ) { }

  ngOnInit() {
  }

  detail(preSale: PreSalePurchase) {

    this.modalService.show({
      type: ModalType.presaleDetails,
      title: 'Detalhes da Pré-Venda',
      message: '',
      data: {
        preSalePurchase: preSale,
      },
    });
  }

}
