import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ModalType, OnlineClassSchedule, AlertMessage } from '@models';
import { AppConstants } from '@utils/app-constants';
import { ModalService, LoadingService, AlertMessageService } from '@services';
import { DatePipe } from '@angular/common';
import { MyOnlineClassSchedulesService } from '@services/my-online-class-schedules.service';
import { BrandService } from '@services/brand.service';
import { CapitalizePipe } from '@pipes';

@Component({
  selector: 'app-online-class-schedules-list',
  templateUrl: './online-class-schedules-list.component.html',
  styleUrls: ['./online-class-schedules-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ CapitalizePipe ],
})
export class OnlineClassSchedulesListComponent implements OnInit {

  locale = AppConstants.LOCALE;

  schedules: OnlineClassSchedule[];

  hasSchedule: boolean;

  userRegistration = +localStorage.getItem(AppConstants.STOR_APP_USER_REGISTRATION);

  featureName = 'Home';

  hasSchedulesLeft = false;

  userAvailabilityToSchedule: any;
  btnDisabled = true;

  constructor(
    private readonly myOnlineScheduleService: MyOnlineClassSchedulesService,
    private readonly alertMessageService: AlertMessageService,
    private readonly modalService: ModalService,
    private readonly loadingService: LoadingService,
    private readonly brandService: BrandService,
    private readonly datePipe: DatePipe,
    private readonly capitalizePipe: CapitalizePipe,
    private readonly ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.myOnlineScheduleService.getAllOnlineClassSchedulingByClient(this.userRegistration)
      .subscribe(schedules => {
        this.schedules = schedules;
        this.ref.markForCheck();
      },
      () => this.ref.markForCheck());

    this.getUserAvailabilityToSchedule();

    this.featureName = (this.brandService.getBrand() === 'bt') ? 'Bodytech Home' : 'Fórmula Home';
  }

  onCancelClick(obj: OnlineClassSchedule) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'CANCELAR AGENDAMENTO?',
      message: `
        <p class="text-center"><strong>Data:</strong> ${this.datePipe.transform(obj.appointmentDate, 'dd/MM/yyyy')}</p>
        <p class="text-center"><strong>Horário:</strong> ${obj.teacherAvailableHour.availableHour.formattedInitialTime} -
        ${obj.teacherAvailableHour.availableHour.formattedFinalTime}</p>
        <p class="text-center"><strong>Professor:</strong>
        ${this.capitalizePipe.transform(obj.teacherAvailableHour.teacher.name)}
        </p>
        <p class="text-center"><strong>Alunos por aula:</strong>
        ${obj.teacherAvailableHour.totalSimultaneousClients > 1 ? 'até' : ''}
        ${obj.teacherAvailableHour.totalSimultaneousClients}
        aluno${obj.teacherAvailableHour.totalSimultaneousClients > 1 ? 's' : ''}</p>
      `,
      cancelButton: 'Não',
      confirmButton: 'Sim',
      data: obj,
      confirmCallback: (data: OnlineClassSchedule) => {
        this.loadingService.startLoading();
        this.myOnlineScheduleService.cancelSchedule(data.id, this.userRegistration)
          .subscribe(
            result => {
              if (result.errors.length) {
                result.errors.forEach((error: any) => {
                  this.alertMessageService.showToastr(AlertMessage.error(error.message));
                });
                this.ref.markForCheck();
                return;
              }
              setTimeout(() => {
                this.showSuccessModal();
                this.getUserAvailabilityToSchedule();
              }, 0);
            },
            err => {
              this.alertMessageService.showToastr(err);
              this.ref.markForCheck();
            },
            () => this.loadingService.stopLoading(),
          );
      },
    });
  }

  private showSuccessModal() {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'AGENDAMENTO CANCELADO!',
      message: `<div class="text-center">Seu agendamento foi cancelado.<div>`,
      dismissCallback: () => this.myOnlineScheduleService.getAllOnlineClassSchedulingByClient(this.userRegistration)
      .subscribe(schedules => {
        this.schedules = schedules;
        this.ref.markForCheck();
      },
      () => this.ref.markForCheck()),
    });
  }

  private getUserAvailabilityToSchedule() {
    this.myOnlineScheduleService.getUserAvailabilityToSchedule(this.userRegistration)
    .subscribe((res) => {
      this.btnDisabled = !res.canSchedule;
      this.userAvailabilityToSchedule = res.userAvailabilityToSchedule;
      this.ref.markForCheck();
    },
    () => this.ref.markForCheck());
  }
}
