<div class="text-center">
  <div *ngIf="config.agendaFilter.howTo" class="howto">{{ config.agendaFilter.howTo }}</div>
</div>
<div class="border card no-side-border-xs" [class.formula-only]="brand === 'fr'">
  <div class="row no-gutters filter tabs">
    <div
      id="agenda-filter-place"
      *ngIf="showPlace"
      appClickable
      (click)="showGymSelectModal()"
      appDropdownArrows
      class="col title-md font-weight-normal collapsed border-bottom border-right p-0"
    >
      <img alt="Filtro por idade" class="filter-icon" src="assets/icons/ic-filter-place.svg">
      <span class="d-none d-lg-block">{{ gym?.portalName || 'Academia' }}</span>
    </div>
    <div
      id="agenda-filter-age"
      *ngIf="brand === 'bt' && !hideActivities"
      (click)="showAgeSelectModal()"
      appClickable
      appDropdownArrows
      class="col title-md font-weight-normal collapsed border-bottom border-right p-0"
    >
      <img alt="Filtro por idade" class="filter-icon" src="assets/icons/ic-filter-age.svg">
      <span class="d-none d-lg-block">Faixa etária</span>
    </div>
    <div
      id="agenda-filter-activities"
      *ngIf="!hideActivities"
      appDropdownArrows
      appClickable="gym"
      (click)="gym && showActivitiesSelectModal()"
      popoverFade="Selecione a academia antes de selecionar as atividades"
      triggers=""
      [popoverDisabled]="gym"
      [class.text-secondary]="!gym"
      [class.fade-icon]="!gym"
      class="col title-md font-weight-normal collapsed border-bottom border-right p-0"
    >
      <img alt="Filtro por tipo de atividade" class="filter-icon" src="assets/icons/ic-filter-exercise.svg">
      <span class="d-lg-block" [class.d-none]="brand === 'bt'">Atividades</span>
    </div>
    <div
      id="gym-filter-clear"
      *ngIf="!hideActivities"
      appClickable
      (click)="clearFilter()"
      class="col title-md collapsed p-0 align-items-center border-bottom d-flex justify-content-center"
      [class.col-3]="brand === 'fr'"
      [ngClass]="activityTags.length || filter.ages.length ? 'text-primary' : 'text-secondary'"
    >
      <img alt="Limpar filtros" class="clear-icon" src="assets/icons_custom/ic-recycle.svg">
      <span class="d-none d-lg-block font-weight-normal">Limpar filtros</span>
    </div>
  </div>

  <div class="selected-activities-container">
    <div *ngIf="showPlace && gym" class="d-block d-lg-none show-gym-name">Pesquisando na unidade {{gym?.portalName}}</div>
    <div id="filter" #filtertags>
      <a
        *ngFor="let age of filter.ages"
        id="agenda-tag-age-{{age.id}}" class="text-light badge" appClickable
        (click)="removeFilter(filter.ages, age)"
      >
        <div class="title">{{ age.name }}</div>
        <img alt="Fechar" class="align-baseline" src="assets/icons/ic-close.svg">
      </a>

      <a
        *ngFor="let activity of activityTags"
        id="agenda-tag-activity-{{activity.id}}" class="text-light badge" appClickable
        [class.all]="activity.activityGroup.id < 0"
        (click)="removeFilter(filter.activities, activity)"
      >
        <div class="title">{{ activity.name }}</div>
        <img alt="Fechar" class="align-baseline" src="assets/icons/ic-close.svg">
      </a>
    </div>
  </div>
  <div class="bg-light row no-gutters justify-content-around border-top button-panel" [class.activities-selected]="activityTags?.length">
    <button
      id="agenda-search"
      *ngIf="!gymMigrationRO.loading; else loading"
      class="btn btn-primary btn-confirm"
      (click)="doSearch()"
    >
      {{ gym ? 'Exibir agenda' : 'Selecione uma academia' }}
    </button>
  </div>
</div>

<ng-template #loading>
  <app-loading-content></app-loading-content>
</ng-template>

<app-modal-gym-select
  [analyticsCategory]="analyticsCategory"
  [analyticsTag]="analyticsTag">
</app-modal-gym-select>

<app-modal-gym-age
  [analyticsCategory]="analyticsCategory"
  [analyticsTag]="analyticsTag">
</app-modal-gym-age>

<app-modal-gym-activity
  [analyticsCategory]="analyticsCategory"
  [analyticsTag]="analyticsTag">
</app-modal-gym-activity>
