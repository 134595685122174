import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'app/shared/shared.module';
import { WorkWithUsRoutingModule } from './work-with-us-routing';
import { WorkWithUsComponent } from './work-with-us.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    WorkWithUsRoutingModule,
  ],

  declarations: [WorkWithUsComponent],
})
export class WorkWithUsModule { }
