import { CurrencyPipe, Location, LocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationConfig } from '@models/configs';
import { AppConstants } from '@utils/app-constants';
import { FormatUtils } from '@utils/format-utils';
import { JSONUtil } from '@utils/json-util';
import { Subscription, throwError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CardPlanComponent } from './card-plan/card-plan.component';
import { FormAdditionalDataComponent } from './form-additional-data/form-additional-data.component';
import { FormPersonalDataComponent } from './form-personal-data/form-personal-data.component';
import { Brand, BrandService } from '@services/brand.service';
import { GeneratePasswordService } from '@services/generate-password.service';
import {
  AlertMessage,
  CouponInfo,
  GymRegistrationResponse,
  RegistrationRequest,
  User,
  CouponResponse,
  RegistrationSuccessRequest,
  MessageMap,
  MyContractResponse, ModalType, Gym, PaymentData, TypesOfProducts, Children
} from '@models';
import {
  AlertMessageService, AnalyticsService,
  AuthService, HeaderService, LoadingService,
  RegistrationService, UserService, MyContractService, ModalService, BTPassService, GymService, PolicyService
} from '@services';
import { MapRegistrationInfoService } from './map-registration-info.service';

declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [MapRegistrationInfoService]
})
export class RegistrationComponent implements OnInit, AfterViewInit {

  location: Location;

  config: RegistrationConfig;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;
  @ViewChild('target', { static: false }) targetComponent: ElementRef;

  @ViewChild('personal', { static: false }) personalForm: FormPersonalDataComponent;
  @ViewChild('additional', { static: false }) additionalForm: FormAdditionalDataComponent;
  @ViewChild('cardPlan', { static: false }) cardPlan: CardPlanComponent;

  subsResolve: Subscription;
  brand: Brand;

  steps = ['Dados pessoais', 'Dados adicionais'];
  errorMessage = '';

  currStep = new BehaviorSubject<number>(0);
  currStep$: Observable<number> = this.currStep.asObservable();
  currentStep = 0;
  retryAllSteps = false;

  flagCardOptions: any[];

  originalFlagOptions: any[];
  originalTotal: number;

  user: User;

  analyticsCategory = 'Vendas';
  analyticsLabel: string;

  formData: RegistrationRequest = {
    personal: {
      cpf: '',
      birthdate: '',
      newsletter: true,
    },
    additional: {
      address: { city: { id: null } },
      payment: {},
    },
  } as RegistrationRequest;

  routerParams: any;
  userBirthDate: string;

  redirectToMe: any;

  applyCoupon = true;
  isRegistration = true;
  isRenew = false;
  isRenewDCC = false;
  data: GymRegistrationResponse = {} as GymRegistrationResponse;

  coupon: CouponInfo;

  typesOfProducts = TypesOfProducts;
  typeOfProduct: TypesOfProducts = TypesOfProducts.Income;
  defaultParcelsOfIncome = 4;
  gymUnit: Gym;

  constructor(
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
    private readonly alertMessageService: AlertMessageService,
    private readonly brandService: BrandService,
    private readonly contractService: MyContractService,
    private readonly gymService: GymService,
    private readonly headerService: HeaderService,
    private readonly loadingService: LoadingService,
    private readonly registrationService: RegistrationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly generatePasswordService: GeneratePasswordService,
    private readonly userService: UserService,
    private readonly locationstrategy: LocationStrategy,
    private readonly modalService: ModalService,
    private readonly mapRegistrationInfoService: MapRegistrationInfoService,
    private readonly policyService: PolicyService,
    private readonly btPassService: BTPassService,
    location: Location,
  ) {
    this.typeOfProduct = this.router.url.includes('academia') ? TypesOfProducts.Plan : TypesOfProducts.Income;

    this.config = this.route.snapshot.data.config.registration;
    this.brand = this.brandService.getBrand();
    this.location = location;
    const routerParams = this.route.snapshot.params;
    this.changeUrl(routerParams);
    this.locationstrategy.onPopState(() => {
      if (this.typeOfProduct === TypesOfProducts.Plan) {
        history.pushState(null, null, `/academia/${this.routerParams.unitSlug}`);

      } else {
        history.pushState(null, null, `/bt-pass/`);
      }
      window.location.reload();
    });
  }

  ngOnInit() {
    this.errorMessage = '';

    this.redirectToMe = { next: this.router.url };

    this.user = this.authService.getUser();

    this.manipulateData();

    if (this.user) {
      this.setUserInfoIfIsLogged();
      if (this.typeOfProduct === TypesOfProducts.Plan) {
        this.initLoggedPurchase();
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  onParcelChange(parcelValue: number) {
    this.data.parcels = parcelValue;
  }

  prevStep() {
    this.changeToStep(this.currentStep - 1);
  }

  nextStep() {
    this.changeToStep(this.currentStep + 1);
  }

  updatedPassQuantity(quantity: number): void {
    this.data.quantity = quantity;

    if (this.currentStep === this.steps.length - 1) {
      if (this.user) {
        this.sendLoggedInitData()
        .pipe(
          finalize(() => this.loadingService.stopLoading()),
          tap((paymenthMethods) => {
            this.setPaymentMethodsInFlagCardOptions(paymenthMethods as PaymentData);
            this.cardPlan.checkCoupon();
          })
        )
        .subscribe();
      } else {
        this.sendPersonalData()
        .pipe(
          finalize(() => this.loadingService.stopLoading()),
          tap((paymenthMethods) => {
            this.setPaymentMethodsInFlagCardOptions(paymenthMethods);
            this.cardPlan.checkCoupon();
          })
        )
        .subscribe();
      }
    }

    /* If the quantity was changed during the purchase process, we have to call StartPurchase */
    /* and set shoppingCart, so to do this we need to set retryAllSteps as true */
    this.retryAllSteps = true;
  }

  updateParcels(coupon: CouponResponse) {
    if (coupon) {
      this.originalFlagOptions = JSON.parse(JSON.stringify(this.flagCardOptions));

      const currencyPipe: CurrencyPipe = new CurrencyPipe(AppConstants.LOCALE);

      this.originalTotal = this.data.value;
      this.data.value = coupon.total;

      this.flagCardOptions.forEach(element => {
        element.parcels.map((parc: any) => {
          const value = coupon.parcels.find(p => p.quantity === parc.parcel).value;

          parc.text =
            parc.parcel === 1
              ? `À vista - ${currencyPipe.transform(value, 'BRL')}`
              : `${parc.parcel}x de ${currencyPipe.transform(value, 'BRL')}`;
        });
      });
    } else {
      this.flagCardOptions = this.originalFlagOptions;
      this.data.value = this.originalTotal;
    }

    setTimeout(
      () => this.additionalForm.selectPaymentFlag(this.formData.additional.payment.cardFlag),
      0,
    );
  }

  private manipulateData() {
    this.data = this.route.snapshot.data.registration;

    this.analyticsLabel = `Formulário de vendas - ${this.data.gym}`;

    this.formData.additional.payment.parcels = this.data?.parcels;

    if (this.data && this.typeOfProduct === TypesOfProducts.Income) {
      this.data.gym = this.route.snapshot.data?.gymUnit;

      if (!this.data.terms) {
        this.getGymPolicyTerm(this.data.gym.id);
      }
    } else {
     if (this.data.coupon.id) {
      this.getCoupomDescription(this.data.coupon.id);
     }
     this.evaluateRenew();
     this.evaluateRenewDCC();
    }
  }

  private getGymPolicyTerm(gymId: number): void {
    let gymSlug = '';
    this.gymService.getGymById(gymId).pipe(switchMap((gym) => {
      gymSlug = gym.slug;
      return this.policyService.getTermByGymUnitSlugObservable(gymSlug);
    }))
    .subscribe((doc) => {
      this.data.terms = doc[gymSlug].url;
    });
  }

  private setUserInfoIfIsLogged() {
    if (!this.user.name) {
      this.steps = [];
      this.errorMessage = 'Dados indisponíveis.';
      return;
    }

    Object.assign(this.formData.personal, {
      name: this.user.name,
      email: this.user.login,
    });

    this.isRegistration = false;
    this.steps = ['Pagamento'];
    this.formData.additional.address = null;
    this.loadingService.startLoading();
    this.registrationService.setTotalSteps(3);
  }

  private initLoggedPurchase() {
    this.sendLoggedInitData()
    .pipe(
      mergeMap(result => this.cardPlan.getValuesFromOldCanais().pipe(map(() => result))),
      catchError(err => {
        if (this.typeOfProduct === TypesOfProducts.Plan) {
          this.router.navigate(['/academia', this.data.gymTag]);
        } else if (this.typeOfProduct === TypesOfProducts.Income) {
          this.router.navigate(['/bt-pass']);
        }

        return throwError(err);
      }),
      finalize(() => this.loadingService.stopLoading()),
    )
    .subscribe(resp => {
      this.tryApplyCoupon();
      this.setPaymentMethodsInFlagCardOptions(resp as PaymentData);
      setTimeout(() => this.additionalForm.init(), 0);
    });
  }

  private getCoupomDescription(couponId: number) {
    this.registrationService.getCoupomDescription(couponId).subscribe(data => {
      this.coupon = data;
      this.cardPlan.prefillCoupon(this.coupon.couponKey);
    });
  }

  private changeUrl(routerParams: any) {
    let baseUrl = '';

    switch (this.typeOfProduct) {
      case TypesOfProducts.Plan:
        baseUrl = `/academia/${routerParams.unitSlug}/compra/${routerParams.planSlug}/${routerParams.periodicitySlug}/`;
        break;
      case TypesOfProducts.Income:
        baseUrl = `/bt-pass/compra/${routerParams.gymUnitId}/${routerParams.additionalIncomeId}/`;
        break;
    }

    this.currStep$.subscribe(step => {
      if (step === 1) {
        this.location.go(baseUrl + 'dados-adicionais');
      } else if (step === 2) {
        this.location.go(baseUrl + 'compra-finalizada');
      }
    });
  }

  private evaluateRenew() {
    this.isRenew = this.router.url.endsWith(Children.RENEW);

    if (this.isRenew) {
      this.analyticsLabel = `Formulário de renovação - ${this.data.gym}`;
      this.analyticsCategory = 'Renovação';
      // Caso haja uma promoção, porém for uma renovação, a renovação deve prevalecer.
      this.data.gym.activePromotion = null;
      this.coupon = {
        couponKey: null,
        description: `Você pode alterar seu plano ou sua unidade antes de renovar!
                      Se quiser renovar com outro plano, <a href=\"/academia/${this.data.gym.slug}/matricule-se\">clique aqui</a>.
                      Se quiser mudar de unidade, <a href=\"/academias\">clique aqui</a>.`,
        name: 'É HORA DE MUDAR?',
        image: '/assets/icons_custom/promo.svg',
      };
    }
  }

  private evaluateRenewDCC() {
    this.isRenewDCC = this.router.url.endsWith(Children.RENEW_DCC);

    if (this.isRenewDCC) {
      this.contractService.getInfo().subscribe((json: MyContractResponse[]) => {
        const hasPlanFromContract = json.find(planContract =>
          planContract.planName.toUpperCase().startsWith(this.data.plan.toUpperCase())
          && planContract.deadline === 'Prazo Indeterminado' && planContract.gym.id === this.data.gymId
          && this.data.planDebitPlanId === null);

        if (!hasPlanFromContract) {
          this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.ATUALIZACAO_DCC_NAO_ACESSIVEL));
          this.router.navigate(['/academia', this.data.gym.slug]);
        }
      });
    }
  }

  private changeToStep(step: number) {
    this.formData.personal.cpf = FormatUtils.getCPFWithoutSpecialCharacters(this.formData.personal.cpf);

    if (this.birthDateHasChanged()) {
      const date = this.formData.personal.birthdate.split('/');
      this.userBirthDate = `${date[2]}-${date[1]}-${date[0]}`;
    }

    this.formData.personal.birthdate = this.formData.personal.birthdate.replace(/[\/]*/g, '');

    // Gera senha aleatóriamente
    this.formData.personal.password = this.generatePasswordService.generatePassword(10);
    this.formData.personal.passwordCheck = this.formData.personal.password;

    if (step < 0) {
      this.typeOfProduct === TypesOfProducts.Plan
        ? this.goCancel('Cancela venda', ['/academia', this.data.gymTag, 'matricule-se'])
        : this.goCancel('Cancel venda BT Pass', ['/bt-pass']);
    } else if (step < this.steps.length) {
      if (this.currentStep < step) {
        this.goForward();
      } else {
        this.goBack(step);
      }
    } else {
      this.goFinalizePurchase();
    }
  }

  private goCancel(trackEventAction: string, urlToNavigate: string[]) {
    this.analyticsService.trackEvent(
      trackEventAction,
      this.analyticsCategory,
      this.analyticsLabel
    );
    this.router.navigate(urlToNavigate);
  }

  private goBack(step: number) {
    this.analyticsService.trackEvent(
      `Volta ao passo ${step} - Botão voltar`,
      this.analyticsCategory,
      this.analyticsLabel,
    );
    this.currentStep = step;
    this.currStep.next(this.currentStep);
  }

  private goForward() {
    const bDay = this.parseDataFromNonSlashString(this.formData.personal.birthdate);
    if (bDay.getTime() > new Date().getTime()) {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.DATA_NASCIMENTO_INVALIDA));
      return;
    }

    if (!this.getCurrentForm().isValid()) {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
      return;
    }

    this.registrationService.setTotalSteps(3);
    this.loadingService.startLoading();
    this.sendPersonalData()
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(resp => {
        this.currentStep++;
        this.currStep.next(this.currentStep);
        this.retryAllSteps = false;

        this.analyticsService.trackEvent(
          `Avança ao passo ${this.currentStep}`,
          this.analyticsCategory,
          this.analyticsLabel,
        );
        this.tryApplyCoupon();
        this.setPaymentMethodsInFlagCardOptions(resp);
        setTimeout(() => this.additionalForm.init(), 0);
      });
  }

  private parseDataFromNonSlashString(dateString: string): Date {
    return new Date(+dateString.slice(4, 8), +dateString.slice(2, 4) - 1, +dateString.slice(0, 2));
  }

  private birthDateHasChanged(): boolean {
    return this.formData.personal.birthdate.includes('/');
  }

  private goFinalizePurchase() {
    if (!this.getCurrentForm().isValid()) {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CONFIRA_DADOS_FORNECIDOS));
      return;
    }

    this.loadingService.startLoading();
    let request;

    if (!this.user) {
      if (this.retryAllSteps) {
        this.registrationService.setTotalSteps(6);
        request = this.sendPersonalData().pipe(
          mergeMap(() => this.reapplayCoupon()),
          mergeMap(() => this.sendAddressData()),
          mergeMap(() => this.finalizeData()),
        );
      } else {
        this.registrationService.setTotalSteps(3);
        request = this.reapplayCoupon().pipe(
          mergeMap(() => this.sendAddressData()),
          mergeMap(() => this.finalizeData()),
        );
        this.retryAllSteps = true;
      }
    } else {
      this.registrationService.setTotalSteps(2);
      request = this.loggedPurchase();
    }

    request
      .pipe(
        finalize(() => this.loadingService.stopLoading()),
      )
      .subscribe(
        payment => {
          this.mapRegistrationInfoService.sendDataLayer(payment, this.data, this.isRenew);

          if (this.isRegistration) {
            this.sendUserPassword();
            this.createUserMicrosservice(payment);
          }

          this.analyticsService.trackEvent(
            this.isRenew ? 'Finaliza renovação' : 'Finaliza venda',
            this.analyticsCategory,
            this.analyticsLabel,
          );

          this.fillPaymentOnSuccessForm();
          this.currentStep++;
          this.currStep.next(this.currentStep);
          const purchaseInfo = JSONUtil.get(payment, 'ResumoCompra.ResumoCompraDcc')
            || JSONUtil.get(payment, 'ResumoCompra.ResumoCompraDemaisPeriodicidades')
            || JSONUtil.get(payment, 'ResumoCompra.ResumoCompraReceitaAdicional');
          this.formData.contractGymUnit = purchaseInfo.IdUnidade;
          this.formData.orderNumber = JSONUtil.get(payment, 'CodigoCompra');
          this.formData.contractCode = JSONUtil.get(payment, 'CodigoContrato') || JSONUtil.get(payment, 'CodigoCompraReceitaAdicional');
          this.formData.useDocuSign = JSONUtil.get(payment, 'UnidadeUtilizaDocusign');
        },
        err => this.alertMessageService.showToastr(err),
      );
  }

  private createUserMicrosservice(userData: any) {
    const params = this.mapRegistrationInfoService.mapUserDataToUserCreateUserMicrosservice(
      this.formData,
      this.data,
      userData,
      this.userBirthDate
    );

    this.userService.createUser(
      params,
    ).subscribe(
      () => { },
      () => this.alertMessageService.showToastr(AlertMessage.error(MessageMap.PROBLEMA_CRIAR_USUARIO_MICROSSERVICO))
    );
  }

  private sendUserPassword() {
    this.userService
      .sendRandomPasswordEmail({
        email: this.formData.personal.email,
        password: this.formData.personal.password,
      } as RegistrationSuccessRequest)
      .subscribe();
  }

  private fillPaymentOnSuccessForm() {
    const flag = this.flagCardOptions.find(i => i.id === +this.formData.additional.payment.cardFlag);

    this.formData.additional.payment.paymentCardText = `Cartão de Crédito ${flag.text}`;
  }

  private reapplayCoupon() {
    return this.cardPlan.applyCoupon();
  }

  private tryApplyCoupon() {
    if (this.applyCoupon && this.coupon) {
      this.applyCoupon = false;
      this.cardPlan.checkCoupon(this.coupon.couponKey);
    }
  }

  private setPaymentMethodsInFlagCardOptions(paymentMethods: PaymentData): void {
    this.flagCardOptions = paymentMethods.DadosPagamento.FormasDePagamento.map(
      this.mapRegistrationInfoService.mapFlagOptionsFromOldCanais,
    );
  }

  private sendPersonalData() {
    /* STEP 1 : Dados Pessoais | novo cliente (não logado) */
    const data = this.mapRegistrationInfoService.mapPersonalDataToOldCanais(this.formData, this.data, this.typeOfProduct);
    this.data.purchaseId = this.user ? null : this.formData.personal.cpf;

    this.loadingService.startLoading();

    switch (this.typeOfProduct) {
      case TypesOfProducts.Plan:
        return this.registrationService
        .startPurchase(data, this.formData.personal.cpf, this.data.gym.preSale)
        .pipe(catchError(err => this.displayErrorMessage(err)));

      case TypesOfProducts.Income:
        return this.btPassService.startIncomePurchase(data, this.formData.personal.cpf)
                    .pipe(catchError(err => this.displayErrorMessage(err)));
    }
  }

  private sendAddressData() {
    /* STEP 2 : Dados Adicionais (Primeira parte) | novo cliente (não logado) */
    const purchaseData = this.mapRegistrationInfoService.mapAdditionalDataToOldCanais(this.formData, this.additionalForm, this.data);
    const cpf = this.formData.personal.cpf;
    return this.registrationService
      .registerUser(purchaseData, cpf)
      .pipe(catchError(err => this.displayErrorMessage(err)));
  }

  private finalizeData() {
    /* STEP 2 Dados Adicionais (Segunda parte) | novo cliente (não logado) */
    const purchaseData = this.mapRegistrationInfoService.mapAdditionalDataToOldCanais(this.formData, this.additionalForm, this.data);
    const cpf = this.formData.personal.cpf;

    if (this.data.gym.preSale) {
      return this.sendFinalizeDataPreSale(purchaseData, cpf);
    } else {
      return this.sendFinalizeData(purchaseData, cpf);
    }
  }

  /**
   * Pré-Venda
   */
  private sendFinalizeDataPreSale(purchaseData: any, cpf: string) {
    return this.registrationService
      .finalizePreSalePurchase(purchaseData, cpf)
      .pipe(catchError(err => this.displayErrorMessage(err)));
  }

  /**
   * Venda Comum
   */
  private sendFinalizeData(purchaseData: any, cpf: string) {
    return this.registrationService
      .finalizePurchase(purchaseData, cpf)
      .pipe(catchError(err => this.displayErrorMessage(err)));
  }

  private sendLoggedInitData() {
    /* STEP 1 : Automático ao entrar na tela | cliente logado */
    const personalData = this.mapRegistrationInfoService.mapPersonalDataToOldCanais(this.formData, this.data, this.typeOfProduct);

    this.loadingService.startLoading();

    switch (this.typeOfProduct) {
      case TypesOfProducts.Plan:
        return this.registrationService
          .checkOperations(this.data.gymId.toString(), this.data.group.toString(), this.data.planId.toString())
          .pipe(
            mergeMap(() =>
              this.registrationService.startLoggedPurchase(personalData, this.data.gym.preSale, this.data.planId.toString()),
            ),
            catchError(err => this.displayErrorMessage(err)),
          );

      case TypesOfProducts.Income:
        return this.btPassService.startLoggedIncomePurchase(personalData)
                    .pipe(catchError(err => this.displayErrorMessage(err)));
    }
  }

  private sendLoggedPurchase() {
    const purchaseData = this.mapRegistrationInfoService.mapLoggedPurchaseToOldCanais(this.formData);
    return this.registrationService
      .finalizeLoggedPurchase(purchaseData)
      .pipe(catchError(err => this.displayErrorMessage(err)));
  }

  private loggedPurchase() {
    /* STEP 2 : Pagamento | cliente logado */
    if (this.data.gym.preSale) {
      return this.sendLoggedPurchasePresale();
    } else {
      return this.sendLoggedPurchase();
    }
  }

  private sendLoggedPurchasePresale() {
    const purchaseData = this.mapRegistrationInfoService.mapLoggedPreSalePurchaseToOldCanais(this.formData);
    return this.registrationService
      .finalizeLoggedPurchasePresale(purchaseData)
      .pipe(catchError(err => this.displayErrorMessage(err)));
  }

  private getCurrentForm(): FormPersonalDataComponent | FormAdditionalDataComponent {
    const forms = this.user ? [this.additionalForm] : [this.personalForm, this.additionalForm];
    return forms[this.currentStep];
  }

  private displayErrorMessage(err: HttpErrorResponse) {
    let hasShowError = false;
    try {
      if (err.error.errorCode === AppConstants.LEGACY_ERROR.EXISTING_CPF) {
        this.cpfModal();
      } else {
        if (err.error.message.startsWith('Token de Usuário')) {
          this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.USER_LEGACY_TOKEN_INDISPONÍVEL));
        } else {
          this.alertMessageService.showToastr(AlertMessage.error(err.error.message));
        }
      }
      hasShowError = true;
    } finally {
      if (!hasShowError) {
        this.alertMessageService.showToastr(err);
      }
    }
    return throwError(err);
  }

  private cpfModal() {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'CPF Cadastrado',
      message: 'Seu CPF já está cadastrado. Recupere seu login para finalizar a compra.',
      confirmButton: 'RECUPERAR LOGIN',
      confirmCallback: () => {
        this.router.navigate(['/cliente/nao-consigo-logar'], { queryParams: this.redirectToMe });
      },
    });
  }

}
