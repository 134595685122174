import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appDate][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidatorDirective,
      multi: true,
    },
  ],
})
export class DateValidatorDirective implements Validator {
  readonly monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  day: number;
  month: number;
  year: number;

  validate(control: AbstractControl): { [key: string]: any } {
    if (control.value != null) {
      const numbers = control.value.split('/');
      if (numbers.length !== 3) {
        return { date: { valid: false } }; // Inválido
      }

      try {
        this.day = parseInt(numbers[0], 10);
        this.month = parseInt(numbers[1], 10);
        this.year = parseInt(numbers[2], 10);

      } catch {
        console.error('Data em formato inválido');
        return { date: { valid: false } }; // Inválido
      }

      if (
        this.month >= 1 && this.month <= 12 &&
        this.day >= 1 && this.day <= this.monthLength[this.month - 1] &&
        this.year >= 1900
      ) {
        return null; // Válido
      }
    }

    return { date: { valid: false } }; // Inválido
  }
}
