<div class="main-wrapper container justify-content-around d-flex text-center">

  <div class="card small-card row-on-xs no-side-border-xs">
    <form [formGroup]="form" class="form form-validate" (submit)="submit()">
      <div class="form-container text-left">
        <div class="title-section text-black">
          {{ currentStage === newRecoveryAccountStages.UpdateExistingAccount  ? 'NOVO' : 'CRIE'}} E-MAIL E SENHA
        </div>
        <div class="flex sm-column">
          <div class="w-100">
            <label for="gym-field" class="label text-uppercase">E-MAIL</label>
            <input name="email" class="form-control select-teacher"
            formControlName="email" required>
            <app-error-message [control]="form.controls.email"></app-error-message>
          </div>
          <div class="w-100 ml-md-24">
            <label for="gym-field" class="label text-uppercase">REPETIR E-MAIL</label>
            <input name="confirmEmail" class="form-control select-teacher"
            formControlName="confirmEmail" required>
            <app-error-message [control]="form.controls.confirmEmail"></app-error-message>
          </div>
        </div>
        <div class="flex sm-column">
          <div class="w-100">
            <label for="gym-field" class="label text-uppercase">SENHA</label>
            <input type="password" name="password" class="form-control select-teacher"
            formControlName="password" required minlength="6">
            <app-error-message [control]="form.controls.password"></app-error-message>
          </div>
          <div class="w-100 ml-md-24">
            <label for="gym-field" class="label text-uppercase">REPETIR SENHA</label>
            <input type="password" name="confirmPassword" class="form-control select-teacher"
            formControlName="confirmPassword" required minlength="6">
            <app-error-message [control]="form.controls.confirmPassword"></app-error-message>
          </div>
        </div>
      </div>

      <div>
        Sua senha deve conter ao menos 6 caracteres e possuir letras e números.
      </div>

      <div class="button-container row no-gutters">
        <button type="button" id="forgot-cancel" class="col btn btn-outline-primary" (click)="returnToFoundAndShowEmailStage()">
          Voltar
        </button>
        <button type="submit" id="forgot-confirm" class="col btn btn-primary submit-btn">
          Enviar
        </button>
      </div>
    </form>
  </div>

</div>
