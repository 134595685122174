<div class="container">
  <div class="section">
    <h2 class="title-lg">TRANSFORMANDO HISTÓRIAS ATRAVÉS DO MOVIMENTO</h2>
    <p>A Bodytech promove a transformação. Queremos ser sempre o apoio para que as pessoas encontrem meios de se superarem,
      entendendo suas prioridades, preferências e até limitações. Nas academias da rede, nossos clientes têm acesso à união
      entre a tecnologia de ponta e o cuidado de equipes especializadas em gente. Estamos prontos para convencê-los o tempo
      todo que não vale a pena aceitar as coisas como são, que é preciso persistir.</p>
  </div>

  <div class="section d-flex">
    <div class="image-col">
      <img
        src="/assets/images/concept_1.jpg"
        alt="Foto - Exercício">
    </div>

    <div class="text-col">
      <h2 class="title-lg">Conforto, segurança e incentivo</h2>
      <p>Incentivamos o movimento, a renovação e podemos estar em qualquer lugar: na piscina, na sala de musculação, no celular
        ou em uma corrida outdoor. Podemos ser ballet, crossfit, natação ou yoga. Estamos com os clientes durante uma tarde
        inteira ou por 20 minutos. Conosco, crianças, adolescentes, adultos e idosos encontram conforto, segurança e incentivos
        para se desafiarem cada vez mais.</p>
    </div>
  </div>
</div>
