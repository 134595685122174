import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { merge, pipe, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap, tap } from 'rxjs/operators';

import {
  EvoMembershipResponseModel,
  Gym,
  GymAgendaFilter,
  GymWithKidsPlans,
  MembershipModel,
  MembershipsResponsesUnionType,
  ModalGymSelectObject,
  ModalType,
} from '@models';
import { HomeResponse } from '@models/responses/home-response.model';
import { EvoMembershipService } from '@services/evo';
import { GymService } from '@services/gym.service';
import { HeaderService } from '@services/header.service';
import { ModalService } from '@services/modal.service';
import { AppConstants } from '@utils/app-constants';
import { FormatUtils } from '@utils/format-utils';
import { RequestObject } from '@utils/request-object.class';

import { EvoGeneralService } from '../shared/services/evo/evo-general.service';
import { TimelineComponent } from './timeline/timeline.component';


@Component({
  selector: 'app-kids',
  templateUrl: './kids.component.html',
  styleUrls: ['./kids.component.scss']
})
export class KidsComponent implements OnInit, AfterViewInit, OnDestroy {

  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;
  @ViewChild('timeline', { static: false }) timelineComponent: TimelineComponent;

  filter: GymAgendaFilter = {
    gym: null,
    ages: [],
    activities: [],
  };

  hideAgenda = true;
  hidePlans = true;

  json: HomeResponse;
  subs: Subscription;

  gym: GymWithKidsPlans;

  selectedGymIsMigrated = true;
  selectedGymId: number;

  public membershipsRO: RequestObject<MembershipsResponsesUnionType, MembershipModel[]>;


  constructor(
    private readonly evoGeneralService: EvoGeneralService,
    private readonly evoMembershipService: EvoMembershipService,
    private readonly gymService: GymService,
    private readonly headerService: HeaderService,
    private readonly modalService: ModalService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  private getTimeline() { return this.timelineComponent; }

  ngOnInit(): void {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;

    const data = this.route.snapshot.data;
    const gyms: GymWithKidsPlans[] = data.gyms;
    const slug: string = this.route.snapshot.params.id;
    this.gym = gyms.find(gym => gym.slug === slug);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));

    this.subs = merge(
      this.route.paramMap.pipe(this.handleParamMap()),
      this.route.queryParamMap,
    ).pipe(
      tap(() => this.getTimeline().isLoading = true),
      debounceTime(50),
    ).subscribe(() => {
      this.hidePlans = true;

      this.getTimeline().populateAgenda();

      if (this.filter.gym) {
        this.onFilterChange(false);
        this.getMemberships();

        setTimeout(() => { this.hidePlans = false; }, 200);

        this.checkGymMigration();
      }
    });
  }

  private getMemberships(): void {
    this.membershipsRO = new RequestObject(this.evoMembershipService.getKids({
      gymUnitId: this.filter.gym.id.toString(),
    }));

    this.membershipsRO.observable$ = this.membershipsRO.observable$.pipe(
      tap<EvoMembershipResponseModel[]>(response => {
        this.membershipsRO.response = this.evoMembershipService.mapMembershipsToComponent(response);
      }),
    );

    this.membershipsRO.observable$.subscribe();
  }

  handleParamMap() {
    return pipe(
      map((params: ParamMap) => params.get('id')),
      mergeMap(slug => {
        if (!slug) {
          slug = localStorage.getItem(AppConstants.STOR_KIDS_GYM);
        }
        return this.gymService.getGymNameBySlug(slug, true);
      }),
      tap(gym => {
        this.hidePlans = false;

        if (gym && this.filter.gym !== gym) {
          this.filter.gym = gym;
          this.hidePlans = false;
          this.setAndNavigateToSelectedGymSlug(gym);
        }
      }),
    );
  }

  setAndNavigateToSelectedGymSlug(gym: any) {
    localStorage.setItem(AppConstants.STOR_KIDS_GYM, gym.slug);
    localStorage.setItem(AppConstants.STOR_KIDS_GYM_ID, gym.id);
    const param = FormatUtils.encodeAgendaFilterToParamObject(this.filter);
    this.router.navigate(['/kids', this.filter.gym.slug], { queryParams: param });
  }

  onFilterChange(isChanged: boolean) {
    this.hideAgenda = isChanged;

    this.updateRouterSlug(this.filter.gym.slug);

    /**
     * Force plans component rendering when changes the gym at the filter component.
     * @todo: remover esta lógica quando refatorar o componente de filtro. */
    this.hidePlans = true;
    setTimeout(() => this.hidePlans = false, 500);
  }

  /** When a gym is selected at the plans button. */
  showGymSelectModal() {
    this.modalService.show({
      type: ModalType.gymSelect,
      title: 'Selecione academia',
      message: '',
      confirmCallback: (gym: Gym) => {
        this.selectedGymId = gym.id;

        if (gym !== this.filter.gym) {
          this.onFilterChange(true);
          this.updateRouterSlug(this.gym.slug);
        }
      },
      gyms: this.route.snapshot.data.gyms,
      selected: this.filter.gym ? this.filter.gym.id : null,
    } as ModalGymSelectObject);
  }

  private updateRouterSlug(slug: string): void {
    this.router.navigate(
      ['kids', slug],
      { queryParams: FormatUtils.encodeAgendaFilterToParamObject(this.filter) },
    ).then(() => {
      setTimeout(() => {
        document.getElementById('gym-detail-plans').scrollIntoView({ behavior: 'smooth' });
      }, 1300);
    });
  }

  private checkGymMigration(): void {
    const id = this.filter.gym?.id ?? this.selectedGymId;

    this.evoGeneralService.checkMigratingGym(id).subscribe(response => {
      const { isAlreadyMigrated } = response;

      this.selectedGymIsMigrated = isAlreadyMigrated;
      this.hideAgenda = isAlreadyMigrated;
    });
  }

}
