<div class="d-flex justify-content-end">
  <div class="bot-container">
    <!-- JANELA DO BOT -->
    <div id="faqbotContent"
        #collapse
        app-collapse
        class="collapse light-shadow chat-container border bg-white p-0 col-11 col-sm-9 col-md-7 col-lg-5">
      <div class="title text-center border-bottom p-2 text-primary font-weight-bold"
          appClickable
          (click)="collapse.toggle()"
          aria-controls="faqbotContent"
          [attr.aria-expanded]="collapse.expanded">
        Bot - Assistente Virtual
      </div>
      <div class="chat" #chatBody>
        <div class="converse text-center" *ngIf="!(messages | async).length">
          <span><i class="fas fa-spinner fa-pulse"></i> Aguarde</span>
        </div>
        <div
          class="converse {{ message.sendBy }}"
          *ngFor="let message of (messages | async)">
          <div class="p-2 message">
            <span class="content" [innerHTML]="message.content"></span>
          </div>
          <small class="time">{{ message.when | date:'mediumTime' }}</small>
        </div>
      </div>
      <div class="d-flex">
        <input id="faqbot-message" #input type="text" class="form-control auto-height" placeholder="Digite sua mensagem…"
          (keyup.enter)="send(input)">
        <button id="faqbot-send" class="send-button btn btn-sm btn-primary send"
          (click)="send(input)"><i class="fas fa-paper-plane"></i></button>
      </div>
    </div>

    <!-- BOTÃO FLUTUANTE DE ABRIR O BOT -->
    <div class="text-right">
      <button id="faqbot-button"
        popoverFade="Bot - Assistente Virtual"
        placement="left"
        class="bot-button btn btn-primary border border-light"
        type="button"
        (click)="open(); collapse.toggle()"
        aria-controls="faqbotContent"
        [attr.aria-expanded]="collapse.expanded">

        <i class="fas fa-comments"></i>
      </button>
    </div>
  </div>
</div>
