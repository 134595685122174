import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class LoadingService {

  loadingEmitter = new EventEmitter<boolean|string>();

  startLoading(message = 'Carregando…') {
    this.loadingEmitter.emit(message ? message : true);
  }

  stopLoading() {
    this.loadingEmitter.emit(false);
  }

}
