<div class="modal fade" bsModal #templateModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}"
    (onShow)="init()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog text-center" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title w-100">{{ modalObj.title }}</div>
      </div>
      <div class="search-container position-relative">
        <label for="modal-act-input-search">
          <img src="assets/icons/ic-search.svg">
        </label>
        <input id="modal-activity-input-search" type="text" class="form-control text-center auto-height adjust-on-ie--h50"
          placeholder="Buscar academia" [(ngModel)]="search">
      </div>
      <div class="modal-body">
        <div class="row justify-content-center">
          <div id="modal-gym-{{index}}" class="gym border"
            [class.selected]="gymSelected && (gym.id === gymSelected || gym.gymUnitId === gymSelected)" appClickable
            *ngFor="let gym of (gyms | fuse:search:{keys: ['portalNameWithState', 'portalName']}); let index = index" (click)="select(gym)">
            <span class="title modal-title font-weight-normal">{{ gym.portalNameWithState || gym.portalName }}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="modal-gym-cancel" type="button" class="col btn btn-primary"
          (click)="cancel()">
          {{ modalObj?.cancelButton || "Cancelar" }}
        </button>
      </div>
    </div>
  </div>
</div>
