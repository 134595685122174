import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '@utils/date-util';

@Pipe({
  name: 'dayToWeekdayName',
})
export class ShortWeekdayNamePipe implements PipeTransform {
  transform(value: string): string {
    const dateUTCday = new Date(value).getUTCDay();
    return DateUtil.SHORT_WEEKDAY_NAMES.find(date => date.id === dateUTCday).name;
  }
}
