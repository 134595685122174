import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation,  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { AptoSolicitarBloqueioDCC, ModalType } from '@models';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { RegistrationLockingService } from '@services/registration-locking.service';
import { AppConstants } from '@utils/app-constants';
import { DateUtil } from '@utils/date-util';

@Component({
  selector: 'app-registration-locking-request',
  templateUrl: './registration-locking-request.component.html',
  styleUrls: ['./registration-locking-request.component.scss', '../../area-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RegistrationLockingRequestComponent implements OnInit {

  locale = AppConstants.LOCALE;
  daysRemaining: number;
  minRequestPeriod: number;
  renovationDay: number;
  public form: FormGroup;
  days: string[] = [];
  contractCode: string;
  maxDate: Date;
  minDate: Date;
  isDCC: boolean;
  selectedInitialDCCDate: Date;
  allowedDates: Date[] = [];
  period: FormControl;
  gymUnitId: number;
  DCClockingInfo: AptoSolicitarBloqueioDCC;
  periodOptions: {
    optionName: string,
    option: number
  }[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private registrationLockingService: RegistrationLockingService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    if (!history.state.contractCode || !history.state.gymUnitId) {
      this.router.navigate(['cliente/perfil/contratos']);
    }

    this.createForm();
    this.contractCode = history.state.contractCode;
    this.maxDate = history.state.maxDate;
    this.minDate = new Date();
    this.isDCC = history.state.isDCC;
    this.gymUnitId = history.state.gymUnitId;
    this.renovationDay = history.state.DCClockingInfo?.DiaFixoBloqueio;
    this.daysRemaining = history.state.daysRemaining;
    this.minRequestPeriod = history.state.minRequestPeriod;
    this.DCClockingInfo = history.state.DCClockingInfo;

    if (this.isDCC) {
      for (let monthNumber = 1; monthNumber <= this.DCClockingInfo.LimiteMesesParaBloquear; monthNumber++) {
        const formatedMonthName = monthNumber === 1 ? `${monthNumber} mês` : `${monthNumber} meses`;
        this.periodOptions.push({
          optionName: formatedMonthName,
          option: monthNumber
        });
      }

      for (let index = 0; index <= this.DCClockingInfo.ListaDatasPossiveisBloqueio.length - 1; index++) {
        const formatedAllowedDate = DateUtil.formatLegacyDate(this.DCClockingInfo.ListaDatasPossiveisBloqueio[index]);
        this.allowedDates.push(formatedAllowedDate);
      }
    }
  }

  lockingRegistration() {
    if (this.form.valid) {
      if (this.isDCC) {
        this.lockingRegistrationDCC();
      } else {
        const formattedInitialDate = this.formatDate();
        this.router.navigate(['/cliente/trancamento/confirma-agendamento'], {
        state: {
          contractCode: history.state.contractCode,
          gymUnitId: this.gymUnitId,
          initialDate: formattedInitialDate,
          unformatedInitialDate: this.form.get('lockingInitialDate').value._d,
          daysRemaining: this.daysRemaining,
          minRequestPeriod: this.minRequestPeriod,
        }});
      }
    }
  }

  lockingRegistrationDCC(): void {
    const formattedInitialDate = this.formatDate();

    this.modalService.show({
      type: ModalType.confirm,
      title: 'AGENDAR TRANCAMENTO?',
      message: `Deseja realmente agendar seu trancamento de ${this.period.value.option === 1
        ? this.period.value.option + ' mês'
        : this.period.value.option + ' meses'},
      iniciando em ${formattedInitialDate}?
      Você não será debitado nos meses de trancamento, voltando a ser debitado no mês seguinte ao trancamento.`,
      cancelButton: 'NÃO',
      confirmButton: 'SIM',
      confirmCallback: () => {
        this.loadingService.startLoading();
        this.registrationLockingService.setVacationDCC(this.gymUnitId, {
          CodigoContrato: this.contractCode,
          DataBloqueio: formattedInitialDate,
          NomeCanal: AppConstants.DEVICE,
          QuantidadeMeses: this.period.value.option,
        }).subscribe(
          (res: any) => {
            this.loadingService.stopLoading();

            const initialDate = new DatePipe(this.locale).transform(
              DateUtil.formatLegacyDate(res.Ocorrencia.DtInicioOcorrencia).toISOString(), 'shortDate');
            const endDate = new DatePipe(this.locale).transform(
              DateUtil.formatLegacyDate(res.Ocorrencia.DtTerminoOcorrencia).toISOString(), 'shortDate');
            this.successfulLockingRegistration(initialDate, endDate);
          },
          (error) => {
            this.loadingService.stopLoading();
            this.unsuccessfulLockingRegistration(error?.error.message);
          }
        );
      }
    });

  }

  successfulLockingRegistration(initialDate: string, endDate: string): void {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'TRANCAMENTO AGENDADO!',
      message: `Seu trancamento foi agendado para o período entre ${initialDate} e ${endDate}.`,
      confirmButton: 'OK',
      confirmCallback: () => {
        this.redirectToInitialScreen();
      }
    });
  }

  formatDate(): string {
    const formattedDay = this.form.get('lockingInitialDate').value._i.date < 10
      ? `0${this.form.get('lockingInitialDate').value._i.date}`
      : this.form.get('lockingInitialDate').value._i.date;

    const formattedMonth = (this.form.get('lockingInitialDate').value._i.month + 1) < 10
      ? `0${this.form.get('lockingInitialDate').value._i.month + 1}`
      : this.form.get('lockingInitialDate').value._i.month + 1;

    return `${formattedDay}/${formattedMonth}/${this.form.get('lockingInitialDate').value._i.year}`;
  }

  allowedDatesFilter = (d: any): boolean => {
    const time = d._d.getTime();
    return !!this.allowedDates.find(x => x.getTime() === time);
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month' && this.allowedDatesFilter(cellDate)) {
      return 'available-dates';
    }

    return '';
  }

  private createForm() {
    this.period = new FormControl();
    this.isDCC ? this.period.setValidators(Validators.required) : this.period.setValidators(null);
    this.form = this.formBuilder.group({
      lockingInitialDate: new FormControl(null, Validators.required),
    });
  }

  unsuccessfulLockingRegistration(message: string) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'Não foi possível realizar seu trancamento',
      message,
      confirmButton: 'OK',
      confirmCallback: () => {
        this.redirectToInitialScreen();
      }
    });
  }

  redirectToInitialScreen() {
    this.router.navigate(['/cliente/trancamento'], {
      state: {
        contractCode: this.contractCode,
        gymUnitId: this.gymUnitId,
    }});
  }
}
