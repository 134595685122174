<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper container">
  <div class="card no-side-border-xs row-on-xs" *ngIf="(jsonPage | async); let json; else loading">
    <app-tabs name="faq" [tabs]="json" [(activeTab)]="activeTab"></app-tabs>

    <div id="accordion" class="tab-content">
      <ng-container *ngFor="let section of activeTab?.sectionsDoubtArea">
        <div class="title-section">{{ section.title }}</div>
        <div class="accordion">
          <ng-container *ngFor="let doubt of section.doubts; let doubtIndex = index;">
            <div id="heading{{ doubt.id }}"
                appClickable
                appDropdownArrows
                (click)="collapse.toggle()"
                class="question card-header"
                [class.collapsed]="!collapse.expanded"
                [attr.aria-expanded]="collapse.expanded"
                attr.aria-controls="question{{ doubt.id }}">
              <div class="question-text">
                <span class="font-weight-bold">{{ doubtIndex + 1 }}.</span> {{ doubt.question }}
              </div>
            </div>
            <div id="question{{ doubt.id }}" #collapse
                app-collapse
                (afterExpand)="expandQuestionAnalytics(section.title, doubtIndex)"
                class="answer border-top-0"
                attr.aria-labelledby="heading{{ doubt.id }}">
              <div class="card-body" [innerHTML]="doubt.answer"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

  </div>
</main>
<ng-template #loading>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>
