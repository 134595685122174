  <ng-template #sectionTitle>
    <div class="title-section text-black text-uppercase">{{ featureName }}</div>
  </ng-template>
  <div>
  <ng-container *ngIf="!!schedules; else loading">
      <ng-container *ngIf="schedules.length; else empty">
        <div class="card border-top-0 border-bottom-0 pb-0 row-on-xs no-side-border-xs">
          <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
          <app-online-class-schedule-item [schedules]="schedules" (cancelClick)="onCancelClick($event)"></app-online-class-schedule-item>
          <div class="btn-container text-center pt-4">

            <ng-container *ngTemplateOutlet="userAvailabilityToScheduleTemplate"></ng-container>

            <button [class.btn-disabled]="btnDisabled" routerLink="/cliente/treinos/online" class="btn btn-primary">
              Agendar novo horário
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #empty>
        <div class="error-container">
          <app-empty-card
            icon="/assets/icons_custom/ic-empty-list.svg"
            altIcon="Lista vazia"
            buttonText="Agendar novo horário"
            buttonLink="/cliente/treinos/online"
            hideBorderTop="true"
            hideBorderBottom="true"
            [btnDisabled]="btnDisabled"
          >
            <ng-template #cardHeader>
              <div class="section-title-container">
                <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
              </div>
            </ng-template>
            <ng-template #cardButtons>
              <ng-container *ngTemplateOutlet="userAvailabilityToScheduleTemplate"></ng-container>
            </ng-template>
          </app-empty-card>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <ng-template #loading>
    <div class="loader title text-center">
      <i class="fas fa-spinner fa-pulse"></i> Carregando
    </div>
  </ng-template>

  <ng-template #userAvailabilityToScheduleTemplate>
    <app-schedules-per-month-info *ngIf="userAvailabilityToSchedule"
      (hasSchedulesLeft)="hasSchedulesLeft = $event"
      [totalOnlineSchedules]="userAvailabilityToSchedule.totalOnlineSchedules"
      [numberOfSchedulesAlreadyMade]="userAvailabilityToSchedule.numberOfSchedulesAlreadyMade"
      [month]="userAvailabilityToSchedule.month"
      [numberOfActiveSchedules]="schedules.length"
    ></app-schedules-per-month-info>
  </ng-template>