<div class="modal fade" bsModal #templateModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}"
    (onShow)="init()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog text-center" *ngIf="modalObj">
    <div class="modal-content">

      <app-tabs name="modal-activity" [tabs]="tabs" [(activeTab)]="activeTab"
        [hasIcon]="true" textKey="name"  (mouseup)="scrollListToStart()"></app-tabs>

      <div class="search-container position-relative">
        <label for="modal-act-input-search">
          <img src="assets/icons/ic-search.svg">
        </label>
        <input id="modal-activity-input-search" type="text" class="form-control text-center auto-height adjust-on-ie--h50"
          placeholder="Buscar atividade" [(ngModel)]="search">
      </div>
      <div #modalBody class="checkboxes-container modal-body text-left border-top">
        <div class="title text-center tab-name-body"> {{ activeTab.name }} </div>
        <ng-container *ngIf="!search.length; else titleSearch">
          <div class="checkbox d-flex mt-0">
            <app-checkbox id="modal-activity-check-all" name="act-all"
              [(check)]="activeTabAllChecked" message="{{config.selectAll}}" class="w-100"></app-checkbox>
          </div>
          <div class="title modal-title" [class.d-none]="!popularList.children.length">{{config.popular}}</div>
          <div #popularList>
            <div class="checkbox d-flex" *ngFor="let act of activities | filterActivity:activeTab.id:true; let index = index">
              <app-checkbox id="modal-activity-pop-check-{{index}}" name="act-{{act.id}}-popular"
                [(check)]="act.selected" [message]="act.name" class="w-100"></app-checkbox>
            </div>
          </div>
          <div class="title modal-title">{{config.all}}</div>
        </ng-container>
        <ng-template #titleSearch>
          <div class="title modal-title mt-0">{{config.searchResultTitle}}</div>
        </ng-template>
        <div #todasList>
          <div class="checkbox d-flex" *ngFor="let act of (activities | filterActivity:activeTab.id | fuse:search:{keys: ['name']})">
            <app-checkbox id="modal-act-check-{{act.id}}" name="act-{{act.id}}"
              [(check)]="act.selected" [message]="act.name" class="w-100"></app-checkbox>
          </div>
        </div>
        <div class="checkbox-empty text-muted" *ngIf="!todasList.children.length">
          {{config.searchResultEmpty}}
        </div>
      </div>

      <div class="modal-footer">
        <button id="modal-activity-confirm" type="button"
          class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || config.buttonText || "OK" }}
        </button>
      </div>

    </div>
  </div>
</div>
