import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalType } from '@models';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { RegistrationLockingService } from '@services/registration-locking.service';
import { AppConstants } from '@utils/app-constants';
import { DateUtil } from '@utils/date-util';

@Component({
  selector: 'app-registration-locking-request-confirm',
  templateUrl: './registration-locking-request-confirm.component.html',
  styleUrls: ['./registration-locking-request-confirm.component.scss', '../../area-user.component.scss']
})

export class RegistrationLockingRequestConfirmComponent implements OnInit {

  daysRemaining: number;
  minRequestPeriod: number;
  public form: FormGroup;
  contractCode: string;
  initialDate: string;
  locale = AppConstants.LOCALE;
  gymUnitId: number;
  periodError: boolean;
  unformatedInitialDate: Date;

  constructor(
    private readonly formBuilder: FormBuilder,
    private registrationLockingService: RegistrationLockingService,
    private modalService: ModalService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    if (!history.state.contractCode || !history.state.gymUnitId) {
      this.router.navigate(['cliente/perfil/contratos']);
    }

    this.createForm();
    this.contractCode = history.state.contractCode;
    this.initialDate = history.state.initialDate;
    this.gymUnitId = history.state.gymUnitId;
    this.daysRemaining = history.state.daysRemaining;
    this.minRequestPeriod = history.state.minRequestPeriod;
    this.unformatedInitialDate = history.state.unformatedInitialDate;
  }

  lockingRegistration() {
  const minRequest = this.daysRemaining < this.minRequestPeriod ? this.daysRemaining : this.minRequestPeriod;
  this.periodError = false;
  const finalDate = new Date(this.unformatedInitialDate);

  if (this.form.valid &&
    (this.form.get('lockingPeriod').value >= minRequest && this.form.get('lockingPeriod').value <= this.daysRemaining)) {
    finalDate.setDate(this.unformatedInitialDate.getDate() + this.form.get('lockingPeriod').value - 1);

    this.modalService.show({
      type: ModalType.confirm,
      title: 'AGENDAR TRANCAMENTO?',
      message: `Deseja realmente agendar seu trancamento para o período entre
      ${this.initialDate} e ${new DatePipe(this.locale).transform(finalDate, 'shortDate')}?`,
      cancelButton: 'NÃO',
      confirmButton: 'SIM',
      confirmCallback: () => {
        this.loadingService.startLoading();
        this.registrationLockingService.setVacation(this.gymUnitId, {
          CodigoContrato: this.contractCode,
          DataInicioFerias: this.initialDate,
          NomeCanal: AppConstants.DEVICE,
          QuantidadeDiasFerias: this.form.get('lockingPeriod').value,
        }).subscribe(
          (res) => {
            this.loadingService.stopLoading();
            this.successfulLockingRegistration(res);
          },
          (error) => {
            this.loadingService.stopLoading();
            this.unsuccessfulLockingRegistration(error?.error.message);
          }
        );
      }
    });
    } else {
      this.periodError = true;
    }
  }

  successfulLockingRegistration(data: any) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'TRANCAMENTO AGENDADO!',
      message: `Seu trancamento foi agendado para o período entre
      ${new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(data.DataInicio).toISOString(), 'shortDate')}
       e
       ${new DatePipe(this.locale).transform(DateUtil.formatLegacyDate(data.DataTermino).toISOString(), 'shortDate')}.`,
      confirmButton: 'OK',
      confirmCallback: () => {
        this.redirectToInitialScreen();
      }
    });
  }

  unsuccessfulLockingRegistration(message: string) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'Não foi possível realizar seu trancamento',
      message,
      confirmButton: 'OK',
      confirmCallback: () => {
        this.redirectToInitialScreen();
      }
    });
  }

  redirectToInitialScreen() {
    this.router.navigate(['/cliente/trancamento'], {
      state: {
        contractCode: this.contractCode,
        gymUnitId: this.gymUnitId,
    }});
  }

  private createForm() {
    this.form = this.formBuilder.group({
      lockingPeriod: new FormControl(null, Validators.required),
    });
  }
}
