import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { BreakpointsService, screenSizeToString } from '@services/breakpoints.service';
import { SectionInfo } from '@models';

@Component({
  selector: 'app-bt-company-header',
  templateUrl: './bt-company-header.component.html',
  styleUrls: [
    './bt-company-header.component.scss',
    '../../styles/shared/content-header-component.scss',
  ],
})
export class BtCompanyHeaderComponent implements OnDestroy {

  screenSize: string;
  private subs: Subscription;

  sections: SectionInfo[] = [{
    title: 'TRANSFORMANDO HISTÓRIAS ATRAVÉS DO MOVIMENTO',
    body: `
      <p>A Bodytech é muito mais do que uma rede de academias. Somos centros de
      atividades físicas, esportes, bem-estar e lazer que já mudaram a vida de milhares
      de clientes. Acreditamos que o movimento pode ser um divisor de águas na história
      de qualquer pessoa e é com esse propósito que a Bodytech Company trabalha há
      quase 20 anos.</p>

      <p>Nosso sucesso no mercado nacional tem uma base sólida que combina
      administração eficiente, estruturas confortáveis e modernas e, principalmente, um
      atendimento que valoriza e entende o que cada cliente busca.</p>

      <p>Venha fazer parte do #mundoBT e viva o que existe de melhor no mercado fitness.
      Aqui você tem o cuidado e a atenção que precisa para ter mais qualidade de vida,
      saúde e bem-estar.</p>
    `,
    subsections: [{
      title: 'ACADEMIAS',
      src: '/assets/icons/ic-front-gyms.svg',
      alt: 'ícone de uma academia',
      body: 'A Bodytech Company opera com duas marcas de academias e tem hoje 92 academias, entre unidades Fórmula e Bodytech.'
    }, {
      title: 'CLIENTES',
      src: '/assets/icons/ic-front-clients.svg',
      alt: 'ícone de pessoas conversando.',
      body: 'Na Bodytech, mais de 150 mil clientes ativos fazem parte de uma verdadeira corrente de pessoas que buscam não apenas qualidade de vida, mas também uma experiência única. Nossos clientes valorizam o atendimento personalizado, o acolhimento e a diversidade de atividades que oferecemos.',
    }, {
      title: 'PROFISSIONAIS',
      src: '/assets/icons/ic-front-professional.svg',
      alt: 'ícone de varias pessoas.',
      body: 'Contamos com uma equipe multidisciplinar de mais de 4.000 colaboradores. Na Bodytech, cada profissional está comprometido em proporcionar uma experiência personalizada e de alta qualidade, garantindo que você se sinta acolhido e bem cuidado em cada visita.',
    }]
  }, {
    title: 'MARCAS',
    body: `
      <p>Nascida em 2005 no Rio de Janeiro, a Bodytech virou sinônimo de excelência e
      inovação no mercado fitness. Com duas redes de academias, Bodytech e Fórmula,
      o grupo tem infraestrutura de ponta, tecnologia avançada, extenso cardápio de
      aulas e um atendimento personalizado.</p>

      <p>Além das redes físicas, temos ainda o <b>BTFIT</b>, uma plataforma de exercícios online
      com diversos conteúdos ao vivo e gravados que permite a democratização do
      acesso à atividade física. O usuário pode treinar onde e quando quiser. São mais de
      25 programas de treinamento , aulas de diversas modalidades, prescrição de treinos
      individualizada com ou sem equipamentos e muito mais com a garantia da
      qualidade da Bodytech Company. Ele está disponível gratuitamente para clientes
      Bodytech e Fórmula e tem planos acessíveis para qualquer pessoa que busca
      treinar com o apoio da tecnologia.</p>
    `,
  }, {
    title: 'HISTÓRIA',
    body: `
      <p>A trajetória da Bodytech começou em 2005, quando a academia Estação do Corpo
      no Shopping Città América, na Barra da Tijuca, Rio de Janeiro, foi adquirida. No ano
      seguinte, a empresa comprou a rede Bodytech, que já era a maior do Rio de
      Janeiro, com quatro unidades na Zona Sul da cidade e 11 anos de experiência.</p>

      <p>Em 2008, a Bodytech expandiu ainda mais ao adquirir todas as academias da Rede
      Fórmula, de São Paulo, conhecida por sua excelência e tradição de 16 anos no
      mercado.</p>

      <p>Em 2015, a Bodytech inovou ao lançar o <b>BTFIT</b>, um aplicativo completo de
      atividades físicas. Com quase 5 milhões de downloads no Brasil e no exterior, o
      BTFIT está disponível em português e inglês.</p>

      <p>Nos últimos anos, a Bodytech passou por diversas transformações que se somam
      às aberturas de novas operações. Continuamos investindo em novidades, como os
      programas exclusivos <b>BT Care</b>, <b>BT Express</b> e <b>Acqua Play</b>. Esses programas são
      criados com o objetivo de facilitar a constância na prática da atividade física,
      oferecendo soluções práticas e motivadoras para o dia a dia dos clientes. Além
      disso, novas aulas coletivas, equipamentos modernos e formatos de contratos e
      planos estão sempre em pauta.</p>

      <p>Mais recentemente, já em 2024, a AFP (Accioly Fitness Participações), que detém o
      controle da empresa, comprou a participação societária que pertencia ao Banco
      BTG, reforçando ainda mais o compromisso dos sócios fundadores com a empresa.</p>
    `,
  }, {
    title: 'DIFERENCIAIS E PIONEIRISMO NO DNA',
    body: `
      Imagine um ambiente ideal para ser mais saudável e feliz! A Bodytech promove
      encontros, networking e pode ser um "respiro" na correria do cotidiano. De olho em
      tendências e recentes pesquisas científicas, o trabalho tem um perfil pioneiro, que faz questão de implementar novos serviços, aulas e aparelhos que farão a diferença
      no dia a dia de quem escolheu a rede. Entre as características mais fortes da marca
      estão a ampla oferta de atividades em um só lugar e a excelência nos quesitos
      infraestrutura e profissionais. Com um público exigente, as academias têm
      aparelhos de marcas internacionalmente reconhecidas, áreas amplas, vestiários
      equipados e atividades para toda a família: de bebês de seis meses a centenários.
      Outra característica forte da marca é a nossa atenção à diversidade e inclusão. Há
      pelo menos cinco anos, investimos em treinamentos internos para que nosso time
      esteja alinhado a esses propósitos, garantindo ambientes seguros em nossas
      academias. Tudo pensado para que a atividade física seja transformadora na vida
      de todos os nossos clientes.
    `,
  }];

  constructor(
    private readonly breakpointsService: BreakpointsService,
  ) {
    this.subs = this.breakpointsService.emitter.subscribe(size => this.screenSize = screenSizeToString(size));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
