import { Component, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ModalType } from '@models';
import { AlertMessageService, HeaderService, LoadingService, ModalService, UserService } from '@services';
import { FormUtil } from '@utils/form-util';
import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from '@services/brand.service';


@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    '../../area-user.component.scss',
  ],
})
export class ForgotPasswordComponent implements AfterViewInit {

  public config: BasicHeaderConfig;

  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  user = {
    email: '',
    password: '',
  };

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly headerService: HeaderService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
    private readonly modalService: ModalService,
    private readonly userService: UserService,
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
   }

  requestNewPassword() {
    FormUtil.touchForm(this.form.control);

    if (this.form.invalid) {
      return;
    }

    this.loadingService.startLoading();

    this.userService.sendForgotPasswordEmail(this.user.email)
      .subscribe(
        () => {
          this.successModal();
        },
        (err: any) => {
          if (((err.error.error as string).includes('bloqueado'))) {
            this.userService.modalResendEmailConfirm(this.user.email);
          } else {
            this.alertMessageService.showToastr(err);
          }
        },
        () => this.loadingService.stopLoading(),
      );
  }

  successModal() {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'Sucesso',
      message: `Um link para gerar uma nova senha foi enviado para o email <b>${this.user.email}</b>.
                Se dentro de alguns minutos a mensagem não chegar,
                verifique a pasta de spam ou tente novamente`,
    });
  }

}
