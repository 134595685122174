<div class="text-dark row align-items-center justify-content-center" *ngIf="data">
  <ng-container *ngFor="let link of data.links; let index = index">
    <a *ngIf="!link.url.includes('//'); else externalLink" id="sitemap-{{index}}" class="title" [routerLink]="link.url"
      routerLinkActive="text-primary">
      {{ link.text }}
    </a>
    <ng-template #externalLink>
      <a id="sitemap-{{index}}" class="title" target="_blank" [href]="link.url" routerLinkActive="text-primary">
        {{ link.text }}
      </a>
    </ng-template>
  </ng-container>
</div>