<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper container">

  <div class="card">
    <div class="title-lg text-center d-print-block d-none">
      Política de privacidade
    </div>
    <div class="card-body">
      <div *ngFor="let line of policy" [innerHTML]="line.trim() || '&nbsp;'"></div>
    </div>
    <div class="btn-container d-flex justify-content-around d-print-none">
      <button id="print-button" class="btn btn-primary" (click)="callPrint()">Imprimir</button>
    </div>
  </div>

</main>
