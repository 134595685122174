<div class="row">
  <div class="col-md-6 col-sm-12 card-group" *ngFor="let c of card">
    <div class="card mb-4">
        <img class="card-img-top" [src]="c.img | bucket" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">{{ c.title }}</h5>
          <p class="card-text text-justify">{{ c.text }}</p>
        </div>
    </div>
  </div>
</div>