import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ResolveResult } from './type-resolve-bundle';
import { MySchedulesService } from '@services/my-schedules.service';


@Injectable()
export class AllGymsCustomerResolve implements Resolve<any> {
  constructor(
    private readonly myScheduleService: MySchedulesService,
  ) {}

  resolve(): ResolveResult<any> {
    return this.myScheduleService.getGymUnitsToAvailable();
  }
}
