import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ResolveResult } from './type-resolve-bundle';
import { AuthService } from '@services';



@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private readonly authService: AuthService,
  ) { }

  private verificarAcesso() {
    return this.authService.checkAuth();
  }

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): ResolveResult<boolean> {
    const auth = this.verificarAcesso();
    if (!auth) {
      this.authService.logout(state.url);
    }
    return auth;
  }

  canLoad(): ResolveResult<boolean> {
    return this.verificarAcesso();
  }

}
