import { AppConstants } from '@utils/app-constants';
import { Component, OnInit } from '@angular/core';
import { ModalType, PreSalePurchase, AlertMessage, MessageMap } from '@models';

import { AuthService, LoadingService, ModalService,  GymService, PolicyService, MyPurchasesService, AlertMessageService } from '@services';

import { ModalCommonComponent } from '../modal-common.component';

import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-presale-details',
  templateUrl: './modal-presale-details.component.html',
  styleUrls: ['./modal-presale-details.component.scss'],
})
export class ModalPresaleDetailsComponent extends ModalCommonComponent implements OnInit {

  readonly locale = AppConstants.LOCALE;
  preSalePurchase: PreSalePurchase;

  cancelConfirm = false;

  user = {
    login: '',
    password: '',
  };

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly authService: AuthService,
    private readonly gymService: GymService,
    private readonly loadingService: LoadingService,
    private readonly myPurchasesService: MyPurchasesService,
    private readonly policyService: PolicyService,
    protected modalService: ModalService,
  ) {
    super();
    this.type = ModalType.presaleDetails;
  }

  init() {
    this.user.login = this.authService.getUser().login;
    this.preSalePurchase = this.modalObj.data.preSalePurchase || {};

    this.gymService.queryGetGymUnitSlug(this.preSalePurchase.idGymUnit)
      .pipe(
        map(json => this.preSalePurchase.slug = json ),
        map(() => {
          this.policyService.getTermByGymUnitSlugObservable(this.preSalePurchase.slug).subscribe((res) => {
            this.preSalePurchase.term = res[this.preSalePurchase.slug].url;
          });
        }),
      ).subscribe();
  }

  hidden() {
    this.cancelConfirm = false;
  }

  preCancelPlan() {
    this.cancelConfirm = true;
  }

  confirmCancel() {
    this.loadingService.startLoading();
    // é feita a chamada de autenticação para o usuario confirmar a senha dele
    this.authService.authenticateUser({email: this.user.login, password: this.user.password})
      .pipe(
        mergeMap(() => this.myPurchasesService.cancelPresalePurchase(this.preSalePurchase)),
      )
      .subscribe(retorno => {
        this.alertMessageService.showToastr(AlertMessage.success(MessageMap.PRE_VENDA_CANCELADA), [retorno.EmailCliente]);
        this.authService.logout();
      },
      () => this.loadingService.stopLoading(),
    );
  }

}
