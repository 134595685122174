import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { LoadingService, UserService } from '@services';
import { FormUtil } from '@utils/form-util';
import { BrandService } from '@services/brand.service';
import { MatchValidation } from 'app/shared/validators/equal-validator';
import { NewRecoveryAccountStages } from '../new-recovery-account.component';
import { UpdateUserCredentials } from '@models';
import { AppConstants } from '@utils/app-constants';

@Component({
  selector: 'app-create-or-recovery-account',
  templateUrl: './create-or-recovery-account.component.html',
  styleUrls: [
    '../../area-user.component.scss',
  ],
})
export class CreateOrRecoveryAccountComponent implements OnInit {

  public form: FormGroup;

  newRecoveryAccountStages = NewRecoveryAccountStages;

  @Input() user: any;
  @Input() currentStage: NewRecoveryAccountStages;

  @Output() currentStageChange = new EventEmitter<NewRecoveryAccountStages>();

  readonly emailRegex = AppConstants.EMAIL_REGEXP;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly loadingService: LoadingService,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  searchEmail() {
    FormUtil.touchForm(this.form);

    if (this.form.invalid) {
      return;
    }

    this.loadingService.startLoading();
  }

  returnToFoundAndShowEmailStage() {
    this.currentStage = this.user.email
      ? this.newRecoveryAccountStages.FoundAndShowEmail
      : this.newRecoveryAccountStages.RecoveryEmailByCpfOrRegistration;
    this.currentStageChange.emit(this.currentStage);
  }

  submit() {
    FormUtil.touchForm(this.form);

    if (this.form.invalid) {
      return;
    }
    const date = this.user.birthdate.split('/');
    const params: UpdateUserCredentials = {
      dateOfBirth: `${date[2]}-${date[1]}-${date[0]}`,
      brand: this.brandService.getBrand().toUpperCase(),
      email: this.form.value.email,
      password: this.form.value.password
    };
    this.user.registration
      ? params.registration = this.user.registration
      : params.cpf = (this.user.cpf as string).replace(/[^0-9]/g, '');
    this.updateOrCreateUser(params);
  }

  private updateOrCreateUser(params: UpdateUserCredentials) {
    this.loadingService.startLoading();
    this.userService.updateUserByRegistrationOrCpf(params).subscribe(res => {
      this.loadingService.stopLoading();
      if (res.data && res.data.updateUserCredentials.code === 25) {
        this.goToSuccessStage();
      }
    }, () => {
      this.loadingService.stopLoading();
    });
  }

  private goToSuccessStage() {
    this.currentStage = this.newRecoveryAccountStages.UpdateOrCreateSuccess;
    this.currentStageChange.emit(this.currentStage);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl(this.user ? this.user.email : null, [Validators.required, Validators.pattern(this.emailRegex)]),
      confirmEmail: new FormControl(this.user ? this.user.email : null, [Validators.required, Validators.pattern(this.emailRegex)]),
      password: new FormControl(null, [Validators.required, Validators.pattern(/(\S*\d\S*[a-zA-Z]\S*)|(\S*[a-zA-Z]\S*\d\S*)/)]),
      confirmPassword: new FormControl(null, Validators.required),
    },
    {
      validator: [MatchValidation.MatchEmail, MatchValidation.MatchPassword],
    });
  }

}
