import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertMessage, NewAccessForm, NewAccessFormControls, NewAccessRequest, ValidateCpfResponse, MessageMap } from '@models';
import { AlertMessageService, AuthService, LoadingService } from '@services';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['../../../area-user.component.scss'],
})
export class CreateAccountFormComponent implements OnInit {

  @Input() formControls: NewAccessFormControls;
  @Input() userForm: NewAccessForm;
  @Input() validateCpfResponse: ValidateCpfResponse;

  @ViewChild('form', { static: false }) form: NgForm;


  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
  ) { }

  ngOnInit() {
    if (!this.userForm.oldEmail) {
      this.formControls.useOldEmail = false;
    }
  }

  generateNewAccessRequest(): NewAccessRequest {
    return {
      matriculaCliente: this.validateCpfResponse.Matricula.toString(),
      cpfCliente: this.userForm.cpf.toString(),
      senha: this.userForm.password1,
      confirmacaoSenha: this.userForm.password2,
      email: (this.formControls.useOldEmail && this.userForm.oldEmail)
        ? this.userForm.oldEmail
        : this.userForm.email1,
      confirmacaoEmail: (this.formControls.useOldEmail && this.userForm.oldEmail)
        ? this.userForm.oldEmail
        : this.userForm.email2,
    };
  }

  sendForm() {
    this.loadingService.startLoading();
    const newAccessData = this.generateNewAccessRequest();

    this.authService.registerNewAccess(this.validateCpfResponse.UnidadePrincipal, newAccessData).subscribe(
      () => this.formControls.currentStep++, // Mostra página de sucesso
      (err: any) => this.alertMessageService.showToastr(err),
      () => this.loadingService.stopLoading(),
    );

  }

  goBack() {
    this.formControls.currentStep--; // Volta à página de digitar o CPF
  }

  send() {
    FormUtil.touchForm(this.form.control);

    if (this.form.valid) {
      this.sendForm();
    } else {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
    }
  }

}
