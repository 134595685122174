<app-modal-credit-card-info></app-modal-credit-card-info>

<div>
    <div class="title-section">
      ATUALIZAR FORMA DE PAGAMENTO
    </div>
    
    <p class="text-center">
      <b>Atenção:</b>
      {{
        userInfo.hasFinancialPending ?
        'seu contrato atualmente está vencido. Ao alterar seu Cartão de Crédito, seu plano 
        será renovado e você receberá uma nova cobrança. Este será seu novo Cartão atrelado à Unidade
        correspondente ao Plano, sendo utilizado em possíveis cobranças futuras nesta Unidade.'
        : 
        'essa alteração não gerará uma nova cobrança, seu novo Cartão de Crédito será 
        utilizado para as cobranças futuras do seu Plano. Este será seu novo Cartão atrelado à Unidade 
        correspondente ao Plano, sendo utilizado em possíveis cobranças futuras nesta Unidade.'
      }}
      
    </p>

    <form [formGroup]="form">
      <div class="row small-padding">
        <div class="col-sm-12 col-md-6">
          <label for="credit-card-number" class="label-input">Número do cartão</label>
          <input id="credit-card-number" type="text" name="credit-card-number" class="form-control" 
          required autocomplete="cc-number" formControlName="creditCardNumber" 
          minlength="14" maxlength="19" onlyNumber>
          <app-error-message [control]="form.controls.creditCardNumber"></app-error-message>
        </div>
        <div class="col-sm-12 col-md-6">
          <label for="card-expiring-month" class="label-input">Validade</label>
          <div class="row">
            <div class="col-6">
              <ng-select [items]="months" [searchable]="false" [clearable]="false"
                [selectOnTab]="true" placeholder="Mês" formControlName="cardExpiringMonth">
              </ng-select>
              <app-error-message [control]="form.controls.cardExpiringMonth"></app-error-message>  
            </div>
            <div class="col-6">
              <ng-select [items]="years" [searchable]="false" [clearable]="false"
              [selectOnTab]="true" placeholder="Ano" formControlName="cardExpiringYear">
              </ng-select>
              <app-error-message [control]="form.controls.cardExpiringYear"></app-error-message>
            </div>
          </div>
        </div>
      </div>
      <div class="row small-padding h-140">
        <div class="col-sm-12 col-md-6">
          <label for="name-printed-credit-card" class="label-input">Nome impresso no cartão</label>
          <input id="name-printed-credit-card" name="name-printed-credit-card" formControlName="namePrintedInCreditCard"
            class="form-control" required autocomplete="cc-name">
          <app-error-message [control]="form.controls.namePrintedInCreditCard"></app-error-message>
        </div>
        <div class="col-sm-12 col-md-3">
          <label for="credit-card-flag" class="label-input">Bandeira</label>
          <ng-select [items]="flagCardOptions" bindLabel="text" [searchable]="false" [clearable]="false"
            [selectOnTab]="true" placeholder="Selecione" formControlName="creditCardFlag">
          </ng-select>
          <app-error-message [control]="form.controls.creditCardFlag"></app-error-message>
        </div>
        <div class="col-sm-12 col-md-3">
          <label for="cvv" class="label-input mr-1">CVV</label>
          <i class="fa fa-question-circle question-icon"  (click)="openCVVModal()" aria-hidden="true"></i>
          <input id="cvv" name="cvv" class="form-control" required minlength="2"   maxlength="4" autocomplete="cc-csc"
            formControlName="CVV" onlyNumber>
          <app-error-message [control]="form.controls.CVV"></app-error-message>
        </div>
      </div>
      <div class="text-center button-container buttons-container">
        <button type="button" id="credit-card-info-back"
        (click)="goBackToContract()"
        class="btn btn-outline-primary btn-confirm mr-24"
      >
        Voltar
      </button>
        <button type="submit" id="credit-card-save"
          (click)="updateCreditCard()"
          class="btn btn-primary btn-confirm mb-12"
        >
          Salvar Alterações
        </button>
      </div>
    </form>
  </div>

<ng-template #error>
  <span class="text-center">{{ errorMessage }}</span>
</ng-template>