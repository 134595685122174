import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { WorkWithUsComponent } from './work-with-us.component';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';

const workWithUsRoute = [
  {
    path: '',
    component: WorkWithUsComponent,
    resolve: {
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.imageHeader.title}',
      windowContext: 'work-with-us',
      slug: 'work-with-us',
      brand: 'bt',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(workWithUsRoute)],
  exports: [RouterModule],
})
export class WorkWithUsRoutingModule { }
