import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ImageHeaderService } from '@services';
import { ResolveResult } from './type-resolve-bundle';
import { of } from 'rxjs';

@Injectable()
export class ImageHeaderResolve implements Resolve<any> {
  constructor(private readonly imageHeaderService: ImageHeaderService) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<any> {
    if (route.data.slug) {
      return this.imageHeaderService.getPortalPageHeaderByTag(route.data.slug);
    } else {
      return of(undefined);
    }
  }
}
