import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[inputType]'
})
export class InputTypeDirective {
  constructor(private _el: ElementRef) { }

  @Input() inputType: string;
  // tipos: number, letter, cuit, tel

  @HostListener('input', ['$event']) onInputChange(event: any) {
    switch (this.inputType) {
      case 'number': {
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
        if (initalValue !== this._el.nativeElement.value) {
          event.stopPropagation();
        }
        break;
      }
      case 'text': {
        const result = event.data?.match(/[^a-zA-Z Ññ]*/g);
        if (result[0] !== '') {
          const initalValue = this._el.nativeElement.value;
          this._el.nativeElement.value = initalValue.replace(
            /[^a-zA-Z Ññ]*/g,
            ''
          );
          event.stopPropagation();
        }
        break;
      }
      case 'tel':
      case 'cuit': {
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(/[^0-9-]*/g, '');
        if (initalValue !== this._el.nativeElement.value) {
          event.stopPropagation();
        }
      }
    }
  }
}
