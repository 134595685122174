import { catchError, switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {
  MyContractResponse,
  MessageMap,
  DocuSignTransaction,
  ModalType,
  DocuSignStatusDoc,
} from '@models';
import { MyContractService } from '@services/my-contract.service';
import { AppConstants } from '@utils/app-constants';
import { EMPTY, Observable } from 'rxjs';
import { MapOfURLs, PolicyService } from '@services/policy.service';
import { of } from 'zen-observable';
import { ContractStatusEnum } from '@utils/contract-status-enum';
import { DocuSignService } from '@services/docuSign.service';
import {
  ContractSearchType,
  docuSignContractErrorMessages,
  DocusignTransactionStatusEnum,
} from '@utils/docusign-transaction-status.util';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent implements OnInit {
  locale = AppConstants.LOCALE;
  contractList: Observable<MyContractResponse[]>;
  contractsURLs: MapOfURLs;
  docuSignContractsURLs: any[];
  emptyMessage = 'Dados indisponíveis';
  showUpdatePaymentForm = false;
  selectedPlanUserInfo: MyContractResponse;
  contractStatusEnum = ContractStatusEnum;

  flagCardOptions: any[] = [
    { text: 'Visa', id: 1, value: 1 },
    { text: 'Mastercard', id: 2, value: 2 },
    { text: 'Amex', id: 3, value: 3 },
    { text: 'Diners', id: 4, value: 4 },
    { text: 'Elo', id: 5, value: 5 },
  ];

  constructor(
    private readonly contractService: MyContractService,
    private readonly policyService: PolicyService,
    private readonly docusignService: DocuSignService,
    private readonly loadingService: LoadingService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit() {
    this.getContractList();
  }

  updatePaymentForm(selectedPlan: MyContractResponse): void {
    this.showUpdatePaymentForm = true;
    this.selectedPlanUserInfo = selectedPlan;
  }

  private getContractList() {
    this.contractList = this.contractService.getInfo().pipe(
      catchError((res) => {
        if (res.status === AppConstants.HTTP_FORBIDDEN) {
          this.emptyMessage = MessageMap.INFO_NAO_DISPONIVEIS;
        }
        return of([]);
      })
    );
  }

  getContractUrl(contractInfo: any) {
    this.loadingService.startLoading();

    this.docusignService.checkDocusignTransaction(contractInfo.gym.id, contractInfo.contractCode, ContractSearchType.Plan)
      .pipe(switchMap((transaction) =>
          this.contractHasDocuSign(transaction)
          ? this.docusignService.getDocuSignStatus(transaction.CodigoTransacaoDocusign)
          : this.contractWithoutDocusign(transaction.CodigoEstadoTransacao)
        ),
      catchError(() => {
        return this.policyService.getTermByGymUnitSlugObservable(contractInfo.gym.slug);
      }))
      .subscribe((doc: DocuSignStatusDoc) => {
        const docUrl = doc.getStatusDoc?.preSignedPdfUrlS3Bucket || doc[contractInfo.gym.slug].url;
        this.loadingService.stopLoading();
        window.open(docUrl);
      },
      () => {
        this.loadingService.stopLoading();
      }
    );
  }

  private contractWithoutDocusign(transactionStatus: number): Observable<never> {
    this.loadingService.stopLoading();

    if (transactionStatus) {
      const message = transactionStatus === DocusignTransactionStatusEnum.Sent
        ? docuSignContractErrorMessages.sentMessage
        : docuSignContractErrorMessages.unavailableMessage;

      const title = transactionStatus === DocusignTransactionStatusEnum.Sent
        ? 'AGUARDANDO ASSINATURA'
        : 'CONTRATO INDISPONÍVEL';

      const modal = {
        type: ModalType.confirm,
        title,
        message,
      };
      this.modalService.show(modal);
    }

    return EMPTY;
  }

  private contractHasDocuSign(docusign: DocuSignTransaction): boolean {
    return (
      docusign.CodigoEstadoTransacao === DocusignTransactionStatusEnum.Completed ||
      docusign.CodigoEstadoTransacao === DocusignTransactionStatusEnum.Refused
    );
  }
}
