import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';

import { FuseModule } from '@pipes/fuse/fuse.module';

import { ModalGymSelectComponent } from './modal-gym-select.component';


@NgModule({
  declarations: [ ModalGymSelectComponent ],
  imports: [
    CommonModule,
    FormsModule,

    ModalModule,

    FuseModule,
  ],
  exports: [ ModalGymSelectComponent ],
})
export class ModalGymSelectModule { }
