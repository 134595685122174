<div class="background-shadow"></div>

<nav
  appOpenModalPadding="right"
  class="navbar fixed-top container w-100 navbar-expand-lg navbar-dark align-items-start justify-content-start py-0"
  [class.formula-only]="brand === 'fr'"
>
  <button
    class="btn no-btn navbar-brand d-flex"
    type="button"
    (click)="openAlertModal()"
  >
    <img class="mt-auto" alt="Logo da {{brandNameLowerCase}}" src="assets/custom_images/img-logo.svg">
  </button>

  <h1
    class="text-primary title-section d-flex align-items-end ml-3"
    *ngIf="(mainGymInfo$ | async) as gymInfo"
  >
    {{ gymInfo.name }}
  </h1>
</nav>

<div class="container-fluid evo d-flex align-items-center pb-5">
  <iframe
    *ngIf="iframeUrl"
    frameborder="0"
    [src]="iframeUrl"
  ></iframe>
</div>