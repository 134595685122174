import { NgModule } from '@angular/core';

import { OpenModalPaddingDirective } from './open-modal-padding.directive';
import { OpenModalPaddingService } from './open-modal-padding.service';
import { WidthFromWindowModule } from './width-from-window/width-from-window.module';


@NgModule({
  providers: [
    OpenModalPaddingService,
  ],
  imports: [
    WidthFromWindowModule,
  ],
  declarations: [
    OpenModalPaddingDirective,
  ],
  exports: [
    OpenModalPaddingDirective,
    WidthFromWindowModule,
  ],
})
export class OpenModalPaddingModule {}
