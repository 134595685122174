import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AccordionModule } from '@directives';
import { BTPassService, EvoGymService } from '@services';

import { SharedModule } from 'app/shared/shared.module';

import { CardPlanComponent } from './card-plan/card-plan.component';
import { FormAdditionalDataComponent } from './form-additional-data/form-additional-data.component';
import { FormPersonalDataComponent } from './form-personal-data/form-personal-data.component';
import { FormSuccessComponent } from './form-success/form-success.component';
import { RegistrationComponent } from './registration.component';
import { RegistrationRoutingModule } from './registration.routing';
import { StepsComponent } from './steps/steps.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,

    AccordionModule,
    RegistrationRoutingModule,
  ],
  declarations: [
    RegistrationComponent,
    StepsComponent,
    FormPersonalDataComponent,
    FormAdditionalDataComponent,
    FormSuccessComponent,
    CardPlanComponent,
  ],
  providers: [
    BTPassService,
    EvoGymService,
  ]
})
export class RegistrationModule { }
