import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTransform',
})
export class DateTransformPipe implements PipeTransform {
  transform(value: string): any {
    return new Date(Number.parseInt(value.slice(6, -7), 10)).toISOString();
  }
}
