<h2 *ngIf="block.title" class="title-section">{{ block.title }}</h2>

<div class="avatar-container" [class.formula-only]="brand === 'fr'" *ngIf="showAll(); else smallPhotos">
  <div class="d-inline-block" *ngFor="let person of block?.content.team; let index = index">
    <img id="person-{{block.id}}-{{index}}"
      alt="Foto de {{person.name}}"
      class="rounded-circle person-avatar"
      [class.active]="activeIndex == index"
      [src]="person.img | bucket"
      appClickable
      (click)="activeIndex = index;">
  </div>
</div>

<ng-template #smallPhotos>
  <div class="d-flex align-middle justify-content-around small-photos" [class.formula-only]="brand === 'fr'">
    <img id="person-{{ block.id }}-prev"
      class="button-prev"
      src="/assets/icons/ic-caret-left-light.svg"
      appClickable
      (click)="prev()">

    <img id="person-{{ block.id }}-{{ activeIndex }}"
      alt="Foto de {{ block?.content.team[activeIndex].name }}"
      class="rounded-circle photo-mobile active"
      [src]="block?.content.team[activeIndex].img | bucket">

    <img id="person-{{ block.id }}-next"
      class="button-next"
      src="/assets/icons/ic-caret-right-light.svg"
      appClickable
      (click)="next()">
  </div>
</ng-template>

<div class="text-container" *ngIf="block?.content.team[activeIndex]">
  <div class="name-container">
    <h3 class="title-md text-primary">{{ block?.content.team[activeIndex].name }}</h3>
    <div class="divider"></div>
    <h4 class="title subtitle">{{ block?.content.team[activeIndex].role | capitalize }}</h4>
  </div>
  <p>{{ block?.content.team[activeIndex].text }}</p>
</div>
