import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocuSignStatusDoc, DocuSignTransaction } from '@models';
import { AppConstants } from '@utils/app-constants';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { AppMicrosservicesDocmodGraphql } from '@utils/app-microservices-docmod-graphql';

import { Apollo } from 'apollo-angular';
import { of, throwError, Observable } from 'rxjs';
import { mergeMap, retry } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { BrandService } from './brand.service';

@Injectable({
  providedIn: 'root'
})
export class DocuSignService {

  constructor(
    private readonly apollo: Apollo,
    private readonly brandService: BrandService,
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    ) { }

  checkDocusignTransaction(gymUnitId: number, searchCode: string, searchType: number, authToken?: any): Observable<DocuSignTransaction> {
    const appToken = localStorage.getItem(AppConstants.STOR_APP_USER_TOKEN) || authToken;
    const headers = { 'X-Authorization': appToken };

    return this.http.get<DocuSignTransaction>(
      back(
        BackEndpoints.checkDocusignTransaction,
        gymUnitId,
        searchCode,
        searchType
      ),
      { headers }
    );
  }

  getDocuSignStatus(uuidTransaction: string): Observable<DocuSignStatusDoc> {
    const brand = this.brandService.getBrand().toUpperCase();

    return this.apollo.use('docModService').mutate({
      mutation: AppMicrosservicesDocmodGraphql.getStatusDoc,
      variables: {
        params: {
          uuidTransaction,
          brand
        }
      },
    }).pipe(
      mergeMap(res => {
        if (res.errors?.length) {
          this.authService.clearAppToken(AppConstants.STOR_DOCUSIGN_TOKEN);
          this.authService.getAppToken(AppConstants.STOR_DOCUSIGN_TOKEN);
          return throwError(res.errors);
        }
        return of(res.data as DocuSignStatusDoc);
      }),
      retry(3)
    );
  }
}
