import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gym, GymPeriodicityStatusEnum, GymUnitGroupPlan, GymUnitPeriodicity,
  GymWithKidsPlans, KidActivity, KidsTimetable, KidsSchedule, KidsWaitingList, ValidateKidsCpfResponse, ScheduleClassResponse } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { back, BackEndpoints } from '@utils/app-endpoints';

@Injectable({
  providedIn: 'root'
})
export class LandingKidsService {
  private invalidateTimetableSource = new BehaviorSubject(false);
  invalidateTimetable = this.invalidateTimetableSource.asObservable();

  constructor(
    private http: HttpClient,
    protected readonly apollo: Apollo,
  ) { }

  getKidsActivitiesList(): Observable<KidActivity[]> {
    // Fará a chamada pra pegar a lista.
    return this.apollo.query<KidActivity[]>({
      query: AppGraphql.queryListBabyKidsActivities,
    })
      .pipe(
        map(JSONUtil.turnApolloMutable<KidActivity[]>('listBabyKidsActivities')),
      );
  }

  listOnlyGymUnitsWithBabyKidsPlansFromView(): Observable<GymWithKidsPlans[]> {
    return this.apollo.query<GymWithKidsPlans[]>({
      query: AppGraphql.queryListOnlyGymUnitsWithBabyKidsPlansFromView,
    })
      .pipe(
        map(JSONUtil.turnApolloMutable<GymWithKidsPlans[]>('listOnlyGymUnitsWithBabyKidsPlansFromView')),
      );
  }

  getKidsPlanList(gym: Gym): Observable<any[]> {

    return this.apollo.query<any[]>({
      query: AppGraphql.queryListBabyKidsPlansByGymUnitFromView,
      variables: {
        gymUnitId: gym.id
      }
    })
      .pipe(
        map(JSONUtil.turnApolloMutable<any[]>('listBabyKidsPlansByGymUnitFromView')),
        map(result => {
          const listGymUnitGroupPlan: GymUnitGroupPlan[] = [];
          let gymUnitGroupPlan: GymUnitGroupPlan;
          let gymUnitPeriodicity: GymUnitPeriodicity;
          let gymUnitPeriodicities: {};

          const saleGroupPlan = result; // .filter(value => value.planForSale);
          const periodicity: any[] = saleGroupPlan.filter((value, index, self) =>
            self.map(x => x.periodicityName).indexOf(value.periodicityName) === index);

          const distinctGroupPlan = saleGroupPlan.filter((value, index, self) =>
            self.map(x => x.planSuperiorId).indexOf(value.planSuperiorId) === index);

          distinctGroupPlan.forEach(groupPlan => {
            // Criando GymUnitGroupPlan e Plans
            gymUnitGroupPlan = {
              shortDescription: groupPlan.gymUnitGroupPlanShortDescription,
              longDescription: groupPlan.gymUnitGroupPlanLongDescription,
              availableActivities: groupPlan.gymUnitGroupPlanAvailableActivities,
              group: null,
              gymUnit: gym,
              portalName: groupPlan.gymUnitGroupPlanPortalName,
              plan: {
                id: groupPlan.planSuperiorId,
                idOriginal: groupPlan.planId,
                slug: groupPlan.planSlug,
                shortName: groupPlan.planShortName,
                longName: groupPlan.planLongName,
                circuit: groupPlan.planCircuit,
                showChannels: groupPlan.planShowChannels,
                debitedMonthly: groupPlan.planDebitedMonthly,
                planExclusive: groupPlan.planGymUnitExclusive,
                gymUnitPeriodicities: [],
              },
            };

            // Criando Periodicities.
            gymUnitPeriodicities = {};

            saleGroupPlan.filter(value => value.planSuperiorId === groupPlan.planSuperiorId)
              .forEach(peri => gymUnitPeriodicities[peri.periodicityName] = peri);

            periodicity.forEach(_periodicity => {

              const periodicityLegacy = gymUnitPeriodicities[_periodicity.periodicityName];

              if (periodicityLegacy) {
                gymUnitPeriodicity = {
                  planValue: periodicityLegacy.gymUnitPeriodicityPlanValue,
                  couponId: periodicityLegacy.gymUnitPeriodicityCouponId,
                  popular: periodicityLegacy.gymUnitPeriodicityPopular,
                  status: GymPeriodicityStatusEnum.DISPONIVEL,
                  planSlug: periodicityLegacy.planSlug,
                  subscriptionFee: periodicityLegacy.gymUnitPeriodicitySubscriptionFee,
                  subscriptionFeeText: periodicityLegacy.gymUnitPeriodicitySubscriptionFeeText,
                  showChannelSubscriptionFee: periodicityLegacy.gymUnitPeriodicityShowChannelSubscriptionFee,
                  registration: periodicityLegacy.gymUnitPeriodicityRegistration,
                  registrationText: periodicityLegacy.gymUnitPeriodicityRegistrationText,
                  showChannelRegistration: periodicityLegacy.gymUnitPeriodicityShowChannelRegistration,
                  periodicity: {
                    id: periodicityLegacy.gymUnitPeriodicityId,
                    name: periodicityLegacy.periodicityName,
                    days: periodicityLegacy.periodicityDays,
                    months: periodicityLegacy.periodicityMonths,
                    slug: periodicityLegacy.periodicitySlug,
                    debitedMonthly: periodicityLegacy.planDebitedMonthly,
                    isAnual: periodicityLegacy.periodicityMonths === 12,
                  },
                };
              } else { // Caso nao haja a periodicidade, criar uma fake
                gymUnitPeriodicity = {
                  status: GymPeriodicityStatusEnum.INDISPONIVEL,
                  periodicity: {
                    name: _periodicity.periodicityName,
                    months: _periodicity.periodicityMonths,
                  },
                };
              }

              gymUnitGroupPlan.plan.gymUnitPeriodicities.push(gymUnitPeriodicity);
            });

            listGymUnitGroupPlan.push(gymUnitGroupPlan);
          });
          return listGymUnitGroupPlan;
        }),
      );
  }

  getKidsTimetable(gymId: number): Observable<KidsTimetable[]> {
    return this.http.get<KidsTimetable[]>(back(BackEndpoints.UrlKidsGrid, gymId));
  }

  validateResponsibleCpf(gymId: string, cpf: number): Observable<ValidateKidsCpfResponse> {
    return this.http.post<ValidateKidsCpfResponse>(back(BackEndpoints.KidsValidateCpf), {
      'idUnidade': gymId,
      'cpf': cpf
    });
  }

  scheduleKidClass(scheduleInfo: KidsSchedule): Observable<ScheduleClassResponse> {
    return this.http.post<ScheduleClassResponse>(back(BackEndpoints.KidsSchedule), scheduleInfo);
  }

  enterWaitingList(studentInfo: KidsWaitingList): Observable<ScheduleClassResponse> {
    return this.http.post<ScheduleClassResponse>(back(BackEndpoints.KidsWaitingList, studentInfo.idUnidade), studentInfo);
  }

  triggerTimetableInvalidation(state: boolean): void {
    this.invalidateTimetableSource.next(state);
  }
}
