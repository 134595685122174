<div class="row">
  <div class="col-12 text-container">
    <h2 class="title-lg text-center">Uma história na medida certa</h2>

    <img *ngIf="screenSize === 'xs' || screenSize === 'sm'"
         class="w-100 width-limit"
         src="/assets/custom_images/timeline/timeline_mobile.svg"
         alt="Linha do tempo">

    <img *ngIf="screenSize === 'md'"
         class="w-100"
         src="/assets/custom_images/timeline/timeline_tablet.svg"
         alt="Linha do tempo">

    <img *ngIf="screenSize === 'lg' || screenSize === 'xl'"
         class="w-100"
         src="/assets/custom_images/timeline/timeline_desktop.svg"
         alt="Linha do tempo">
  </div>
</div>
