import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TagManagerService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  create(id: string) {
    const head = this.document.querySelector('head');
    const body = this.document.querySelector('body');

    const tagManagerElement = this.createScriptElement(this.getScript(id));
    const noScriptElement = this.getNoScriptElemenet(id);
    const tagDmpElement = this.getTagDmp();

    head.insertAdjacentElement('afterbegin', tagManagerElement);
    tagManagerElement.insertAdjacentHTML('beforebegin', '<!-- Google Tag Manager -->');
    tagManagerElement.insertAdjacentHTML('afterend', '<!-- End Google Tag Manager -->');

    head.insertAdjacentElement('afterbegin', tagDmpElement);
    tagDmpElement.insertAdjacentHTML('beforebegin', '<!-- Tag DMP -->');
    tagDmpElement.insertAdjacentHTML('afterend', '<!-- Tag DMP -->');

    body.insertAdjacentElement('afterbegin', noScriptElement);
    noScriptElement.insertAdjacentHTML('beforebegin', '<!-- Google Tag Manager (noscript) -->');
    noScriptElement.insertAdjacentHTML('afterend', '<!-- End Google Tag Manager (noscript) -->');
  }

  private getTagDmp() {
    const scriptElement = this.document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML =
      `var _rl_cn = _rl_cn
        || 0,_rl_ptc = ("https:" == window.location.protocol ? "https" : "http");
      window._rl_ids = window._rl_ids
        || []; window._rely = window._rely
        || [];
      _rl_ids.push({pid:5049,src:3});
      _rely.send = _rely.send?_rely.send:function() {};
      (function() {
        var rl = document.createElement("script");
        rl.type = "text/javascript";
        rl.async = true;
        rl.src = _rl_ptc
          + "://api.retargetly.com/loader?id="
          +  _rl_ids[_rl_ids.length-1].pid;
        rl.id = "rely-api-"+(_rl_cn++);
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(rl, s);
      })();`;

    return scriptElement;
  }

  private createScriptElement(script: string) {
    const scriptElement = this.document.createElement('script');
    scriptElement.setAttribute('async', '');
    scriptElement.innerHTML = script;
    return scriptElement;
  }

  private getScript(id: string): string {
    return `(function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${id}');`;
  }

  private getNoScriptElemenet(id: string) {
    const noScriptElement = this.document.createElement('noscript');

    noScriptElement.insertAdjacentHTML(
      'afterbegin',
      ` <iframe
        src="https://www.googletagmanager.com/ns.html?id=${id}"
        height="0"
        width="0"
        style="display: none; visibility: hidden">
      </iframe> `
    );

    return noScriptElement;
  }
}
