
export class IdHolder<PortalType> {
  canais: any;
  portal: PortalType;
}

export interface ClientData {
  Cliente: {
    Ativo: boolean;
    CPF: string;
    CPFResponsavel: number;
    EmailPrincipal: string;
    ConfirmacaoEmailPrincipal: string;
    DadosCadastro: {
      EstadoCivil: string;
      Nacionalidade: number;
      Naturalidade: string;
      NomeMae: string;
      NomePai: string;
      Profissao: string;
      RG: string;
      OrgaoExpeditor: string;
      Sexo: string;
    },
    DadosContato: {
      Bairro: string;
      CEP: string;
      Complemento: string;
      EmailPublicidade: boolean;
      Endereco: string;
      IdCidade: number;
      Numero: string;
      SQTipoLogradouro: number;
      ListaTelefones: [
        {
          CodigoDDD: string;
          CodigoDDI: number;
          IdTipoTelefone: number;
          NomeTipoTelefone: number;
          NumeroTelefone: string;
          TelefonePreferencial: boolean;
        },
      ],
    },
    Editavel: boolean;
    Estrangeiro: boolean;
    IdEstado: number;
    IdPais: number;
    IdTipoCliente: number;
    Matricula: number;
    IdUsuario: string;
    Nome: string;
    NomeReduzido: string;
    NomeResponsavel: string;
    UnidadePrincipal: number;
  };
  Senha: string;
  SenhaConfirmacao: string;
}
