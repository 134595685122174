import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollaboratorsDepartmentsResponse, CollaboratorsRange, CorporateFormRequest, Department, SelectItem } from '@models';
import { CorporatePrices } from '@models/corporate-prices';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';

import { Apollo } from 'apollo-angular';


@Injectable()
export class CorporateService {
  constructor(
    private readonly apollo: Apollo,
    private readonly http: HttpClient,
  ) { }

  getCollaboratorsAndDepartments(): Observable<CollaboratorsDepartmentsResponse> {
    return this.apollo.query({
      query: AppGraphql.queryListCollaboratorDepartment,
    }).pipe(
      map(JSONUtil.turnApolloMutable()),
    );
  }

  postCorporateForm(formData: CorporateFormRequest): Observable<any> {
    const requestBody = {
      ...(formData.contact),
      ...(formData.company),
      ...(formData.responsible),
    };

    return this.http.post(sur(SurEndpoints.CorporateForm), requestBody);
  }

  collaboratorToSelect(collaborator: CollaboratorsRange): SelectItem<CollaboratorsRange> {
    return Object.assign({
      text: collaborator.numberRange,
    }, collaborator);
  }

  departmentToSelect(dept: Department): SelectItem<Department> {
    return Object.assign({
      text: dept.name,
    }, dept);
  }

  getCorporatePricesDeprecated(): Observable<CorporatePrices[]> {
    return this.http.get<CorporatePrices[]>('assets/data/bt-corporate.json');
  }

  getCorporatePrices(): Observable<CorporatePrices[]> {
    return this.apollo.query<any>({
      query: AppGraphql.queryListCorporates,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<any[]>('listCorporates')),
      map(res => {
        return res.map(item => (
          {
            state: item.gymUnit.address.city.state.name,
            city: item.gymUnit.address.city.name,
            gym: item.gymUnit.portalName,
            dcc: item.dccValue,
            semestral: item.halfYearlyValue,
            anual: item.yearlyValue,
            gymExhibition: '',
            link: item.gymUnit.slug,
          } as CorporatePrices));
      }),
    );
  }
}
