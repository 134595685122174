import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalTabSelectObject, ModalType } from '@models/modal.model';
import { Tab } from '@models/tab.model';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-lateral-tabs',
  templateUrl: './lateral-tabs.component.html',
  styleUrls: ['./lateral-tabs.component.scss']
})
export class LateralTabsComponent {

  @Input() name = 'tab';
  @Input() textKey = 'name';
  @Input() hasIcon = false;
  @Input() tabs: Tab[] = [];
  @Input() activeTab: Tab;
  @Output() activeTabChange = new EventEmitter<Tab>();

  constructor(
    private readonly modalService: ModalService,
    private router: Router,
  ) { }

  selectTab(): void {
    this.modalService.show({
      type: ModalType.tabSelect,
      title: 'Selecione uma Opção',
      message: '',
      confirmCallback: (data: Tab) => {
        this.router.navigate([data.url]);
      },
      tabs: this.tabs,
    } as ModalTabSelectObject);
  }

}
