import gql from 'graphql-tag';

export class AppMicrosservicesDocmodGraphql {

  public static readonly getAuth = gql`
    mutation getAuth($params: GetAuthInput!) {
      getAuth(params: $params) {
        message,
        code,
        success,
        token,
      }
    }
  `;

  public static readonly getStatusDoc = gql`
  mutation getStatusDoc($params: GetStatusDocInput!) {
    getStatusDoc(params: $params) {
      uuidTransaction
      embeddedSignURLs {
        email
        url
        registration
      }
      preSignedPdfUrlS3Bucket
    }
  }
`;
}

