<div *ngIf="post"
    class="post card d-flex flex-md-row flex-column"
    (deferLoad)="loadImage=true">
  <div class="thumbnail image-wrapper"
      [style.background-image]="'url('+ ( loadImage ? ((post.media | async)?.source_url || 'assets/custom_images/img-logo-gray.svg') : '' ) + ')'">
    <div *ngIf="post.date" class="date title">
      <div class="day">{{ post.date | date:'dd':'':locale }}</div>
      <div class="month">{{ post.date | date:'MMM':'':locale }}</div>
    </div>
  </div>
  <div class="post-content">
    <a id="blog-post-{{post.id}}" class="title-section"
      [href]="post.link"
      (click)="clickBlogEntry()"
      (auxclick)="clickBlogEntry()"
      rel="noopener"
      target="_blank"
      [innerHTML]="post?.title.rendered">
    </a>
    <div [appMaxLines]="6" class="excerpt" [innerHTML]="post.excerpt.rendered"></div>
    <a id="sticky-more" class="read-more-link title link"
      rel="noopener"
      target="_blank"
      (click)="clickBlogEntry()"
      (auxclick)="clickBlogEntry()"
      [href]="post.link">
      Leia mais
    </a>
  </div>
</div>
