<ng-template #header>
  <app-header-static-image [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main class="main-wrapper container">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="title-lg">
        <ng-container *ngIf="brand === 'bt'; else privacyFormula1title">
          SUA PRIVACIDADE ESTÁ SEGURA NA BT
        </ng-container>
        <ng-template #privacyFormula1title>
          SUA PRIVACIDADE ESTÁ SEGURA NA FÓRMULA
        </ng-template>
      </h2>
      <p class="main-text">
        <ng-container *ngIf="brand === 'bt'; else privacyFormula1">
          Estamos conectados o tempo todo e na BT não é diferente.
          Seja quando você recebe um e-mail nosso ou quando acessa a agenda das aulas no aplicativo, seus dados são usados.
          E para que a sua jornada digital com a Bodytech seja segura, nosso time trabalha diariamente na proteção dos seus dados pessoais.
          De olho na transparência, criamos esse espaço para os clientes acompanharem o que está sendo feito e tirarem dúvidas.<br><br>
          Se preferir, você pode acessar nossa política de privacidade completa
          <a class="text-underline" href="/privacidade">aqui</a>.
        </ng-container>
        <ng-template #privacyFormula1>
          Estamos conectados o tempo todo e na Fórmula não é diferente.
          Seja quando você recebe um e-mail nosso ou quando acessa a agenda das aulas no site, seus dados são usados.
          E para que a sua jornada digital com a Fórmula seja segura, nosso time trabalha diariamente na proteção dos seus dados pessoais.
          De olho na transparência, criamos esse espaço para os clientes acompanharem o que está sendo feito e tirarem dúvidas.<br><br>
          Se preferir, você pode acessar nossa política de privacidade completa
          <a class="text-underline" href="/privacidade">aqui</a>.
        </ng-template>
      </p>
    </div>
  </div>
  <div class="row card no-side-border-xs p-4 p-md-5 mb-4">
    <div class="title-section">QUAIS INFORMAÇÕES PEDIMOS AOS CLIENTES?</div>
    <div class="mb-secundary">
      Coletamos apenas os dados necessários para prestar o serviço da melhor forma.
      Quando você se cadastra em uma recepção ou pelo site, nós pedimos nome, endereço, e-mail, CPF,
      biometria, dados de pagamento, entre outros.
      <span *ngIf="brand === 'bt'">Para tratar dados de menores de idade, solicitamos sempre o consentimento de seus responsáveis legais.</span>
    </div>
    <div class="title-section">
      <ng-container *ngIf="brand === 'bt'; else privacyFormula3title">
        MAS PARA QUE A BT USA MEUS DADOS?
      </ng-container>
      <ng-template #privacyFormula3title>
        MAS PARA QUE A FÓRMULA USA MEUS DADOS?
      </ng-template>
    </div>
    <div class="mb-secundary">
      <div class="d-flex">
        <div class="point">•</div>
        <div>
          Tornar possível a prestação de serviço, como, por exemplo, quando enviamos comunicados aos clientes ou
          elaboramos os contratos.
        </div>
      </div>
      <div class="d-flex">
        <div class="point">•</div>
        <div>
          <ng-container *ngIf="brand === 'bt'; else privacyFormula3b">
            Garantir uma boa experiência entre você e a Bodytech, como quando você entra em contato com a gente pelo site.
          </ng-container>
          <ng-template #privacyFormula3b>
            Garantir uma boa experiência entre você e a Fórmula, como quando você entra em contato com a gente pelo site.
          </ng-template>
        </div>
      </div>
      <div class="d-flex">
        <div class="point">•</div>
        <div>
          Prevenir e evitar fraudes ou qualquer tipo de ato ilegal.
        </div>
      </div>
      <div class="d-flex">
        <div class="point">•</div>
        <div>
          Fazer pesquisas de satisfação diretamente ou através de parceiros.
        </div>
      </div>
      <div class="d-flex">
        <div class="point">•</div>
        <div>
          Cumprir obrigações legais e regulatórias.
        </div>
      </div>
      <div class="d-flex">
        <div class="point">•</div>
        <div>
          Realizar análises estatísticas que permitam avaliar a demanda por academia e ou região.
        </div>
      </div>
      <div class="d-flex">
        <div class="point">•</div>
        <div>
          <ng-container *ngIf="brand === 'bt'; else privacyFormula3g">
            Mostrar nossas campanhas, novos produtos e outros tipos de publicidade através de redes sociais, e-mail ou
            push notification do aplicativo.
          </ng-container>
          <ng-template #privacyFormula3g>
            Mostrar nossas campanhas, novos produtos e outros tipos de publicidade através de redes sociais ou e-mail.
          </ng-template>
        </div>
      </div>
    </div>
    <div class="title-section">COMO CUIDAMOS DAS SUAS INFORMAÇÕES</div>
    <ng-container *ngFor="let card of privacyStorageItems; let i = index; let last = last">
      <div class="d-flex flex-column flex-lg-row align-items-center"
        [ngClass]="{'mb-secundary': last, 'mb-terciary': !last}">
        <div class="card-image"><img [id]="'promo-icon-'+i" class="icon-badge" [src]="card.image"></div>
        <div class="card-text text-center-on-small">{{ card.text }}</div>
      </div>
    </ng-container>

    <div class="title-section">COMPARTILHAMENTO E ARMAZENAMENTO</div>
    <div class="mb-secundary">
      Podemos compartilhar seus dados para cumprir obrigações legais e com parceiros, empresas do grupo e fornecedores
      dentro ou fora do Brasil. Tudo isso é feito apenas quando necessário para garantir a prestação de serviço e
      seguindo medidas de confidencialidade e integridade das informações.
    </div>

    <div class="title-section">NOSSOS COMPROMISSOS</div>
    <div class="mb-secundary">
      <ng-container *ngIf="brand === 'bt'; else privacyFormula6">
        Cada decisão na BT é tomada com cuidado no tratamento de dados pessoais. Por isso, adotamos a metodologia da
        privacidade desde a concepção de qualquer sistema próprio de tecnologia da informação ao ponto de partida do
        desenvolvimento dos nossos projetos de negócio. Buscamos implementar os princípios de <i>privacy by design</i> através da
        responsabilidade ativa nos serviços.
      </ng-container>
      <ng-template #privacyFormula6>
        Cada decisão na Fórmula é tomada com cuidado no tratamento de dados pessoais. Por isso, adotamos a metodologia da
        privacidade desde a concepção de qualquer sistema próprio de tecnologia da informação ao ponto de partida do
        desenvolvimento dos nossos projetos de negócio. Buscamos implementar os princípios de <i>privacy by design</i> através da
        responsabilidade ativa nos serviços.
      </ng-template>
    </div>

    <div class="title-section">CHEGOU ALGUM SMS OU MENSAGEM NO INSTAGRAM COM CARA DE GOLPE?</div>
    <div class="mb-secundary">
      <ng-container *ngIf="brand === 'bt'; else privacyFormula7">
        Envie para qualquer canal oficial da BT e nos ajude a denunciar essa prática. Além do site e dos nossos perfis
        institucionais nas redes, temos os perfis de cada unidade. É importante lembrar que não pedimos aos clientes
        durante atendimento qualquer tipo de código de verificação.
      </ng-container>
      <ng-template #privacyFormula7>
        Envie para qualquer canal oficial da Fórmula e nos ajude a denunciar essa prática. Além do site e dos nossos perfis
        institucionais nas redes, temos os perfis de cada unidade. É importante lembrar que não pedimos aos clientes
        durante atendimento qualquer tipo de código de verificação.
      </ng-template>
    </div>
    <div class="title-section">TEM ALGUMA DÚVIDA? FALE COM A GENTE!</div>
    <ng-container *ngIf="brand === 'bt'; else privacyFormula8">
      <ng-container *ngFor="let card of questions; let i = index; let last = last">
        <div class="d-flex flex-column flex-lg-row align-items-center">
          <div class="card-image"><img [id]="'promo-icon-'+i" class="icon-badge" [src]="card.image"></div>
          <div class="card-text  text-center-on-small" [innerHtml]="card.text"></div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #privacyFormula8>
      <ng-container *ngFor="let card of questionsFr; let i = index; let last = last">
        <div class="d-flex flex-column flex-lg-row align-items-center">
          <div class="card-image"><img [id]="'promo-icon-'+i" class="icon-badge" [src]="card.image"></div>
          <div class="card-text  text-center-on-small" [innerHtml]="card.text"></div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</main>
