<div class="main-wrapper container justify-content-around d-md-flex">
  <form [formGroup]="form" class="form-validate card small-card row-on-xs no-side-border-xs">
    <div class="mb-24">
      Se você não consegue logar, insira seus dados abaixo.
    </div>

    <div class="flex sm-column">
      <div class="w-100 form-container">
        <label for="gym-field" class="label text-uppercase">TIPO DE DOCUMENTO</label>
        <app-select
          formControlName="documentType"
          name="documentType"
          [list]="documentTypeOptions"
          required
          (ngModelChange)="form.controls.cpf.reset();"
          [searchable]="false"
        ></app-select>

        <app-error-message [control]="form.controls.documentType"></app-error-message>
      </div>

      <div class="w-100 text-left ml-md-24 form-container">
        <label for="gym-field" class="label text-uppercase">DOCUMENTO</label>

        <input
          name="cpf"
          class="form-control
          select-teacher"
          formControlName="cpf"
          appCpfValidator
          [appMaskedInput]="docMask"
          required
        >

        <app-error-message [control]="form.controls.cpf"></app-error-message>
      </div>
    </div>

    <div class="flex mt-15">
      <button type="button" class="btn btn-outline-primary w-100" routerLink="/cliente/treinos">VOLTAR</button>
      <button type="submit" class="btn btn-primary w-100 ml-8 ml-md-24" (click)="submit()">ENVIAR</button>
    </div>
  </form>
</div>
