<div *ngIf="cell.classes; else agendaCell">
  <ng-container *ngFor="let event of cell.classes">
    <div class="event-container kids event" *ngIf="event.nomeAtividade; else eventPlaceholder" appClickable id="agenda-{{event.nomeAtividade}}"
      (click)="showEventKids(event)">
      <div class="title" appMaxLines="1">{{ event.nomeAtividade }}</div>
      <span class="instructor" appMaxLines="2">{{ event.nomeProfessor | capitalize }}</span>
      <div class="learn-more-disclaimer ml-1 d-md-none bottom-position">
        ver mais
      </div>
    </div>
  </ng-container>
</div>

<ng-template #agendaCell>
  <ng-container *ngFor="let event of cell">
    <div class="event-container event" *ngIf="event.itemGridTitle; else eventPlaceholder" appClickable id="agenda-{{event.id}}"
      (click)="showEvent(event)">
      <!-- REMOVE_INTENSITY -->
      <!-- <div class="legend legend-{{ event.intensityColor || 0 }}"></div> -->
      <div class="title" appMaxLines="1">{{ event.itemGridTitle }}</div>
      <span class="instructor" appMaxLines="2">{{ event.teacherName | capitalize }}</span>
      <div [class.ml-1]="!event.isOutdoorPlace" class="bottom-position">
        <div class="learn-more-disclaimer d-md-none">
          ver mais
        </div>
        <img *ngIf="event.isOutdoorPlace" [popoverFade]="popTemplate"
        class="ml-1 mt-1 mr-1" src="assets/icons/outdoor.svg" alt="Aula ao ar Livre">
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #popTemplate>  
  <div class="title font-weight-normal">ATIVIDADE OUTDOOR</div>
</ng-template>

<ng-template #eventPlaceholder>
  <div class="event-container"></div>
</ng-template>
