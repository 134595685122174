import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { map, tap } from 'rxjs/operators';

import { environment, googlemapsapikey } from '@env/environment';
import {
  EvoBaseParamsModel,
  EvoGymResponse,
  Gym,
  GymDetailText,
  GymFilter,
  GymModel,
  GymUnitGroupPlan,
  MembershipModel,
} from '@models';
import { GymSearchConfig } from '@models/configs';
import {
  EvoMembershipResponseModel,
  EvoMembershipsResponseToLegacy,
  MembershipsResponsesUnionType,
} from '@models/membership.model';
import {
  BrandService,
  EvoGeneralService,
  EvoGymService,
  GymService,
  HeaderService,
  PlanService,
  SEOService,
} from '@services';
import { EvoMembershipService } from '@services/evo/evo-membership.service';
import { RequestObject } from '@utils/request-object.class';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-gym',
  templateUrl: './gym.component.html',
  styleUrls: ['./gym.component.scss'],
})
export class GymComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;
  @ViewChild('membershipSection', { static: false })
  membershipSection: ElementRef<HTMLElement>;
  public readonly BT_KIDS_PLAN = 63;

  public membershipsRO: RequestObject<
    MembershipsResponsesUnionType,
    MembershipModel[]
  >;

  public config: GymSearchConfig;

  public gym: GymModel;
  public gymLegacyRO: RequestObject<Gym>;
  public gymDetailText: GymDetailText;
  public services: GymFilter[];

  public contactInfoRO: RequestObject<EvoGymResponse[]>;

  public imageHeader: string;

  constructor(
    public readonly brandService: BrandService,
    public readonly evoGeneralService: EvoGeneralService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly evoGymService: EvoGymService,
    private readonly evoMembershipService: EvoMembershipService,
    private readonly gymService: GymService,
    private readonly headerService: HeaderService,
    private readonly planService: PlanService,
    private readonly router: Router,
    private readonly seoService: SEOService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit(): void {
    this.config = this.activatedRoute.snapshot.data.config.gymSearch;
    this.gymDetailText = this.activatedRoute.snapshot.data.config.gymDetailText;
    this.services = this.getTraining();

    this.requestGymLegacy();
  }

  async ngAfterViewInit(): Promise<void> {
    this.refreshHeader();
  }

  getTraining(): GymFilter[] | [] {
    const training =
      (this.activatedRoute.snapshot.data.groups as GymFilter[]) || [];

    return training.map((specificTraining) => ({
      ...specificTraining,
      activityGroup: { id: -1 },
    }));
  }

  /**
   * Scroll to membership section with a smooth animation.
   *
   * @param delay - *`Optional`* Apply a `ms` delay to scroll. `scrollIntoView` can't scroll to a element if some element is current
   *                             changing his size, so the delay has to be the total of time that took to a element to change his size.
   */
  scrollToMembership(delay = 0): void {
    setTimeout(
      () =>
        this.membershipSection.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        }),
      delay
    );
  }

  private refreshHeader(): void {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  private getContactInfo(): void {
    this.contactInfoRO = new RequestObject<EvoGymResponse[]>(
      this.evoGymService.getGymsContactInfo(this.getGymUnitIdParam())
    );

    this.contactInfoRO.observable$.subscribe((response) => {
      this.setSEO();

      this.gym.contactInfo = response[0];
    });
  }

  private getGym(): GymModel {
    return {
      ...this.gymService.mapGymToGymModel(this.gymLegacyRO.response),
      coordinates: {
        latitude: +this.gymLegacyRO.response.address.latitude,
        longitude: +this.gymLegacyRO.response.address.longitude,
      },
    };
  }

  private requestGymLegacy(): void {
    const slug = this.activatedRoute.snapshot.params.slug;

    this.gymLegacyRO = new RequestObject<Gym>(
      this.gymService.getLegacyGymBySlug(slug)
    );

    this.gymLegacyRO.observable$.subscribe((response) => {
      if (!response) {
        this.gymLegacyRO.error = true;
        this.router.navigate(['/academias']);

        return;
      }

      this.gymLegacyRO.response = response;

      this.gym = this.getGym();

      this.imageHeader = this.gymService.getBannerImage(response.photos)?.url;

      this.refreshHeader();
      this.requestMembership();

      const scriptMap = this.document.createElement('script');
      scriptMap.innerHTML = this.getScriptMap();
      this.document.body.appendChild(scriptMap);
      this.initMap();

      if (this.gym.evoMigratedUnit) {
        this.getContactInfo();
      } else {
        this.setSEO();
      }
    });
  }

  private requestMembership(): void {
    if (this.gym.evoMigratedUnit) {
      this.membershipsRO = new RequestObject(
        this.evoMembershipService.getMemberships({
          ...this.getGymUnitIdParam(),
        })
      );
    } else {
      this.membershipsRO = new RequestObject(
        this.planService.getActivePlansByGymUnit(this.gymLegacyRO.response)
      );

      this.membershipsRO.observable$ = this.membershipsRO.observable$.pipe(
        map<GymUnitGroupPlan[], EvoMembershipsResponseToLegacy[]>((response) =>
          this.evoMembershipService.convertLegacyToEvo(response)
        )
      );
    }

    this.membershipsRO.observable$ = this.membershipsRO.observable$.pipe(
      tap<EvoMembershipResponseModel[] | EvoMembershipsResponseToLegacy[]>(
        (response) => {
          this.membershipsRO.response =
            this.evoMembershipService.mapMembershipsToComponent(response);
        }
      )
    );

    this.membershipsRO.observable$.subscribe((_) => {
      if (this.router.url.endsWith('matricule-se')) {
        this.scrollToMembership();
      }
    });
  }

  private getGymUnitIdParam(): Pick<EvoBaseParamsModel, 'gymUnitId'> {
    return { gymUnitId: this.gym.id.toString() };
  }

  private setSEO(): void {
    this.seoService.setGeneralSEO(
      {
        ...this.activatedRoute.data,
        windowTitle: `${environment.seo.DEFAULT_TITLE} - ${this.gym.contactInfo.name}`,
        slug: this.activatedRoute.snapshot.params.slug,
      },
      false
    );
  }

 // tslint:disable
  async initMap() {
    let googleMap;

    // @ts-ignore
    const { Map } = (await google.maps.importLibrary(
      'maps'
      //@ts-ignore
    )) as google.maps.MapsLibrary;
    //@ts-ignore
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
      //@ts-ignore
    )) as google.maps.MarkerLibrary;

    const mapPosition = {
      lat: this.gym.coordinates ? this.gym.coordinates.latitude + 0.0008 : 0,
      lng: this.gym.coordinates ? this.gym.coordinates.longitude : 0,
    };

    const markerPosition = {
      ...mapPosition,
      lat: this.gym.coordinates ? this.gym.coordinates.latitude : 0,
    };

    googleMap = new Map(this.document.getElementById('map') as HTMLElement, {
      zoom: 17,
      center: mapPosition,
      mapId: 'map',
      clickableIcons: false,
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      keyboardShortcuts: false,
      mapDraggable: false,
      scrollwheel: false,
    });

    const iconImg = document.createElement('img');
    iconImg.src = 'assets/icons_custom/ic-link-location@2x.png';

    new AdvancedMarkerElement({
      map: googleMap,
      position: markerPosition,
      content: iconImg,
    });
  }
    // tslint:enable

  getScriptMap() {
    return `(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
    v: "weekly",
    key: "${googlemapsapikey}"
    });`;
    }
}
