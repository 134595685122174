import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { HomeComponent } from './home.component';

const homeRoute: Route[] = [
    {
        path: '',
        component: HomeComponent,
        resolve: { config: PortalConfigResolveGuard },
        data: {
            windowContext: 'main',
            config: { home: 'home' },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(homeRoute)],
    exports: [RouterModule],
})
export class HomeRoutingModule { }
