<ng-template #header>
  <app-header-static-image pageTitle="{{pageTitle}}" [prefixBucket]="false" imageUrl="assets/headers/user_area.jpg"></app-header-static-image>
</ng-template>

<div class="main-wrapper container justify-content-around d-md-flex">

  <div class="card small-card row-on-xs no-side-border-xs">
    <div class="form-container text-left">
      <div class="title-section text-black">{{ title }}</div>
      <div>
        {{ message }}
      </div>
    </div>

    <div class="button-container flex sm-column-reverse no-gutters" *ngIf="secundaryButtonName; else singleButton">
      <button type="button" id="secundary-button" class="col btn btn-outline-primary" [routerLink]="secundaryRedirectTo">
        {{ secundaryButtonName }}
      </button>
      <button type="submit" id="primary-button" class="col btn btn-primary" [routerLink]="redirectTo" [queryParams]="queryParams">
        {{ buttonName }}
      </button>
    </div>
    <ng-template #singleButton>
      <div class="button-container text-center" >
        <a id="request-confirm" class="col btn btn-primary btn-confirm" [routerLink]="redirectTo" [queryParams]="queryParams">
          {{ buttonName }}
        </a>
      </div>
    </ng-template>
  </div>

</div>
