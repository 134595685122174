<div class="alert-container pull-right col-12 col-md-6 col-lg-4">
  <ng-container *ngFor="let message of messages | async; let i = index">
    <div class="text-right">
      <div class="badge badge-light badge-date small text-muted">
        <i *ngIf="message.timeout <= 0" class="fas fa-thumbtack"></i>
        <i *ngIf="message.timer != -1" class="far fa-clock fa-spin"></i>
        {{ message.when | date:'medium' }}
      </div>
    </div>
    <div id="alert-{{i}}" class="clickable alert alert-{{ message.type || 'info' }}"
      (mouseenter)="alertService.stopTimer(message)"
      (mouseleave)="alertService.startTimer(message)"
      (click)="alertService.dismiss(message)">
      <button id="alert-close-{{i}}" aria-label="Close" class="clickable close" type="button">
        <span aria-hidden="true">×</span><span class="sr-only">Close</span>
      </button>
      <span><i class="fas pr-1 fa-{{ message.icon() }}"></i> <strong>{{ message.title }}</strong></span>
      <div class="pt-2">
        <span style="white-space: pre-line;">{{ message.message }} </span>
      </div>
    </div>
  </ng-container>
</div>
