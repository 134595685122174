<h2 class="title-lg {{ titleClasses }}">{{ title }}</h2>

<div class="container mb-3 pb-3 z-index-2">
  <div class="row card filter-container">
    <div class="row no-gutters filter">
      <div
        *ngIf="isPlaceFilter"
        appDropdownArrows
        id="gym-filter-activities"
        (click)="showStatesModal()"
        class="col title-md collapsed border-right cursor-pointer"
      >
        <img alt="Filtro por tipo de atividade" class="filter-icon" src="assets/icons/ic-state.svg">
        <span class="d-none d-lg-block font-weight-normal">ESTADO</span>
      </div>

      <div
        *ngIf="isPlaceFilter"
        appDropdownArrows
        id="gym-filter-benefits"
        (click)="showCitiesModal()"
        class="col title-md collapsed border-right cursor-pointer"
      >
        <img alt="Filtro por serviço" class="filter-icon" src="assets/icons/ic-city.svg">
        <span class="d-none d-lg-block font-weight-normal">CIDADE</span>
      </div>

      <div
        *ngIf="isGymFilter"
        appDropdownArrows
        id="agenda-filter-place"
        (click)="showGymByCitiesOfStateModal()"
        class="col title-md collapsed font-weight-normal p-0 cursor-pointer"
      >
        <img alt="Filtro por academia" class="filter-icon gym-filter-icon" src="assets/icons/ic-filter-place.svg">
        <span class="d-none d-lg-block font-weight-normal">ACADEMIA</span>
      </div>

      <div
        *ngIf="isPlaceFilter"
        class="col title-md collapsed align-items-center d-flex justify-content-center text-secondary cursor-pointer"
        id="gym-filter-clear"
        (click)="clearFilter()"
      >
        <img alt="Limpar filtros" class="clear-icon mr-lg-2" src="assets/icons_custom/ic-recycle-bold.svg">
        <span
          class="d-none d-lg-block font-weight-normal"
          [class.text-primary]="selectedState.length || selectedCity.length || selectedGyms?.length"
        >
          Limpar Filtros
        </span>
      </div>
    </div>

    <div #filtertags *ngIf="isPlaceFilter" class="row selected-container">
      <ng-container *ngIf="selectedState.length">
        <ng-container *ngTemplateOutlet="filterBadgeTemplate; context: { label: selectedState, clickFunction: removeStateFilter }"></ng-container>
      </ng-container>

      <ng-container *ngIf="selectedCity.length">
        <ng-container *ngTemplateOutlet="filterBadgeTemplate; context: { label: selectedCity, clickFunction: removeCityFilter }"></ng-container>
      </ng-container>

      <ng-container *ngIf="selectedGyms?.length">
        <ng-container *ngTemplateOutlet="filterBadgeTemplate; context: { label: selectedGyms?.length, clickFunction: removeGymFilter }"></ng-container>
      </ng-container>
    </div>

    <ng-template let-label="label" let-clickFunction="clickFunction" #filterBadgeTemplate>
      <div class="text-light badge" id="corporate-filter-tag-city">
        <div class="title">{{ label }}</div>

        <button class="btn no-btn" (click)="clickFunction(); filterCorporateData()">
          <img alt="Fechar" class="align-baseline" src="assets/icons/ic-close.svg">
        </button>
      </div>
    </ng-template>

    <div *ngIf="isPlaceFilter" class="d-flex justify-content-center bg-light button-panel">
      <button type="button" class="btn btn-primary btn-confirm" (click)="filterCorporateData()">
        {{ selectedState.length || selectedCity.length || selectedGyms?.length  ? 'EXIBIR VALORES' : 'SELECIONE A REGIÃO' }}
      </button>
    </div>

    <div *ngIf="isGymFilter" class="d-flex justify-content-center bg-light button-panel">
      <button type="button" class="btn btn-primary btn-confirm" (click)="showGymByCitiesOfStateModal()">
        SELECIONE A ACADEMIA
      </button>
    </div>
  </div>
</div>

<app-modal-gym-select-by-city *ngIf="isGymFilter"></app-modal-gym-select-by-city>
<app-modal-single-select *ngIf="isPlaceFilter"></app-modal-single-select>
