<div class="section" *ngIf="showComponent" >
  <!-- NÃO ENCONTROU O QUE PROCURA -->
  <app-footer-help [data]="json?.help"></app-footer-help>

  <div class="container" [class.formula-only]="brand === 'fr'">
    <!-- PARCEIROS -->
    <div class="partners-container" *ngIf="jsonPartners">
      <app-footer-partners [title]="json?.partners.title" [data]="(jsonPartners | async)"></app-footer-partners>
    </div>

    <hr class="divider">

    <!-- MAPAS DO SITE -->
    <app-sitemap [data]="json?.sitemap"></app-sitemap>

    <div class="media-container row justify-content-center">
      <!-- APLICATIVOS -->
      <div class="col-sm-12 col-md no-padding border-right-med-and-up" *ngIf="json?.apps">
        <app-footer-get-app [data]="json?.apps"></app-footer-get-app>
      </div>

      <!-- REDES SOCIAIS -->
      <div class="col-sm-12 col-md no-padding">
        <app-footer-social-media [data]="json?.social"></app-footer-social-media>
      </div>
    </div>
  </div>

  <!-- FUNDO DO SITE -->
  <div class="banner-container">
    <app-footer-bottom-banner [data]="json?.footerText"></app-footer-bottom-banner>
  </div>
</div>
