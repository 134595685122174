<div class="item d-flex flex-row justify-content-between">
  <div class="d-flex flex-column flex-sm-row" [class.flex-column]="myWorkoutsPage">
    <div class="d-flex">
      <div class="week-day" *ngIf="myWorkoutsPage">{{ classInfo.weekDay.name  }}</div>
      <div class="class-hour">
        {{ classHour > 9 ? classHour : '0' + classHour }}:{{ classInfo.initialMinute > 9 ? classInfo.initialMinute : '0' + classInfo.initialMinute }}
      </div>
    </div>
    <div class="class-info d-flex flex-column">
      <div class="class-name">{{ classInfo.activity }}</div>
      <div class="teacher-name">{{ classInfo.teacher.reducedName | capitalize }}</div>
      <div class="class-duration">Duração: {{ classInfo.duration }} minutos</div>
      <div class="fav d-lg-none d-flex flex-row align-items-center mt-3" (click)="favoriteButtonAction(classInfo)">
        <ng-container *ngTemplateOutlet="buttonsSection"></ng-container>
      </div>
    </div>
  </div>
  <div class="d-none d-lg-flex flex-row align-items-center">
    <!-- <ng-container *ngIf="!settingFavorites;else loader"> -->
      <div class="d-flex fav" (click)="favoriteButtonAction(classInfo)">
        <ng-container *ngTemplateOutlet="buttonsSection"></ng-container>
      </div>
    <!-- </ng-container> -->
  </div>
</div>

<ng-template #loader>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>

<ng-template #buttonsSection>
  <ng-container *ngIf="myWorkoutsPage; else groupClassesPage">
    <img class="mr-1 icon" src="/assets/bt_home/ic-cancel.svg" alt="favoritar aula">
    <div class="fav-button scarlet">REMOVER FAVORITO</div>
  </ng-container>
  <ng-template #groupClassesPage>
    <img class="mr-1 icon" [src]="classInfo.favoriteGroupClass ? '/assets/bt_home/ic-fav-2.svg' : '/assets/bt_home/ic-fav-1.svg'" alt="favoritar aula">
    <div class="fav-button green">FAVORITAR</div>
  </ng-template>
</ng-template>
