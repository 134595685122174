import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';

import { Gym, GymUnitGroupPlan } from '@models';
import { Brand, BrandService, BreakpointsService, LandingKidsService, screenSizeMap } from '@services';


@Component({
  selector: 'app-gym-detail-plans',
  templateUrl: './gym-detail-plans.component.html',
  styleUrls: ['./gym-detail-plans.component.scss'],
})
export class GymDetailPlansComponent implements OnDestroy, OnInit {
  private subs: Subscription;

  @Input() gym: Gym;

  gymUnitGroupPlans: GymUnitGroupPlan[];

  swiperConfig: SwiperConfigInterface = {
    loop: false,
    navigation: { nextEl: '.plans-next', prevEl: '.plans-prev' },
    pagination: { el: '.swiper-pagination', clickable: true, hideOnClick: false,
      bulletActiveClass: 'swiper-pagination-bullet-dark' },
    spaceBetween: 12,
    speed: 500,
    threshold: 10,
  };

  plansExpanded = false;
  brand: Brand;


  constructor(
    public readonly brandService: BrandService,
    private readonly breakpointsService: BreakpointsService,
    private readonly landingKidsService: LandingKidsService,
  ) { }

  ngOnInit() {
    this.subs = this.breakpointsService.emitter.subscribe((size: any) => {
      const slidesPerView = screenSizeMap(size, [1, 1, 2, 3, 3]);
      Object.assign(this.swiperConfig, { slidesPerView, slidesPerGroup: 1 });
    });

    this.brand = this.brandService.getBrand();

    this.updateGymPlans();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private updateGymPlans() {
    if (this.gym?.id) {
      this.getLandingPageKidsPlans(this.gym);
    }
  }

  private getLandingPageKidsPlans(gym: Gym) {
    this.landingKidsService.getKidsPlanList(gym).subscribe(res => {
      this.gymUnitGroupPlans = res;
    });
  }

  refresh() {
    Object.assign(this.swiperConfig, { speed: this.swiperConfig.speed > 500 ? 500 : 501 });
  }

  orderUsingAnotherOrderedVector(
    arrayToOrder: GymUnitGroupPlan[],
    orderedArray: string[],
  ): GymUnitGroupPlan[] {
    let orderedReturnArray: GymUnitGroupPlan[] = [];

    orderedArray.forEach(item => {
      const itemToPush = arrayToOrder.find(itemToOrder => item.toUpperCase() === itemToOrder.portalName);
      if (itemToPush) {
        orderedReturnArray = [...orderedReturnArray, itemToPush];
      }
    });

    arrayToOrder.forEach(item => {
      const itemAlreadyPushed = orderedReturnArray.find(itemAlreadyOrdened => item.portalName === itemAlreadyOrdened.portalName);
      if (!itemAlreadyPushed) {
        orderedReturnArray = [...orderedReturnArray, item];
      }
    });

    return orderedReturnArray;
  }
}
