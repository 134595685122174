import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Brand } from './brand.service';

export interface Notice {
  title: string;
  description: string;
  image?: string;
  gyms?: { id: number, brand: Brand }[];
  publishingDate: {
    initial: Date;
    final: Date;
  };
}

@Injectable({
  providedIn: 'root',
})
export class NoticeService {

  notices: Notice[] = [
    {
      title: 'Período de Carnaval',
      description: `Atenção: devido ao período de Carnaval teremos uma grade de aulas diferenciada.
        Para mais informações, consulte a recepção da sua unidade.`,
      publishingDate: {
        initial: new Date('Sat, 22 Feb 2020 01:00:00 GMT'),
        final: new Date('Wed, 26 Feb 2020 16:00:00 GMT'),
      },
    },
    {
      title: 'Período de Fim de Ano',
      description: 'Atenção: devido ao período de Festas teremos uma grade de aulas diferenciada. Para mais informações, consulte a recepção da sua unidade.',
      publishingDate: {
        initial: new Date('23 Dec 2020 23:00:00 GMT'),
        final: new Date('02 Jan 2021 23:00:00 GMT')
      }
    },
  ];

  public getActiveNoticesByGym(id: number, brand: Brand): Observable<Notice[]> {
    const today = new Date();
    return of(this.notices.filter((notice: Notice) => {
      if (notice.publishingDate.initial <= today && notice.publishingDate.final >= today
        && (!notice.gyms || notice.gyms.some(gym => gym.id === id && gym.brand === brand))) {
        return true;
      }
      return false;
    }));
  }
}
