<ng-template #header>
  <app-header-static-image [headerObj]="config.header" [pageTitle]="headerTitle" [imageUrl]="imageHeader"></app-header-static-image>
</ng-template>

<main *ngIf="brand === 'bt'" class="main-wrapper container">
  <app-bt-company-header></app-bt-company-header>

  <div class="card row-on-xs no-side-border-xs section">
    <ng-container *ngFor="let block of config?.blocks">
      <ng-container [ngSwitch]="block.type">
        <div class="block-content block-{{block.type}}" *ngIf="block.type != 'graphic'">
          <app-text-block [block]="block" *ngSwitchCase="'text'"></app-text-block>
          <app-team-block [block]="block" *ngSwitchCase="'team'"></app-team-block>
          <app-file-block [block]="block" *ngSwitchCase="'file'"></app-file-block>
          <app-dropdown-block [block]="block" *ngSwitchCase="'dropdown'"></app-dropdown-block>
        </div>
      </ng-container>
    </ng-container>
  </div>
</main>

<main *ngIf="brand === 'fr'" class="formula-only main-wrapper container">
  <div class="card">
    <app-fr-company-header></app-fr-company-header>

    <ng-container *ngFor="let block of config?.blocks">
      <div class="block-content block-{{block.type}}" *ngIf="block.type === 'team'">
        <app-team-block [block]="block"></app-team-block>
      </div>
    </ng-container>

    <app-fr-timeline></app-fr-timeline>
  </div>
</main>
