import { SelectItem } from '@models';

export class DateUtil {
  public static SHORT_WEEKDAY_NAMES = [
    {id: 0, name: 'DOM'},
    {id: 1, name: 'SEG'},
    {id: 2, name: 'TER'},
    {id: 3, name: 'QUA'},
    {id: 4, name: 'QUI'},
    {id: 5, name: 'SEX'},
    {id: 6, name: 'SAB'},
  ];

  static newDateFromDateString(dateString: string): Date {
    return new Date(dateString + ' 12:00:00');
  }

  /* Converte string de data do formato brasileiro (DD/MM/YYYY) para o formato ISO (YYYY-MM-DDT00:00:00.000Z)
   * Checa apenas formato, não acusando erros em dias ou meses inválidos
   */
  static dateToISO(brDateString: string) {
    try {
      let numbers: any[] = brDateString.split('/');
      numbers = numbers.map(number => parseInt(number, 10));

      const date = new Date(numbers[2], numbers[1] - 1, numbers[0]);
      date.setUTCHours(0, 0, 0, 0);
      return date.toISOString();

    } catch {
      console.error('Data em formato incorreto');
    }
  }

  static sortDateArray(array: Date[]): Date[] {
    return array.sort((a, b) => a.getTime() - b.getTime());
  }

  static filterUniqueDateArray(array: Date[]): Date[] {
    return array.filter((day, index) => DateUtil.getFirstIndex(day, array) === index);
  }

  static getMinutesInDay(date: Date): number {
    return date.getHours() * 60 + date.getMinutes();
  }

  static getFirstIndex(date: Date, array: Date[]): number {
    return array.findIndex(d => d.toDateString() === date.toDateString());
  }

  static timeSince(date: Date): number {
    if (!date) {
      return Number.MAX_SAFE_INTEGER;
    }
    return new Date().getTime() - date.getTime();
  }


  static getMonthsOfTheYear(forAppSelect = false): string[] {
    const months: SelectItem<any>[] = [];
    for (let i = 1; i <= 12; i++) {
      if (i.toString().length === 1) {
        const currentMonth = '0' + i.toString();
        months.push(forAppSelect ? { id: currentMonth, text: currentMonth, value: currentMonth } : currentMonth);
      } else {
        months.push(forAppSelect ? { id: i.toString(), text: i.toString(), value: i.toString() } : i.toString());
      }
    }
    return months;
  }

  static formatLegacyDate(legacyDate: string): Date {
    return new Date(Number.parseInt(legacyDate.slice(6, -7), 10));
  }

  static getYears(forAppSelect = false): string[] {
    const d = new Date();
    const currentYear = d.getFullYear();
    const years: SelectItem<any>[] = [];
    for (let i = currentYear; i <= currentYear + 15; i++) {
      years.push(forAppSelect ? { id: i.toString(), text: i.toString(), value: i.toString() } : i.toString());
    }
    return years;
  }

  // HH:mm to mm, example: 02:23 -> 143
  static getDurationInMinutes(timeToConvert: string): number {
    const hourFromTime = timeToConvert.substr(0, 2);
    const minutesFromTime = timeToConvert.substr(3, 2);

    return (parseInt(hourFromTime, 10) * 60) + parseInt(minutesFromTime, 10);
  }

  static calculateAge(birthday: Date) {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    // ageDifMs acaba retornando um timestamp da diferença entre Agora e a data de nascimento + 1970
    // esse é um comportamento padrão dos Dates no javascript
    // então para pegar apenas a diferença em anos, tiramos 1970 na conta abaixo.
    return Math.floor(ageDate.getUTCFullYear() - 1970);
  }
}
