<img
  appWidthFromWindow
  class="header-image d-block"
  [class.fixed-height]="!unfixedHeight"
  [src]="prefixBucket ? ((imageUrl || headerObj?.image) | bucket) : (imageUrl || headerObj?.image)"
>

<div class="header-text text-light text-center w-100">
  <div class="container">
    <h1 class="title header-title" [ngStyle]="headerObj?.titleStyle">{{ pageTitle || headerObj?.title }}</h1>
  </div>

  <ng-content></ng-content>
</div>
