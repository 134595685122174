import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {

  MIN_LENGHT = 2;
  ignoredWords = ['são'];

  transform(value: string): any {
    if (!value) {
      return '';
    }
    let arr = value.split(' ');
    arr = arr.map(v => this.canCapitalize(v) ? this.doCapitalize(v) : v.toLowerCase() );
    return arr.join(' ');
  }

  canCapitalize(value: string) {
    return value.length > this.MIN_LENGHT && !this.ignoredWords.includes(value.toLowerCase());
  }

  doCapitalize(value: string) {
    return value[0].toUpperCase() + value.slice(1).toLowerCase();
  }

}
