import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AgendaEvent, AgendaResponse, GymAgendaFilter } from '@models';
import { AgendaService } from '@services';
import { ArrayUtil } from '@utils/array-util';

@Component({
  selector: 'app-agenda-timeline',
  templateUrl: './agenda-timeline.component.html',
  styleUrls: ['./agenda-timeline.component.scss'],
})
export class AgendaTimelineComponent implements AfterViewInit {

  readonly weekstart = 1;
  _left = 0;
  get left() { return this._left; }
  set left(val: number) {
    this._left = val;
    this.tick();
  }
  readonly today: number = 0;
  daysColHeader = AgendaService.WEEKDAYS;
  isLoading = true;

  eventTable: AgendaEvent[][][] = [];
  timesRowHeader: string[] = [];

  _hoveringColumn: number;
  get hoveringColumn() { return this._hoveringColumn; }
  set hoveringColumn(val: number) {
    if (this._hoveringColumn !== val) {
      this._hoveringColumn = val;
      this.tick();
    }
  }

  @Input() filter: GymAgendaFilter = {} as GymAgendaFilter;

  constructor(
    private readonly agendaService: AgendaService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.today = new Date().getDay() - this.weekstart;
  }

  ngAfterViewInit() {
    this.cdr.detach();
  }

  public tick() {
    this.cdr.detectChanges();
  }

  public populateAgenda() {
    this.eventTable = [];
    this.timesRowHeader = [];
    this.isLoading = true;
    this.tick();

    if (!this.filter.gym) {
      this.isLoading = false;
      this.tick();
      return;
    }

    const id = this.filter.gym.id;
    const activities = this.filter.activities.map(i => i.id);
    const ages = this.filter.ages.map(i => i.id);

    this.agendaService.getEvents(id, activities, ages)
      .subscribe(json => {
        const eventsByHour = this.getEventsByHourFromJson(json);
        const eventsByHourByDay = this.groupEventsByHourByDay(eventsByHour);

        for (const eventRow of eventsByHourByDay) {
          this.insertEventRowIntoArrays(eventRow);
        }

        setTimeout(() => {
          this.isLoading = false;
          this.tick();
        }, 0);
      });
  }

  private getEventsByHourFromJson(json: AgendaResponse): AgendaEvent[][] {
    const allEvents: AgendaEvent[][] = [];
    for (const column of json.days) {
      for (const backendEvent of column.events) {
        // Creating a copy and converting dayOfTheWeek to index
        const frontendEvent = Object.assign({}, backendEvent, {
          dayOfTheWeek: json.days.indexOf(column),
        });
        ArrayUtil.safePushIntoMap(allEvents, this.getTimeInMinutes(frontendEvent), frontendEvent);
      }
    }

    return ArrayUtil.mapToArray(allEvents);
  }

  private getTimeInMinutes(event: AgendaEvent) {
    return event.activityInitialTimeHour * 60 + event.activityInitialTimeMinute;
  }

  private groupEventsByHourByDay(eventsByHour: AgendaEvent[][]): AgendaEvent[][][] {
    const eventsByHourByDay = [];

    for (const eventsHour of eventsByHour) {
      const eventsCells = this.groupEventsByDay(eventsHour);
      eventsByHourByDay.push(eventsCells);
    }

    return eventsByHourByDay;
  }

  private groupEventsByDay(eventsByHourRow: AgendaEvent[]): AgendaEvent[][] {
    const eventsByDay: AgendaEvent[][] = this.initEventsByDay();

    for (const event of eventsByHourRow) {
      eventsByDay[event.dayOfTheWeek].push(event);
    }
    return ArrayUtil.mapToArray(eventsByDay);
  }

  private initEventsByDay(): AgendaEvent[][] {
    return this.daysColHeader.map(_ => []);
  }

  private insertEventRowIntoArrays(eventRow: AgendaEvent[][]) {
    const firstEvent = ArrayUtil.getFirstNonNullElementFromMap(eventRow);
    const insertTime = firstEvent.activityInitialTimeFormatted;
    let insertIndex = 0;

    while (insertIndex < this.timesRowHeader.length && this.timesRowHeader[insertIndex] < insertTime ) {
        insertIndex++;
    }

    this.fillWithEmptyEventsToFillTable(eventRow);

    ArrayUtil.insertIntoIndex(this.timesRowHeader, insertIndex, insertTime);
    ArrayUtil.insertIntoIndex(this.eventTable, insertIndex, eventRow);
  }

  fillWithEmptyEventsToFillTable(eventRow: AgendaEvent[][]) {
    const maxLenght = eventRow.reduce((max, value) => max = Math.max(max, value.length), 0);
    eventRow.forEach(value => value.splice(value.length, 0, ...Array(maxLenght - value.length).fill({})));
  }

}
