import { Component } from '@angular/core';


@Component({
  selector: 'app-concept-gyms',
  templateUrl: './concept-gyms.component.html',
  styleUrls: [
    '../concept.component.scss',
    './concept-gyms.component.scss',
  ],
})
export class ConceptGymsComponent {}
