import { Component, OnInit } from '@angular/core';
import { ModalCommonComponent } from '../modal-common.component';
import { ModalService } from '@services';
import { ModalType, ModalGymSelectObject } from '@models';

@Component({
  selector: 'app-modal-single-select',
  templateUrl: './modal-single-select.component.html',
  styleUrls: ['./modal-single-select.component.scss'],
})
export class ModalSingleSelectComponent extends ModalCommonComponent implements OnInit {

  gyms: any;
  gymSelected: number;
  data: string[];
  selected: any;
  search: string;
  modalObj: ModalGymSelectObject;

  constructor(
    protected modalService: ModalService,
  ) {
    super();
    this.type = ModalType.singleSelect;
  }

  init() {
    this.gyms = this.modalObj.gyms || [];
    this.gymSelected = this.modalObj.selected;
    this.data = this.modalObj.data || [];
    this.selected = this.modalObj.selected;
  }

  select(item: any) {
    this.modalObj.data = item;
    super.confirm();
  }

}
