<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<div
  *ngIf="formControls.currentStep < 3; else success"
  class="main-wrapper container justify-content-around d-md-flex">

  <div class="form-validate card small-card row-on-xs no-side-border-xs">
    <app-create-account-cpf
      *appLazyCreate="formControls.currentStep == 1"
      [(userForm)]="userForm"
      [(formControls)]="formControls"
      [(validateCpfResponse)]="validateCpfResponse">
    </app-create-account-cpf>

    <app-create-account-form
      *appLazyCreate="formControls.currentStep == 2"
      [(userForm)]="userForm"
      [(formControls)]="formControls"
      [(validateCpfResponse)]="validateCpfResponse">
    </app-create-account-form>
  </div>

</div>

<ng-template #success>
  <app-success-page
    *appLazyCreate="formControls.currentStep == 3"
    buttonName="Login"
    pageTitle="Login"
    title="Sucesso"
    message="Cadastro realizado com sucesso! Em breve você irá receber um e-mail com instruções para ativar a sua conta."
    redirectTo="/cliente/login">
  </app-success-page>
</ng-template>
