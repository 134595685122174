import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { ConceptComponent } from './concept.component';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';


const conceptRoute: Route[] = [
  {
    path: '',
    component: ConceptComponent,
    resolve: {
      config: PortalConfigResolveGuard,
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.config.company.header.title}',
      windowContext: 'concept',
      config: { company: 'mundo-bt' },
      slug: 'concept',
    },
  },
];

@NgModule({
    imports: [RouterModule.forChild(conceptRoute)],
    exports: [RouterModule],
})
export class ConceptRoutingModule { }
