import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { BlogPostResponse } from '@models';
import { HomeBlog } from '@models';
import { BlogService } from '@services/blog.service';
import { map, retry, timeout, pluck } from 'rxjs/operators';

import { PostCarouselComponent } from './post-carousel/post-carousel.component';


@Component({
  selector: 'app-main-blog',
  templateUrl: './main-blog.component.html',
  styleUrls: ['./main-blog.component.scss'],
})
export class MainBlogComponent implements AfterContentInit {

  @Input() data: HomeBlog;

  @ViewChild('carousel', { static: false }) blogCarousel: PostCarouselComponent;

  defaultPost: BlogPostResponse = {
    title: { rendered: 'Carregando' },
    excerpt: { rendered: 'Por favor, aguarde' },
    link: '',
  } as BlogPostResponse;

  stickyPost: BlogPostResponse = this.defaultPost;
  listPost: BlogPostResponse[] = [];
  showStickyPost = true;
  showListPost = true;
  retry = 0;

  constructor(
    private readonly blogService: BlogService,
  ) { }

  ngAfterContentInit() {
    this.getPage();
  }

  getPage() {
    this.defaultPost.title.rendered = 'Carregando';
    this.defaultPost.excerpt.rendered = 'Por favor, aguarde';
    this.getStickyPost();
    this.getCarouselPosts();
  }

  getStickyPost() {
    // The sticky post could be older than 10 first posts, so it need to be queried separeted
    this.blogService.getPostPage(1, 1, 'true')
      .pipe(
        retry(4),
        timeout(15000),
        map(response => response.body),
      )
      .subscribe((data: BlogPostResponse[]) => {
        this.stickyPost = data[0];
      },
      err => {
        this.showStickyPost = false;
        this.catchErrors(err);
      });
  }

  getCarouselPosts() {
    this.blogService.getPostPage(1, 10)
      .pipe(
        retry(4),
        timeout(15000),
        pluck('body'),
      )
      .subscribe(
        (data: BlogPostResponse[]) => {
          this.listPost = this.removeFirstStickyPost(data);
          if (this.listPost.length) {
            this.blogCarousel.configurePostCarousel();
          } else {
            this.showListPost = false;
          }
        },
        err => {
          this.showListPost = false;
          this.catchErrors(err);
        });
  }

  removeFirstStickyPost(listPost: BlogPostResponse[]): BlogPostResponse[] {
    if (!listPost) {
      return [];
    }

    if (!this.stickyPost) {
      return listPost;
    }

    const stickyPost = listPost.findIndex(post => post.id === this.stickyPost.id);
    if (stickyPost > -1) {
      listPost.splice(stickyPost, 1);
      return listPost;
    } else {
      return listPost.slice(0, 9);
    }
  }

  catchErrors(err: any): ArrayLike<any> {
    console.warn('Blog', err);
    this.defaultPost.title.rendered = 'Indisponível';
    this.defaultPost.excerpt.rendered = 'Lamentamos, mas o servidor está temporariamente indisponível';
    return [false];
  }

}
