import { AppConstants } from '@utils/app-constants';
import { Component, Input, OnInit } from '@angular/core';

import { DocuSignStatusDoc, ModalType, MyPurchasesResponse, Purchase } from '@models';
import { LoadingService } from '@services/loading.service';
import { DocuSignService } from '@services/docuSign.service';
import { switchMap } from 'rxjs/operators';
import { PolicyService } from '@services/policy.service';
import { GymService } from '@services/gym.service';
import { ContractSearchType, docuSignContractErrorMessages } from '@utils/docusign-transaction-status.util';
import { ModalService } from '@services/modal.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchase-item',
  templateUrl: './purchase-item.component.html',
  styleUrls: ['../purchases.component.scss'],
})
export class PurchaseItemComponent implements OnInit {
  readonly locale = AppConstants.LOCALE;
  readonly dateFormat = 'dd/MM/yyyy';

  @Input() purchaseItem: MyPurchasesResponse;

  constructor(
    private loadingService: LoadingService,
    private datePipe: DatePipe,
    private docusignService: DocuSignService,
    private modalService: ModalService,
    private policyService: PolicyService,
    private gymService: GymService
  ) { }

  ngOnInit(): void {
    this.purchaseItem.purchases = this.purchaseItem.purchases.map((item) => ({
      ...item,
      date: this.datePipe.transform(item.date, this.dateFormat)
    }));
  }

  getContractUrl(purchaseInfo: Purchase) {
    this.loadingService.startLoading();
    if (purchaseInfo.hasDocuSign) {
      this.getDocusignDocument(purchaseInfo);
    } else {
      this.getPolicyDocument(purchaseInfo);
    }
  }

  getDocusignDocument(purchaseInfo: Purchase) {
    this.docusignService.checkDocusignTransaction(
      purchaseInfo.gymId,
      purchaseInfo.shoppingCartId,
      ContractSearchType.ShoppingCartId
    ).pipe(switchMap((transaction) =>
        this.docusignService.getDocuSignStatus(transaction.CodigoTransacaoDocusign)
      ))
      .subscribe((doc: DocuSignStatusDoc) => {
        const docusignUrl = doc.getStatusDoc?.preSignedPdfUrlS3Bucket;

        docusignUrl
          ? this.openContract(docusignUrl)
          : this.openErrorMessage();
      },
      () => {
        this.getPolicyDocument(purchaseInfo);
        this.loadingService.stopLoading();
      }
    );
  }

  getPolicyDocument(purchaseInfo: Purchase): void {
    let gymSlug = '';
    this.gymService.getGymById(purchaseInfo.gymId)
      .pipe(switchMap((gym) => {
        gymSlug = gym.slug;
        return this.policyService.getTermByGymUnitSlugObservable(gymSlug);
      }))
      .subscribe((doc) => {
        this.openContract(doc[gymSlug].url);
      },
      () => {
        this.loadingService.stopLoading();
      });
  }

  private openContract(contractUrl: string): void {
    this.loadingService.stopLoading();
    window.open(contractUrl);
  }

  private openErrorMessage() {
    this.loadingService.stopLoading();
    const message = docuSignContractErrorMessages.genericUnavailableMessage;
    const title = 'CONTRATO INDISPONÍVEL';

    const modal = {
      type: ModalType.confirm,
      title,
      message,
    };
    this.modalService.show(modal);
  }
}
