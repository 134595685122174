import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-quantity-choicer',
  templateUrl: './product-quantity-choicer.component.html',
  styleUrls: ['./product-quantity-choicer.component.scss'],
})
export class ProductQuantityChoicerComponent {
  static minimumQuantityOfProducts = 1;

  @Output() changeQuantityOfProducts: EventEmitter<number> = new EventEmitter();

  @Input() disableQuantitySelector = false;
  @Input() numberOfProducts = 1;

  constructor() { }

  addProduct() {
    this.numberOfProducts = this.numberOfProducts + 1;
    this.changeQuantityOfProducts.emit(this.numberOfProducts);
  }

  removeProduct() {
    if (this.numberOfProducts > ProductQuantityChoicerComponent.minimumQuantityOfProducts) {
      this.numberOfProducts = this.numberOfProducts - 1;
      this.changeQuantityOfProducts.emit(this.numberOfProducts);
    }
  }
}
