import { Component, EventEmitter, Input, Output } from '@angular/core';

import { GymsByCitiesOfState } from '@models/bt-pass.model';


@Component({
  selector: 'app-select-city-tab',
  templateUrl: './select-city-tab.component.html',
  styleUrls: ['./select-city-tab.component.scss', '../modal-gym-select-by-city.component.scss'],
})
export class SelectCityTabComponent {

  @Input() citiesByState: GymsByCitiesOfState[];
  @Output() selectCityEmitter = new EventEmitter<string>();

  searchCity: string;

  selectCity(city: string): void {
    this.selectCityEmitter.emit(city);
  }
}
