import { Component, HostListener } from '@angular/core';
import { isProduction } from '@env/environment';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent {

  readonly guidelineStorKey = 'debug-guidelines';
  readonly panelStorKey = 'debug-panel';
  readonly mouseOnlyStorKey = 'debug-mouse-info';

  readonly inProd = isProduction;

  debug = sessionStorage.getItem(this.panelStorKey) === 'true';
  guidelines = sessionStorage.getItem(this.guidelineStorKey) === 'true';
  mouseinfo = sessionStorage.getItem(this.mouseOnlyStorKey) === 'true';

  @HostListener('window:keydown', ['$event'])
  keyTrigger(event: KeyboardEvent) {
    if (!isProduction && event.ctrlKey) {
      if (event.code === 'Numpad8') {
        this.toggleGuideline();
        event.preventDefault();
      }
      if (event.code === 'Numpad5') {
        this.toggleDebug();
        event.preventDefault();
      }
      if (event.code === 'Numpad1') {
        this.toggleMouse();
        event.preventDefault();
      }
    }
  }

  toggleDebug() {
    this.debug = !this.debug;
    sessionStorage.setItem(this.panelStorKey, this.debug.toString());
  }

  toggleGuideline() {
    this.guidelines = !this.guidelines;
    sessionStorage.setItem(this.guidelineStorKey, this.guidelines.toString());
  }

  toggleMouse() {
    this.mouseinfo = !this.mouseinfo;
    sessionStorage.setItem(this.mouseOnlyStorKey, this.mouseinfo.toString());
  }

}
