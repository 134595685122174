import { Component, Input } from '@angular/core';
import { Ergometer } from '@models';

@Component({
  selector: 'app-ergometer-table',
  templateUrl: './ergometer-table.component.html',
  styleUrls: ['./ergometer-table.component.scss'],
})
export class ErgometerTableComponent {

  @Input() jsonData: Ergometer[] = [];

}
