<h5 class="title mb-3">{{ groupName }}</h5>

<ng-container *ngIf="activities.length > maxIndexShow; else noAccordionTemplate">
  <div
    class="d-flex flex-column"
    stopContractionInSelector=".stop-contraction-element"
    [(appAccordion)]="activitiesIsContract"
  >
    <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
  </div>

  <button class="btn btn-link align-self-center" (click)="activitiesIsContract = !activitiesIsContract">
    <img src="assets/icons/ic-caret-{{ activitiesIsContract ? 'down' : 'up' }}.svg">

    {{ activitiesIsContract ? 'Ver Todas' : 'Ver Menos' }}
  </button>
</ng-container>

<ng-template #noAccordionTemplate>
  <div class="d-flex flex-column">
    <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #buttonsTemplate>
  <ng-container *ngFor="let activity of activities; let index = index">
    <button
      *ngIf="!activity.inactive"
      class="btn no-btn btn-activity align-self-start"
      [class.stop-contraction-element]="index === maxIndexShow && activities.length > maxIndexShow"
      (click)="showDescriptionModal.emit(activity)"
    >
      {{ activity.name }}
    </button>
  </ng-container>
</ng-template>