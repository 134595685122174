import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';
import { PrivacyCenterRoutingModule } from './privacy-center.routing';

import { PrivacyCenterComponent } from './privacy-center.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    PrivacyCenterRoutingModule,
  ],
  declarations: [
    PrivacyCenterComponent,
  ],
})
export class PrivacyCenterModule { }
