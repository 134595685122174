import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, OperatorFunction } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GymPeriodicityStatusEnum, GymUnitGroupPlan, MembershipModel } from '@models';
import { BtPassPlanInfo, Income } from '@models/bt-pass.model';
import {
  EvoMembershipParamsRequestModel,
  EvoMembershipResponseModel,
  EvoMembershipSplitFunctionParams,
  EvoMembershipsResponseToLegacy,
  MembershipOfferModel,
} from '@models/membership.model';
import { evoBack, EvoBackEndpoints } from '@utils/app-endpoints';
import { EVO_MEMBERSHIP_ORDER_ID } from '@utils/evo';
import { MembershipOfferFeeType, MembershipStatus } from '@utils/gym-util';
import { evoGetAllPagination } from '@utils/rxjs-operators';


@Injectable()
export class EvoMembershipService {

  readonly COMMITMENT = new Map<number, string>([
    [12, 'Anual'],
    [6,  'Semestral'],
    [3,  'Trimestral'],
    [0,  'Débito Mensal'],
    [1,  'Mensal'],
  ]);

  /** Used for analytics. */
  gymName: string;

  /**
   * Has to be `true` when the membership data isn't from EVO.
   *
   * This will render a internal offer link and before `ModalGymPlanComponent` open a `getActivePlansByGymUnit`
   * request it's done.
   *
   * @todo Remove when EVO migration finish.
   */
  legacy = false;

  /**
   * Used for request branches that have access to a membership.
   *
   * @todo Remove when EVO migration finish.
   */
  legacyGymId: number;


  constructor(private readonly http: HttpClient) { }

  /**
   * Convert a response from legacy services, which isn't migrate to EVO, to EVO response alike.
   * This has to be done before use `mapMembershipsToComponent` function.
   *
   * @todo Remove when EVO migration finish.
   */
   convertLegacyToEvo(membershipsLegacy: GymUnitGroupPlan[]): EvoMembershipsResponseToLegacy[] {
    const evoMemberships: EvoMembershipsResponseToLegacy[] = [];

    this.legacy       = true;
    this.legacyGymId  = membershipsLegacy[0].gymUnit.id;

    membershipsLegacy.forEach(membership => {
      evoMemberships.push(
        ...membership.plan.gymUnitPeriodicities.map<EvoMembershipsResponseToLegacy>(offer => {
          return {
            accessBranches: [],
            additionalService: {
              name: 'TAXA DE ADESÃO',
              value: offer.showChannelSubscriptionFee ? offer.subscriptionFee : offer.registration,
            },
            displayName: membership.portalName || membership.plan.shortName,
            maxAmountInstallments: !offer.periodicity.debitedMonthly ? offer.periodicity.months : 0,
            idMembership: membership.plan.id,
            inactive: offer.status === GymPeriodicityStatusEnum.INDISPONIVEL,
            nameMembership: membership.plan.shortName,
            onlineSalesObservations: membership.shortDescription,
            urlSale: `./compra/${ offer.planSlug }/${ offer.periodicity.slug }/dados-pessoais`,
            value: offer.planValue,
          };
        }),
      );
    });

    return evoMemberships;
  }

  getBTPass({
    gymUnitId = '78',
    skip = '0',
    take = '50'
  }: Partial<EvoMembershipParamsRequestModel> = {}): Observable<EvoMembershipResponseModel[]> {
    return this.getMemberships({ gymUnitId, skip, take }).pipe(
      map(response => {
        return response
          .filter(membership => membership.nameMembership.toLocaleUpperCase().includes('BT PASS'))
          .sort((a, b) => a.nameMembership < b.nameMembership ? -1 : 1);
      })
    );
  }

  getKids({
    gymUnitId = null,
    skip = '0',
    take = '50',
  }: Partial<EvoMembershipParamsRequestModel> = {}): Observable<EvoMembershipResponseModel[]> {
    const kidsSubStr = [ 'KIDS', 'BABY' ];

      /** Removes all membership types except from 5X
        * (Bodytech told thats the only type of plan we should use
        * to present on this screen, ignoring the others including 1x,2x,3x...)*/

      /* Removes all Jiu Jitsu Kids memberships */
    const filterByKids = (membership: EvoMembershipResponseModel) => {
      return (kidsSubStr.some(kids => membership.nameMembership.toLocaleUpperCase().includes(kids)) &&
        membership.nameMembership.toLocaleUpperCase().includes('5X') &&
        !membership.nameMembership.toLocaleUpperCase().includes('JIU JITSU'));
    };

    return this.getMemberships({ gymUnitId, skip, take, filter: null }).pipe(
      map(response => {
        return response
          .filter(filterByKids)
          .sort((a, b) => a.nameMembership < b.nameMembership ? -1 : 1);
      }),
      tap(res => console.log(res.filter(item => !item.displayName)))
    );
  }

  /**
   * This service will get all memberships of a gym from EVO database. Recursion was apply to this
   * method because of the service pagination, for each request `skip` attr from `params` will be increased
   * by his total + request response length. The recursion ends when response is empty.
   *
   * @param params - Represent values accept for query params. When use, pay attention to `skip` attr because
   * of his requirement to make pagination work.
   */
  getMemberships({
    gymUnitId = null,
    skip = '0',
    take = '50',
    filter = this.filterUrlSale,
  }: Partial<EvoMembershipParamsRequestModel> = {}): Observable<EvoMembershipResponseModel[]> {
    this.legacy = false;

    const response$ = this.getMembershipsRecursive({ gymUnitId, skip, take });

    return filter ? response$.pipe(map(filter)) : response$;
  }

  mapMembershipsToBTpassGrid(memberships: EvoMembershipResponseModel[]): Income[] {
    return memberships.map((membership) => ({
      id: membership?.idMembership,
      name: membership?.displayName,
      description: membership?.description,
      planName: membership?.nameMembership,
      sessionsNumber: membership?.duration,
      basePrice: membership?.value,
      urlSale: membership?.urlSale,
    }));
  }

  /** Transform a `EvoMembershipResponseModel[]` to `MembershipModel[]`. */
  mapMembershipsToComponent(memberships: EvoMembershipResponseModel[] | EvoMembershipsResponseToLegacy[]): MembershipModel[] {
    /** Removes each BT Pass memberships because they should be show only in BT Pass route. */
    memberships = memberships.filter(membership => !membership.nameMembership.toLocaleUpperCase().includes('BT PASS'));

    const membershipSplitted = this.splitMemberships(memberships);
    const orderedMembership: MembershipModel[] = [];
    const notOrderedMembership: MembershipModel[] = [];

    Object.keys(membershipSplitted).forEach(membershipTitle => {
      const { accessBranches, onlineSalesObservations } = membershipSplitted[membershipTitle][0];
      const salesPage = membershipSplitted[membershipTitle].find(membership => membership.salesPage?.length)?.salesPage[0];

      const baseMembership: Omit<MembershipModel, 'offers'> = {
        analyticsSlug: this.convertTitleToSlug(membershipTitle),
        description: onlineSalesObservations,
        title: membershipTitle,
        moreInfoModal: {
          data: {
            gym: { portalName: membershipTitle },
            gymsAvailableOnPlan: accessBranches.map(branch => ({ portalName: branch.name })),
          },
          description: onlineSalesObservations,
        }
      };

      const offers = this.getMembershipOffers(membershipSplitted[membershipTitle]);

      if (this.legacy) {
        baseMembership.legacy = {
          id: membershipSplitted[membershipTitle][0].idMembership,
        };
      }

      const newMembership = { ...baseMembership, offers };

      if (typeof salesPage?.order === 'number') {
        orderedMembership[salesPage.order] = newMembership;
      } else {
        notOrderedMembership.push(newMembership);
      }
    });

    return orderedMembership.concat(notOrderedMembership).filter(Boolean);
  }

  mapMembershipsToBTPassList(): OperatorFunction<EvoMembershipResponseModel[], BtPassPlanInfo[]> {
    return map(membershipsResponse => {
      return membershipsResponse
        .map<BtPassPlanInfo>(membership => ({
          durationInDays:       membership.duration,
          numberOfPasses:       membership.entries.entriesQuantity,
          planName:             membership.displayName,
          planShortDescription: membership.onlineSalesObservations,
        }));
    });
  }

  private filterUrlSale = (response: EvoMembershipResponseModel[]) => response.filter(membership => membership.urlSale);

  private convertTitleToSlug(title: string): string {
    const regexToReplace = new RegExp(/ /, 'g');

    return title.replace(regexToReplace, '-').toLowerCase();
  }

  private getMembershipsRecursive(
    params: Partial<Omit<EvoMembershipParamsRequestModel, 'filter'>>,
  ): Observable<EvoMembershipResponseModel[]> {
    return this.http.get<EvoMembershipResponseModel[]>(
      evoBack(EvoBackEndpoints.Membership), { params: { ...params } },
    ).pipe(
      evoGetAllPagination(params, this.getMembershipsRecursive.bind(this)),
    );
  }

  private getMembershipOffers(memberships: EvoMembershipResponseModel[]): MembershipOfferModel[] {
    const commitmentsMissing = new Set<number>(this.COMMITMENT.keys());

    const offers: MembershipOfferModel[] = memberships.map<MembershipOfferModel>(membership => {
      commitmentsMissing.delete(membership.maxAmountInstallments || 0);

      return this.mapMembershipOffer(membership);
    });

    commitmentsMissing.forEach(commitmentMissing => {
      const membership = memberships[0];

      membership.maxAmountInstallments = commitmentMissing;
      membership.inactive = true;

      offers.push(this.mapMembershipOffer(membership));
    });

    return offers;
  }

  private getCommitment(membership: EvoMembershipResponseModel): string {
    return membership.nameMembership.toLowerCase().includes('bianual')
      ? 'Bianual'
      : this.COMMITMENT.get(membership.maxAmountInstallments || 0);
  }

  private mapMembershipOffer(membership: EvoMembershipResponseModel): MembershipOfferModel {
    const commitment      = this.getCommitment(membership);
    const membershipTitle = membership.displayName?.toLowerCase();

    return {
      analyticsSlug: this.convertTitleToSlug(`${membershipTitle} ${commitment}`),
      commitment,
      monthlySubscription: !membership.maxAmountInstallments,
      months: membership.maxAmountInstallments,
      prices: {
        feeDescription: MembershipOfferFeeType[
          (membership.additionalService?.value !== null || membership.additionalService?.value !== undefined) &&
          MembershipOfferFeeType[membership.additionalService?.name] &&
          membership.additionalService?.name ||
          'MATRÍCULA'
        ],
        fee: membership.additionalService?.value || 0,
        offer: membership.maxAmountInstallments
                ? membership.value / membership.maxAmountInstallments
                : membership.value,
      },
      status: membership.inactive ? MembershipStatus.Unavailable : MembershipStatus.Available,
      url: membership.urlSale,
    };
  }

  private splitMemberships(memberships: EvoMembershipSplitFunctionParams): { [key: string]: EvoMembershipResponseModel[] } {
    const membershipSplitted = {};

    memberships.forEach(membership => {
      const membershipTitle = membership.displayName?.toLowerCase();

      membership.salesPage = membership.salesPage?.filter(order => order.salesPageDescription === EVO_MEMBERSHIP_ORDER_ID);

      if (!membershipSplitted[membershipTitle]) {
        membershipSplitted[membershipTitle] = [];
      }

      membershipSplitted[membershipTitle].push(membership);
    });

    return membershipSplitted;
  }
}
