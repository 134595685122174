<div class="d-none d-lg-flex flex-column side-menu-container pt-36">

  <div *ngFor="let tab of tabs; let first = first" class="py-13 pl-16 tab cursor-pointer" [routerLink]="[tab.url]" 
  routerLinkActive="active" [class.border-top]="!first" [class.has-icon]="hasIcon" 
  [class.selected]="activeTab === tab" appClickable>
    <img *ngIf="hasIcon"
      class="menu-tab-icon icon"
      [src]="activeTab === tab ? tab.icon+'1.svg' : tab.icon+'2.svg'"
      appClickable>
      <div class="title-section no-bottom-line">
        {{ tab.name }}
      </div>
  </div>
</div>


<div class="d-lg-none d-flex justify-content-center card-block text-center no-rounded-corner pb-0 mb-16">
  <div
  (click)="selectTab()"
  appClickable
  appDropdownArrows
  class="col d-flex dropdown-arrows align-items-center justify-content-around title-md font-weight-normal collapsed mobile-tab">
    <img [alt]="activeTab.name" class="filter-icon" [src]="activeTab.icon+'1.svg'">
    <span>{{ activeTab.name }}</span>
  </div>
</div>

<app-modal-tab-select></app-modal-tab-select>