import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActivityGroupModel, ActivityModel, EvoActivitiesResponseModel, GymActivity } from '@models';
import { EvoBaseParamsModel } from '@models/evo.model';
import { evoBack, EvoBackEndpoints } from '@utils/app-endpoints';
import { evoGetAllPagination } from '@utils/rxjs-operators';

import { ActivityService } from '../activity.service';


@Injectable()
export class EvoGymExperienceService {

  constructor(
    private readonly http: HttpClient,
    private readonly legacyActivityService: ActivityService,
  ) { }

  convertLegacyToEvo(legacyActivities: GymActivity[]): Observable<ActivityGroupModel[]> {
    return this.legacyActivityService.getActiveActivityGroups().pipe(
      map(response => {
        return legacyActivities.map<EvoActivitiesResponseModel>(legacyActivity => {
          const legacyActivityGroup = response.find(activityGroup => activityGroup.id === legacyActivity.activityGroup.id);

          return {
            activityGroup: legacyActivityGroup.name,
            idActivity: legacyActivity.id,
            idActivityGroup: legacyActivity.activityGroup.id,
            name: legacyActivity.name,
            photo: legacyActivityGroup.icon,
            showOnWebsite: true,
          };
        });
      }),
      this.mapToComponent(),
    );
  }

  getActivities({ gymUnitId = null, skip = '0', take = '50' }: EvoBaseParamsModel = {}): Observable<ActivityGroupModel[]> {
    return this.getActivitiesRecursive({ gymUnitId, skip, take }).pipe(this.mapToComponent());
  }

  mapToComponent(): OperatorFunction<EvoActivitiesResponseModel[], ActivityGroupModel[]> {
    return map(activitiesResponse => {
      const activitiesGrouped = activitiesResponse
        .filter(activity => {
          const activeActivity = !activity.inactive && activity.showOnWebsite;

          const { name, activityGroup } = activity;

          const filterKids = (str: string): boolean => str.toLocaleLowerCase().includes('kids 6ª feira');
          const isNotKids = !filterKids(name) && !filterKids(activityGroup);

          return activeActivity && isNotKids;
        })
        .groupBy('idActivityGroup');

      return Object.keys(activitiesGrouped).map<ActivityGroupModel>(idActivityGroup => ({
        activities: this.mapActivities(activitiesGrouped[idActivityGroup]),
        groupName: activitiesGrouped[idActivityGroup][0].activityGroup,
      }));
    });
  }

  private getActivitiesRecursive(params: EvoBaseParamsModel): Observable<EvoActivitiesResponseModel[]> {
    return this.http.get<EvoActivitiesResponseModel[]>(evoBack(EvoBackEndpoints.Activities), { params: { ...params }, }).pipe(
      evoGetAllPagination(params, this.getActivitiesRecursive.bind(this)),
    );
  }

  private mapActivities(activities: EvoActivitiesResponseModel[]): ActivityModel[] {
    return activities.map(activity => ({
      description: activity.description,
      name: activity.name,
      inactive: activity.inactive,
      legacyId: activity.idActivity,
      showOnWebsite: activity.showOnWebsite,
    }));
  }
}
