import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { BehaviorSubject } from 'rxjs';
import { catchError, scan } from 'rxjs/operators';

export class Message {
  public static readonly USER = 'user';
  public static readonly BOT = 'bot';

  constructor(
    public content: string,
    public sendBy: string,
    public when: Date,
  ) { }
}

// tslint:disable-next-line:max-classes-per-file
class BotResponse {
  answer: string;
  code: string;
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class FaqBotService {

  readonly endpoint = sur(SurEndpoints.FaqBot);

  readonly helloRequest = 'oi';
  readonly helloResponse = 'Olá, em que posso ajudar?';

  private readonly _conversation = new BehaviorSubject<Message[]>([]);

  get conversation() {
    return this._conversation.asObservable()
      .pipe(
        scan((acc, val) => acc.concat(val)),
      );
  }

  get conversationValue() {
    return this._conversation.getValue();
  }

  constructor(
    private readonly http: HttpClient,
  ) { }

  startConversation() {
    this.http.get(this.endpoint, { params: { message: this.helloRequest } })
      .pipe(catchError(this.catchError))
      .subscribe((data: BotResponse) => this.botMessage(data));
  }

  // Send a user text input
  converse(msg: string) {
    const userMessage = new Message(msg, Message.USER, new Date());
    this.update(userMessage);

    this.http.get(this.endpoint, { params: { message: msg } })
      .pipe(catchError(this.catchError))
      .subscribe((data: BotResponse) => this.botMessage(data));
  }

  // Handles error situations
  catchError(): any {
    this.botMessage({
      answer: 'Desculpe. O Assistente Virtual está indisponível no momento.',
      code: 'notFound',
    });

    return [];
  }

  // Handles bot's response
  botMessage(data: BotResponse) {
    let message = 'Desculpe, não entendi.';

    if (data.code === 'chatStart') {
      message = this.helloResponse;

    } else if (data.answer) {
      message = data.answer;
    }

    const botMessage = new Message(message, Message.BOT, new Date());
    this.update(botMessage);
  }

  // Update interface
  update(msg: Message) {
    this._conversation.next([msg]);
  }



}
