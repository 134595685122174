import { Component, Input } from '@angular/core';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { GymImagesModel, ModalType } from '@models';
import { GymService, ModalService } from '@services';


@Component({
  selector: 'app-gym-images-carousel',
  templateUrl: './gym-images-carousel.component.html',
  styleUrls: ['./gym-images-carousel.component.scss'],
})
export class GymImagesCarouselComponent {

  @Input() set images(value: GymImagesModel[]) {
    this.carouselImages = this.gymService.getCarouselImages(value);
  }
  @Input() gymName: string;

  private readonly defaultAutoplay = { delay: 6000, disableOnInteraction: false };

  public readonly swiperConfig: SwiperConfigInterface = {
    loop: true,
    autoplay: this.defaultAutoplay,
    speed: 700,
    navigation: true,
    threshold: 10,
  };

  public carouselImages: GymImagesModel[];
  public currentImageIndex = 0;


  constructor(
    private readonly gymService: GymService,
    private readonly modalService: ModalService,
  ) { }

  public showModalGymImages(): void {
    this.swiperConfig.autoplay = false;

    this.modalService.show({
      type: ModalType.fullScreenImage,
      title: '',
      message: '',
      data: {
        currentImage: this.currentImageIndex,
      },
    });
  }

  public onCloseModalGymImages(index: number): void {
    this.currentImageIndex = index;
    this.swiperConfig.autoplay = this.defaultAutoplay;
  }

}
