<div class="post non-sticky card">
  <div class="thumbnail image-wrapper"
      [style.background-image]="'url('+ ( post.loadImage ? ((post.media | async)?.source_url || 'assets/custom_images/img-logo-gray.svg') : 'assets/custom_images/img-logo-gray.svg' ) + ')'">
    <div *ngIf="post?.date" class="date title">
      <div class="day">{{ post.date | date:'dd':'':locale }}</div>
      <div class="month text-uppercase">{{ post.date | date:'MMM':'':locale }}</div>
    </div>
  </div>

  <div class="post-content">
    <a id="blog-post-{{post?.id}}" class="title"
     appClickable
     (click)="clickBlogEntry()"
     (auxclick)="clickBlogEntry()"
     [href]="post?.link"
     rel="noopener"
     target="_blank">
      <span appMaxLines="2" [innerHTML]="post?.title.rendered"></span>
    </a>
  </div>
</div>
