import { Component, OnDestroy, OnInit } from '@angular/core';
import { KidActivity } from '@models';
import { BreakpointsService, screenSizeMap } from '@services/breakpoints.service';
import { LandingKidsService } from '@services/landing-kids.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-activities-list',
  templateUrl: './activities-list.component.html',
  styleUrls: ['./activities-list.component.scss']
})
export class ActivitiesListComponent implements OnInit, OnDestroy {
  private subs: Subscription;

  swiperConfig: SwiperConfigInterface = {
    loop: true,
    navigation: { nextEl: '.activity-next', prevEl: '.activity-prev' },
    pagination: { el: '.swiper-pagination', clickable: true, hideOnClick: false,
      bulletActiveClass: 'swiper-pagination-bullet-dark' },
    spaceBetween: 36,
    speed: 500,
    threshold: 10,
  };

  activitiesList$: Observable<KidActivity[]> = this.landingKidsService.getKidsActivitiesList();

  constructor(
    private landingKidsService: LandingKidsService,
    private breakpointsService: BreakpointsService
  ) { }

  ngOnInit(): void {
    this.subs = this.breakpointsService.emitter.subscribe((size: any) => {
      const w = screenSizeMap(size, [1, 1, 2, 3, 4]);
      Object.assign(this.swiperConfig, { slidesPerView: w, slidesPerGroup: 1 });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
