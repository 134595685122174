<div class="position-relative">
  <div class="input-group-search input-group light-shadow">
    <div class="input-group-prepend">
      <label id="search-input-label" class="input-group-text border-0" for="search-input">
        <img alt="Pesquisar" class="icon-search" src="/assets/icons/ic-search.svg">
      </label>
    </div>

    <input
      #search
      id="search-input"
      type="text"
      autocomplete="off"
      class="form-control text-center auto-height input-min"
      [(ngModel)]="query"
      (keyup.enter)="confirmButton.click()"
      (keyup)="updateQuery($event.target.value)"
      (blur)="blur()"
      aria-label="Pesquisa">

    <div class="input-group-append">
      <span class="input-group-text border-0 p-0">
        <button id="gym-search-confirm"
            #confirmButton
            class="btn btn-primary"
            (click)="confirmClicked()">
          Buscar
        </button>
      </span>
    </div>
  </div>
  <ul *ngIf="focused" class="list-group position-absolute w-100">
    <li *ngIf="loading; else showResults" class="list-group-item list-group-item-action">
      <i class="fas fa-spinner fa-pulse"></i> Carregando...
    </li>
    <ng-template #showResults>
      <li id="gym-suggestion-{{index}}"
          class="list-group-item list-group-item-action"
          *ngFor="let gym of suggestions; let index = index"
          appClickable
          (click)="selectGym(gym.slug)">
        {{ gym.portalName }}
      </li>
      <li *ngIf="suggestions && !suggestions.length" class="list-group-item list-group-item-action text-muted"
          appClickable
          (click)="confirmClicked()">
        Veja as academias mais próximas
      </li>
    </ng-template>
  </ul>
</div>
