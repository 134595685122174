import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { CancelLockingRequest, CancelScheduleRegistrationLockingRequest, SetVacationDCCRequest, SetVacationRequest } from '@models';

@Injectable()
export class RegistrationLockingService {
  constructor(
     private http: HttpClient
  ) {}

  cancelVacationDCC(gymUnitId: number, cancelDCCRequest: CancelLockingRequest) {
    return this.http.post<any>(back(BackEndpoints.cancelVacationDCC, gymUnitId), cancelDCCRequest);
  }

  setVacationDCC(gymUnitId: number, vacationDCCRequest: SetVacationDCCRequest) {
    return this.http.post<any>(back(BackEndpoints.setVacationDCC, gymUnitId), vacationDCCRequest);
  }

  cancelCurrentVacation(gymUnitId: number, cancelOnGoingRequest: CancelLockingRequest) {
    return this.http.post<any>(back(BackEndpoints.cancelCurrentVacation, gymUnitId), cancelOnGoingRequest);
  }

  cancelScheduledVacation(gymUnitId: number, cancelScheduleRequest: CancelScheduleRegistrationLockingRequest) {
    return this.http.post<any>(back(BackEndpoints.cancelScheduledVacation, gymUnitId), cancelScheduleRequest);
  }

  setVacation(gymUnitId: number, vacationRequest: SetVacationRequest) {
    return this.http.post<any>(back(BackEndpoints.setVacation, gymUnitId), vacationRequest);
  }

  consultExtensionData(gymUnitId: number, contractCode: string) {
      return this.http.get<any>(back(BackEndpoints.consultExtensionData, gymUnitId, contractCode));
  }
}
