import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HtppGetOptions } from '@models';


@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  private readonly cachedResponses = new Map<string, any>();


  constructor(private readonly http: HttpClient) { }

  private getHttpParams = (params: HttpParams | HtppGetOptions['params'] ): string => {
    if (!params) { return ''; }

    const httpParams = params instanceof HttpParams
      ? params
      : new HttpParams({ fromObject: params });

    return `?${httpParams.toString()}`;
  }


  public get<T>(url: string, options: HtppGetOptions = {}): Observable<T> {
    const params  = this.getHttpParams(options.params);
    const fullUrl = url + params;

    const data = this.cachedResponses.get(fullUrl);

    return data
      ? of(data)
      : this.http.get<T>(url, options).pipe(
          tap(response => this.cachedResponses.set(fullUrl, response)),
        );
  }
}
