import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EvoGeneralService } from '@services';

import { PopoverFadeModule } from 'app/component-modules/popover-fade/popover-fade.module';
import { SharedModule } from 'app/shared/shared.module';

import { AgendaTimelineIEComponent } from './agenda-timeline-ie/agenda-timeline-ie.component';
import { AgendaTimelineComponent } from './agenda-timeline/agenda-timeline.component';
import { AgendaComponent } from './agenda.component';
import { AgendaRoutingModule } from './agenda.routing';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PopoverFadeModule,

    AgendaRoutingModule,
  ],
  declarations: [
    AgendaComponent,
    AgendaTimelineComponent,
    AgendaTimelineIEComponent,
  ],
  providers: [ EvoGeneralService ],
})
export class AgendaModule { }
