import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-concept-fr',
  templateUrl: './concept-fr.component.html',
  styleUrls: ['./concept-fr.component.scss'],
})
export class ConceptFrComponent implements OnInit {

  @Input() json: any;

  constructor() { }

  ngOnInit() {
  }

}
