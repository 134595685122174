import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, Input } from '@angular/core';
import { ModalType, Gym, ModalGymSelectObject, SelectItem, AlertMessage, MessageMap, User } from '@models';
import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@services/header.service';
import { ModalService } from '@services/modal.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormUtil } from '@utils/form-util';
import { MySchedulesService } from '@services/my-schedules.service';
import { DatePipe } from '@angular/common';
import { ScheduleRequest } from '@models/requests/schedule-request.model';
import { AppConstants } from '@utils/app-constants';
import { LoadingService } from '@services/loading.service';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthService, CustomerService } from '@services';
import { StringUtil } from '@utils/string-util';

@Component({
  selector: 'app-schedules-create',
  templateUrl: './schedules-create.component.html',
  styleUrls: [
    '../../area-user.component.scss',
    './schedules-create.component.scss',
  ],
})
export class SchedulesCreateComponent implements OnInit, AfterViewInit {

  @Input() analyticsCategory: string;
  @Input() analyticsTag: string;

  form: FormGroup;

  config: BasicHeaderConfig;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  gyms: Gym[];
  selectedGym: Gym;
  plan: number;

  availableSchedules: any[];
  dates: SelectItem<any>[];
  hours: SelectItem<{id: number, text: string, value: any}>[];
  user: User;
  hasPersonal = false;
  defaultText = 'Existe um limite de agendamentos diários. O cancelamento pode ser realizado até o horário de início do agendamento.';
  gymAdviceText = this.defaultText;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly modalService: ModalService,
    private readonly mySchedulesService: MySchedulesService,
    private readonly datePipe: DatePipe,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly customerService: CustomerService,
  ) {
    this.config = this.activatedRoute.snapshot.data.config.schedules;
    this.loadingService.startLoading();
    this.customerService.listCustomerOpenGymUnit().subscribe(res => {
      this.gyms = res.gyms;
      this.plan = res.plan;
      this.loadingService.stopLoading();
    }, () => {
      this.loadingService.stopLoading();
    });
  }

  ngOnInit() {
    this.user = this.authService.getUser();

    this.form = this.formBuilder.group({
      gym: new FormControl(null, Validators.required),
      date: new FormControl({ value: null, disabled: true }, Validators.required),
      hour: new FormControl({ value: null, disabled: true }, Validators.required),
    });

  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  getGymUnitAvailableSchedulingMessage(gymId: number) {
    this.mySchedulesService.getGymUnitAvailableSchedulingMessage(gymId).subscribe((res: any) => {
      this.gymAdviceText = res.message || this.defaultText;
    });
  }

  showGymSelectModal() {
    this.modalService.show({
      type: ModalType.gymSelect,
      title: 'Selecione academia',
      message: '',
      confirmCallback: (data: Gym) => {
        this.selectedGym = data;
        this.form.get('gym').setValue(data.portalNameWithState);
        this.setAvailableSchedules(data.id);
        this.getGymUnitAvailableSchedulingMessage(data.id);
      },
      gyms: this.gyms,
      selected: this.selectedGym ? this.selectedGym.id : null,
    } as ModalGymSelectObject);
  }

  setAvailableSchedules(gymUnitId: number) {
    this.loadingService.startLoading();
    this.mySchedulesService.getAvailableHourToScheduleByGymUnitAndPlan(gymUnitId, this.plan).subscribe(schedules => {
      if (schedules) {
        this.availableSchedules = schedules
          .filter((schedule: any) => schedule.gymUnitAvaiabilityHourList.length);
        this.updateAvailableDates();
      }
      this.loadingService.stopLoading();

    }, () => {
      this.loadingService.stopLoading();
    });
  }

  updateAvailableDates() {
    this.resetHour();
    this.dates = this.availableSchedules
      .map((schedule: any) => {
        return {
          id: schedule.dateToSchedule,
          text: this.datePipe.transform(schedule.dateToSchedule, 'dd/MM/yyyy'),
        };
      });

    if (this.dates.length) {
      this.form.get('date').enable();
    } else {
      this.form.get('date').disable();
      this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.AGENDAMENTO_INDISPONIVEL), ['academia']);
    }
  }

  updateAvailableHours() {
    this.resetHour();
    const schedule = this.availableSchedules?.find(
      _schedule => _schedule.dateToSchedule === this.form.get('date').value,
    );
    if (!schedule) { return; }
    this.hours = schedule.gymUnitAvaiabilityHourList.sort((
      a: { formattedInitialTime: string; formattedFinalTime: string; },
      b: { formattedInitialTime: string; formattedFinalTime: string; }) => {
      return StringUtil.strcmp(
        a.formattedInitialTime + ' - ' + a.formattedFinalTime,
        b.formattedInitialTime + ' - ' + b.formattedFinalTime
      );
    }).map((hour: any, index: any) => {
      return {
        // id: hour.id,
        id: index,
        text: hour.formattedInitialTime + ' - ' + hour.formattedFinalTime,
        value: hour,
      };
    });

    if (this.hours.length) {
      this.form.get('hour').enable();
    } else {
      this.form.get('hour').disable();
      this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.AGENDAMENTO_INDISPONIVEL), ['data']);
    }
  }

  resetHour() {
    this.hours = [];
    this.form.controls.hour.reset();
  }

  submit() {
    FormUtil.touchForm(this.form);

    if (this.form.valid) {
      const userRegistration = localStorage.getItem(
        AppConstants.STOR_APP_USER_REGISTRATION,
      );
      const formValue = this.form.value;
      const schedule: ScheduleRequest = {
        gymUnit: {
          id: this.selectedGym.id,
        },
        gymUnitAvaiabilityHour: {
          id: this.hours[formValue.hour].value.id,
        },
        scheduledDate: formValue.date,
        userRegistration: parseInt(userRegistration, 10),
        hasPersonal: this.hasPersonal,
      };

      const obj = this.hours[formValue.hour].value;
      obj.scheduledDate = schedule.scheduledDate;

      this.modalService.show({
        type: ModalType.confirm,
        title: 'AGENDAR HORÁRIO?',
        message: `Deseja realmente agendar o horário ${this.formatScheduleToString(obj)}?`,
        cancelButton: 'Não',
        confirmButton: 'Sim',
        confirmCallback: () => {
          this.loadingService.startLoading();
          this.mySchedulesService.createScheduling(schedule)
            .subscribe(
              result => {
                if (result.errors.length) {
                  result.errors.forEach((error: any) => {
                    this.alertMessageService.showToastr(AlertMessage.error(error.message));
                  });
                  return;
                }
                this.sendDataLayer(+userRegistration, formValue.date);
                this.showSuccessModal();
              },
              err => this.alertMessageService.showToastr(err),
              () => this.loadingService.stopLoading(),
            );
        },
      });
    }
  }

  private showSuccessModal() {
    this.router.navigate(['/cliente/agendamentos']);
    this.modalService.show({
      type: ModalType.confirm,
      title: 'HORÁRIO AGENDADO!',
      message: `Compareça à academia selecionada no horário agendado portando um documento com foto.`,
    });
  }

  private formatScheduleToString(schedule: any) {
    const dateStr = this.datePipe.transform(schedule.scheduledDate, 'dd/MM/yyyy');
    return `entre ${schedule.formattedInitialTime} e ${schedule.formattedFinalTime} no dia ${dateStr}`;
  }


  private sendDataLayer(userRegistration: number, scheduledDate: string) {
    const dataLayer = {
      selectedGym: {
        name: this.selectedGym.name,
        slug: this.selectedGym.slug,
        tag: this.selectedGym.tag,
      },
      scheduledDate,
      user: {
        id: this.user.id,
        registration: userRegistration,
        name: this.user.fullname,
        email: this.user.login,
      },
    };
    window.dataLayer.push(dataLayer);
  }
}
