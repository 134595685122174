import { BasicHeaderConfig } from '@models/configs';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tab } from '@models';
import { HeaderService } from '@services';

import { TabControllerComponent } from 'app/shared/abstracts/tab-controller.component';

@Component({
  templateUrl: './finances.component.html',
  styleUrls: [],
})
export class FinancesComponent extends TabControllerComponent {
  public config: BasicHeaderConfig;

  tabs: Tab[] = [
    { id: 0, name: 'Meu contrato', url: '/cliente/perfil/contratos' },
    { id: 1, name: 'Minhas compras', url: '/cliente/perfil/historico' },
  ];

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly headerService: HeaderService,
  ) {
    super(route, headerService);
    this.config = this.route.snapshot.data.config.finances;
  }

}
