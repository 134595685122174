import { Component, OnInit } from '@angular/core';


import { ProfileData, MessageMap } from '@models';
import { UserProfileService } from '@services/user-profile.service';

import { BrandService } from '@services/brand.service';


@Component({
  selector: 'app-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: [
    './profile-data.component.scss',
    '../profile.shared.scss',
  ],
})
export class ProfileDataComponent implements OnInit {
  info: ProfileData;
  loading = true;

  emptyMessage = MessageMap.INFO_NAO_DISPONIVEIS;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly profileService: UserProfileService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  private getData() {
    this.profileService.getCachedData()
    .subscribe(data => {
      this.loading = false;
      this.info = data;
    },
    () => {
      this.loading = false;
    });
  }

}
