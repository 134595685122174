import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'removeBrand'
})
export class RemoveBrandPipe implements PipeTransform {


  transform(value: string): string {
    const removeString = /Fórmula |Formula |Bodytech /gi;

    return value.replace(removeString, '');
  }
}
