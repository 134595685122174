import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { ContactInfoModel, ModalType } from '@models';
import { EvoPageQueryParams } from '@models/evo.model';
import { BrandService } from '@services/brand.service';
import { EvoGymService } from '@services/evo';
import { FooterService } from '@services/footer.service';
import { HeaderService } from '@services/header.service';
import { ModalService } from '@services/modal.service';
import { AppConstants } from '@utils/app-constants';
import { EvoBaseUrls } from '@utils/evo';


@Component({
  selector: 'app-evo-page',
  templateUrl: './evo-page.component.html',
  styleUrls: ['./evo-page.component.scss']
})
export class EvoPageComponent implements OnInit, OnDestroy {

  brandNameLowerCase: string;
  brand = environment.brand;

  mainGymInfo$: Observable<ContactInfoModel>;

  iframeUrl: SafeUrl;


  constructor(
    private headerService: HeaderService,
    private footerService: FooterService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly brandService: BrandService,
    private readonly evoGymService: EvoGymService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly sanitazer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.brandNameLowerCase = this.brandService.getFullBrandName().toLowerCase();
    this.mainGymInfo$ = this.evoGymService.getGymsContactInfo().pipe(
      map((gyms) => {
        return this.evoGymService.getContactInfoById(
          gyms,
          parseInt(localStorage.getItem(AppConstants.STOR_MAIN_GYM_ID), 10)
        );
      })
    );

    setTimeout(() => {
      this.headerService.showHeader = false;
      this.footerService.showFooter = false;
    });

    this.stylizeBodyTag();
    this.queryParamsHandler();
  }

  ngOnDestroy(): void {
    this.stylizeBodyTag(true);

    setTimeout(() => {
      this.headerService.showHeader = true;
    });
  }

  openAlertModal(): void {
    this.modalService.show({
      title: 'TEM CERTEZA?',
      message: `<p class="text-center mb-0">Você será redirecionado para o Portal ${environment.seo.DEFAULT_TITLE} e, caso esteja logado, será deslogado de sua conta.</p>`,
      type: ModalType.confirm,
      confirmButton: 'Ok',
      confirmCallback: () => {
        this.footerService.showFooter = true;
        this.router.navigate(['/']);
      },
      cancelButton: 'Cancelar',
    });
  }

  openRedirectAdviceModal(): void {
    this.modalService.show({
      title: 'ATENÇÃO',
      message: `
      <p class="text-center mb-0">
        Não é possível prosseguir com esta ação. Caso deseje realizar uma compra procure o balcão da sua academia para mais informações.
      </p>`,
      type: ModalType.error,
    });
  }

  /**
   * Apply or reset initial style in body tag.
   *
   * @param reset - indicate if initial style applied need to be reset.
   */
  private stylizeBodyTag(reset = false): void {
    const body = document.querySelector('body');

    body.style.overflow         = reset ? '' : 'hidden';
    body.style.backgroundColor  = reset ? '' : 'white';
  }

  private queryParamsHandler() {
    const queryParams = this.activatedRoute.snapshot.queryParams as EvoPageQueryParams;

    if (queryParams?.iframeUrl) {
      const queryParamUrl         = window.decodeURIComponent(queryParams.iframeUrl);
      const validEvoBaseUrlRegex  = new RegExp(`^(${EvoBaseUrls.generalBaseUrl})`, 'g');

      this.iframeUrl = validEvoBaseUrlRegex.test(queryParamUrl) ? this.sanitazer.bypassSecurityTrustResourceUrl(queryParamUrl) : '';
    }

    if (queryParams?.isRedirect === 'true') {
      this.openRedirectAdviceModal();
    }
  }

}
