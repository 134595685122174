import { Component, OnInit, Input } from '@angular/core';
import { SimpleLink } from '@models';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-navbar-user-menu',
  templateUrl: './navbar-user-menu.component.html',
  styleUrls: ['./navbar-user-menu.component.scss'],
})
export class NavbarUserMenuComponent implements OnInit {

  isUserLogged = false;
  userName = '';
  userAvatar = '';

  @Input() navbarUserMenu: SimpleLink[];

  constructor(
    public readonly authService: AuthService,
  ) { }

  ngOnInit() {
    const user = this.authService.getUser();
    this.userName = user?.name;
    this.userAvatar = user?.avatar;
  }

  logout() {
    this.authService.logout(null);
  }

}
