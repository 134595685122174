import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {

  @Input() gymName = '';
  @Input() steps = [''];

  @Input() currentStep = 1;
  @Output() currentStepChange = new EventEmitter<number>();

  constructor(
    private readonly analyticsService: AnalyticsService,
  ) { }

  selectClick(step: number) {
    if (step < this.currentStep) {
      this.analyticsService.trackEvent(
        `Volta ao passo ${step} - Marcador de passos`,
        'Vendas',
        `Formulário de vendas - ${this.gymName}`,
      );

      this.currentStepChange.emit(step);
    }
  }

}
