<!-- O CSS desse arquivo está dentro da tag `<style>` do index.html para
  ser possível mostrar esse loading antes mesmo do angular terminar de carregar. -->
<div class="loading-wrapper" [hidden]="!visible">
  <div class="spinner-wrapper">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div class="text-center text-light">Carregando…</div>
  </div>
</div>
