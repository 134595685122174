<div class="card p-4 p-md-5 mb-4 d-flex flex-column flex-md-row row-on-xs">
  <div class="youtube-container p-0 col-12 col-md-7">
    <iframe width="100%" height="100%" [src]="url | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <div class="col align-self-center ml-md-3 pt-4 pt-md-0">
    <div class="title-section">{{ title }}</div>
    <div class="message">
      {{ firstParagraph }}<br><br>
      {{ secondParagraph }}
    </div>
  </div>
</div>