import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';

import { EvoPageRoutingModule } from './evo-page-routing.module';
import { EvoPageComponent } from './evo-page.component';


@NgModule({
  declarations: [
    EvoPageComponent,
  ],
  imports: [
    CommonModule,
    EvoPageRoutingModule,
    RouterModule,
    SharedModule,
  ]
})
export class EvoPageModule { }
