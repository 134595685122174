<nav aria-label="Pagination">
  <ul class="pagination justify-content-center">
    <li class="page-item page-first" [class.disabled]="p == 1" appClickable (click)="getPage(1)">
      <a class="page-link">
        <i class="fas fa-angle-double-left"></i>
        <span class="d-none d-md-inline"></span>
      </a>
    </li>
    <li class="page-item page-back" [class.disabled]="p == 1" appClickable (click)="getPage(p-1)">
      <a class="page-link"><i class="fas fa-angle-left"></i></a>
    </li>
    <li class="page-item disabled" *ngIf="getStart() > 1">
      <a class="page-link">…</a>
    </li>

    <li *ngFor="let i of getRange()" class="page-item page-number" appClickable (click)="getPage(i)" [class.active]="i === p">
      <a class="page-link">{{ i }}</a>
    </li>
    <li *ngIf="!totalpages" class="page-item disabled"><a class="page-link">0</a></li>

    <li class="page-item disabled" *ngIf="getEnd() < totalpages">
      <a class="alert-secondary page-link">…</a>
    </li>
    <li class="page-item page-next" [class.disabled]="p >= totalpages" appClickable (click)="getPage(p+1)">
      <a class="page-link"><i class="fas fa-angle-right"></i></a>
    </li>
    <li class="page-item page-last" [class.disabled]="p >= totalpages" appClickable (click)="getPage(totalpages)">
      <a class="page-link">
        <span class="d-none d-md-inline"></span>
        <i class="fas fa-angle-double-right"></i>
      </a>
    </li>
  </ul>
</nav>
