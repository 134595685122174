<div class="modal fade" bsModal #templateModal="bs-modal" (onShow)="init()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog text-center" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title w-100">{{ modalObj.title }}</div>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center">
          <div id="modal-benefit-{{index}}" class="benefit border cursor-pointer" [class.selected]="benefit.selected"
            *ngFor="let benefit of benefits; let index = index" (click)="benefit.selected = !benefit.selected">
            <div><img [src]="benefit.icon"></div>
            <span class="title modal-title font-weight-normal">{{ benefit.name }}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="modal-benefit-confirm" type="button" class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || "OK" }}
        </button>
      </div>
    </div>
  </div>
</div>
