import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { GalleryComponent, GalleryItem, ImageItem } from '@ngx-gallery/core';

import { GymImagesModel, ModalType } from '@models';
import { BodyClassService, ModalService } from '@services';
import { ModalCommonComponent } from '@sharedcomponents/modal/modal-common.component';


export const slideInAnimation = trigger(
  'slideAnimation',
  [
    state('in', style({transform: 'translateY(0)', opacity: 1})),
    transition(
      ':enter',
      [
        style({transform: 'translateY(-100%)', opacity: 0}),
        animate(400),
      ],
    ),
    transition(
      ':leave',
      [ animate(400, style({transform: 'translateY(-100%)', opacity: 0})) ],
    ),
  ],
);


@Component({
  selector: 'app-modal-gym-images',
  templateUrl: './modal-gym-images.component.html',
  styleUrls: ['./modal-gym-images.component.scss'],
  animations: [ slideInAnimation ],
})
export class ModalGymImagesComponent extends ModalCommonComponent {

  @ViewChild(GalleryComponent, { static: false }) gallery: GalleryComponent;

  @Input() images: GymImagesModel[];
  @Input() gymName: string;

  @Output() closeEvent = new EventEmitter<number>();

  protected type = ModalType.fullScreenImage;

  public currentImage = 0;
  public ready = false;
  public galleryimages: GalleryItem[];


  constructor(
    protected modalService: ModalService,
    private readonly bodyClassService: BodyClassService,
  ) { super(); }

  private mapToGalleryAttributes(image: GymImagesModel): ImageItem {
    return new ImageItem({
      src: image.url,
      thumb: image.url,
      title: `${this.gymName} - ${image.portalDescription}`,
    });
  }

  public init(): void {
    this.currentImage = this.modalObj.data.currentImage;
    this.gallery.set(this.currentImage);

    this.galleryimages = this.images.map(image => this.mapToGalleryAttributes(image));

    this.bodyClassService.setClass('darker-backdrop');

    requestAnimationFrame(() => requestAnimationFrame(() => this.ready = true));
  }

  public close() {
    this.ready = false;
    this.closeEvent.emit(this.currentImage);
  }

  public clear(): void {
    this.bodyClassService.removeClass('darker-backdrop');
  }

}
