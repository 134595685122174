<h3 class="title-section">Meus Pedidos em Pré-Venda</h3>
<table class="table table-flush table-striped">
  <thead>
    <tr class="title">
      <th>Data</th>
      <th>Código</th>
      <th>Unidade</th>
      <th>Valor</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let preSale of item">
      <td>{{ preSale.date | date:'shortDate':'':locale }}</td>
      <td>{{ preSale.code }}</td>
      <td>{{ preSale.gymUnit }}</td>
      <td>{{ preSale.value | currency:'BRL':'symbol':'':locale }}</td>
      <td>{{ preSale.stats }}</td>
      <td><a (click)="detail(preSale)" href="javascript:void(0)">Detalhes</a></td>
    </tr>
  </tbody>
</table>