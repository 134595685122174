<div class="d-flex flex-column justify-content-between h-100">
  <div class="pr-2">
    <p class="m-0">{{ contactInfo.address }}, {{ contactInfo.number }}, {{ contactInfo.neighborhood }}</p>
    <p class="m-0">{{ contactInfo.city }}, {{ contactInfo.stateShort }}</p>
    <p class="m-0">CEP: {{ contactInfo.zipCode | format: 'zip' }}</p>
  </div>

  <div class="d-flex mt-2">
    <ng-content></ng-content>

    <a
      *ngIf="contactInfo.telephone"
      class="d-flex link-small"
      (click)="clickTelephone()"
      (auxclick)="clickTelephone()"
      [href]="'tel:0' + contactInfo.telephone"
    >
      <img
        class="mr-1 icon"
        alt="Ícone de telefone"
        src="assets/icons_custom/ic-link-phone.svg"
      >
      <span>{{ contactInfo.telephone }}</span>
    </a>
  </div>
</div>
