<app-modal-credit-card-info></app-modal-credit-card-info>

<ng-template #header>
  <app-header-static-image pageTitle="Cancelamento" [prefixBucket]="false" imageUrl="assets/headers/image4.jpg"></app-header-static-image>
</ng-template>

<main class="main-wrapper container" *ngIf="stepToShow !== 'success' && stepToShow !== 'error'">
  <div class="card row-on-xs no-side-border-xs">
    <div class="tab-content">
      <div>
        Lamentamos que você queira cancelar seu contrato.<br>
        Leia atentamente as instruções abaixo e confira se todas as informações estão corretas:
      </div>

      <div class="block-info row no-gutters border">
        <div class="col-12 col-md border-right">
          <label class="label-input">Código de Compra</label>
          <div>{{ info.contract.purchaseCode }}</div>

          <label class="label-input">Plano</label>
          <div>{{ info.contract.plan }}</div>

          <label class="label-input">Data de inicio</label>
          <div>{{ info.contract.dates.start | date:'shortDate':'':locale }}</div>
        </div>

        <div class="col-12 col-md border-right">
          <hr class="d-md-none">

          <label class="label-input">Periodicidade</label>
          <div>{{ info.contract.deadline }}</div>

          <label class="label-input">Frequência</label>
          <div>{{ info.contract.frequency }}</div>
        </div>

        <div class="col-12 col-md border-right">
          <hr class="d-md-none">

          <label class="label-input">Valor do plano</label>
          <div>{{ info.contract.planValue | currency:'BRL':'symbol':'':locale }}</div>

          <label class="label-input">Serviços adicionais</label>
          <div>{{ info.contract.servicesValue | currency:'BRL':'symbol':'':locale }}</div>

          <label class="label-input">Valor total</label>
          <div>{{ info.contract.planValue + info.contract.servicesValue | currency:'BRL':'symbol':'':locale }}</div>
        </div>
      </div>
      <form #formData="ngForm" class="form-validate">
        <div class="alert alert-warning mt-4" *ngIf="!info.data.requestPayment && info.data.isRecurrent">
          Lembramos que o pedido de cancelamento deve ser feito com antecedência mínima de 15 dias da data prevista para o próximo débito,
          sob pena de cobrança desta mensalidade.
        </div>

        <ng-container *ngIf="info.data.requestPayment">
          <div class="title section">Política de cancelamento</div>
          <div>
            <div *ngIf="info.data.isRecurrent">
              A forma de pagamento do seu plano (Débito Mensal no Cartão de Crédito ou DCC) tem uma carência de {{ numberDebts }} débitos.<br>
              Caso você cancele 7 dias após a data da sua matrícula online e dentro do período de carência,
              será cobrada uma multa equivalente a {{ info.contract.gym.address.city.state.initials === 'SP' ? '20%' : '30%' }} do valor total que
              falta para completar os {{ numberDebts }} débitos devidos.<br>
            </div>
            <div class="alert alert-warning">
              Lembramos que o pedido de cancelamento deve ser feito com antecedência mínima de 15 dias da data prevista para o próximo débito,
              sob pena de cobrança desta mensalidade, além da multa.
            </div>
          </div>

          <div class="block-info row no-gutters border">
            <div class="col-12 col-md border-right">
              <label class="label-input">Valor Pago</label>
              <div>{{ info.data.valuePayed | currency:'BRL':'symbol':'':locale }}</div>

              <label class="label-input">Valor Restante</label>
              <div>{{ info.data.valueRemaining | currency:'BRL':'symbol':'':locale }}</div>

              <label class="label-input">Multa por cancelamento antecipado</label>
              <div class="font-weight-bold">{{ info.data.cancelTax | currency:'BRL':'symbol':'':locale }}</div>
            </div>

            <div class="col-12 col-md border-right">
              <hr class="d-md-none">

              <label class="label-input">Data de Início</label>
              <div>{{ info.data.dateStart | date:'shortDate':'':locale }}</div>

              <label class="label-input">Data de Cancelamento</label>
              <div>{{ info.data.dateCancel | date:'shortDate':'':locale }}</div>

              <label class="label-input">Data Mínima Acordada</label>
              <div>{{ info.data.dateMin | date:'shortDate':'':locale }}</div>
            </div>
          </div>

          <div>
            <span *ngIf="info.data.isRecurrent">
              Aviso: sua forma de pagamento DCC requer um prazo mínimo para cancelamento de {{ numberDebts }} meses.
            </span>
              Como o cancelamento está sendo feito num período inferior, é aplicável uma multa ao cancelamento.
              Se deseja cancelar mesmo assim, preencha os dados abaixo.
          </div>

          <div class="section">
            <div class="title">Débito recorrente em cartão de crédito</div>
            <div>
              O cartão de crédito é o único meio de pagamento para concluir o cancelamento online do contrato.<br>
              O lançamento do valor do cancelamento do plano será feito em sua fatura de cartão de crédito.
            </div>
            <div class="row">
              <div class="col-12 col-md">
                <div class="row small-padding">
                  <div class="col-12 col-sm-4 col-md-6">
                    <label for="payment-name" class="label-input">Nome impresso no cartão</label>
                    <input #ccOwnerName="ngModel" id="payment-name" name="cardOwner"
                      class="form-control"
                      autocomplete="cc-name"
                      required
                      [disabled]="info.payment.justClick"
                      [(ngModel)]="info.payment.cardOwnerName">
                    <app-error-message [control]="ccOwnerName"></app-error-message>
                  </div>
                  <div class="col-12 col-sm-4 col-md-6">
                    <label for="payment-card" class="label-input">Número do cartão</label>
                    <input #ccNumber="ngModel" id="payment-card" name="cardNumber"
                      class="form-control"
                      autocomplete="cc-number"
                      required
                      minlength="14" maxlength="19" onlyNumber
                      [disabled]="info.payment.justClick"
                      [(ngModel)]="info.payment.cardNumber">
                    <app-error-message [control]="ccNumber"></app-error-message>
                  </div>
                </div>
                <div class="row small-padding">
                  <div class="col-6">
                    <label for="card-expiring-month" class="label-input">Validade</label>
                    <ng-select 
                    [disabled]="info.payment.justClick"
                      [(ngModel)]="info.payment.cardExpiringMonth"
                      [items]="months" 
                      [searchable]="false" 
                      #cardExpiringMonth="ngModel"
                      name="cardExpiringMonth"
                      [clearable]="false"
                      [selectOnTab]="true" 
                      placeholder="Mês" 
                      required
                    ></ng-select>
                    <app-error-message [control]="cardExpiringMonth.control"></app-error-message>
                  </div>
                  <div class="col-6">
                    <label for="card-expiring-year" class="label-input">&nbsp;</label>
                    <ng-select
                      [disabled]="info.payment.justClick"
                      [(ngModel)]="info.payment.cardExpiringYear"
                      [items]="years" 
                      [searchable]="false" 
                      #cardExpiringYear="ngModel"
                      name="cardExpiringYear"
                      [clearable]="false"
                      [selectOnTab]="true" 
                      placeholder="Ano" 
                      required
                    ></ng-select>
                    <app-error-message [control]="cardExpiringYear.control"></app-error-message>
                  </div>
                </div>
                <div class="row small-padding">
                  <div class="col-12 col-sm-4 col-md-6">
                    <label for="payment-flag" class="label-input">Bandeira</label>
                    <app-select id="payment-flag"
                      name="payment-flag" #ccFlag="ngModel"
                      class="form-custom"
                      textForUndefined="Selecione"
                      [(ngModel)]="info.payment.cardFlag"
                      [list]="info.flagOptions"
                      [disabled]="info.payment.justClick"
                      ngDefaultControl
                      required></app-select>
                      <app-error-message [control]="ccFlag"></app-error-message>
                  </div>
                  <div class="col-12 col-sm-4 col-md-6">
                    <label for="payment-cvv" class="label-input">
                      CVV <i class="fa fa-question-circle question-icon"  (click)="openCVVModal()" aria-hidden="true"></i>
                    </label>
                    <input #ccCVV="ngModel" id="payment-cvv" name="cardCVV"
                      class="form-control"
                      autocomplete="cc-csc"
                      required
                      minlength="2" maxlength="4" onlyNumber
                      [(ngModel)]="info.payment.cardCVV">
                    <app-error-message [control]="ccCVV"></app-error-message>
                  </div>
                </div>

              </div>
            </div>
            <div class="section row" *ngIf="justClickPayment">
              <div class="col-md-6">
                <app-checkbox
                  name="one-click-shop"
                  [(check)]="info.payment.justClick"
                  (checkChange)="prefillJustClick($event)"
                  message="Usar dados do meu cartão salvos no 1-Click Shop"
                ></app-checkbox>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="section">
          Confirme seu e-mail e informe a sua senha
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <label for="login-email" class="label-input">E-mail</label>
            <input id="login-email"
              class="form-control"
              [value]="user.login"
              autocomplete="off"
              disabled>
          </div>
          <div class="col-12 col-md-6">
            <label for="login-password" class="label-input">Senha</label>
            <input #password="ngModel" id="login-password" name="password"
              type="password"
              class="form-control"
              autocomplete="current-password"
              required
              [(ngModel)]="user.password">
            <app-error-message [control]="password"></app-error-message>
          </div>
        </div>

        <div class="text-center button-container">
          <a id="go-back" routerLink="/cliente/perfil/contratos"
            class="btn btn-outline-primary btn-confirm">
            Voltar para contratos
          </a>
          <button id="confirm-cancel" class="btn btn-primary btn-confirm"
            (click)="confirmCancel()">
            Confirmar cancelamento
          </button>
        </div>
      </form>
    </div>
  </div>
</main>

<app-success-page
  *ngIf="stepToShow == 'success'"
  title="Seu plano foi cancelado com sucesso"
  message="O seu plano já foi cancelado e em breve você receberá um e-mail com mais informações. Caso você tenha algum estorno a receber, a unidade entrará em contato. Se quiser agilizar esse processo, ligue para a sua unidade ou dirija-se à recepção."
  buttonName="Voltar para contratos"
  redirectTo="/cliente/perfil/contratos"
></app-success-page>

<main class="main-wrapper container error-container" *ngIf="stepToShow == 'error'">
  <app-empty-card
    icon="/assets/icons_custom/ic-empty-list.svg"
    altIcon="Contrato não pode ser cancelado"
    [message]="reason || (info.error.error.message | slice:8)"
    buttonText="Ir para academia"
    [buttonLink]="['/academia', info.contract.gym.slug]">
    <ng-template #cardButtons>
      <a *ngIf="info.contract" id="error-go-gym" routerLink="/cliente/perfil/contratos"
        class="btn btn-outline-primary btn-confirm">
        Voltar para contratos
      </a>
    </ng-template>
  </app-empty-card>
</main>
