import { Component, ContentChild, Input, TemplateRef } from '@angular/core';



@Component({
  selector: 'app-empty-card',
  templateUrl: './empty-card.component.html',
  styleUrls: ['./empty-card.component.scss'],
})
export class EmptyCardComponent {

  @ContentChild('cardHeader', { static: false, read: TemplateRef }) cardHeader: TemplateRef<any>;
  @ContentChild('cardButtons', { static: false, read: TemplateRef }) cardButtons: TemplateRef<any>;
  @ContentChild('cardFooter', { static: false, read: TemplateRef }) cardFooter: TemplateRef<any>;
  @Input() message = '';

  @Input() icon = '';
  @Input() altIcon = '';

  @Input() buttonText = '';
  @Input() buttonLink = '';
  @Input() btnDisabled = false;

  @Input() hideBorderTop = false;
  @Input() hideBorderBottom = false;

}
