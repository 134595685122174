<div class="row social-login-container">
  <div class="col-12 col-md-6 col-left">
    <button id="login-facebook" class="d-flex justify-content-between w-100 btn btn-social btn-facebook"
        (click)="login('facebook')">
      <i class="fa-fw fab fa-facebook-f align-self-center"></i>
      <span class="social-btn-text">Login com facebook</span>
    </button>
  </div>
  <div class="col-12 col-md-6 col-right">
    <button id="login-google" class="d-flex justify-content-between w-100 btn btn-social btn-google"
        (click)="login('google')">
      <i class="fa-fw fab fa-google align-self-center"></i>
      <span class="social-btn-text">Login com Google</span>
    </button>
  </div>
</div>
