import { Injectable } from '@angular/core';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { PriceRange } from '@models';
import { Observable } from 'rxjs';

@Injectable()
export class PriceService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getMinMaxPrice(): Observable<PriceRange> {
    return this.apollo.query<PriceRange[]>({
      query: AppGraphql.queryListPlanViewMinAndMaxTranchesValues,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<any[]>('listPlanViewMinAndMaxTranchesValues')),
      map(result => {
        return {
          min: parseInt(result[0].gymUnitPeriodicityTrancheValue, 10),
          max: parseInt(result[1].gymUnitPeriodicityTrancheValue, 10),
        };
      }),
    );
  }
}
