import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { HomeSearch } from '@models';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'app-main-gym',
  templateUrl: './main-gym.component.html',
  styleUrls: ['./main-gym.component.scss'],
})
export class MainGymComponent {

  @Input() data: HomeSearch;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly route: Router,
  ) { }

  doSearch(event: string) {
    this.analyticsService.trackEvent(
      `Busca: "${event}"`,
      'Home',
      'Busca',
    );

    this.route.navigate(['academias'], {queryParams: {q: event}});
  }

}
