<app-modal-credit-card-info></app-modal-credit-card-info>

<form #formAdditional="ngForm" class="form">
  <app-data-address #formAddress name="address" *ngIf="formAdditionalData.address"
    [(address)]="formAdditionalData.address"></app-data-address>
  <div class="title title-section section">Pagamento (Cartão de Crédito)</div>

  <div class="row">
    <div class="colx-12 col-md">
      <div class="row small-padding">
        <div class="col-sm-12 col-md-6">
          <label for="payment-card" class="label-input">Número do cartão</label>
          <input id="payment-card" name="cardNumber" #cardNumber="ngModel" class="form-control" required minlength="14"
            maxlength="19" onlyNumber autocomplete="cc-number" [(ngModel)]="formAdditionalData.payment.cardNumber">
          <app-error-message [control]="cardNumber"></app-error-message>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="row small-padding">
            <div class="col-6">
              <label for="card-expiring-month" class="label-input">Validade</label>
              <ng-select 
                [(ngModel)]="formAdditionalData.payment.cardExpiringMonth"
                [items]="listMounths" 
                [searchable]="false" 
                #cardExpiringMonth="ngModel"
                name="cardExpiringMonth"
                [clearable]="false"
                [selectOnTab]="true" 
                placeholder="Mês" 
                required
              ></ng-select>
              <app-error-message [control]="cardExpiringMonth.control"></app-error-message>
            </div>
            <div class="col-6">
              <label for="card-expiring-year" class="label-input">&nbsp;</label>
              <ng-select 
                [(ngModel)]="formAdditionalData.payment.cardExpiringYear"
                [items]="listYears" 
                [searchable]="false" 
                #cardExpiringYear="ngModel"
                name="cardExpiringYear"
                [clearable]="false"
                [selectOnTab]="true" 
                placeholder="Ano" 
                required
              ></ng-select>
              <app-error-message [control]="cardExpiringYear.control"></app-error-message>
            </div>
          </div>
        </div>

      </div>
      <div class="row small-padding">
        <div class="col-sm-12 col-md-6">
          <label for="payment-name" class="label-input">Nome impresso no cartão</label>
          <input id="payment-name" name="cardOwner" #cardOwner="ngModel" class="form-control" required
            autocomplete="cc-name" [(ngModel)]="formAdditionalData.payment.cardOwnerName">
          <app-error-message [control]="cardOwner"></app-error-message>
        </div>
        <div class="col-sm-12 col-md-3">
          <label for="payment-flag" class="label-input">Bandeira</label>
          <app-select id="payment-flag" name="payment-flag" #cardFlag="ngModel" class="form-custom"
            textForUndefined="Selecione" [(ngModel)]="formAdditionalData.payment.cardFlag"
            (ngModelChange)="selectPaymentFlag($event)" [list]="flagCardOptions" ngDefaultControl required></app-select>
          <app-error-message [control]="cardFlag"></app-error-message>
        </div>
        <div class="col-sm-12 col-md-3">
          <label for="payment-cvv" class="label-input">
            CVV <i class="fa fa-question-circle question-icon"  (click)="openCVVModal()" aria-hidden="true"></i>
          </label>
          <input id="payment-cvv" name="cardCVV" #cardCVV="ngModel" class="form-control" required minlength="2"
            maxlength="4" onlyNumber autocomplete="cc-csc" [(ngModel)]="formAdditionalData.payment.cardCVV">
          <app-error-message [control]="cardCVV"></app-error-message>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">  
          <label for="payment-parcels" class="label-input">Parcelas</label>
          <app-select
            ngDefaultControl
            required
            class="form-custom"
            id="payment-parcels"
            name="parcels"
            [list]="parcelOptions"
            [textForDisabledUndefined]="'Selecione uma bandeira'"
            (ngModelChange)="parcelChange($event)" 
            [(ngModel)]="formAdditionalData.payment.parcels"
            #cardParcels="ngModel"
          ></app-select>
          <app-error-message [control]="cardParcels"></app-error-message>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <app-checkbox name="accept" [(check)]="accept" class="form-custom w-100" [ngModel]="accept ? true : null" required
      ngDefaultControl message="Declaro que estou ciente e concordo com o termo de adesão"></app-checkbox>
  </div>

  <div class="link-section text-center" *ngIf="adhesionUrl">
    <a id="payment-accept-adhesion" class="title link" [href]="adhesionUrl" target="_blank" rel="noopener">Conheça o
      Termo de Adesão</a>
  </div>

</form>