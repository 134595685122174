import { Component, Input, OnChanges } from '@angular/core';
import { BlogPostResponse } from '@models';
import { AnalyticsService } from '@services/analytics.service';
import { BlogService } from '@services/blog.service';
import { AppConstants } from '@utils/app-constants';

@Component({
  selector: 'app-post-carousel-item',
  templateUrl: './post-carousel-item.component.html',
  styleUrls: ['../../common-styles.scss', './post-carousel-item.component.scss'],
})
export class PostCarouselItemComponent implements OnChanges {

  readonly locale = AppConstants.LOCALE;
  @Input() post: BlogPostResponse;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly blogService: BlogService,
  ) { }

  ngOnChanges() {
    if (this.post) {
      this.updateFeaturedMedia();
    }
  }

  updateFeaturedMedia() {
    if (this.post.featured_media > 0) {
      this.post.media = this.blogService.getMedia(this.post.featured_media);
    }
  }

  clickBlogEntry() {
    this.analyticsService.trackEvent(
      'Blog - Carrossel',
      'Home',
      'Blog',
    );
  }
}
