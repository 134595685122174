<div class="card d-block no-side-border-xs h-100">
  <ng-container *ngIf="carouselImages?.length; else emptyImages">
    <button
      class="border-0 expand d-flex justify-content-center align-items-center text-white"
      (click)="showModalGymImages()"
    >
      <img src="assets/icons/ic-fullscreen.svg">
    </button>

    <swiper [config]="swiperConfig" [index]="currentImageIndex" (indexChange)="currentImageIndex = $event">
      <div class="swiper-slide d-flex flex-column" *ngFor="let image of carouselImages">
        <img class="gym-image flex-grow-1 w-100" [src]="image.url">

        <div class="title text-center font-weight-normal">
          {{ image.portalDescription }}
        </div>
      </div>
    </swiper>
  </ng-container>
</div>

<ng-template #emptyImages>
  <div class="d-flex justify-content-center align-items-center text-center h-100 p-5 p-md-0">
    <app-error display="column"></app-error>
  </div>
</ng-template>

<app-modal-gym-images
  [images]="carouselImages"
  [gymName]="gymName"
  (closeEvent)="onCloseModalGymImages($event)"
></app-modal-gym-images>
