import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TicketComponent } from './ticket.component';

const btRoute = [
  {
    path: '',
    resolve: {
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.imageHeader.title}',
      windowContext: 'ticket',
      slug: 'ticket',
    },
    children: [
      {
        path: '',
        component: TicketComponent,
      },
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(btRoute)],
  exports: [RouterModule],
})
export class TicketRoutingModule { }
