<div class="card text-center h-100 pt-3 px-3 pb-0">
  <h3 class="title-section no-bottom-line">{{ localMembership.title }}</h3>

  <p
    class="membership-description flex-fill mx-3 mb-0"
    [innerHTML]="localMembership.description | safeHtml"
  ></p>

  <button
    *ngIf="!hideMoreInfo"
    class="btn btn-link align-self-center text-primary text-underline p-0"
    id="plan-{{localMembership.analyticsSlug}}-info"
    (click)="showMoreInfoModal()"
  >
    Mais informações
  </button>

  <app-membership-offer-price
    [hasSubscribeButton]="hasSubscribeButton"
    [highlightOffer]="true"
    [legacy]="evoMembershipService.legacy"
    [membershipOffer]="highlightOffer"
    [membershipTitle]="membership.title"
    [specialOfferLabel]="highlightOffer.specialOffer ? 'mais vendido' : null"
    (clickJoinButton)="clickJoinButtonTrackAnalytics(highlightOffer.commitment)"
  ></app-membership-offer-price>

  <div [(appAccordion)]="offersIsContract">
    <ng-container *ngFor="let offer of localMembership.offers">
      <app-membership-offer-price
        [hasSubscribeButton]="hasSubscribeButton"
        [legacy]="evoMembershipService.legacy"
        [membershipOffer]="offer"
        [membershipTitle]="localMembership.title"
        (clickJoinButton)="clickJoinButtonTrackAnalytics(offer.commitment)"
      ></app-membership-offer-price>
    </ng-container>
  </div>

  <button
    class="btn no-btn btn-show-more d-flex justify-content-center align-items-center title font-weight-normal mx-n3"
    id="plan-{{localMembership.analyticsSlug}}-more"
    [class.offers-contract]="offersIsContract"
    (click)="clickShowMoreButton()"
  >
    <img class="img-fluid" src="assets/icons/ic-caret-up.svg">

    {{ !offersIsContract ? 'Ver menos' : 'Ver mais' }}
  </button>
</div>
